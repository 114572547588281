<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-history"></i>{{profile.owners.agreementflow.agreement_flow}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <section class="modal-search" :class="{active: searchActive}">
                    <form v-on:submit.prevent>
                        <input type="text" data-testid="agreementflowListInput" :placeholder="profile.incident_detail.search_agreement_items" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" />
                        <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                    </form>
                </section>
                <table class="table table-striped" v-if="!searchActive || (searchActive && searchSuggestions.length)">
                    <thead>
                        <tr>
                            <th scope="col" @click="sortTable('id_incident')">{{profile.incident_detail.number}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('createdDate')">{{profile.dashboard.building_manager.message_table.date}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('description')">{{profile.incidents.incidenttable.description}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('place_name')">{{profile.dashboard.building_manager.message_table.place}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('address')">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in agreementFlowItems" :key="item.id_incident" @click="toIncident(item.id_incident)" v-show="!searchActive">
                            <td>{{item.id_incident}}</td>
                            <td>{{item.createdDate}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.place_name}}</td>
                            <td>{{item.address}}</td>
                        </tr>
                        <tr v-for="(item, index) in searchSuggestions" :key="index" @click="toIncident(item.id_incident)" v-show="searchActive">
                            <td data-testid="agreementflowListId">{{item.id_incident}}</td>
                            <td data-testid="agreementflowListDate">{{item.createdDate}}</td>
                            <td data-testid="agreementflowListDescription">{{item.description}}</td>
                            <td data-testid="agreementflowListLocation">{{item.place_name}}</td>
                            <td data-testid="agreementflowListAddress">{{item.address}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="notification notification--warning" v-if="searchActive && !searchSuggestions.length">{{profile.dashboard.building_manager.no_resultss}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export default {
    mixins: [setLocale],
    name: 'agreementFlowItems',
    data() {
        return {
            profile: {},
            searchSuggestions: [],
            searchTerm: '',
            searchActive: false
        }
    },
    props: ['agreementFlowItems'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.agreementFlowItems = sortBy(this.agreementFlowItems, sorter);
            if (this.sortOrder === 'desc') {
                this.agreementFlowItems.reverse();
            }
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.agreementFlowItems.length; i++) {
                    let item = this.agreementFlowItems[i];
                    let itemDescr = item.description.toLowerCase();
                    let itemId = item.id_incident.toString();
                    let itemPlaceName = item.place_name.toLowerCase();
                    let itemAddress = item.address.toLowerCase();
                    if (itemDescr.includes(searchTerm) || itemId.includes(searchTerm) || itemPlaceName.includes(searchTerm) || itemAddress.includes(searchTerm)) {
                        this.searchSuggestions.push(item);
                    }
                }
            }
            else {
                this.searchActive = false;
            }
            this.searchSuggestions = uniqBy(this.searchSuggestions, 'id_incident');
            if($event.keyCode === 27) {
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        toIncident(id) {
            this.$emit('close');
            this.$router.replace({ path: '/buildingmanager/incident-detail', query: { id: id} });
        }
    }
}
</script>
