<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-trash-alt"></i> {{profile.invoicing.remove_invoice}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{ profile.invoicing.remove_invoice_sure }} <b>{{ selectedInvoice.invoice_number }}</b> {{ profile.invoicing.remove_invoice_sure2 }}</p>
                <button class="button button--icon button--right" v-on:click="removeInvoice()"><i class="fas fa-trash-alt"></i>{{profile.invoicing.remove_invoice}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';

export default {
    mixins: [setLocale],
    props: ['selectedInvoice', 'incidentInfo'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getInvoices() {
            let self = this;
            fetch('/api/v1/order/getsupplierinvoices?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        let invoices = [];
                        for (var i = 0; i < data.length; i++) {
                            let invoice = data[i];
                            if (self.locale === 'nl') {
                                invoice.date = moment(invoice.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                                invoice.description = 'Factuur-' + invoice.invoice_number;
                            }
                            else {
                                invoice.date = moment(invoice.created).format('MMM D YYYY HH:mm:ss');
                                invoice.description = 'Invoice-' + invoice.invoice_number;
                            }
                            if (invoice.invoice_value && invoice.invoice_value.toString().includes('.')) {
                                invoice.invoice_value = (Math.round(invoice.invoice_value * 100) / 100).toFixed(2);
                                invoice.invoice_value = invoice.invoice_value.toString().replaceAll('.', ',');
                            } 
                            invoices.push(invoice);
                        }
                        self.invoiceList = invoices;
                        self.$store.dispatch('updateInvoices', self.invoiceList);
                    }                 
                });
            });
        },
        removeInvoice() {
            this.removeInvoiceValues(this.selectedInvoice.id_supplier_invoice);      
            const params = new URLSearchParams();
            params.append('id_incident_invoice', this.selectedInvoice.id_supplier_invoice);
            let self = this;
            fetch('/api/v1/order/removesupplierinvoice', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.getInvoices();
            });
        },
        removeInvoiceValues(id) {
            const data = new URLSearchParams();
            data.append('id_incident_invoice', id);
            data.append('invoice_value_supplier', '');
            data.append('invoice_number_supplier', '');
            let self = this;
            fetch('/api/v1/order/updatesupplierinvoice', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
            });
        }
    }
}
</script>
