<template>
    <div>
        <div class="button-header">
            <div class="incident-head">
                <h1>{{profile.incident_detail.plan_appointment}}</h1>
            </div>
            <div class="row">
                <div class="col">
                    <button class="button button--outline today" @click="today()" style="float: left;">Vandaag</button>
                    <div class="view-buttons">
                        <button class="button button--outline switch-button" @click="changeView('week')" style="float: left;">Week</button>
                        <button class="button button--outline switch-button" @click="changeView('day')" style="float: left;">Dag</button>
                    </div>
                    <button class="button button--outline next" @click="next()" style="float: right; margin-left: 5px;"><i class="fas fa-chevron-right"></i></button>
                    <button class="button button--outline prev" @click="prev()" style="float: right;"><i class="fas fa-chevron-left"></i></button>
                    <button class="button button--outline weekends" @click="toggleWeekends()" :class="{active: !calendarOptions.week.workweek}">{{profile.incident_detail.weekend}}</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <Calendar style="height: 800px"
                    ref="calendar"
                    :template="calendarOptions.template"
                    :calendars="calendarList"
                    :view="calendarOptions.view"
                    :usage-statistics="false"
                    :use-detail-popup="false"
                    :month="calendarOptions.month"
                    :events="calendarOptions.events"
                    :week="calendarOptions.week"
                />
                <div class="creation-popup" ref="draggableContainer" id="draggable-container" v-if="createNewAppointment">
                    <div class="popup-header" @mousedown="dragMouseDown">
                        <span><i class="fas fa-expand-arrows-alt"></i>{{profile.incident_detail.drag_here}}</span>
                    </div>
                    <div class="eventreadonly">
                        <div v-html="currentEvent.title"></div>
                        <router-link tag="a" v-bind:to="'/buildingmanager/incident-detail?id=' + currentEvent.id" style="float: left; font-weight: 500;">{{currentEvent.id}}</router-link>
                        <span class="status-bar" v-if="currentEvent.location && !profile['statusses'][currentEvent.location].buildingmanager" style="position: absolute; right: 5px;">
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic'"></div>
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][currentEvent.location]}}</span>
                            <span v-if="currentEvent.location && currentEvent.location === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][currentEvent.location]}}</span>
                            <span v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && currentEvent.location !== 'cancelled'">{{profile['statusses'][currentEvent.location]}}</span>
                        </span>
                        <span class="status-bar" v-if="currentEvent.location && profile['statusses'][currentEvent.location].buildingmanager" style="position: absolute; right: 5px;">
                            <div v-html="profile['status_icons'][currentEvent.location].buildingmanager" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && profile['status_icons'][currentEvent.location].buildingmanager"></div>
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && !profile['status_icons'][currentEvent.location].buildingmanager"></div>
                            <div v-html="profile['status_icons'][currentEvent.location].buildingmanager" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic' && profile['status_icons'][currentEvent.location].buildingmanager" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][currentEvent.location].buildingmanager}}</span>
                            <span v-if="currentEvent.location && currentEvent.location === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][currentEvent.locations].buildingmanager}}</span>
                            <span v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && currentEvent.location !== 'cancelled'">{{profile['statusses'][currentEvent.location].buildingmanager}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import Calendar from '@toast-ui/vue-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.css';
import moment from 'moment';

export default {
    name: 'Schedule',
    mixins: [setLocale],
    components: {
        Calendar
    },
    data: function () {
        return {
            calendarOptions: {
                view: 'week',
                week: {
                    dayNames: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
                    workweek: true,
                    taskView: false,
                    hourStart: 7,
                    hourEnd: 21,
                    visibleWeeksCount: 3
                },
                month: {
                    dayNames: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
                    visibleWeeksCount: 3,
                },
                template: {
                    timegridDisplayPrimaryTime({ time }) {
                        let date = time.d.d;
                        let times = moment(date).format('HH:mm');
                        return times;
                    },
                    timegridDisplayTime({ time }) {
                        return `sub timezone: ${time}`;
                    },
                    alldayTitle() {
                        return 'Uiterste<br />uitvoer';
                    }
                },
                events: []
            },
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            calendarList: [],
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            },
            eventReadOnly: false,
            currentEvent: {},
            createNewAppointment: false
        }
    },
    props: ['specialistInfo'],
    computed: {
        calendarInstance() {
            if (this.$refs.calendar) {
                return this.$refs.calendar.getInstance();
            }
            else {
                return ''
            }
        }
    },
    created() {
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        var curr = new Date;
        this.getAppointments(curr);
    },
    methods: {
        getAppointments(curr) {
            this.calendarOptions.events = [];
            this.eventReadOnly = false;
            var first = curr.getDate() - curr.getDay();
            first = first - 31;
            var last = first + 300;
            var firstday = new Date(curr.setDate(first)).valueOf();
            var lastday = new Date(curr.setDate(last)).valueOf();
            let self = this;
            fetch('/api/v1/appointment/bymechanicforcal?id_mechanic=' + this.specialistInfo.id_mechanic + '&fetchStartDate=' + firstday + '&fetchEndDate=' + lastday)
            .then(response => { response.json().then(
                function(data) {
                    let appointments = data;
                    if (appointments.length) {
                        let tempEvents = [];
                        for (var i = 0; i < appointments.length; i++) {
                            let event = appointments[i];
                            event.startTime = new Date(event.start);
                            event.endTime = new Date(event.end);
                            let calendarEvent = {};
                            calendarEvent.id = event.url;
                            calendarEvent.title = event.title;
                            calendarEvent.start = new Date(event.start);
                            calendarEvent.end = new Date(event.end);
                            calendarEvent.isReadOnly = true;
                            calendarEvent.color = 'white';
                            calendarEvent.isVisible = true;
                            calendarEvent.calendarId = '1';
                            calendarEvent.location = event.incident_status;
                            if (calendarEvent.location === 'on_location_mechanic'
                                || calendarEvent.location === 'fixed_mechanic'
                                || calendarEvent.location === 'fixed_agreed_auto'
                                || calendarEvent.location === 'closed'
                                || calendarEvent.location === 'cancelled'
                                || calendarEvent.location === 'fixed_agreed_repaircompany'
                                || calendarEvent.location === 'maintenance_checkin_mechanic'
                            ) {
                                calendarEvent.backgroundColor = '#ccc';
                                calendarEvent.isReadOnly = true;
                            }
                            let startTime = calendarEvent.start.getHours();
                            let endTime = calendarEvent.end.getHours();
                            if (startTime === 1 & endTime === 23) {
                                calendarEvent.isAllday = true;
                            }
                            tempEvents.push(calendarEvent);
                        }
                        self.calendarOptions.events = tempEvents;
                        setTimeout(function () {
                            let date = '';
                            if (self.calendarInstance.container) {
                                date = self.calendarInstance.getDateRangeStart();
                            }
                            date = date.d.d;
                            if (self.locale === 'nl') {
                                self.currentMonth = moment(date).locale('nl').format('MMMM');
                            }
                            else {
                                self.currentMonth = moment(date).format('MMMM');
                            }
                        }.bind(this), 500);
                       
                    }
                });
            });
        },
        prev() {
            this.calendarInstance.prev();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
            this.getAppointments(date, true);
        },
        next() {
            this.calendarInstance.next();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
            this.getAppointments(date, true);
        },
        changeView(type) {
            if (type === 'week') {
                this.calendarInstance.changeView('week');
            }
            else {
                this.calendarInstance.changeView('day');
            }
        },
        today() {
            this.calendarInstance.today();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
        },
        toggleWeekends() {
            if (this.calendarOptions.week.workweek) {
                this.calendarOptions.week.workweek = false;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: false
                    }
                });
            }
            else {
                this.calendarOptions.week.workweek = true;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: true
                    }
                });
            }
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        },
        eventEdit(event) {
            let strippedTitle = event.event.title.replaceAll('|', '');
            if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'none';
            }
            this.createNewAppointment = true;
            this.appointmentEdit = true;
            if (event.event.isReadOnly) {
                this.eventReadOnly = true;
                let currentEvent = {
                    title: strippedTitle,
                    id: event.event.id,
                    location: event.event.location
                }
                this.currentEvent = currentEvent;
            }
            let now = new Date();
            let nowTime = now.getTime();
            let start_time;
            let end_time;
            if (event.changes && event.changes.start) {
                start_time = event.changes.start.d.d.getTime();
                this.newAppointment.date = event.changes.start.d.d;
                this.newAppointment.start_time = moment(event.changes.start.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    start: event.changes.start.d.d
                });
            }
            else {
                start_time = event.event.start.d.d.getTime();
                this.newAppointment.date = event.event.start.d.d;
                this.newAppointment.start_time = moment(event.event.start.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    start: event.event.start.d.d
                });
            }
            if (event.changes && event.changes.end) {
                end_time = event.changes.end.d.d.getTime();
                this.newAppointment.end_time = moment(event.changes.end.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    end: event.changes.end.d.d
                });
            }
            else {
                end_time = event.event.end.d.d.getTime();
                this.newAppointment.end_time = moment(event.event.end.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    end: event.event.end.d.d
                });
            }
            if (start_time < nowTime) {
                this.pastError = true;
            }
            else {
                this.pastError = false;
            }
            if (this.latestPossible && moment(event.event.start.d.d).format('YY MM DD') === moment(now).format('YY MM DD')) {
                this.pastError = false;
            }
        }
    }
}
</script>
