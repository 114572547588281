<template>
    <div class="block block--incident_detail" style="min-height: 0;" :class="{nonotes: !notes.length}">
          <div class="incident-notes" style="position: relative; overflow: auto; min-height: 26px; max-height: 420px;">
              <div class="incident-head">
                  <h1 style="max-width: 100%; font-size: 18px;">{{profile.incident_detail.notes}} <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.notes_tooltip"></i></h1>
                  <span class="edit-button edit-button--small icon" data-testid="noteAddButton" @click="createNoteModalOpen = true;" style="margin-top: 0;"><i class="fas fa-plus" style="font-size: 18px;"></i></span>
              </div>
              <div v-for="(note, index) in notes" :key="index">
                  <div class="note" @click.stop="selectNote(note)" v-tooltip.right="note.content">
                      <span class="written-by"><i class="fas fa-feather"></i> {{note.actual_name}}</span>
                      <span data-testid="noteViolationText" class="mandate-violation" style="display: block; clear: both;" :class="{active: incidentInfo.mandate_warning}" v-if="note.note_type === 'mandate'"><i class="fa fa-exclamation"></i> {{profile.mandates.mandate_violation}}</span>
                      <span data-testid="noteDescriptionText" style="clear: both; text-decoration: underline; color: #007bff;" v-if="note.is_url" @click.stop="goUrl(note.content)">{{note.content}}</span>
                      <span data-testid="noteDescriptionText" style="clear: both;" v-else>{{note.content}}</span>
                      <b>{{note.date}}</b>
                      <i class="fas fa-exclamation" v-if="note.incident_note_notification_id_shiro_user_receiver && !note.incident_note_notification_incident_note_read" style="color: #ffa500; position: absolute; bottom: 10px; right: 5px;" v-tooltip.right="profile.incident_detail.important + ' ' + note.incident_note_notification_name_user_receiver"></i>
                  </div>
              </div>
              <CreateNoteModal v-if="createNoteModalOpen" @close="createNoteModalOpen = false" :incidentInfo="incidentInfo" @getnotes="getNotes" />
              <EditNoteModal v-if="editNoteModalOpen" @close="getImportantNotes()" :incidentInfo="incidentInfo" :note="selectedNote" @getnotes="getNotes" />
              <ViewNoteModal v-if="viewNoteModalOpen" @close="getImportantNotes()" :note="selectedNote" />
          </div>
    </div>
</template>

<script>
import moment from 'moment';
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import CreateNoteModal from "@/components/modals/notes/CreateNoteModal.vue";
import EditNoteModal from "@/components/modals/notes/EditNoteModal.vue";
import ViewNoteModal from "@/components/modals/notes/ViewNoteModal.vue";
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export default {
    name: 'Notes',
    mixins: [setLocale],
    components: {
        CreateNoteModal,
        EditNoteModal,
        ViewNoteModal
    },
    data: function () {
        return {
            notes: [],
            profile: {},
            createNoteModalOpen: false,
            editNoteModalOpen: false,
            removeNoteModalOpen: false,
            viewNoteModalOpen: false,
            selectedNote: {}
        }
    },
    props: ['readOnly', 'forwardedReadOnly', 'incidentInfo'],
    computed: mapState(['getNotess']),
    watch: {
        getNotess() {
            this.getNotes();
        }
    },
    created() {
        this.getNotes();
    },
    methods: {
        getNotes() {
            this.editNoteModalOpen = false;
            this.notes = [];
            let self = this;
            fetch('/api/v1/incident_note/byidincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    let queryString = window.location.search;
                    let noteId = queryString.split('&opennote=')[1];
                    for (var i = 0; i < data.length; i++) {
                        let note = data[i];
                        if (!note.id_repair_company) {
                            self.notes.push(note);
                        }
                        if (note.note_type === 'mandate') {
                            self.notes.push(note);
                        }
                        if (self.locale === 'nl') {
                            note.date = moment(note.created).locale('nl').format('D MMM YYYY');
                        }
                        else {
                            note.date = moment(note.created).format('MMM Do YYYY');
                        }
                        if (noteId === note.id_incident_note.toString() && !note.incident_note_notification_incident_note_read) {
                            self.selectNote(note, true);
                        }
                    }
                    self.notes = sortBy(self.notes, 'created');
                    self.notes = uniqBy(self.notes, 'created');
                    self.notes.reverse();
                });
            });
        },
        selectNote(note, view) {
            this.selectedNote = note;
            if (view) {
                this.viewNoteModalOpen = true;
            }
            else {
                this.editNoteModalOpen = true;
            }
        },
        goUrl(url) {
            window.open(url, '_blank');
        },
        getImportantNotes() {
            this.getNotes();
            this.viewNoteModalOpen = false;
            this.editNoteModalOpen = false;
            this.$store.dispatch('updateGetImportantNotes');
        }
    }
}
</script>
