<template>
    <div class="modal-wrapper" v-if="profile.specialists">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.specialists.add_specialist}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createNewMechanic()">
                    <div class="employees" style="margin-bottom: 15px;" v-if="selectedMechanic.id_mechanic">
                        <div class="employee">
                            <i class="fa fa-times" @click="selectedMechanic = {}" style="position: absolute; right: 10px; top: 10px; cursor: pointer; z-index: 1;"></i>
                            <h3 data-testid="specialistKnownNameText" style="color: #212529; padding-left: 0;">{{selectedMechanic.name}}</h3>
                            <span class="phone" v-if="selectedMechanic.mobile"><i class="fas fa-phone-alt"></i> {{selectedMechanic.mobile}}</span>
                        </div>
                    </div>
                    <div class="form-field" :class="{focus:fields.name.length}" v-if="!selectedMechanic.id_mechanic">
                        <label>{{profile.employees.name}} *</label>
                        <input type="text" ref="name" class="form-control" data-testid="specialistInputName" v-model="fields.name" required />
                    </div>
                    <div class="row" v-if="!selectedMechanic.id_mechanic">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.email.length, valid: validEmail, error: invalidEmail}">
                                <label>{{profile.employees.email}} *</label>
                                <input type="text" class="form-control" data-testid="specialistInputEmail" v-model="fields.email" @input="validateEmail()" required />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.mobile.length, valid: validPhone, error: invalidPhone}">
                                <label>{{profile.employees.phone}} *</label>
                                <input type="text" class="form-control" data-testid="specialistInputPhone" v-model="fields.mobile" @input="validatePhone()" required />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="foundMechanics.length && !selectedMechanic.id_mechanic">
                        <div class="col">
                            <div class="notification notification--warning" style="margin-bottom: 15px;">
                                <p><i class="fas fa-exclamation-circle"></i> {{profile.mechanics.mechanictable.already_exists}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="nameError">
                        <div class="col">
                            <div class="notification notification--warning" style="margin-bottom: 15px;">
                                <p><i class="fas fa-exclamation-circle"></i> {{profile.mechanics.mechanictable.nameError}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="employees" style="margin-bottom: 15px;" v-if="foundMechanics.length && !selectedMechanic.id_mechanic">
                        <div class="employee" v-for="(mechanic, index) in foundMechanics" :key="index" @click="selectMechanic(mechanic)" style="cursor: pointer;">
                            <h3 data-testid="specialistKnownNameText" style="color: #212529; padding-left: 0;">{{mechanic.name}}</h3>
                            <span class="phone" v-if="mechanic.mobile"><i class="fas fa-phone-alt"></i> {{mechanic.mobile}}</span>
                        </div>
                    </div>
                    <div class="form-field" :class="{focus:fields.description.length}" v-if="!selectedMechanic.id_mechanic">
                        <label>{{profile.repair_companies.repaircompanydetail.description}} *</label>
                        <textarea class="form-control" data-testid="mechanicInputDescription" v-model="fields.description" required></textarea>
                    </div>
                    <div v-if="!this.selectedMechanic.name">
                        <h3 style="font-size: 16px; color: #212529; padding-left: 0;">{{profile.mechanics.whatsapp_settings}}</h3>
                        <div class="switch-wrapper">
                            <label class="switch">
                                <input type="checkbox" v-model="fields.notify_whatsapp_new_incident">
                                <span class="slider round" data-testid="specialistAlertSlider"></span>
                            </label>
                            <span style="font-weight: 100;">{{profile.accountPage.account.new_incidents_wa}}</span>
                        </div>
                        <div class="switch-wrapper">
                            <label class="switch">
                                <input type="checkbox" v-model="fields.notify_whatsapp_status">
                                <span class="slider round" data-testid="specialistStatusSlider"></span>
                            </label>
                            <span style="font-weight: 100;">{{profile.accountPage.account.status_updates_wa}}</span>
                        </div>
                        <div class="switch-wrapper">
                            <label class="switch">
                                <input type="checkbox" v-model="fields.notify_whatsapp_chat">
                                <span class="slider round" data-testid="specialistChatSlider"></span>
                            </label>
                            <span style="font-weight: 100;">{{profile.accountPage.account.chat_updates_wa}}</span>
                        </div>
                    </div>
                    <h3 style="color: #212529; padding-left: 0; margin-top: 10px;">{{profile.repair_companies.repaircompanydetail.communication}}</h3>
                    <div class="switch-wrapper">
                        <label class="switch">
                            <input type="checkbox" v-model="fields.onlyEmail" @change="fields.noComm = false">
                            <span class="slider round" data-testid="specialistEmailSlider"></span>
                        </label>
                        <span>{{profile.repair_companies.repaircompanydetail.onlyemail}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.onlyemail_tooltip" style="cursor: pointer;"></i></span>
                    </div>
                    <div class="switch-wrapper">
                        <label class="switch">
                            <input type="checkbox" v-model="fields.noComm" @change="fields.onlyEmail = false">
                            <span class="slider round" data-testid="specialistCommunicationSlider"></span>
                        </label>
                        <span>{{profile.repair_companies.repaircompanydetail.nocommunication}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.nocommunication_tooltip" style="cursor: pointer;"></i></span>
                    </div>
                    <button type="submit" class="button button--icon" data-testid="specialistSubmitButton" v-if="(foundMechanics.length && selectedMechanic.id_mechanic) || !foundMechanics.length"><i class="fas fa-user"></i>{{profile.specialists.add_specialist}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'newLocationModal',
    data() {
        return {
            profile: {},
            fields: {
                name: '',
                email: '',
                mobile: '',
                description: '',
                notify_whatsapp_chat: true,
                notify_whatsapp_new_incident: true,
                notify_whatsapp_status: true,
                onlyEmail: false,
                noComm: false
            },
            validPhone: false,
            invalidPhone: false,
            validEmail: false,
            invalidEmail: false,
            foundMechanics: [],
            selectedMechanic: {},
            nameError: false
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        checkEmail() {
            let self = this;
            fetch('/api/v1/mechanic/findbyemailorusername?email=' + this.fields.email)
            .then(response => { response.json().then(
                function(data) {
                    if (data.data.length) {
                        self.foundMechanics = data.data;
                    }
                    else {
                        self.foundMechanics = [];
                    }
                });
            });
        },
        selectMechanic(mechanic) {
            this.selectedMechanic = mechanic;
        },
        validatePhone() {
            let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            if (pattern.test(this.fields.mobile)) {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            else {
                this.invalidPhone = true;
                this.validPhone = false;
            }
            if (!this.fields.mobile.length) {
                this.validPhone = false;
                this.invalidPhone = false;
            }
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.fields.email)) {
                this.validEmail = true;
                this.invalidEmail = false;
                this.checkEmail();
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        createNewMechanic() {
            if (this.selectedMechanic.name) {
                this.linkMechanic();
            }
            else {
                let self = this;
                const data = new URLSearchParams();
                data.append('action', 'create');
                data.append('data[0][name]', this.fields.name);
                data.append('data[0][email]', this.fields.email);
                data.append('data[0][mobile]', this.fields.mobile);
                data.append('data[0][description]', this.fields.description);
                data.append('data[0][only_email]', this.fields.onlyEmail);
                data.append('data[0][no_communication]', this.fields.noComm);
                if (!this.fields.mobile.length) {
                    this.validPhone = true;
                }
                if (this.validPhone && this.validEmail) {
                    fetch('/api/v1/mechanic', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (data.data[0].id_mechanic) {
                            self.toggleNotsWa(data.data[0].id_mechanic);
                        }
                        else {
                            self.nameError = true;
                        }
                    });
                }
            }
        },
        toggleNotsWa(id) {
            const data = new URLSearchParams();
            data.append('id_mechanic',  id);
            data.append('notify_whatsapp_chat',  this.fields.notify_whatsapp_chat);
            data.append('notify_whatsapp_new_incident',  this.fields.notify_whatsapp_new_incident);
            data.append('notify_whatsapp_status',  this.fields.notify_whatsapp_status);
            let self = this;
            fetch('/api/v1/mechanic/updatenotify', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.closeModal();
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        linkMechanic() {
            let self = this;
            const data = new URLSearchParams();
            data.append('id_mechanic', this.selectedMechanic.id_mechanic);
            fetch('/api/v1/mechanic/link_to_repair_company', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.closeModal();
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
