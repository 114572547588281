<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-parking"></i>{{profile.incident_detail.park_incident}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="parkIncident()">
                    <label>{{profile.incident_detail.parked_until}}</label>
                    <flat-pickr v-model="fields.until" class="form-control" data-testid="parkIncidentDate" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" ref="datepicker"></flat-pickr>
                    <label>{{profile.incident_detail.park_reason}}</label>
                    <textarea class="form-control" data-testid="parkIncidentReason" v-model="fields.reason" id="reason"></textarea>
                    <button type="submit" class="button button--cta button--icon" data-testid="parkIncidentSubmit"><i class="fas fa-parking"></i>{{profile.incident_detail.park_incident}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
    mixins: [setLocale],
    components: {
        flatPickr
    },
    name: 'parkIncidentModal',
    data() {
        return {
            profile: {},
            fields: {
                until: '',
                reason: ''
            },
            tomorrow: '',
            placeholder: '',
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
            },
        }
    },
    props: ['incidentInfo'],
    created() {
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        if (this.incidentInfo.service_desk_suspended_reason) {
            this.fields.reason = this.incidentInfo.service_desk_suspended_reason;
        }
        if (this.incidentInfo.service_desk_suspended_until) {
            let untilDate = new Date(this.incidentInfo.service_desk_suspended_until)
            this.fields.until = moment(untilDate).add(1, 'days').format('YYYY-MM-DD');
        }
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate()+1);
        this.tomorrow = moment(this.tomorrow).format();
        this.tomorrow = this.tomorrow.split('T')[0];
        this.flatPickrDateConfig.minDate = this.tomorrow;
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        setTimeout(function () {
            this.$refs.datepicker.fp.open();
        }.bind(this), 200);
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        parkIncident() {
            let endDate = new Date(this.fields.until);
            endDate.setDate(endDate.getDate()-1);
            endDate.setHours(23, 59, 59);
            endDate = endDate.getTime();
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('service_desk_suspended_reason', this.fields.reason);
            data.append('service_desk_suspended_until', endDate);
            let self = this;
            fetch('/api/v1/incident/suspendservicedesk', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                document.querySelector('body').classList.remove('modal-open');
                self.$emit('success');
            });
        },
        closeField() {
            let reason = document.getElementById('reason');
            reason.focus();
        }
    }
}
</script>
