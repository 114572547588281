<template>
    <div>
        <div class="main-content" v-if="profile.owners">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="block block--account">
                    <div class="profile-card" style="border-right: 0;">
                        <div class="row">
                            <div class="col-3">
                                <div class="profile-pic-wrapper">
                                    <div class="profile-pic placeholder">
                                        <i class="far fa-image"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-9">
                                <h4>{{profile.parts.part}}</h4>
                                <h2>{{owner.name}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="tabs">
                    <div class="tab" data-testid="ownerGeneralTab" @click="currentTab = 'general'" :class="{active: currentTab === 'general'}">
                        <h3>{{profile.accountPage.account.general}}</h3>
                    </div>
                    <div class="tab" data-testid="ownerLocationTab" @click="currentTab = 'locations'" :class="{active: currentTab === 'locations'}">
                        <h3>{{profile.places.placetable.tablename}}</h3>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'general'}">
                    <div class="block block--owner_detail">
                        <section class="incident-head">
                            <h1 data-testid="ownerEditTitle" style="max-width: 100%;">{{profile.accountPage.account.general}}</h1>
                        </section>
                        <section class="owner-contents">
                            <div class="owner-info">
                                <div style="position: relative;">
                                    <br />
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>{{profile.parts.part_name}}</label>
                                            <p data-testid="ownerNameText">{{owner.name}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>{{profile.incidents.new_incident.phone}}</label>
                                            <p data-testid="ownerPhoneText">{{owner.phone}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>{{profile.accountPage.account.contactmobile}}</label>
                                            <p data-testid="ownerMobileText">{{owner.mobile}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>{{profile.repair_companies.repaircompanytable.corrEmail}}</label>
                                            <p data-testid="ownerEmailText">{{owner.email}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <label>{{profile.owners.ownertable.description}}</label>
                                            <p data-testid="ownerDescriptionText" v-html="owner.description" style="white-space: break-spaces;"></p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>{{profile.assets.assetmodal.external_id}}</label>
                                            <p data-testid="ownerExternalText">{{owner.id_external}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>{{profile.owners.ownertable.manager}}</label>
                                            <p data-testid="ownerManagerText">{{assignedManager}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="owner.invoice_number">
                                        <div class="col-sm-6">
                                            <label>{{profile.incident_detail.ref}}</label>
                                            <p data-testid="ownerInvoiceText">{{owner.invoice_number}}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr style="margin-top: 0;"  />
                                <div class="row">
                                    <div class="col">
                                        <p style="white-space: normal;"><b>{{ profile.owners.ownerdetail.email_expla }} <i style="font-style:italic; font-weight: 500;">{{ owner.email }}</i>. {{ profile.owners.ownerdetail.email_expla_2 }}</b></p>
                                        <div class="switch-wrapper">
                                            <label class="switch" style="float: left;" v-tooltip.right="profile.owners.ownerdetail.only_use_when">
                                                <input type="checkbox" data-testid="ownerEditSendIncidentEmail" v-model="owner.send_incident_email" @change="saveIncidentEmail()">
                                                <span class="slider round"></span>
                                            </label>
                                            <span v-tooltip.right="profile.owners.ownerdetail.only_use_when" style="display: block;">{{profile.owners.ownerdetail.sendincidentemail}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="switch-wrapper" style="margin: 0;">
                                            <label class="switch" style="float: left; margin-top: 10px;">
                                                <input type="checkbox" v-model="owner.send_incident_closed" @change="toggleSendMailClose()">
                                                <span class="slider round"></span>
                                            </label>
                                            <span style="padding-top: 10px; display: block;">{{profile.employees.send_on_close}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <hr style="margin-top: 0;" />
                    <div class="block block--owner_detail" :class="{empty: !owner.invoice_email || !owner.invoice_address}">
                        <div class="incident-head">
                            <h3 style="margin: 0;">{{profile.invoicing.invoicing}} <i class="fas fa-info-circle" v-tooltip.right="profile.invoicing.invoicing_tooltip" style="cursor: pointer;"></i></h3>
                        </div>
                        <div class="incident-contents">
                            <div class="incident-info">
                                <div class="row">
                                    <div class="col-md-4" v-if="owner.invoice_email">
                                        <label>{{profile.invoicing.invoice_email}}</label>
                                        <p data-testid="invoiceEmailText">{{owner.invoice_email}}</p>
                                    </div>
                                    <div class="col-md-8" v-if="owner.invoice_address">
                                        <label>{{profile.invoicing.invoice_address}}</label>
                                        <p data-testid="invoiceAddressText">{{owner.invoice_address}} {{owner.invoice_house_number}}, {{owner.invoice_zipcode}} {{owner.invoice_city}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'locations'}">
                    <div class="block block--owner_detail" v-if="places.length">
                        <div class="incident-head">
                            <h1 style="max-width: 100%;">{{profile.places.placetable.tablename}}</h1>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <section class="search search-inpage">
                                    <form>
                                        <input type="text" data-testid="locationSearchInput" v-bind:placeholder="profile.assets.search_asset" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)"/>
                                        <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                                    </form>
                                </section>
                            </div>
                            <div class="col-sm-4">
                                <div class="pagination pagination--alt" v-if="!searchSuggestions.length && places.length > 25">
                                    <span class="prev" v-if="placesCurrentPage !== 1" @click="prevPage()"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="placesCurrentPage !== 1" @click="firstPage()">1</span>
                                    <span class="page-indicator">{{placesCurrentPage}} / {{placesTotalPages}}</span>
                                    <span class="page" v-if="placesCurrentPage > 2 && placesCurrentPage !== placesTotalPages" @click="lastPage()">{{placesTotalPages}}</span>
                                    <span class="next" @click="nextPage()" v-if="placesCurrentPage !== placesTotalPages"><i class="fas fa-chevron-right"></i></span>
                                </div>
                                <div class="pagination pagination--alt" v-if="searchSuggestions.length">
                                    <span class="prev" v-if="suggestionsCurrentPage !== 1" @click="prevPage()"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="suggestionsCurrentPage !== 1" @click="firstPage()">1</span>
                                    <span class="page-indicator">{{suggestionsCurrentPage}} / {{suggestionsTotalPages}}</span>
                                    <span class="page" v-if="suggestionsTotalPages > 2" @click="lastPage('currentincident')">{{suggestionsTotalPages}}</span>
                                    <span class="next" @click="nextPage()" v-if="suggestionsCurrentPage !== suggestionsTotalPages"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="table-wrap">
                            <h4 class="result-counter" v-if="!searchActive && totalPlaces" style="margin-bottom: 20px;">{{totalPlaces}} <span style="text-transform: lowercase; font-weight: 500;">{{profile.places.placetable.tablename}}</span></h4>
                            <h4 class="result-counter" v-if="searchActive && !totalSearchPlaces" style="margin-bottom: 20px;">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                            <h4 class="result-counter" v-if="searchActive && totalSearchPlaces >= 1" style="margin-bottom: 20px;">{{totalSearchPlaces}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                            <table class="table table-striped" v-if="!searchActive || (searchActive && searchSuggestions.length)">
                                <thead>
                                    <tr>
                                        <th scope="col" @click="sortTable('address')" :class="{active: sortType === 'address'}">{{profile.places.placetable.address}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('house_number')" :class="{active: sortType === 'house_number'}">{{profile.places.placetable.house_number}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('zipcode')" :class="{active: sortType === 'zipcode'}">{{profile.places.placetable.zipcode}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('city')" :class="{active: sortType === 'city'}">{{profile.places.placetable.city}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('name')" :class="{active: sortType === 'name'}">{{profile.places.placetable.name}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('floor_name')" :class="{active: sortType === 'floor_name'}">{{profile.places.placetable.floor}}<i class="fas fa-sort"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in placesInView" :key="item.id_place" v-show="!searchActive" @click="selectLocation(item.id_place)">
                                        <td>{{item.address}}</td>
                                        <td>{{item.house_number}}</td>
                                        <td>{{item.zipcode}}</td>
                                        <td>{{item.city}}</td>
                                        <td data-testid="locationNameRow">{{item.name}}</td>
                                        <td>{{item.floor_name}}</td>
                                    </tr>
                                    <tr v-for="(item, index) in suggestionsInView" :key="index" v-show="searchActive" @click="selectLocation(item.id_place)">
                                        <td>{{item.address}}</td>
                                        <td>{{item.house_number}}</td>
                                        <td>{{item.zipcode}}</td>
                                        <td>{{item.city}}</td>
                                        <td data-testid="locationNameRow">{{item.name}}</td>
                                        <td>{{item.floor_name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="notification notification--warning" v-if="searchActive && !searchSuggestions.length">{{profile.dashboard.building_manager.no_resultss}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            currentTab: 'general',
            ownerId: null,
            owner: {},
            places: [],
            placesInView: [],
            totalPlaces: 0,
            totalSearchPlaces: 0,
            sortType: '',
            placesCurrentPage: 1,
            placesTotalPages: 1,
            searchActive: false,
            searchSuggestions: [],
            sortOrder: 'asc',
            searchTerm: '',
            suggestionsInView: [],
            suggestionsCurrentPage: 1,
            suggestionsTotalPages: 1,
            assignedManager: false
        }
    },
    created() {
        let queryString = window.location.search;
        this.ownerId = queryString.split('=')[1];
        this.getOwner();
        this.getOwners();
        this.getPlaces();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getOwner() {
            let self = this;
            fetch('/api/v1/owneraccount/owner?id_owner=' + this.ownerId)
            .then(response => { response.json().then(
                function(data) {
                    self.owner = data[0];
                    document.title = 'S1MONE | ' + self.owner.name;
                    self.validPhone = true;
                    if (self.owner.mobile) {
                        self.owner.mobile = self.owner.mobile.replace(' ', '');
                    }
                    self.tempEmail = self.owner.email;
                });
            });
        },
        getOwners() {
            let self = this;
            fetch('/api/v1/owneraccount/stats')
            .then(response => { response.json().then(
                function(data) {
                    self.owners = data.owners;
                    self.owners = sortBy(self.owners, 'name');
                });
            });
        },
        searchOwners() {
            this.tempOwners = [];
            if (this.ownerSearchTerm.length > 1) {
                let searchTerm = this.ownerSearchTerm.toLowerCase();
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    let ownerName = owner.name.toLowerCase();
                    if (ownerName.includes(searchTerm)) {
                        this.tempOwners.push(owner);
                    }
                    if (!this.tempOwners.length) {
                        this.ownersNoResults = true;
                    }
                    else {
                        this.ownersNoResults = false;
                    }
                }
            }
            else {
                this.ownersNoResults = false;
            }
        },
        getPlaces() {
            let self = this;
            let queryString = window.location.search;
            this.ownerId = queryString.split('=')[1];
            fetch('/api/v1/owneraccount/places')
            .then(response => { response.json().then(
                function(data) {
                    let totalPagesRaw = data.length / 25;
                    self.placesTotalPages = Math.ceil(totalPagesRaw);
                    for (var j = 0; j < data.length; j++) {
                        let place = data[j];
                        if (place.floor_name === '0') {
                            if (self.locale === 'nl') {
                                place.floor_name = 'Begane grond'
                            }
                            if (self.locale === 'en') {
                                place.floor_name = 'Ground floor'
                            }
                        }
                        let placeId = place.id_place;
                        if (j < 25) {
                            self.placesInView.push(place);
                        }
                        self.places.push(place);
                    }
                    self.totalPlaces = self.places.length;
                });
            });
        },
        saveIncidentEmail() {
            const data = new URLSearchParams();
            data.append('id_owner', this.ownerId);
            data.append('send_incident_mail', this.owner.send_incident_email);
            let self = this;
            fetch('/api/v1/owner/setsendincidentmail', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        toggleSendMailClose() {
            const params = new URLSearchParams();
            params.append('id_owner', this.ownerId);
            params.append('send_incident_closed', this.owner.send_incident_closed);
            let self = this;
            fetch('/api/v1/owner/setsendincidentclosed', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.placesInView = [];
            this.placesSortType = sorter;
            this.places = sortBy(this.places, sorter);
            if (this.sortOrder === 'desc') {
                this.places.reverse();
            }
            for (var i = 0; i < this.places.length; i++) {
                let item = this.places[i];
                this.placesCurrentPage = 1;
                if (i < 25) {
                    this.placesInView.push(item);
                }
            }
        },
        nextPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                this.suggestionsCurrentPage++;
                let from = (this.suggestionsCurrentPage - 1) * 25;
                let to = from + 25;
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    if (i > from && i <= to) {
                        this.suggestionsInView.push(item);
                    }
                }
            }
            else {
                this.placesInView = [];
                this.placesCurrentPage++;
                let from = (this.placesCurrentPage - 1) * 25;
                let to = from + 25;
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    if (j > from && j <= to) {
                        this.placesInView.push(item);
                    }
                }
            }
        },
        prevPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                this.suggestionsCurrentPage--;
                let from = this.suggestionsCurrentPage * 25;
                let to = from + 25;
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    if (this.suggestionsCurrentPage === 1) {
                        if (i < 25) {
                            this.suggestionsInView.push(item);
                        }
                    }
                    if (this.suggestionsCurrentPage > 1) {
                        if (i > from && i <= to) {
                            this.suggestionsInView.push(item);
                        }
                    }
                }
            }
            else {
                this.placesInView = [];
                this.placesCurrentPage--;
                let from = this.placesCurrentPage * 25;
                let to = from + 25;
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    if (this.placesCurrentPage === 1) {
                        if (j < 25) {
                            this.placesInView.push(item);
                        }
                    }
                    if (this.placesCurrentPage > 1) {
                        if (j > from && j <= to) {
                            this.placesInView.push(item);
                        }
                    }
                }
            }
        },
        firstPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    this.suggestionsCurrentPage = 1;
                    if (i < 25) {
                        this.suggestionsInView.push(item);
                    }
                }
            }
            else {
                this.placesInView = [];
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    this.placesCurrentPage = 1;
                    if (j < 25) {
                        this.placesInView.push(item);
                    }
                }
            }
        },
        lastPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    let lastPageStart = this.suggestionsTotalPages * 25;
                    this.suggestionsCurrentPage = this.suggestionsTotalPages;
                    if (i > lastPageStart) {
                        this.suggestionsInView.push(item);
                    }
                }
            }
            else {
                this.placesInView = [];
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    let lastPageStart = this.placesTotalPages * 25;
                    this.placesCurrentPage = this.placesTotalPages;
                    if (j > lastPageStart) {
                        this.placesInView.push(item);
                    }
                }
            }
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            this.suggestionsInView = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.places.length; i++) {
                    let item = this.places[i];
                    let itemAddress = item.address.toLowerCase();
                    let itemName = item.name.toLowerCase();
                    let itemZipcode = item.zipcode.toLowerCase();
                    let itemCity = item.city.toLowerCase();
                    if (itemAddress.includes(searchTerm) || itemName.includes(searchTerm) || itemZipcode.includes(searchTerm) || itemCity.includes(searchTerm)) {
                        this.searchSuggestions.push(item);
                    }
                }
            }
            else {
                this.searchActive = false;
            }
            this.searchSuggestions = uniqBy(this.searchSuggestions, 'id_place');
            let totalPagesRaw = (this.searchSuggestions.length / 25);
            this.suggestionsTotalPages = Math.ceil(totalPagesRaw);
            for (var j = 0; j < this.searchSuggestions.length; j++) {
                let item = this.searchSuggestions[j];
                if (j < 25) {
                    this.suggestionsInView.push(item);
                }
            }
            this.suggestionsInView = uniqBy(this.suggestionsInView, 'id_place');
            if($event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
            this.totalSearchPlaces = this.searchSuggestions.length;
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
            this.totalSearchPlaces = 0;
        },
        validatePhone: function (number, object) {
            this.countryCode = object.countryCode;
            if (object.countryCode === undefined) {
                object.countryCode = this.countryCode;
            }
            if (object.formatted) {
                if (object.valid) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.validPhone = true;
                this.invalidPhone = false;
            }
        },
        selectLocation(id) {
            this.$router.push('/owner/location-detail?id=' + id);
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
