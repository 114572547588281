<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3 v-if="note.note_type === 'mandate'"><i class="fas fa-sticky-note"></i>{{profile.incident_detail.see_note}}</h3>
                <h3 v-else><i class="fas fa-sticky-note"></i>{{profile.incident_detail.edit_note}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fa fa-times"></i></span>
                <form v-on:submit.prevent="addHandler()" v-if="currentTab === 'note'">
                    <h4 v-if="note.note_type === 'mandate'">{{profile.incident_detail.see_note}}</h4>
                    <h4 v-else>{{profile.incident_detail.edit_note}}</h4>
                    <span class="written-by" style="max-width: 275px; margin-bottom: 10px; float: left; clear: none;"><i class="fas fa-feather"></i> {{note.actual_name}}</span>
                    <span class="written-by" style="max-width: 275px; margin-bottom: 10px; float: left; clear: none; margin-left: 5px;" v-if="note.incident_note_notification_id_shiro_user_receiver && !note.incident_note_notification_incident_note_read" v-tooltip.right="profile.incident_detail.important + ' ' + note.incident_note_notification_name_user_receiver"><i class="fas fa-exclamation" style="color: orange !important;"></i></span>
                    <span class="mandate-violation" :class="{active: incidentInfo.mandate_warning}" v-if="note.note_type === 'mandate'" style="font-size: 10px; margin-top: 0; margin-left: 5px;"><i class="fa fa-exclamation"></i> {{profile.mandates.mandate_violation}}</span>
                    <div class="form-field" :class="{focus:note.content.length}" style="clear: both;">
                        <label v-if="note.note_type !== 'mandate'">{{profile.incident_detail.note}}</label>
                        <p v-if="note.note_type === 'mandate'" style="float: left; margin: 15px 0;">{{note.content}}</p>
                        <textarea class="form-control" data-testid="noteTextEdit" v-model="note.content" v-else style="resize: vertical; height: 200px;"></textarea>
                    </div>
                    <div class="button-wrap">
                        <button type="submit" class="button button--icon" style="margin-right: 0;" data-testid="noteSubmitEdit" v-if="note.note_type !== 'mandate'"><i class="fa fa-save"></i>{{profile.buttons.save}}</button>
                        <span class="button button--icon button--left button--remove" data-testid="noteDeleteButton" @click="removeNote()" style="margin-right: 20px;" v-if="note.note_type !== 'mandate'"><i class="far fa-trash-alt"></i>{{profile.incident_detail.delete_note}}</span>
                    </div>
                </form>
                <form v-on:submit.prevent="addHandler()" v-else>
                    <div class="col">
                        <div class="row">
                            <span class="pre-url" style="float: left; margin: 12px 10px 0 0; font-weight: 500;">https://</span>
                            <div class="form-field" :class="{focus:note.content.length}" style="float: left;">
                                <label>Hyperlink</label>
                                <input type="text" class="form-control url" v-model="hyperlink" />
                            </div>
                        </div>
                    </div>
                    <div class="button-wrap">
                        <button type="submit" class="button button--icon" data-testid="noteSubmitButton"><i class="fas fa-sticky-note"></i>{{profile.buttons.save}}</button>
                        <span class="button button--icon button--left button--remove" data-testid="noteDeleteButton" @click="removeNote()" style="margin-right: 20px;" v-if="note.note_type !== 'mandate'"><i class="far fa-trash-alt"></i>{{profile.incident_detail.delete_note}}</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'createNoteModal',
    data() {
        return {
            profile: {},
            currentTab: '',
            hyperlink: ''
        }
    },
    props: ['note', 'incidentInfo', 'supplier'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        if (this.note.is_url) {
            this.currentTab = 'link'
            this.hyperlink = this.note.content.split('https://')[1];
        }
        else {
            this.currentTab = 'note';
        }
    },
    beforeDestroy() {
        this.removeHandler();
    },
    methods: {
        closeModal() {
            if (this.note.incident_note_notification_id_shiro_user_receiver && this.note.incident_note_notification_id_shiro_user_receiver === this.incidentInfo.current_user_id && !this.note.incident_note_notification_incident_note_read) {
                this.setAsRead();
            }
            else {
                this.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            }
        },
        addHandler() {
            window.addEventListener('submit', this.editNote);
        },
        removeHandler() {
            window.removeEventListener('submit', this.editNote);
        },
        editNote() {
            this.applicationLog();
            let currentIncidentId = this.getUrlParameter('id');
            if (this.note.incident_note_notification_id_shiro_user_receiver && this.note.incident_note_notification_id_shiro_user_receiver === this.incidentInfo.current_user_id && !this.note.incident_note_notification_incident_note_read) {
                this.setAsRead();
            }
            if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString()) {
                const data = new URLSearchParams();
                data.append('id_incident_note', this.note.id_incident_note);
                if (this.currentTab === 'link') {
                    data.append('content', 'https://' + this.hyperlink);
                }
                else {
                    data.append('content', this.note.content);
                }
                data.append('action', 'edit');
                let self = this;
                fetch('/api/v1/incident_note/update', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.closeModal();
                    self.$emit('getnotes');
                });
            }
        },
        removeNote() {
            this.applicationLogRemove();
            let queryString = window.location.search;
            let currentIncidentId = this.getUrlParameter('id');
            if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString()) {
                const data = new URLSearchParams();
                data.append('id_incident_note', this.note.id_incident_note);
                data.append('action', 'remove');
                let self = this;
                fetch('/api/v1/incident_note/delete', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('getnotes');
                    self.closeModal();
                });
            }
        },
        applicationLog() {
            try {
                const data = new URLSearchParams();
                data.append('message', 'Edit note');
                data.append('id_incident', this.incidentInfo.id_incident);
                let self = this;
                fetch('/api/v1/applicationlog/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {});
            } catch (err) {
                console.log(err);
            }
        },
        applicationLogRemove() {
            try {
                const data = new URLSearchParams();
                data.append('message', 'Remove note');
                data.append('id_incident', this.incidentInfo.id_incident);
                let self = this;
                fetch('/api/v1/applicationlog/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {});
            } catch (err) {
                console.log(err);
            }
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        setAsRead() {
            const data = new URLSearchParams();
            data.append('id_incident_note', this.note.id_incident_note);
            data.append('id_shiro_user_receiver', this.note.incident_note_notification_id_shiro_user_receiver);
            let self = this;
            fetch('/api/v1/incident_note/setnotificationasread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('getnotes');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>
