<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-list"></i>{{profile.actionable.recordings}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div v-if="!selectedAction.id_planned_action">
                    <div class="row" v-if="actionListsPlanned.length">
                        <div class="col">
                            <h2 style="font-size: 18px; margin-bottom: 20px;">{{profile.actionable.planned_recordings}}</h2>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">{{profile.actionable.actions}}</th>
                                        <th width="20%" scope="col">{{profile.actionable.latest_recording_date}}</th>
                                        <th width="20%" scope="col">{{profile.incident_detail.mechanic_comment}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="list in actionListsPlanned" :key="list.id_planned_action_list">
                                        <td width="40%">
                                            <span v-for="action in list.planned_actions" :key="action.id_planned_action" style="display: block; text-decoration: underline; color: #0867b3;" @click="selectActionList(list, action)">
                                                {{ action.name }}
                                            </span>
                                        </td>
                                        <td width="20%">{{list.latestDate}}</td>
                                        <td width="20%">{{list.mechanic_name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-if="actionListsStarted.length">
                        <div class="col">
                            <h2 style="font-size: 18px; margin-bottom: 20px;">{{profile.actionable.started_recordings}}</h2>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">{{profile.actionable.actions}}</th>
                                        <th width="20%" scope="col">{{profile.actionable.latest_recording_date}}</th>
                                        <th width="20%" scope="col">{{profile.actionable.recording_date}}</th>
                                        <th width="20%" scope="col">{{profile.incident_detail.mechanic_comment}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="list in actionListsStarted" :key="list.id_planned_action_list">
                                        <td width="40%">
                                            <span v-for="action in list.planned_actions" :key="action.id_planned_action" style="display: block; text-decoration: underline; color: #0867b3;" @click="selectActionList(list, action)">
                                                {{ action.name }}
                                            </span>
                                        </td>
                                        <td width="20%">{{list.latestDate}}</td>
                                        <td width="20%">{{list.completedDate}}</td>
                                        <td width="20%">{{list.mechanic_name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-if="actionListsCompleted.length">
                        <div class="col">
                            <h2 style="font-size: 18px; margin-bottom: 20px;">{{profile.actionable.completed_recordings}}</h2>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">{{profile.actionable.actionable_name}}</th>
                                        <th width="20%" scope="col">{{profile.actionable.latest_recording_date}}</th>
                                        <th width="20%" scope="col">{{profile.actionable.recording_date}}</th>
                                        <th width="20%" scope="col">{{profile.incident_detail.mechanic_comment}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="list in actionListsCompleted" :key="list.id_planned_action_list">
                                        <td width="40%">
                                            <span v-for="action in list.planned_actions" :key="action.id_planned_action" @click="selectActionList(list, action)" style="display: block; text-decoration: underline; color: #0867b3;">
                                                {{ action.name }}
                                            </span>
                                        </td>                                        
                                        <td width="20%">{{list.latestDate}}</td>
                                        <td width="20%">{{list.completedDate}}</td>
                                        <td width="20%">{{list.mechanic_name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-if="actionListsArchived.length">
                        <div class="col">
                            <h2 class="head-toggle" style="cursor: pointer; font-size: 18px; margin: 20px 0;" :class="{ active: showArchived }" @click="showArchived = !showArchived">{{ profile.actionable.archived_recordings }} <i class="fas fa-chevron-down"></i></h2>
                            <table class="table table-striped" v-if="actionListsArchived.length && showArchived">
                                <thead>
                                    <tr>
                                        <th width="40%" scope="col">{{profile.actionable.actionable_name}}</th>
                                        <th width="20%" scope="col">{{profile.actionable.latest_recording_date}}</th>
                                        <th width="20%" scope="col">{{profile.actionable.recording_date}}</th>
                                        <th width="20%" scope="col">{{profile.incident_detail.mechanic_comment}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="list in actionListsArchived" :key="list.id_planned_action_list">
                                        <td width="40%">
                                            <span v-for="action in list.planned_actions" :key="action.id_planned_action" @click="selectActionList(list, action)" style="display: block; text-decoration: underline; color: #0867b3;">
                                                {{ action.name }}
                                            </span>
                                        </td>                                        
                                        <td width="20%">{{list.latestDate}}</td>
                                        <td width="20%">{{list.completedDate}}</td>
                                        <td width="20%">{{list.mechanic_name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col">
                            <a class="back-link" @click="selectedAction = {}" style="margin-bottom: 10px;"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h3 style="font-size: 21px; margin: 0 0 20px 0; color: #212121; padding-left: 0;">{{selectedAction.planned_action_list_name}}</h3>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label>{{profile.incidents.new_incident.id_asset}}</label>
                                    <p><router-link tag="a" style="display: inline;" v-bind:to="'/buildingmanager/asset-detail?asset=' + selectedAction.id_asset">{{selectedAction.asset_name}}</router-link><br />{{selectedAction.address}} {{selectedAction.house_number}}, {{profile.incident_detail.floor_name}} {{selectedAction.floor_name}} <br /><router-link tag="a" style="display: inline;" v-bind:to="'/buildingmanager/location-detail?id_place=' + selectedAction.id_place">{{selectedAction.place_name}}</router-link></p>
                                </div>
                                <div class="col-sm-6">
                                    <label>{{profile.actionable.latest_recording_date}}</label>
                                    <p>{{selectedAction.latestDate}}</p>
                                </div>
                            </div>
                            <div class="row" v-if="selectedAction.completedDate">
                                <div class="col-sm-6">
                                    <label>{{profile.actionable.recording_date}}</label>
                                    <p>{{ selectedAction.completedDate }}</p>
                                </div>          
                            </div>
                            <p class="invoice-toggler active">{{profile.actionable.actionable}}: <b>{{selectedAction.planned_action_name}} <span v-if="selectedAction.origin === 'mechanic'" class="role-label role-label--mechanic">{{profile.incident_detail.mechanic_comment}}</span></b></p>
                            <div class="invoice-info" v-if="actionInfoToggled">
                                <h2 style="margin-bottom: 10px; font-size: 18px;">{{ profile.accountPage.account.general }}</h2>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAction.state">
                                        <label>{{profile.actionable.allover_state}}</label>
                                        <p>{{selectedAction.state}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAction.remarks">
                                        <label>{{profile.owners.ownertable.description}}</label>
                                        <p>{{selectedAction.remarks}}</p>
                                    </div>
                                </div>
                                <h2 style="margin-bottom: 10px; font-size: 18px;">{{ profile.actionable.repeating_job }}</h2>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAction.work">
                                        <label>{{profile.actionable.work}}</label>
                                        <p>{{selectedAction.work}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAction.frequency">
                                        <label>{{profile.actionable.frequency}}</label>
                                        <p>{{selectedAction.frequency}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAction.firstAction">
                                        <label>{{profile.actionable.suggestion_first_action}}</label>
                                        <p>{{selectedAction.firstAction}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="media.length || movs.length">
                        <div class="col">
                            <div class="incident-media" style="margin-top: 20px;">
                                <label>{{profile.incident_detail.imagery}}</label>
                                <div class="media-wrapper recordings">
                                    <div class="item-wrapper" v-for="(item, index) in media" :key="index">
                                        <div class="media media--small" :class="{active: item.popover, video: item.content_type === 'video/mp4'}" v-tooltip.left="item.file_upload_description">
                                            <img :src="item.file_url" @click="openPopover(item)" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                                            <div class="iframe_overlay" v-if="item.i_frame_code" @click="openPopover(item)" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                                            <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                                            <div class="video_overlay" @click="openPopover(item)" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                                            <video v-if="item.content_type === 'video/mp4'" @click="openPopover(item)">
                                                <source :src="item.file_url" type="video/mp4">
                                            </video>
                                            <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                            <div class="media-pop-over">
                                                <span class="close-popover" @click="closePopover()"><i class="fa fa-times"></i></span>
                                                <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                <span class="media-counter">{{index + 1}} / {{media.length}}</span>
                                                <figure class="zoom" :style="{ 'background-image': 'url(' + item.file_url + ')' }" @mousemove="zoom" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}">
                                                    <img @click="closePopover()" :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}" />
                                                </figure>
                                                <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: 40px;"></div>
                                                <video v-if="item.content_type === 'video/mp4'" :class="{rotated: item.rotate}" controls><source :src="item.file_url" type="video/mp4"></video>
                                                <p v-if="item.description">{{item.description}}</p>
                                                <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index)"><i class="fa fa-chevron-left"></i></span>
                                                <span class="media-control right" v-if="index + 1 !== media.length" @click="selectMedia('next', index)"><i class="fa fa-chevron-right"></i></span>
                                            </div>
                                        </div>
                                        <div class="media video" v-for="item in movs" :key="item.id_file_upload">
                                            <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                            <span class="placeholder"><i class="fas fa-file-video"></i></span>
                                            <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';

export default {
    mixins: [setLocale],
    props: ['actionListsPlanned', 'actionListsStarted', 'actionListsCompleted', 'actionListsArchived', 'qrCode'],
    data() {
        return {
            showArchived: false,
            selectedAction: {},
            actionInfoToggled: true,
            media: [],
            movs: []
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getActionLists() {
            this.selectedAction = {};
            this.actionListsCompleted = [];
            this.actionListsArchived = [];
            let self = this;
            fetch('/api/v1/plannedactionlist/byqrcodewithactions?qr_code=' + this.qrCode)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let list = data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            list.latestDate  = moment(list.latest_possible).locale('nl').format('D MMMM YYYY');
                            if (list.completed_date) {
                                list.completedDate  = moment(list.completed_date).locale('nl').format('D MMMM YYYY');
                            }
                        }
                        if (locale === 'en') {
                            list.latestDate  = moment(list.latest_possible).locale('en').format('MMMM D YYYY');
                            if (list.completed_date) {
                                list.completedDate  = moment(list.completed_date).locale('en').format('MMMM D YYYY');
                            }
                        }
                        if (list.status === 'planned') {
                            self.actionListsPlanned.push(list);
                        }
                        if (list.status === 'started') {
                            self.actionListsStarted.push(list);
                        }
                        if (list.status === 'completed') {
                            self.actionListsCompleted.push(list);
                        }
                        if (list.status === 'archived') {
                            self.actionListsArchived.push(list);
                        }
                    }
                });
            });
        },
        selectActionList(list, action) {
            let self = this;
            fetch('/api/v1/plannedaction?id_planned_action=' + action.id_planned_action)
            .then(response => { response.json().then(
                function(data) {
                    self.selectedAction = list;
                    self.selectedAction.id_planned_action = action.id_planned_action;
                    self.selectedAction.id_asset = data.id_asset;
                    self.selectedAction.id_place = data.id_place;
                    self.media = [];
                    self.movs = [];
                    for (var i = 0; i < data.planned_action_fields.length; i++) {
                        let field = data.planned_action_fields[i];
                        if (field.actionable_field_type === 'media' && field.content_type !== 'video/quicktime' && field.file_url) {
                            field.toggledAdd = false;
                            field.popover = false;
                            field.rotate = 1;
                            field.order = 1;
                            field.selected = false;
                            self.media.push(field);
                        }
                        if (field.content_type === 'video/quicktime') {
                            self.movs.push(field);
                        }
                        console.log(self.media);
                        if (field.name === 'Algehele staat') {
                            self.selectedAction.state = field.value;
                        }
                        if (field.name === 'Werk') {
                            self.selectedAction.work = field.value;
                        }
                        if (field.name === 'Repeterend werk frequentie') {
                            self.selectedAction.frequency = field.value;
                        }
                        if (field.name === 'Voorstel eerste actie') {
                            self.selectedAction.firstAction = Number(field.value);          
                        }
                        if (field.name === 'Opmerkingen') {
                            self.selectedAction.remarks = field.value;
                        }
                    }
                    if (self.selectedAction.firstAction) {
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            self.selectedAction.firstAction  = moment(self.selectedAction.firstAction).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            self.selectedAction.firstAction  = moment(self.selectedAction.firstAction).locale('en').format('MMMM D YYYY');
                        }
                    }           
                });
            });
        },
        zoom(e){
            let offsetX;
            let offsetY;
            let x;
            let y;
            let zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            document.querySelector('body').classList.add('media-open');
        },
        closePopover() {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < this.media.length; i++) {
                this.media[i].popover = false;
            }
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        }
    }
}
</script>
