<template>
    <div class="block block--account block--border">
        <div class="account-info">
            <h4>{{profile.accountPage.company.my_company}}
                <span class="edit-button edit-button--small" @click="companyInfoEdit = true" v-if="!companyInfoEdit && accountInfo.building_manager_admin" data-testid="companyEditButton"><i class="fas fa-pen"></i></span>
                <span class="edit-button edit-button--small" @click="saveCompanyInfo()" v-if="companyInfoEdit  && accountInfo.building_manager_admin" data-testid="companySaveButton"><i class="fas fa-save"></i></span>
            </h4>
            <div class="account-contents">
                <div v-if="!companyInfoEdit">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactname}}</label>
                            <p data-testid="managerNameText">{{accountInfo.building_manager_contact_name}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactmobile}}</label>
                            <p data-testid="managerMobileText">{{accountInfo.building_manager_mobile}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactphone}}</label>
                            <p data-testid="managerPhoneText">{{accountInfo.building_manager_phone}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.ooo_phone}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.no_ooo" style="cursor: pointer;"></i></label>
                            <p data-testid="managerOutsidePhoneText">{{accountInfo.ooophone}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.correspondence_email}}  <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.correspondence_tooltip" style="cursor: pointer;"></i></label>
                            <p data-testid="managerCorrespondentText" v-if="accountInfo.building_manager_correspondence_email !== 'null'">{{accountInfo.building_manager_correspondence_email}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactemail}}</label>
                            <p data-testid="managerEmailText">{{accountInfo.building_manager_email}}</p>
                        </div>
                    </div>
                    <div class="row" v-if="vve">
                        <div class="col-sm-6">
                            <label>GLN</label>
                            <p>{{accountInfo.gln}}</p>
                        </div>
                    </div>
                </div>
                <div v-if="companyInfoEdit">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactname}}</label>
                            <input type="text" class="form-control" v-model="accountInfo.building_manager_contact_name" data-testid="companyInputName" />
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactmobile}}</label>
                            <input type="text" class="form-control" v-model="accountInfo.building_manager_mobile" @input="validateMobilePhone()" :class="{valid: validMobilePhone, error: invalidMobilePhone}" data-testid="companyInputMobile" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactphone}}</label>
                            <input type="text" class="form-control" v-model="accountInfo.building_manager_phone" @input="validateGeneralPhone()" :class="{valid: validGeneralPhone, error: invalidGeneralPhone}" data-testid="companyInputPhone" />
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.ooo_phone}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.no_ooo" style="cursor: pointer;"></i></label>
                            <input type="text" class="form-control" v-model="accountInfo.ooophone" @input="validateOooPhone()" :class="{valid: validOooPhone, error: invalidOooPhone}" data-testid="companyInputExtraPhone" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.correspondence_email}}</label>
                            <input type="text" class="form-control" v-model="accountInfo.building_manager_correspondence_email" @input="validateCorrEmail()" :class="{valid: validCorrEmail, error: invalidCorrEmail}" data-testid="companyInputEmail" />
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactemail}}</label>
                            <input type="text" class="form-control" v-model="accountInfo.building_manager_email" @input="validateEmail()" :class="{valid: validEmail, error: invalidEmail}" data-testid="companyInputContact" />
                        </div>
                    </div>
                    <div class="row" v-if="vve">
                        <div class="col-sm-6">
                            <label>GLN</label>
                            <input type="text" class="form-control" v-model="accountInfo.gln" @keypress="isNumber($event)" @keyup="validateGLN()" :class="{valid: validGLN}" maxlength="20" />
                        </div>
                    </div>  
                </div>
            </div>
            <hr />
            <div class="account-info">
                <div class="account-contents">
                    <div v-if="companyInfoEdit">
                        <div class="row">
                            <div class="col">
                                <label>{{profile.repair_companies.repaircompanytable.corrEmail}}</label>
                                <input type="text" class="form-control" v-model="accountInfo.building_manager_email_addresses" data-testid="companyInputReceiver" />
                            </div> 
                        </div>  
                    </div>
                    <div v-else>
                        <div class="row">
                            <div class="col-sm-6">
                                <label>{{profile.repair_companies.repaircompanytable.corrEmail}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.receivers_tooltip" style="cursor: pointer;"></i></label>
                                <p data-testid="managerNewEmailText">{{accountInfo.building_manager_email_addresses}}</p>
                            </div>
                        </div>            
                    </div>
                </div>
            </div>     
            <div class="row">
                <div class="col">
                    <div class="switch-wrapper" v-if="accountInfo.building_manager_admin" style="margin: 0 0 5px 0;">
                        <label class="switch" :class="{disabled: !accountInfo.building_manager_email_addresses}">
                            <input type="checkbox" v-model="accountInfo.send_incident_new" @change="toggleSendMailNewIncident()" :disabled="!accountInfo.building_manager_email_addresses">
                            <span class="slider round"></span>
                        </label>
                        <span>{{profile.employees.send_new_incident}} <i class="fas fa-info-circle" v-tooltip.right="profile.employees.send_new_incident_tooltip" style="cursor: pointer;"></i></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="switch-wrapper" v-if="accountInfo.building_manager_admin" style="margin: 0;">
                        <label class="switch" :class="{disabled: !accountInfo.building_manager_email_addresses}">
                            <input type="checkbox" v-model="accountInfo.send_incident_closed" @change="toggleSendMailClose()" :disabled="!accountInfo.building_manager_email_addresses">
                            <span class="slider round"></span>
                        </label>
                        <span>{{profile.employees.send_on_close}} <i class="fas fa-info-circle" v-tooltip.right="profile.employees.send_on_close_tooltip" style="cursor: pointer;"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    name: 'CompanyBlock',
    mixins: [setLocale],
    data: function () {
        return {
            companyInfoEdit: false,
            validGeneralPhone: false,
            invalidGeneralPhone: false,
            validMobilePhone: false,
            invalidMobilePhone: false,
            validOooPhone: false,
            invalidOooPhone: false,
            validCorrEmail: false,
            invalidCorrEmail: false,
            validEmail: false,
            invalidEmail: false,
            validGLN: false
        }
    },
    props: ['accountInfo', 'vve'],
    methods: {
        saveCompanyInfo() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][contact_name]', this.accountInfo.building_manager_contact_name);
            params.append('data[0][mobile]', this.accountInfo.building_manager_mobile);
            params.append('data[0][phone]', this.accountInfo.building_manager_phone);
            params.append('data[0][email]', this.accountInfo.building_manager_email);
            params.append('data[0][ooophone]', this.accountInfo.ooophone);
            params.append('data[0][correspondence_email]', this.accountInfo.building_manager_correspondence_email);
            params.append('data[0][email_addresses]', this.accountInfo.building_manager_email_addresses);
            params.append('data[0][gln]', this.accountInfo.gln);
            let self = this;
            if (!this.invalidEmail && !this.invalidCorrEmail && !this.invalidOooPhone && !this.invalidMobilePhone && !this.invalidGeneralPhone) {
                fetch('/api/v1/buildingmanager', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.companyInfoEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        validateCorrEmail() {
            if (this.accountInfo.building_manager_correspondence_email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.accountInfo.building_manager_correspondence_email)) {
                    this.validCorrEmail = true;
                    this.invalidCorrEmail = false;
                }
                else {
                    this.invalidCorrEmail = true;
                    this.validCorrEmail = false;
                }
            }
            else {
                this.invalidCorrEmail = false;
                this.validCorrEmail = false;
            }
        },
        validateEmail() {
            if (this.accountInfo.building_manager_email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.accountInfo.building_manager_email)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        validateGeneralPhone() {
            if (this.accountInfo.building_manager_phone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.building_manager_phone)) {
                    this.validGeneralPhone = true;
                    this.invalidGeneralPhone = false;
                }
                else {
                    this.invalidGeneralPhone = true;
                    this.validGeneralPhone = false;
                }
            }
            else {
                this.invalidGeneralPhone = false;
                this.validGeneralPhone = false;
            }
        },
        validateMobilePhone() {
            if (this.accountInfo.building_manager_mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.building_manager_mobile)) {
                    this.validMobilePhone = true;
                    this.invalidMobilePhone = false;
                }
                else {
                    this.invalidMobilePhone = true;
                    this.validMobilePhone = false;
                }
            }
            else {
                this.invalidMobilePhone = false;
                this.validMobilePhone = false;
            }
        },
        validateOooPhone() {
            if (this.accountInfo.ooophone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.ooophone)) {
                    this.validOooPhone = true;
                    this.invalidOooPhone = false;
                }
                else {
                    this.invalidOooPhone = true;
                    this.validOooPhone = false;
                }
            }
            else {
                this.invalidOooPhone = false;
                this.validOooPhone = false;
            }
        },
        validateGLN() {
            if (this.accountInfo.gln) {
                if (this.accountInfo.gln.length) {
                    this.validGLN = true;
                }
                else {
                    this.validGLN = false;
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        toggleSendMailClose() {
            const params = new URLSearchParams();
            params.append('data[0][send_incident_closed]', this.accountInfo.send_incident_closed);
            params.append('action', 'edit');
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        toggleSendMailNewIncident() {
            const params = new URLSearchParams();
            params.append('data[0][send_incident_new]', this.accountInfo.send_incident_new);
            params.append('action', 'edit');
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
