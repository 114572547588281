<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>Terug naar vorige</a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-10">
                        <div class="block block--account">
                            <div class="profile-card" style="border-right: 0;">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="profile-pic-wrapper">
                                            <img src="@/assets/icons/loading.gif" v-if="logoLoading" class="loader" style="position: relative; width: 100%; float: right; right: auto;" />
                                            <div v-else>
                                                <span class="edit" @click="editLogo()" v-if="manager.profile_image_loc && manager.profile_image_loc !== '/images/no-profile-img.png'"><i class="fas fa-pen"></i></span>
                                                <span class="seperator" v-if="manager.profile_image_loc && manager.profile_image_loc !== '/images/no-profile-img.png'">|</span>
                                                <span class="remove" @click="removeLogo()" v-if="manager.profile_image_loc && manager.profile_image_loc !== '/images/no-profile-img.png'"><i class="far fa-trash-alt"></i></span>
                                                <img :src="manager.profile_image_loc" class="profile-pic" v-if="manager.profile_image_loc && manager.profile_image_loc !== '/images/no-profile-img.png'" />
                                                <span class="add" @click="editLogo()" v-if="!manager.profile_image_loc || manager.profile_image_loc === '/images/no-profile-img.png'"><i class="fas fa-plus"></i></span>
                                                <div class="profile-pic placeholder" v-if="!manager.profile_image_loc || manager.profile_image_loc === '/images/no-profile-img.png'">
                                                    <i class="far fa-image"></i>
                                                </div>
                                                <input type="file" id="profile-image-upload" style="display: none;" @change="uploadLogo($event)"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-9" style="margin-bottom:7px;">
                                        <h4>Beheerder</h4>
                                        <h2>{{manager.name}}</h2>
                                        <span style="margin-top:15px; min-width:220px" class="button button--icon" @click="uploadLocationModalOpen = true"><i class="fa fa-upload"></i>Importeer locatiebestand</span>
                                    </div>
                                </div>
                            </div>
                            <span class="edit-button icon" @click="removePartnerModalOpen = true" style="position: absolute; top: 0;
                            bottom: 0; margin: auto; height: 35px;" v-tooltip.left="'Beheerder inactiveren'"><i class="fa fa-user-alt-slash"></i></span>
                            <!-- fa fa-eye-slash -->
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="block block--incident_detail location" style="min-height: 0;">
                            <img :src="'/api/v1/buildingmanager/qrcodeasimage?id_building_manager=' + manager.id_building_manager" class="qr-code" style="margin: 2px auto -5px auto; width: 100px; display: block;" v-tooltip.right="manager.service_qr_code" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="tabs">
                    <div class="tab" @click="switchTab('general')" :class="{active: currentTab === 'general'}">
                        <h3>Algemeen</h3>
                    </div>
                    <div class="tab" @click="switchTab('categories')" :class="{active: currentTab === 'categories'}">
                        <h3>Categorieën</h3>
                    </div>
                    <div class="tab" @click="switchTab('categorysets')" :class="{active: currentTab === 'categorysets'}">
                        <h3>Categoriesets</h3>
                    </div>
                    <div class="tab" @click="switchTab('RelatedBM')" :class="{active: currentTab === 'RelatedBM'}">
                        <h3>Gerelateerde beheerders</h3>
                    </div>
                    <div class="tab" @click="switchTab('API Users')" :class="{active: currentTab === 'API Users'}">
                        <h3>API Gebruikers</h3>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'general'}">
                    <div class="block block--border">
                        <section class="incident-head">
                            <h1>Features</h1>
                        </section>
                        <br>
                        <div class="row">
                            <b class="col-sm-4">Basis features</b>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-sm-4">
                                <span>Servicedesk betaald account</span>
                                <input type="checkbox" v-model="manager.is_paid_account" @change="toggleFeature('is_paid_account', manager.is_paid_account)">
                            </div>
                            <div class="col-sm-4">
                                <span>Mag gekoppelde meldingen maken</span>
                                <input type="checkbox" v-model="manager.can_copy_incidents" @change="toggleFeature('can_copy_incidents', manager.can_copy_incidents)">
                            </div>
                            <div class="col-sm-4">
                                <span>Mag externe ID's aanpassen</span>
                                <input type="checkbox" v-model="manager.can_change_external_ids" @change="toggleFeature('can_change_external_ids', manager.can_change_external_ids)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <span>Whatsapp flow</span>
                                <input type="checkbox" v-model="manager.whatsapp_flow" @change="toggleFeature('whatsapp_flow', manager.whatsapp_flow)">
                            </div>
                            <div class="col-sm-4">
                                <span>Twinq flow</span>
                                <input type="checkbox" v-model="manager.twinq_flow" @change="toggleFeature('twinq_flow', manager.twinq_flow)">
                            </div>
                            <div class="col-sm-4">
                                <span>Twinq no status updates</span>
                                <input type="checkbox" v-model="manager.twinq_no_status_updates" @change="toggleFeature('twinq_no_status_updates', manager.twinq_no_status_updates)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <span>Is VVE</span>
                                <input type="checkbox" v-model="manager.is_vve" @change="toggleFeature('is_vve', manager.is_vve)">
                            </div>
                            <div class="col-sm-4">
                                <span>Aanpassen basisdata uitzetten</span>
                                <input type="checkbox" v-model="manager.disable_edit_basedata" @change="toggleFeature('disable_edit_basedata', manager.disable_edit_basedata)">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <b class="col-sm-4">Premium features</b>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-sm-4">
                                <span>Uitgebreide e-mail reparateur</span>
                                <input type="checkbox" v-model="manager.comprehensive_emails_repair_company" @change="toggleFeature('comprehensive_emails_repair_company', manager.comprehensive_emails_repair_company)">
                            </div>
                            <div class="col-sm-4">
                                <span>Uitgebreide e-mail eigenaar</span>
                                <input type="checkbox" v-model="manager.comprehensive_emails_owner" @change="toggleFeature('comprehensive_emails_owner', manager.comprehensive_emails_owner)">
                            </div>
                            <div class="col-sm-4">
                                <span>Uitgebreide e-mail beheerder</span>
                                <input type="checkbox" v-model="manager.comprehensive_emails_building_manager" @change="toggleFeature('comprehensive_emails_building_manager', manager.comprehensive_emails_building_manager)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <span>Autodispatch</span>
                                <input type="checkbox" v-model="manager.can_autodispatch" @change="toggleFeature('can_autodispatch', manager.can_autodispatch)">
                            </div>
                            <div class="col-sm-4">
                                <span>Mandaten</span>
                                <input type="checkbox" v-model="manager.has_mandates" @change="toggleFeature('has_mandates', manager.has_mandates)">
                            </div>
                            <div class="col-sm-4">
                                <span>Opname module</span>
                                <input type="checkbox" v-model="manager.has_actionable_work" @change="toggleFeature('has_actionable_work', manager.has_actionable_work)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <span>Repeterend werk module</span>
                                <input type="checkbox" v-model="manager.recurring_job_module" @change="toggleFeature('recurring_job_module', manager.recurring_job_module)">
                            </div>
                            <div class="col-sm-4">
                                <span>Eigenarenchat</span>
                                <input type="checkbox" v-model="manager.can_owner_chat" @change="toggleFeature('can_owner_chat', manager.can_owner_chat)">
                            </div>
                            <div class="col-sm-4">
                                <span>Updates via WhatsApp</span>
                                <input type="checkbox" v-model="manager.updates_via_wa_active" @change="toggleFeature('updates_via_wa_active', manager.updates_via_wa_active)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <span>Akkorderingsflow</span>
                                <input type="checkbox" v-model="manager.has_agreement_flow" @change="toggleFeature('has_agreement_flow', manager.has_agreement_flow)">
                            </div>
                            <div class="col-sm-4">
                                <span>Mededelen extra kosten buiten kantoor uren</span>
                                <input type="checkbox" v-model="manager.outside_office_hours_extra" @change="toggleFeature('outside_office_hours_extra', manager.outside_office_hours_extra)">
                            </div>
                            <div class="col-sm-4">
                                <span>Auto translate</span>
                                <input type="checkbox" v-model="manager.auto_translate" @change="toggleFeature('auto_translate', manager.auto_translate)">
                            </div>
                        </div>
                    </div>
                    <div class="block block--border">
                        <div>
                            <div class="incident-head" style="position:relative">
                                <h3 style="margin: 0;"> Branche </h3>
                                <span class="edit-button icon" @click="saveIndustryInfo()" v-if="industryEdit" style="right: 0;"><i class="fa fa-save" style="font-size: 18px;"></i></span>
                            </div>
                            <div class="incident-contents" style="position:relative;" v-if="!industryEdit">
                                <div class="incident-info">
                                    <p>{{industryText}}</p>
                                    <span class="edit-button icon" @click="industryEdit = true" style="right: 0;"><i class="fas fa-pen" style="font-size: 18px;"></i></span>
                                </div>
                            </div>
                            <div class="incident-contents" style="position:relative;" v-else>
                                <div class="incident-info">
                                    <div class="dropdown-section" style="margin-bottom: 20px;">
                                        <select class="form-select" v-model="manager.industry_constant">
                                            <option v-for="industry in industries" :value="industry.constant_name" :key="industry.constant_name">{{industry.display_name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <hr style="margin-top: 0;"/>
                            <div class="incident-head" style="position:relative">
                                <h3 style="margin: 0;"> Algemene voorwaarden </h3>
                                <span class="edit-button icon" @click="saveTermsInfo()" v-if="termsConditionsEdit" style="right: 0;"><i class="fa fa-save" style="font-size: 18px;"></i></span>
                            </div>
                            <div class="incident-contents" style="position:relative;" v-if="!termsConditionsEdit">
                                <div class="incident-info">
                                    <p>{{manager.terms_conditions_url}}</p>
                                    <span class="edit-button icon" @click="termsConditionsEdit = true" style="right: 0;"><i class="fas fa-pen" style="font-size: 18px;"></i></span>
                                </div>
                            </div>
                            <div class="incident-contents" style="position:relative;" v-else>
                                <div class="incident-info">
                                    <input type="text" class="form-control" v-model="manager.terms_conditions_url" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <hr style="margin-top: 0;"/>
                            <div class="incident-head" style="position:relative">
                                <h3 style="margin: 0;"> Business Card Url </h3>
                                <span class="edit-button icon" @click="saveBusinessCardInfo()" v-if="businessCardEdit" style="right: 0;"><i class="fa fa-save" style="font-size: 18px;"></i></span>
                            </div>
                            <div class="incident-contents" style="position:relative;" v-if="!businessCardEdit">
                                <div class="incident-info">
                                    <p>{{manager.business_card_url}}</p>
                                    <span class="edit-button icon" @click="businessCardEdit = true" style="right: 0;"><i class="fas fa-pen" style="font-size: 18px;"></i></span>
                                </div>
                            </div>
                            <div class="incident-contents" style="position:relative;" v-else>
                                <div class="incident-info">
                                    <input type="text" class="form-control" v-model="manager.business_card_url" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block block--location_detail block--border">
                        <section class="incident-head">
                            <h1>Algemeen</h1>
                            <span class="edit-button icon" @click="editMode()" v-if="!partnerEdit" style="right: 0; top: 5px;"><i class="fas fa-pen" style="font-size: 18px; margin-right: 10px;"></i></span>
                            <span class="edit-button icon" @click="savePartnerInfo()" v-if="partnerEdit" style="right: 0;"><i class="fa fa-save" style="font-size: 18px; margin-right: 10px;"></i></span>
                        </section>
                        <section class="owner-contents">
                            <div class="owner-info">
                                <div style="position: relative;" v-if="!partnerEdit">
                                    <br />
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Naam beheerder</label>
                                            <p style="white-space: normal;">{{manager.name}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Telefoonnummer bedrijf</label>
                                            <p>{{manager.phone}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>E-mailadres</label>
                                            <p style="white-space: normal;">{{manager.email}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Facturatie e-mailadres</label>
                                            <p>{{manager.billing_email}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Contactpersoon</label>
                                            <p style="white-space: normal;">{{manager.contact_name}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Mobiel telefoonnummer contactpersoon</label>
                                            <p>{{manager.mobile}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Aantal actieve objecten</label>
                                            <p style="white-space: normal;">{{stickers}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Maximaal aantal objecten</label>
                                            <p>{{manager.paid_amount_assets}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Type account</label>
                                            <p style="white-space: normal;" v-if="manager.demo_account === false">Dit is een normaal account</p>
                                            <p style="white-space: normal;" v-else>Dit is een demo account</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Adres</label>
                                            <p>{{manager.address}} {{manager.house_number}}, {{manager.zipcode}} {{manager.city}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>KvK-nummer</label>
                                            <p style="white-space: normal;">{{manager.kvk}}</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Omschrijving</label>
                                            <p>{{ manager.description }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div style="position: relative;" v-else>
                                    <br />
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Naam beheerder</label>
                                            <input type="text" class="form-control" v-model="manager.name" />
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Telefoonnummer bedrijf</label>
                                            <input type="text" class="form-control" v-model="manager.phone" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>E-mailadres</label>
                                            <input type="text" class="form-control" v-model="manager.email" />
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Facturatie e-mailadres</label>
                                            <input type="text" class="form-control" v-model="manager.billing_email" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Contactpersoon</label>
                                            <input type="text" class="form-control" v-model="manager.contact_name" />
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Mobiel telefoonnummer contactpersoon</label>
                                            <vue-tel-input v-model="manager.mobile" class="form-control small" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone"></vue-tel-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Aantal actieve objecten</label>
                                            <input type="text" class="form-control" v-model="stickers" disabled />
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Maximaal aantal objecten</label>
                                            <input type="text" class="form-control" v-model="manager.paid_amount_assets" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <label>Straatnaam</label>
                                            <input type="text" class="form-control" v-model="manager.address" />
                                        </div>
                                        <div class="col-sm-3">
                                            <label>Huisnummer</label>
                                            <input type="text" class="form-control" v-model="manager.house_number" />
                                        </div>
                                        <div class="col-sm-3">
                                            <label>Postcode</label>
                                            <input type="text" class="form-control" v-model="manager.zipcode" />
                                        </div>
                                        <div class="col-sm-3">
                                            <label>Plaats</label>
                                            <input type="text" class="form-control" v-model="manager.city" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label for="manager.demo_account">Type account</label>
                                            <select class="form-select" v-model="manager.demo_account">
                                                <option :value="true" >Dit is een demo account</option>
                                                <option :value="false">Dit is een normaal account</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Adres</label>
                                            <p>{{manager.address}} {{manager.house_number}}, {{manager.zipcode}} {{manager.city}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>KvK-nummer</label>
                                            <input type="text" class="form-control" v-model="manager.kvk" />
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Omschrijving</label>
                                            <input type="text" class="form-control" v-model="manager.description" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'categories'}">
                    <Categories :managerID="managerID"/>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'categorysets'}">
                    <CategorySets :managerID="managerID" :assetTypes="assetTypes"/>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'RelatedBM'}">
                    <RelatedBM :managerID="managerID" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'API Users'}">
                    <ApiUsers :managerID="managerID" />
                </div>
                <RemovePartnerModal v-if="removePartnerModalOpen" @close="removePartnerModalOpen = false" :managerID="managerID" />
                <UploadLocationModal v-if="uploadLocationModalOpen" @close="uploadLocationModalOpen = false" :managerID="managerID" />
            </div>
        </div>
    </div>
</template>
<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { VueTelInput } from 'vue-tel-input';
import sortBy from 'lodash/sortBy';
import RemovePartnerModal from '@/views/s1mone/modals/RemovePartnerModal.vue';
import UploadLocationModal from '@/views/s1mone/modals/UploadLocationModal.vue';
import Categories from '@/views/s1mone/Categories.vue';
import CategorySets from '@/views/s1mone/CategorySets.vue';
import RelatedBM from '@/views/s1mone/RelatedBM.vue';
import ApiUsers from '@/views/s1mone/ApiUsers.vue';

export default {
    mixins: [setLocale],
    components: {
        VueTelInput,
        Categories,
        CategorySets,
        RelatedBM,
        RemovePartnerModal,
        UploadLocationModal,
        ApiUsers
    },
    data(){
        return {
            currentTab: 'general',
            managerID: null,
            manager: {},
            removePartnerModalOpen: false,
            uploadLocationModalOpen: false,
            logoLoading: false,
            partnerEdit: false,
            industryEdit: false,
            businessCardEdit: false,
            termsConditionsEdit: false,
            countryCode: '',
            validPhone: false,
            invalidPhone: false,
            formattedPhone: '',
            industryText: '',
            industries: [],
            assetTypes: [],
            relatedBuildingmanagers: [],
            stickers: 0,
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder:'',
                }
            }
        }
    },
    created() {
        this.bindProps.inputOptions.placeholder = "Mobiel Telefoonnummer";
        let queryString = window.location.search;
        this.managerID = queryString.split('=')[1];
        this.managerID = this.managerID.split('&')[0];
        this.loadManager();
        this.loadIndustries();
        this.loadStickers();
        this.getAssetTypes();
    },
    methods: {
        loadManager(){
            let self = this;
            fetch('/api/v1/buildingmanager/byid?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.manager = data;
                    self.validPhone = true;
                    fetch('/api/v1/industry/byname?constant_name=' + data.industry_constant)
            	        .then(response => { response.json().then(
            	            function(data) {
                                self.industryText = data.display_name;
            	            });
            	        });
                });
            });
        },
        loadIndustries(){
            let self = this;
            fetch('/api/v1/industry')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let industry = data.data[i];
                        if (industry.display_name !== 'Sensors') {
                            self.industries.push(industry);
                        }
                    }
                });
            });
        },
        loadStickers(){
            let self = this;
            fetch('/api/v1/stickerhistory/totalbybuildingmanager?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.stickers = data.coupledTotal;
                });
            });
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type')
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypes = sortBy(self.assetTypes, 'display_name');
                });
            });
        },
        uploadLogo($event) {
            this.logoLoading = true;
            if ($event.target.files.length == 1) {
                const data = new FormData();
                data.append('file', $event.target.files[0]);
                data.append('id_building_manager', this.managerID);
                let self = this;
                fetch('/api/v1/fileupload/uploadlogo', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.manager.profile_image_loc = data;
                    self.logoLoading = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        editLogo() {
            document.getElementById('profile-image-upload').click();
        },
        removeLogo() {
            const data = new URLSearchParams();
            data.append('id_building_manager', this.managerID);
            let self = this;
            fetch('/api/v1/fileupload/deletelogo', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.loadManager();
            });
        },
        switchTab(type) {
            this.currentTab = type;
        },
        editMode() {
            this.partnerEdit = true;
        },
        savePartnerInfo() {
            this.saveInfo();
            this.saveLocation();
            this.partnerEdit = false;
            this.$vToastify.success(this.profile.toast.edited, this.profile.toast.changes_saved);
        },
        saveInfo(){
            if (!this.manager.mobile) {
                this.formattedPhone = '';
            }
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.managerID + '][name]', this.manager.name);
            data.append('data[' + this.managerID + '][phone]', this.manager.phone);
            data.append('data[' + this.managerID + '][email]', this.manager.email);
            data.append('data[' + this.managerID + '][billingemail]', this.manager.billing_email);
            data.append('data[' + this.managerID + '][contact_name]', this.manager.contact_name);
            data.append('data[' + this.managerID + '][mobile]', this.manager.mobile);
            data.append('data[' + this.managerID + '][kvk]', this.manager.kvk);
            data.append('data[' + this.managerID + '][paid_amount_assets]', this.manager.paid_amount_assets);
            data.append('data[' + this.managerID + '][demo_account]', this.manager.demo_account);
            data.append('data[' + this.managerID + '][description]', this.manager.description);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.manager.mobile = self.formattedPhone;
            });
        },
        saveLocation(){
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.managerID + '][address]', this.manager.address);
            data.append('data[' + this.managerID + '][house_number]', this.manager.house_number);
            data.append('data[' + this.managerID + '][zipcode]', this.manager.zipcode);
            data.append('data[' + this.managerID + '][city]', this.manager.city);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
        },
        saveIndustryInfo(){
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.managerID + '][industry_constant]', this.manager.industry_constant);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                fetch('/api/v1/industry/byname?constant_name=' + self.manager.industry_constant)
            	        .then(response => { response.json().then(
            	            function(data) {
                                self.industryText = data.display_name;
            	            });
            	        });
                self.industryEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveTermsInfo(){
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.managerID + '][terms_conditions_url]', this.manager.terms_conditions_url);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.termsConditionsEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveBusinessCardInfo(){
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.managerID + '][business_card_url]', this.manager.business_card_url);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.businessCardEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        toggleFeature(feature, value) {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[' + this.manager.id_building_manager + '][' + feature +  ']', value);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
        },
        validatePhone: function (number, object) {
            this.countryCode = object.countryCode;
            if (object.countryCode === undefined) {
                object.countryCode = this.countryCode;
            }
            if (object.formatted) {
                if (object.valid) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.validPhone = true;
                this.invalidPhone = false;
            }
        }
    }
}
</script>