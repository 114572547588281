export const websocketBm = {
    data() {
        return {
            websocketBm: null,
            errorCount: 0
        }
    },
    created() {
        this.connect();
    },
    methods: {
        connect() {
            if ('localhost' === window.location.hostname) {
                this.websocketBm = new WebSocket('ws://'+window.location.hostname+':8080/ws/v2/buildingmanager');
            }
            else {
                this.websocketBm = new WebSocket('wss://'+window.location.host+'/ws/v2/buildingmanager');
            }
            this.websocketBm.onopen = () => {
                let route = window.location.pathname + window.location.search;
                let now = new Date().getTime();
                let routeObject = {
                    "type": "route_update",
                    "timestamp": now,
                    "data": {
                        "route": route
                    }
                }
                this.sendRoute(routeObject);
            };

            this.websocketBm.onmessage = (event) => {
                let obj = JSON.parse(event.data);
                if (obj.data && obj.data.route.includes('incident-detail?id=')) {
                    let id = obj.data.route.split('id=')[1];
                    let updateObj = {
                        "id_incident": id,
                        "user": obj.data.from_username,
                    }
                    this.updateItem(updateObj);
                }
                if (obj.data && obj.data.from_route && obj.data.from_route.includes('incident-detail?id=')) {
                    let id = obj.data.from_route.split('id=')[1];
                    let updateObj = {
                        "id_incident": id,
                        "from": true,
                        "user": obj.data.from_username
                    }
                    this.updateItem(updateObj);
                }
            };

            this.websocketBm.onclose = () => {
                let self = this;
                fetch('/api/v1/isauthenticated/asservicedesk')
                .then(response => { response.text().then(
                    function(data) {
                        if (data === 'true') {
                            self.connect();
                        }
                        else {
                            window.location = '/signin.html';
                        }
                    });
                });
            };

            this.websocketBm.onerror = (error) => {
                this.errorCount++;
            };
        },
        disconnect() {
            if (this.websocketBm) {
                this.websocketBm.close();
            }
        },
        sendRoute(routeObject) {
            this.errorCount = 0;
            const jsonString = JSON.stringify(routeObject);
            this.websocketBm.send(jsonString);
        }
    }
}
