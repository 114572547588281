<template>
    <div class="modal-wrapper" v-if="profile.assets">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-trash-alt"></i>{{profile.assets.assetmodal.remove_asset}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p v-html="profile.assets.assettable.removeareyousure"></p>
                <p><br /></p>
                <button class="button button--icon button--right" v-on:click="deactivateAsset()"><i class="fas fa-trash-alt"></i>{{profile.assets.assetmodal.remove_asset}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'deactivateAssetModal',
    props: ['asset', 'selectedAssetId', 'selectedPlace', 'selectedAssetTypeId'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        saveAsset() {
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            if (this.selectedPlace.id_place) {
                data.append('id_place', this.selectedPlace.id_place);
            }
            data.append('id_asset', this.asset.id_asset);
            data.append('holder', this.asset.holder);
            data.append('partof_id_asset', this.selectedAssetId);
            data.append('key_number', this.asset.key_number);
            data.append('link_360', this.asset.link_360);
            data.append('name', this.asset.name);
            if (this.selectedAssetTypeId === null || this.selectedAssetTypeId === 'none') {
                this.selectedAssetTypeId = 0;
            }
            if (this.canChangeExternalIds && this.asset.id_external) {
                data.append('id_external', this.asset.id_external);
            }
            if (this.selectedAssetTypeId !== this.asset.id_asset_type) {
                data.append('id_asset_type', this.selectedAssetTypeId);
                data.append('extra_description_a', '');
                data.append('extra_description_b', '');
                data.append('extra_description_c', '');
                data.append('extra_description_d', '');
                data.append('extra_description_e', '');
                data.append('extra_description_f', '');
                data.append('extra_description_g', '');
                data.append('extra_description_h', '');
                data.append('extra_description_i', '');
                data.append('extra_description_j', '');
            }
            else {
                data.append('id_asset_type', this.selectedAssetTypeId);
                data.append('extra_description_a', this.asset.extra_description_a);
                data.append('extra_description_b', this.asset.extra_description_b);
                data.append('extra_description_c', this.asset.extra_description_c);
                data.append('extra_description_d', this.asset.extra_description_d);
                data.append('extra_description_e', this.asset.extra_description_e);
                data.append('extra_description_f', this.asset.extra_description_f);
                data.append('extra_description_g', this.asset.extra_description_g);
                data.append('extra_description_h', this.asset.extra_description_h);
                data.append('extra_description_i', this.asset.extra_description_i);
                data.append('extra_description_j', this.asset.extra_description_j);
            }
            data.append('hidden', true);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
        },
        deactivateAsset() {
            this.saveAsset();
            const data = new URLSearchParams();
            let paramAddress = 'data[' + this.asset.id_asset + '][id_asset]';
            data.append('action', 'remove');
            data.append(paramAddress, this.asset.id_asset);
            let self = this;
            fetch('/api/v1/asset', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$router.push('/buildingmanager/assets');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
