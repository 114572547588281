<template>
    <div v-if="profile.places">
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--assets">
                    <section class="incident-head">
                        <h1 data-testid="newAssetTitle">{{profile.assets.add_object}}</h1>
                    </section>
                    <form v-on:submit.prevent="createNewAsset()" style="padding-top: 15px;">
                        <label>{{profile.incidents.new_incident.choose_place}} *</label>
                        <div class="form-field clearable" :class="{focus:placeSearchTerm.length}" style="margin-bottom: 10px;">
                            <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}}</label>
                            <div>
                                <div class="selected-info seperate" v-if="selectedPlace.name">
                                    <h4>{{selectedPlace.name}}</h4>
                                    <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                                    <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                    <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                </div>
                                <input type="text" ref="place" data-testid="newAssetPlaceSearch" class="form-control" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                            </div>
                        </div>
                        <ul v-if="places.length" data-testid="newAssetPlacesDropdown" class="dropdown-list" style="padding: 0; margin-top: -12px;">
                            <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" v-on:click="selectPlace(place)">
                                <h4 data-testid="newAssetLocationText">{{place.name}}</h4>
                                <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                                <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                            </li>
                        </ul>
                        <div class="row">
                            <div class="col-sm-6">
                                <label>{{profile.assets.assettable.name}} *</label>
                                <div class="form-field" :class="{focus:fields.name.length}" style="margin-bottom: 10px;">
                                    <label>{{profile.assets.assettable.name}}</label>
                                    <input type="text" data-testid="newAssetName" class="form-control" v-model="fields.name" />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="dropdown-section" style="margin-bottom: 10px;">
                                    <label>{{profile.assets.select_objecttype}}</label>
                                    <span class="dropdown" data-testid="newAssetAssetTypeDropdown" :class="{active: assetTypeDropdownToggled}" v-on:click="assetTypeDropdownToggled = !assetTypeDropdownToggled">
                                        <span v-if="!selectedAssetType.id_asset_type" class="placeholder">{{profile.assets.select_objecttype}}</span>
                                        <span v-if="selectedAssetType.id_asset_type" class="selected">{{selectedAssetType.display_name}}</span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </span>
                                    <div class="dropdown-list" data-testid="newAssetAssetTypeDropdownList" v-if="assetTypeDropdownToggled" :class="{search: assetTypes.length > 9}">
                                        <input type="text" data-testid="newAssetAssetTypeSearch" @input="e => assetTypeSearchTerm = e.target.value" :value="assetTypeSearchTerm" :placeholder="profile.assets.select_objecttype" @keyup="searchAssetTypes()" v-if="assetTypes.length > 9" />
                                        <ul>
                                            <li v-on:click="deselectAssetType()" v-if="!assetTypeNoResult && !tempAssetTypes.length">{{profile.assets.none}}</li>
                                            <li v-for="assetType in assetTypes" v-on:click="selectAssetType(assetType)" :key="assetType.id" v-show="!tempAssetTypes.length && !assetTypeNoResult">{{assetType.display_name}}</li>
                                            <li v-for="(assetType, index) in tempAssetTypes" v-on:click="selectAssetType(assetType)" :key="index" v-show="tempAssetTypes.length">{{assetType.display_name}}</li>
                                            <li v-if="assetTypeNoResult" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedAssetType.id_asset_type">
                            <label>{{profile.assets.objecttype_fields}}</label>
                            <div class="row">
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_a">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_a_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_a}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_a" v-model="assetTypeFields.extra_description_a" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_b">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_b_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_b}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_b" v-model="assetTypeFields.extra_description_b" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_c">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_c_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_c}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_c" v-model="assetTypeFields.extra_description_c" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_d">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_d_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_d}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_d" v-model="assetTypeFields.extra_description_d" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_e">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_e_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_e}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_e" v-model="assetTypeFields.extra_description_e" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_f">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_f_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_f}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_f" v-model="assetTypeFields.extra_description_f" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_g">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_g_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_g}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_g" v-model="assetTypeFields.extra_description_g" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_h">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_h_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_h}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_h" v-model="assetTypeFields.extra_description_h" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_i">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_i_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_i}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_i" v-model="assetTypeFields.extra_description_i" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6" v-if="selectedAssetType.extra_description_j">
                                    <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_j_default}">
                                        <label style="font-weight: 500;">{{selectedAssetType.extra_description_j}}</label>
                                        <input type="text" class="form-control" data-testid="newAssetAssetType_j" v-model="assetTypeFields.extra_description_j" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="notification notification--error" v-if="nameError">{{profile.assets.fill_name}}.</div>
                        <button type="submit" data-testid="newAssetSubmit" class="button button--icon"><i class="fas fa-map-marker-alt"></i>{{profile.assets.add_object}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    name: 'newLocation',
    data() {
        return {
            profile: {},
            places: [],
            fields: {
                name: ''
            },
            dropdownToggled: false,
            selectedPlace: {},
            placesNoResults: false,
            placeSearchTerm: '',
            assetTypes: [],
            tempAssetTypes: [],
            assetTypeNoResult: false,
            assetTypeDropdownToggled: false,
            assetTypeSearchTerm: '',
            selectedAssetType: {},
            nameError: false,
            assetTypeFields: {
                extra_description_a: '',
                extra_description_b: '',
                extra_description_c: '',
                extra_description_d: '',
                extra_description_e: '',
                extra_description_f: '',
                extra_description_g: '',
                extra_description_h: '',
                extra_description_i: '',
                extra_description_j: ''
            }
        }
    },
    created() {
        this.getCurrentBm();
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.place.focus();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getCurrentBm() {
            let self = this;
            fetch('/api/v1/buildingmanagerstats')
            .then(response => { response.json().then(
                function(data) {
                    let id_bm = data.building_manager.id_building_manager;
                    self.getAssetTypes(id_bm);
                });
            });
        },
        getAssetTypes(id_bm) {
            let self = this;
            fetch('/api/v1/asset_type/bybuildingmanager?id_building_manager=' + id_bm)
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypes = sortBy(self.assetTypes, 'display_name');
                });
            });
        },
        togglePlacesDropdown() {
            this.dropdownToggled = !this.dropdownToggled;
        },
        selectPlace(place) {
            this.dropdownToggled = false;
            this.selectedPlace = place;
            this.places = [];
            this.placeSearchTerm = '';
        },
        searchPlaces() {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/withownerinfo' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            self.places.push(place);
                        }
                        if (!self.places) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
            }
        },
        removePlaceSelection() {
            this.selectedPlace = {};
            this.places = [];
        },
        searchAssetTypes() {
            this.tempAssetTypes = [];
            if (this.assetTypeSearchTerm.length > 1) {
                let searchTerm = this.assetTypeSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetTypes.length; i++) {
                    let assetType = this.assetTypes[i];
                    let assetName = assetType.display_name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssetTypes.push(assetType);
                    }
                    if (!this.tempAssetTypes.length) {
                        this.assetTypeNoResult = true;
                    }
                    else {
                        this.assetTypeNoResult = false;
                    }
                }
            }
            else {
                this.assetTypeNoResult = false;
            }
        },
        selectAssetType(assetType) {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetType = assetType;
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
            if (assetType.extra_description_a_default) {
                this.assetTypeFields.extra_description_a = assetType.extra_description_a_default;
            }
            if (assetType.extra_description_b_default) {
                this.assetTypeFields.extra_description_b = assetType.extra_description_b_default;
            }
            if (assetType.extra_description_c_default) {
                this.assetTypeFields.extra_description_c = assetType.extra_description_c_default;
            }
            if (assetType.extra_description_d_default) {
                this.assetTypeFields.extra_description_d = assetType.extra_description_d_default;
            }
            if (assetType.extra_description_e_default) {
                this.assetTypeFields.extra_description_e = assetType.extra_description_e_default;
            }
            if (assetType.extra_description_f_default) {
                this.assetTypeFields.extra_description_f = assetType.extra_description_f_default;
            }
            if (assetType.extra_description_g_default) {
                this.assetTypeFields.extra_description_g = assetType.extra_description_g_default;
            }
            if (assetType.extra_description_h_default) {
                this.assetTypeFields.extra_description_h = assetType.extra_description_h_default;
            }
            if (assetType.extra_description_i_default) {
                this.assetTypeFields.extra_description_i = assetType.extra_description_i_default;
            }
            if (assetType.extra_description_j_default) {
                this.assetTypeFields.extra_description_j = assetType.extra_description_j_default;
            }
        },
        deselectAssetType() {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetType = {};
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
        },
        createNewAsset() {
            if (this.fields.name.length && this.selectedPlace) {
                let self = this;
                const data = new URLSearchParams();
                let qrcodeParam = 'data[0][qr_code]';
                let idPlaceParam = 'data[0][id_place]';
                let holderParam = 'data[0][holder]';
                let shortNameParam = 'data[0][short_name]';
                let nameParam = 'data[0][name]';
                let assetTypeParam = 'data[0][id_asset_type]';
                let assetTypeFieldAParam = 'data[0][extra_description_a]';
                let assetTypeFieldBParam = 'data[0][extra_description_b]';
                let assetTypeFieldCParam = 'data[0][extra_description_c]';
                let assetTypeFieldDParam = 'data[0][extra_description_d]';
                let assetTypeFieldEParam = 'data[0][extra_description_e]';
                let assetTypeFieldFParam = 'data[0][extra_description_f]';
                let assetTypeFieldGParam = 'data[0][extra_description_g]';
                let assetTypeFieldHParam = 'data[0][extra_description_h]';
                let assetTypeFieldIParam = 'data[0][extra_description_i]';
                let assetTypeFieldJParam = 'data[0][extra_description_j]';
                data.append('action', 'create');
                data.append(qrcodeParam, '');
                data.append(idPlaceParam, self.selectedPlace.id_place);
                data.append(holderParam, true);
                data.append(shortNameParam, '');
                data.append(nameParam, self.fields.name);
                if (self.selectedAssetType.id_asset_type) {
                    data.append(assetTypeParam, self.selectedAssetType.id_asset_type);
                }
                if (self.assetTypeFields.extra_description_a) {
                    data.append(assetTypeFieldAParam, self.assetTypeFields.extra_description_a);
                }
                if (self.assetTypeFields.extra_description_b) {
                    data.append(assetTypeFieldBParam, self.assetTypeFields.extra_description_b);
                }
                if (self.assetTypeFields.extra_description_c) {
                    data.append(assetTypeFieldCParam, self.assetTypeFields.extra_description_c);
                }
                if (self.assetTypeFields.extra_description_d) {
                    data.append(assetTypeFieldDParam, self.assetTypeFields.extra_description_d);
                }
                if (self.assetTypeFields.extra_description_e) {
                    data.append(assetTypeFieldEParam, self.assetTypeFields.extra_description_e);
                }
                if (self.assetTypeFields.extra_description_f) {
                    data.append(assetTypeFieldFParam, self.assetTypeFields.extra_description_f);
                }
                if (self.assetTypeFields.extra_description_g) {
                    data.append(assetTypeFieldGParam, self.assetTypeFields.extra_description_g);
                }
                if (self.assetTypeFields.extra_description_h) {
                    data.append(assetTypeFieldHParam, self.assetTypeFields.extra_description_h);
                }
                if (self.assetTypeFields.extra_description_i) {
                    data.append(assetTypeFieldIParam, self.assetTypeFields.extra_description_i);
                }
                if (self.assetTypeFields.extra_description_j) {
                    data.append(assetTypeFieldJParam, self.assetTypeFields.extra_description_j);
                }
                fetch('/api/v1/asset', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$router.push('/buildingmanager/asset-detail?asset=' + data.data[0].id_asset);
                });
            }
            else {
                this.nameError = true;
            }
        }
    }
}
</script>
