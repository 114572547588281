<template>
    <div>
        <div class="main-content" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--actions" style="border-radius: 20px;">
                    <section class="incident-head">
                        <h1 v-if="!selectedIncident.id_asset && !assetId">{{profile.incident_detail.scan_qr_code}}</h1>
                        <h1 v-if="plannedAsset.length">{{profile.incident_detail.scanned_qr_code}}</h1>
                        <h1 v-if="error">{{profile.incident_detail.no_appointments_found}}</h1>
                    </section>
                    <p style="margin-top: 20px; font-size: 16px;" v-if="!selectedIncident.id_asset && !assetId">{{profile.incident_detail.scan_explanation}}</p>
                    <Scanner @scanned="getAsset" :key="scannerKey" v-if="!plannedAsset.length && !recordings.length" />
                    <div class="row" v-if="plannedAsset.length">
                        <div class="col">
                            <h3 style="margin: 15px 0; float: left;">{{profile.actionable.assignments}}</h3>
                        </div>
                    </div>
                    <div class="row" v-if="plannedAsset.length">
                        <div class="col-sm-6 col-lg-4" v-for="(item, index) in plannedAsset" :key="index" @click="toIncident(item.id_incident)">
                            <span class="bm-label">{{item.building_manager_name}}</span>
                            <span class="id">{{item.id_incident}}</span>
                            <div class="appointment-block" @click="toIncident(item.id_incident)">
                                <span class="chatcount" v-if="item.chat_count">{{item.chat_count}}</span>
                                <span class="time"><i class="fas fa-calendar-day"></i> {{item.dateTime}}</span>
                                <span class="status-bar" :class="item.status">
                                    <span>{{profile['mechanic_statusses'][item.status]}}</span>
                                </span>
                                <p>{{item.description}}</p>
                                <div class="row">
                                    <div class="col">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <span class="address">
                                            {{item.address_1}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}<br />
                                            {{item.address_2}}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <i class="fas fa-cube"></i>
                                        <span class="object">{{item.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="plannedAsset.length > 1 && !selectedIncident.id_asset">
                        <h3 style="margin: 15px 0 0 0;">{{profile.incident_detail.select_appointment}}</h3>
                        <div v-if="plannedAsset.length" class="row">
                            <div class="col-sm-6 col-lg-4" v-for="(item, index) in plannedAsset" :key="index">
                                <span class="bm-label">{{item.building_manager_name}}</span>
                                <span class="id">{{item.id_incident}}</span>
                                <div class="appointment-block" @click="selectIncident(item.id_incident)">
                                    <span class="time"><i class="fas fa-calendar-day"></i> {{item.dateTime}}</span>
                                    <span class="status-bar">
                                        <span>{{profile['mechanic_statusses'][item.status]}}</span>
                                    </span>
                                    <p style="margin-bottom: 0; clear: both;">{{item.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="selectedIncident.id_asset">
                        <h3 style="margin: 10px 0 0 0;" v-if="plannedAsset.length > 1">{{profile.incident_detail.selected_appointment}}</h3>
                        <section class="incident-head" style="padding-bottom: 0;">
                            <h2>{{selectedIncident.name}}</h2>
                            <span class="status-bar" style="margin: 10px 0 0 10px;">
                                <span>{{profile['mechanic_statusses'][selectedIncident.status]}}</span>
                            </span>
                            <div class="deselect-incident" v-if="plannedAsset.length > 1" @click="selectedIncident = {}"><i class="fas fa-times"></i></div>
                        </section>
                        <section class="incident-contents">
                            <div class="row">
                                <div class="col">
                                    <div class="checkin-buttons" style="margin: 0 0 15px 0; overflow: hidden;">
                                        <span class="button button--cta button--icon" style="margin-bottom: 0;" @click="checkIn()" v-if="selectedIncident.status === 'planned' || selectedIncident.status === 'paused_mechanic'"><i class="fas fa-play"></i>Check in</span>
                                        <span class="button button--icon" @click="pauseReasonToggled = true;" v-if="selectedIncident.status === 'on_location_mechanic' && !pauseReasonToggled && !checkOutToggled"><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic_alt}}</span>
                                        <span class="button button--cta button--icon" @click="checkOutToggled = true" v-if="selectedIncident.status === 'on_location_mechanic' && !pauseReasonToggled && !checkOutToggled"><i class="fas fa-stop"></i>Check out</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="checkOutToggled && selectedIncident.status === 'on_location_mechanic'">
                                <div class="col">
                                    <div class="form-field" :class="{focus:fields.descr.length}">
                                        <label style="float: left;">{{profile.incident_detail.feedback}}</label>
                                        <span class="remove" @click="checkOutToggled = false" style="float: right; cursor: pointer;"><i class="fa fa-times"></i></span>
                                        <textarea class="form-control" v-model="fields.feedback"></textarea>
                                    </div>
                                    <div class="switch-wrapper">
                                        <label class="switch">
                                            <input type="checkbox" v-model="fields.followup">
                                            <span class="slider round"></span>
                                        </label>
                                        <span><b>{{profile.incident_detail.followup_label}}</b></span>
                                    </div>
                                    <span class="button button--cta button--icon" @click="checkOut()" style="margin-top: 15px; margin-bottom: 0; float: right;"><i class="fas fa-stop"></i>Check out</span>
                                </div>
                            </div>
                            <div class="row" v-if="pauseReasonToggled && selectedIncident.status === 'on_location_mechanic'">
                                <div class="col">
                                    <div class="form-field" :class="{focus:fields.descr.length}">
                                        <label style="float: left;">{{profile.incident_detail.paused_incident_reason}}</label>
                                        <span class="remove" @click="pauseReasonToggled = false" style="float: right; cursor: pointer;"><i class="fa fa-times"></i></span>
                                        <textarea class="form-control" v-model="fields.descr"></textarea>
                                    </div>
                                    <span class="button button--icon" @click="pause()" style="margin-top: 15px; margin-bottom: 0; float: right;"><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic_alt}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="incident-info">
                                        <label>{{profile.incidents.incidenttable.id_incident}}</label>
                                        <p>{{selectedIncident.id_incident}}</p>
                                        <label>{{profile.incident_detail.latest_appointment}}</label>
                                        <p v-if="selectedIncident.calendar_choice === 'choose_latest_possible'">{{profile.incident_detail.choose_latest_possible}}: {{selectedIncident.dateTime}}</p>
                                        <p v-else>{{selectedIncident.dateTime}}</p>
                                        <label>{{profile.dashboard.building_manager.message_table.place}}</label>
                                        <p>
                                            {{selectedIncident.address}} {{selectedIncident.house_number}}<br />
                                            {{selectedIncident.zipcode}} {{selectedIncident.city}}<br />
                                            {{profile.incident_detail.floor_name}}: {{selectedIncident.floor_name}}
                                        </p>
                                        <label>{{profile.incident_detail.description}}</label>
                                        <p>{{selectedIncident.description}}</p>
                                        <label>{{profile.incident_detail.rating_comment}}</label>
                                        <p>{{selectedIncident.reporter_name}} |
                                            <a v-if="selectedIncident.reporter_email_address" :href="'mailto:' + selectedIncident.reporter_email_address"><i class="fas fa-at" v-tooltip.right="selectedIncident.reporter_email_address"></i></a>
                                            <span v-if="selectedIncident.reporter_email_address && selectedIncident.reporter_phone">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                            <a v-if="selectedIncident.reporter_phone" :href="'tel:' + selectedIncident.reporter_phone"><i class="fas fa-mobile-alt" v-tooltip.right="selectedIncident.reporter_phone"></i></a>
                                        </p>
                                        <label>{{profile.incidents.incidenttable.repair_company}}</label>
                                        <p>{{selectedIncident.repair_company_name}}</p>
                                        <label>{{profile.incidents.incidenttable.building_manager}}</label>
                                        <p>{{selectedIncident.building_manager_name}}</p>
                                        <label>{{profile.incident_detail.incident_owner}}</label>
                                        <p>{{selectedIncident.owner_name}}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div> -->
                    <hr v-if="recordings.length" />
                    <div v-if="recordings.length">
                        <div class="items-wrap">
                            <div class="row">
                                <div class="col">
                                    <h3 style="margin: 0 0 15px 0; float: left;">{{profile.actionable.recordings}}</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-lg-4" v-for="(item, index) in recordings" :key="index" @click="toRecording(item.id_planned_action_list)">
                                    <span class="bm-label" style="background: #ffa500;">{{item.building_manager_name}}</span>
                                    <div class="appointment-block">
                                        <div class="post-it" v-if="item.status === 'started'" v-tooltip.right="profile.actionable.open_actionables"><i class="fas fa-exclamation-circle"></i></div>
                                        <div class="row">
                                            <div class="col">
                                                <i class="fas fa-cube"></i>
                                                <span class="address">
                                                    <b>{{item.asset_name}}</b><br />
                                                    {{item.address}} {{item.house_number}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}<br />
                                                    {{item.place_name}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p><b><i class="fas fa-clipboard-list"></i> {{profile.actionable.recording}}</b></p>
                                                <span class="address">{{item.planned_action_list_name}}</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p><b><i class="fas fa-calendar-day"></i> {{profile.actionable.latest_recording_date}}</b></p>
                                                <span class="address">{{item.latestDate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="notification notification--error" v-if="error" style="margin-top: 20px;">{{profile.incident_detail.no_appointments_found_expla}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import Scanner from '@/components/mechanic/Scanner.vue';
import moment from 'moment';

export default {
    mixins: [setLocale],
    components: {
        Scanner
    },
    data() {
        return {
            profile: {},
            qrCode: 0,
            asset: {},
            readMore: false,
            assetId: null,
            planned: [],
            plannedAsset: [],
            recordings: [],
            selectedIncident: {},
            pauseReasonToggled: false,
            checkOutToggled: false,
            fields: {
                descr: '',
                feedback: '',
                followup: false
            },
            error: false,
            scannerKey: 0
        }
    },
    created() {
        this.getPlanned();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getPlanned() {
            let self = this;
            fetch('/api/v1/appointment/planned')
            .then(response => { response.json().then(
                function(data) {
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.dateTime = moment(item.start_time).locale('nl').format('D MMMM YYYY, HH:mm');
                        item.date = moment(item.start_time).locale('nl').format('D MMMM YYYY');
                        items.push(item);
                    }
                    self.planned = items;
                });
            });
        },
        getAsset(object) {
            this.error = false;
            this.qrCode = object.qr_code;
            let self = this;
            fetch('/open-api/v1/asset/limited/qr_code?qr_code=' + object.qr_code)
            .then(response => { response.json().then(
                function(data) {
                    self.asset = data.asset;
                    self.assetId = data.asset.id_asset;
                    for (var i = 0; i < self.planned.length; i++) {
                        let item = self.planned[i];
                        if (item.qr_code === object.qr_code) {
                            self.plannedAsset.push(item);
                        }
                    }
                    if (!self.plannedAsset.length) {
                        self.error = true;
                        self.scannerKey += 1;
                    }
                    if (self.plannedAsset.length === 1) {
                        self.selectIncident(self.plannedAsset[0].id_incident);
                    }
                    self.getRecordings(object.qr_code);
                });
            });
        },
        getRecordings(qrcode) {
            let self = this;
            fetch('/api/v1/plannedactionlist/byqrcodewithactions?qr_code=' + qrcode)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        item.latestDate = moment(item.latest_possible).locale('nl').format('D MMMM YYYY');
                        self.recordings.push(item);
                    }
                    if (self.recordings.length) {
                        self.error = false;
                    }
                });
            });
        },
        selectIncident(id) {
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/idincident?id_incident=' + id)
            .then(response => { response.json().then(
                function(data) {
                    self.selectedIncident = data.appointment;
                    self.selectedIncident.dateTime = moment(self.selectedIncident.start_time).locale('nl').format('D MMMM YYYY, HH:mm');
                    self.selectedIncident.date = moment(self.selectedIncident.start_time).locale('nl').format('D MMMM YYYY');
                });
            });
        },
        checkIn() {
            const data = new URLSearchParams();
            data.append('id_incident', this.selectedIncident.id_incident);
            data.append('checkin', true);
            data.append('checkout', false);
            data.append('qr_code', this.qrCode);
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/checkincheckout', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$router.push('/monteur/incident-detail?id=' + self.selectedIncident.id_incident);
            });
        },
        checkOut() {
            const data = new URLSearchParams();
            data.append('id_incident', this.selectedIncident.id_incident);
            data.append('checkin', false);
            data.append('checkout', true);
            data.append('qr_code', this.qrCode);
            data.append('checkOutMessage', this.fields.feedback);
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/checkincheckout', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                if (self.fields.followup) {
                    self.followUp();
                }
                else {
                    self.$router.push('/monteur/incident-detail?id=' + self.selectedIncident.id_incident);
                }
            });
        },
        followUp() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('wants_copy', true);
            let self = this;
            fetch('/api/v1/incident/setcopywanted', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$router.push('/monteur/incident-detail?id=' + self.selectedIncident.id_incident);
            });
        },
        pause() {
            const data = new URLSearchParams();
            data.append('id_incident', this.selectedIncident.id_incident);
            data.append('pauseReason', this.fields.descr);
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/pause_mechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$router.push('/monteur/incident-detail?id=' + self.selectedIncident.id_incident);
            });
        },
        toRecording(id) {
            this.$router.push('/monteur/opname-detail?id=' + id);
        },
        toIncident(id) {
            this.$router.push('/monteur/incident-detail?id=' + id);
        }
    }
}
</script>
