<template>
    <div>
        <div class="main-content" v-if="profile.accountPage">
            <div class="container">
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import moment from 'moment';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},  
            incidentId: 0
        }
    },
    created() {
        document.title = 'S1MONE | Lite';
        let queryString = window.location.search;
        this.incidentId = queryString.split('=')[1];
        this.getIncidentInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getIncidentInfo() {
            this.subButtonsToggled = false;
            this.manualCheckIn = false;
            this.manualCheckOut = false;
            this.manualCheckInOut = false;
            this.repairInfo = {};
            this.currentAppointment = '';
            let self = this;
            fetch('/api/v1/incidentcombined?id_incident=' + self.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    data.day = moment(data.last_status_time).locale('nl').format('dddd');
                    data.date = moment(data.last_status_time).locale('nl').format('D MMMM YYYY');
                    data.time = moment(data.last_status_time).locale('nl').format('LT');
                    let created = moment(data.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                    let now = moment().format('DD/MM/YYYY HH:mm:ss');
                    let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                    let differenceObj = self.convertToDays(difference);
                    let differenceString = differenceObj.days + self.profile.datetime.days + ' ' + differenceObj.hours + self.profile.datetime.hours + ' ' +  differenceObj.minutes  + self.profile.datetime.minutes;
                    data.overtime = differenceString;
                    if (differenceObj.days < 1) {
                        data.overtimeStatus = 'green';
                    }
                    if (differenceObj.days > 1) {
                        data.overtimeStatus = 'orange';
                    }
                    if (differenceObj.days > 3) {
                        data.overtimeStatus = 'red';
                    }
                    if (data.start_time) {
                        var date = moment(data.start_time).locale('nl').format('D MMMM');
                        var start = moment(data.start_time).format();
                        start = moment(data.start_time).format('D/MM, H:mm');
                        var end = moment(data.end_time).format();
                        end = moment(data.end_time).format('H:mm');
                        self.currentAppointment = start + ' - ' + end;
                        self.currentAppointmentDate = date;
                    }
                    let gmapsAddress    = data.place_address.replace(/ /g , "+")+"+"+data.place_city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    self.selectedRepairCompany = data.id_repair_company;
                    if (data.id_mechanic) {
                        self.selectedMechanic = data.id_mechanic;
                    }
                    else {
                        self.selectedMechanic = 0;
                    }
                    //Action buttons handling
                    if (!data.serviced_by_rep_user_id) {
                        if ((data.serviced_by_rep_user_id === null && data.incident_status === 'maintenance_checkin_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'fixed_agreed_auto') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'paused_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'pending_repaircompany')
                        ) {
                            self.noStatusChange = true;
                        }
                        if (data.incident_status ==='new_' ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'new_repaircompany') ||
                           (data.serviced_by_rep_user_id === null && data.incident_status === 'maintenance_checkin_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'fixed_agreed_auto') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'paused_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'pending_repaircompany')
                        )  {
                            self.newIncident = true;
                            self.startIncidentModalOpen = true;
                            self.startIncidentModalOpen = setTimeout(()=>{ self.startIncidentModalOpen = false; document.querySelector('body').classList.remove('modal-open'); }, 3000);
                            self.readOnly = setTimeout(()=>{ self.readOnly = true; }, 3000);
                        }
                    }
                    if (data.incident_status !== 'new_' && data.incident_status !== 'closed') {
                        self.readyToFinish = true;
                        if (data.serviced_by_rep_user_id === null) {
                            self.readOnly = true;
                        }
                    }
                    if (data.incident_status === 'closed' || data.incident_status === 'cancelled' || data.incident_status === 'returned_to_buildingmanager') {
                        self.readOnly = true;
                    }
                    if (data.has_order && data.id_appointment && !data.id_mechanic) {
                        self.readOnly = false;
                    }
                    if (data.incident_status=='fixed_mechanic' || data.incident_status=='fixed_agreed_reporter' || data.incident_status=='fixed_agreed_auto' || data.incident_status=='fixed_agreed_repaircompany' || data.incident_status=='closed') {
                        fetch('/api/v1/incidentcombined/repairinfo?id_incident=' + self.incidentId)
                        .then(response => { response.json().then(
                            function(data) {
                                if (data.timeWorked) {
                                    var start = moment(data.start).format();
                                    start = moment(data.start).format('D/MM/YYYY HH:mm');
                                    var end = moment(data.end).format();
                                    end = moment(data.end).format('D/MM/YYYY HH:mm');
                                    var timeWorked = self.createWorkedString(data.timeWorked);
                                    data.start = start;
                                    data.end = end;
                                    data.timeWorked = timeWorked;
                                    self.repairInfo = data;
                                }
                            });
                        });
                    }
                    if (data.incident_status === 'planned' || data.incident_status=== 'pending_mechanic' || data.incident_status=== 'paused_mechanic' ) {
                        self.manualCheckIn = true;
                    }
                    if (data.incident_status === 'on_location_mechanic') {
                        self.manualCheckOut = true;
                    }
                    if (data.incident_status === 'planned') {
                        self.manualCheckInOut = true;
                    }
                    if (data.pictures) {
                        self.media = [];
                        self.movs = [];
                        self.docs = [];
                        for (var i = 0; i < data.pictures.length; i++) {
                            let media = data.pictures[i];
                            if (self.locale === 'nl') {
                                media.date = moment(media.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                            }
                            else {
                                media.date = moment(media.created).format('MMM D YYYY HH:mm:ss');
                            }
                            if ((!media.file_url.includes('.pdf') && media.content_type.toLowerCase() !== 'application/pdf') && media.content_type !== 'video/quicktime') {
                                media.popover = false;
                                media.rotate = 1;
                                if (media.type === 'incident_reporter') {
                                    media.order = 1;
                                }
                                if (media.type === 'incident_building_manager') {
                                    media.order = 2;
                                }
                                if (media.type === 'incident_mechanic') {
                                    media.order = 3;
                                }
                                media.selected = false;
                                if (!media.content_type) {
                                    media.content_type = 'image/jpeg';
                                }
                                self.media.push(media);
                                self.media = sortBy(self.media, 'order');
                            }
                            if (media.file_url.includes('.pdf') || media.content_type.toLowerCase() === 'application/pdf') {
                                let n = media.file_url.lastIndexOf('/');
                                let fileName = media.file_url.substring(n + 1);
                                media.pdf = true;
                                media.filename = fileName;
                                media.selected = false;
                                if (media.type !== 'invoice_pdf_supplier') {
                                    self.docs.push(media);
                                }
                                if (media.type === 'quotation_pdf_supplier') {
                                    data.quotationUrl = media.file_url;
                                }
                            }
                            if (media.content_type === 'video/quicktime') {
                                self.movs.push(media);
                            }
                        }
                    }
                    self.docs = sortBy(self.docs, 'id_shiro_user');
                    if (data.place_floor_name === 'VVEVEST') {
                        self.vvevest = true;
                        if (self.locale === 'nl') {
                            data.place_floor_name = 'Niet van toepassing'
                        }
                        if (self.locale === 'en') {
                            data.place_floor_name = 'Not applicable'
                        }
                    }
                    self.incidentInfo = data;
                    if (self.incidentInfo.quotation_value_supplier && self.incidentInfo.quotation_value_supplier.toString().includes('.')) {
                        self.incidentInfo.quotation_value_supplier = (Math.round(self.incidentInfo.quotation_value_supplier * 100) / 100).toFixed(2);
                        self.incidentInfo.quotation_value_supplier = self.incidentInfo.quotation_value_supplier.toString().replaceAll('.', ',');
                    } 
                    self.loading = false;
                    console.log(self.incidentInfo);
                });
            });
        },
    }
}
</script>
