<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-pen"></i>{{profile.owners.contacts.edit_contact}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:tempContact.name.length, error: nameError}">
                                <label>{{profile.accountPage.account.contactname}} *</label>
                                <input type="text" ref="name" class="form-control" data-testid="contactNameEdit" v-model="tempContact.name" @input="checkName()" :disabled="tempContact.id_shiro_user">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label style="margin-bottom: 0;">{{profile.owners.contacts.role}}</label>
                            <p>Eigenaar <span v-if="tempContact.id_shiro_user" style="border: 1px solid gray; padding: 3px; border-radius: 3px; font-weight: 500; margin-left: 5px;">Account</span></p>
                        </div> 
                    </div>
                    <div class="row">   
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:tempContact.mobile.length, valid: validPhone, error: invalidPhone}">
                                <label>{{profile.accountPage.account.contactmobile}}</label>
                                <input type="text" class="form-control" data-testid="contactPhoneEdit" v-model="tempContact.mobile" @input="validatePhone()">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:tempContact.email.length, valid: validEmail, error: invalidEmail}">
                                <label>{{profile.incidents.new_incident.emailaddress}}</label>
                                <input type="text" class="form-control" data-testid="contactEmailEdit" v-model="tempContact.email" @input="validateEmail()" :disabled="tempContact.id_shiro_user">
                            </div>
                        </div>   
                    </div>
                    <div class="form-field" :class="{focus:tempContact.role_description.length}" style="margin-bottom: 0;">
                        <label>{{profile.text_languages.text_languagetable.description}}</label>
                        <input type="text" class="form-control" v-model="tempContact.role_description" />
                    </div>                 
                    <span class="button button--icon button--right" data-testid="contactSaveButton" @click="saveContact()"><i class="fas fa-pen"></i>{{profile.buttons.save}}</span>
                    <span class="button button--icon button--right" data-testid="contactDeleteButton" @click="removeConfirm = true" style="margin-right: 15px;" v-if="!tempContact.id_shiro_user"><i class="far fa-trash-alt"></i>{{profile.owners.contacts.remove_contact}}</span>
                    <span class="button button--icon button--right" @click="createConfirm = true" style="margin-right: 15px;" v-if="!tempContact.id_shiro_user"><i class="fas fa-plus"></i>{{profile.repair_companies.repaircompanytable.createAccount}}</span>
                    <div v-else>
                        <span class="button button--icon button--right" @click="disconnectConfirm = true" style="margin-right: 15px;"><i class="fas fa-unlink"></i>{{profile.owners.contacts.remove_owner}}</span>
                        <span class="button button--icon button--right" @click="removeAndDisconnectConfirm = true" style="margin-right: 15px;"><i class="far fa-trash-alt"></i>{{profile.owners.contacts.remove_owner_contact}}</span>

                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="removeConfirm">
                        <span class="close-notification" @click="removeConfirm = false"><i class="fas fa-times"></i></span>
                        <p>{{profile.owners.contacts.remove_contact_sure}}</p>
                        <span class="button button--small" data-testid="contactDeleteConfirm" @click="removeContact()">{{profile.owners.contacts.remove_contact}}</span>
                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="createConfirm">
                        <span class="close-notification" @click="createConfirm = false"><i class="fas fa-times"></i></span>
                        <p>{{profile.owners.contacts.create_account_sure}}</p>
                        <span class="button button--small" @click="createAccount()">{{profile.repair_companies.repaircompanytable.createAccount}}</span>
                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="confirmAdd">
                        <span class="close-notification" @click="confirmAdd = false"><i class="fas fa-times"></i></span>
                        <p>{{profile.owners.contacts.confirm_add}}</p>
                        <span class="button button--small" @click="addOwner()">{{profile.owners.contacts.confirm_add_button}}</span>
                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="disconnectConfirm">
                        <span class="close-notification" @click="disconnectConfirm = false"><i class="fas fa-times"></i></span>
                        <p>{{profile.owners.contacts.remove_owner_sure}}</p>
                        <span class="button button--small" @click="removeOwner()">{{profile.owners.contacts.remove_owner}}</span>
                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="removeAndDisconnectConfirm">
                        <span class="close-notification" @click="removeAndDisconnectConfirm = false"><i class="fas fa-times"></i></span>
                        <p>{{profile.owners.contacts.remove_owner_contact_sure}}</p>
                        <span class="button button--small" @click="removeOwnerAndContact()">{{profile.owners.contacts.remove_owner_contact}}</span>
                    </div>
                    <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="roleError">
                        <span class="close-notification" @click="roleError = false"><i class="fas fa-times"></i></span>
                        <p style="padding-right: 20px;">{{profile.owners.contacts.role_error}}</p>
                    </div>
                    <div class="notification notification--warning" style="margin: 20px 0 5px 0; width: 100%;" v-if="userError">
                        <p>{{profile.mechanics.mechanictable.nameError}}</p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['ownerId', 'selectedContact', 'contacts'],
    data() {
        return {
            profile: {},
            tempContact: {},
            validPhone: false,
            invalidPhone: false,
            validEmail: false,
            invalidEmail: false,
            removeConfirm: false,
            createConfirm: false,
            nameError: false,
            roleError: false,
            userError: false,
            confirmAdd: false,
            disconnectConfirm: false,
            removeAndDisconnectConfirm: false,
            userToAdd: 0
        }
    },
    created() {
        this.tempContact = this.selectedContact;
        if (this.tempContact.role_description === 'undefined') {
            this.tempContact.role_description = '';
        }
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.tempContact.email)) {
                this.validEmail = true;
                this.invalidEmail = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        validatePhone() {
            if (this.tempContact.mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.tempContact.mobile)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }    
            else {
                this.validPhone = true;
                this.invalidPhone = false;
            }
        },
        saveContact() {
            if (!this.tempContact.name.length) {
                this.nameError = true;
            }
            if (this.tempContact.name.length && !this.invalidPhone && !this.invalidEmail) {
                const data = new URLSearchParams();
                data.append('id_contact_person', this.tempContact.id_contact_person);
                data.append('email', this.tempContact.email);
                data.append('mobile', this.tempContact.mobile);
                data.append('name', this.tempContact.name);
                data.append('role_description', this.tempContact.role_description);
                let self = this;
                fetch('/api/v1/contactperson/update', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$emit('success');
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    document.querySelector('body').classList.remove('modal-open');
                });
            }
        },
        removeContact() {
            const data = new URLSearchParams();
            data.append('id_contact_person', this.tempContact.id_contact_person);
            let self = this;
            fetch('/api/v1/contactperson/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                setTimeout(function () {
                    self.$emit('success');
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    document.querySelector('body').classList.remove('modal-open');
                }.bind(this), 1000)         
            });
        },
        removeOwner() {
            const data = new URLSearchParams();
            data.append('id_shiro_user', this.tempContact.id_shiro_user);
            data.append('id_owner', this.ownerId);
            let self = this;
            fetch('/api/v1/owneraccount/removeowner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                setTimeout(function () {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                    document.querySelector('body').classList.remove('modal-open');
                }.bind(this), 1000)         
            });
        },
        removeOwnerAndContact() {
            this.removeOwner();
            this.removeContact();
        },
        checkName() {
            if (!this.tempContact.name.length) {
                this.nameError = true;
            }
            else {
                this.nameError = false;
            }
        },
        createAccount() {
            const data = new URLSearchParams();
            data.append('username', this.tempContact.email);
            data.append('actual_name', this.tempContact.name);
            data.append('mobile', this.tempContact.mobile);
            let self = this;
            fetch('/api/v1/owneraccount/create', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.createConfirm = false;
                if (!data.EXISTS) {
                    self.addOwner(data.id_shiro_user);
                }
                else {
                    if ((data.roles[0] && data.roles[0].name === 'owner') || (data.roles[1] && data.roles[1].name === 'owner') || (data.roles[2] && data.roles[2].name === 'owner')) {
                        const containsEmail = self.contacts.some((contact) => contact.email === self.tempContact.email);
                        if (!containsEmail) {
                            self.confirmAdd = true;
                            self.userToAdd = data.id_shiro_user;
                            self.userToAddName = data.actual_name;
                            self.fields.name = data.actual_name;
                        }
                        else {
                            setTimeout(function () {                        
                                self.addOwner(data.id_shiro_user);
                            }.bind(this), 1000) 
                        }
                    }
                    if ((data.roles[0] && data.roles[0].name !== 'owner') || (data.roles[1] && data.roles[1].name !== 'owner') || (data.roles[2] && data.roles[2].name !== 'owner')) {
                        self.roleError = true;
                    }
                }         
            });
        },
        addOwner(shiroUser) {
            let userId;
            if (!shiroUser) {
                userId = this.userToAdd;
            }
            else {
                userId = shiroUser;
            }
            setTimeout(function () {
                const data = new URLSearchParams();
                data.append('id_shiro_user', userId);
                data.append('id_owner', this.ownerId);
                let self = this;
                fetch('/api/v1/owneraccount/addowner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                    document.querySelector('body').classList.remove('modal-open');
            });
            }.bind(this), 400);
        }
    }
}
</script>
