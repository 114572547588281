<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-pen"></i>{{profile.incident_detail.activate_task}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.incident_detail.new_incident_start}}</p>
                <br />
                <section class="actions actions--full">
                    <div class="button-wrap">
                        <span class="button button--icon button--cta button--right" data-testid="startIncidentButton" @click="addHandler()" style="margin-left: 10px;"><i class="fas fa-play"></i>{{profile.incident_detail.start_incident}}</span>
                        <span class="button button--icon button--right" @click="viewIncident()" style="font-size: 16px;"><i class="fas fa-eye"></i>{{profile.incident_detail.view_incident}}</span>
                        <span class="back-link" @click="backToDash()" style="font-size: 16px; margin: 7px 10px 0 0; text-decoration: underline;"><i class="fa fa-chevron-left" style="font-size: 12px;"></i>{{profile.incident_detail.back_to_dashboard}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'appointmentEditModal',
    data() {
        return {
            profile: {},
            started: false
        }
    },
    props: ['incidentInfo'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    beforeDestroy() {
        this.removeHandler();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            this.$emit('readonly');
            document.querySelector('body').classList.remove('modal-open');
        },
        viewIncident() {
            this.$emit('readonly');
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        addHandler() {
            window.addEventListener('click', this.startIncident);
        },
        removeHandler() {
            window.removeEventListener('click', this.startIncident);
        },
        startIncident() {
            if (!this.started) {
                this.applicationLog();
                let currentIncidentId = this.getUrlParameter('id');
                if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString()) {
                    this.started = true;
                    this.$emit('startincident');
                    this.$emit('close');
                    document.querySelector('body').classList.remove('modal-open');
                }
                else {
                    this.applicationLogError();
                }
            }
           
        },
        applicationLog() {
            try {
                const data = new URLSearchParams();
                data.append('message', 'Incident Start');
                data.append('id_incident', this.incidentInfo.id_incident);
                let self = this;
                fetch('/api/v1/applicationlog/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {});
            } catch (err) {
                console.log(err);
            }
        },
        applicationLogError() {
            try {
                const data = new URLSearchParams();
                data.append('message', 'Incident Start: ID Mismatch');
                data.append('id_incident', this.incidentInfo.id_incident);
                let self = this;
                fetch('/api/v1/applicationlog/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {});
            } catch (err) {
                console.log(err);
            }
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        backToDash() {
            this.$router.push('/buildingmanager');
        },
    }
}
</script>
