import Vue from 'vue'
import VueGtag from 'vue-gtag'
import store from './store/store'
import Vidle from 'v-idle'
import App from './App.vue'
import VTooltip from 'v-tooltip'
import VueToastify from "vue-toastify";
import VueScrollTo from 'vue-scrollto';
import router from './router';
import Header from '@/components/headers/Header.vue';
import * as Sentry from "@sentry/vue";

Vue.component('Header', Header);
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(VueGtag, {
  config: {
      id: 'G-VVF69GRH13'
  }
}, router)
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click'
})
Vue.use(Vidle)
Vue.use(VueToastify, {
    iconEnabled: false,
    successDuration: 2000
});
Vue.use(VueScrollTo, {
     duration: 500,
     easing: "ease",
     offset: -50,
 })
 Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
if (window.location.href.includes('application')) {
  Sentry.init({
    Vue,
    dsn: "https://73ec5b96a4959bb726680a710964fe9f@o4506116490919936.ingest.sentry.io/4506116492558336",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    release: "s1mone-manager@5.7.2",
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
