<template>
    <div>
        <div class="row">
            <div class="col" style="position: relative;">
                <h1>{{profile.mechanic_detail.mechanic_incident_categoryTable.name}}</h1>
                <span class="edit-button icon" @click="newCategoryModalOpen = true"><i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <section class="search search-inpage" :class="{active: searchActive}">
                    <form @submit.prevent="search()">
                        <input type="text" ref="search" v-bind:placeholder="'Zoek een categorie'"  @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="search($event)"  />
                        <span class="close-suggestions" @click="clearSearch()" v-if="searchActive"><i class="fas fa-times"></i></span>
                    </form>
                </section>
            </div>
        </div>
        <div class="table-wrap" style="height: 600px; overflow: auto;">
            <h4 class="result-counter" v-if="!searchActive && categories.length" style="margin-bottom: 20px;">{{categories.length}} <span style="text-transform: lowercase; font-weight: 500;">{{profile.mechanic_detail.mechanic_incident_categoryTable.name}}</span></h4>
            <h4 class="result-counter" v-if="searchActive && !searchedCategories.length" style="margin-bottom: 20px;">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
            <h4 class="result-counter" v-if="searchActive && searchedCategories.length >= 1" style="margin-bottom: 20px;">{{searchedCategories.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
            <table class="table table-striped" v-if="categories.length && !searchActive">
                <thead>
                    <tr>
                        <th scope="col" @click="sortTable('dutch_name')" :class="{active: categorySortType === 'dutch_name'}">Nederlands <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('english_name')" :class="{active: categorySortType === 'english_name'}">Engels <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('media_wanted')" :class="{active: categorySortType === 'media_wanted'}">Media gewild <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('media_required')" :class="{active: categorySortType === 'media_required'}">Media verplicht <i class="fas fa-sort"></i></th>  
                        <th scope="col" @click="sortTable('list_order')" :class="{active: categorySortType === 'list_order'}">Volgorde <i class="fas fa-sort"></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(category, index) in categories" :key="index" @click="editCategory(category)">
                        <td>{{category.dutch_name}}</td>
                        <td>{{category.english_name}}</td>
                        <td><span v-if="category.media_wanted"><i class="fas fa-check"></i></span></td>
                        <td><span v-if="category.media_required"><i class="fas fa-check"></i></span></td>
                        <td>{{category.list_order}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-striped" v-if="searchedCategories.length && searchActive">
                <thead>
                    <tr>
                        <th scope="col" @click="sortTable('dutch_name')" :class="{active: categorySortType === 'dutch_name'}">Nederlands <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('english_name')" :class="{active: categorySortType === 'english_name'}">Engels <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('media_wanted')" :class="{active: categorySortType === 'media_wanted'}">Media gewild <i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('media_required')" :class="{active: categorySortType === 'media_required'}">Media verplicht <i class="fas fa-sort"></i></th>  
                        <th scope="col" @click="sortTable('list_order')" :class="{active: categorySortType === 'list_order'}">Volgorde <i class="fas fa-sort"></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(category, index) in searchedCategories" :key="index" @click="editCategory(category)">
                        <td>{{category.dutch_name}}</td>
                        <td>{{category.english_name}}</td>
                        <td><span v-if="category.media_wanted"><i class="fas fa-check"></i></span></td>
                        <td><span v-if="category.media_required"><i class="fas fa-check"></i></span></td> 
                        <td>{{category.list_order}}</td>  
                    </tr>
                </tbody>
            </table>
        </div>
        <EditCategoryModal v-if="editCategoryModalOpen" @close="editCategoryModalOpen = false" @success="getCategories()" :selectedCategory="selectedCategory" />
        <NewCategoryModal v-if="newCategoryModalOpen" @close="newCategoryModalOpen = false" @success="getCategories()" :categories="categories" :bmId="managerID" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import EditCategoryModal from "@/components/modals/EditCategoryModal.vue";
import NewCategoryModal from "@/components/modals/NewCategoryModal.vue";
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    components: {
        EditCategoryModal,
        NewCategoryModal
    },
    data() {
        return {
            profile: {},
            categories: [],
            searchedCategories: [],
            searchActive: false,
            searchTerm: '',
            categorySortType: '',
            editCategoryModalOpen: false,
            newCategoryModalOpen: false,
            selectedCategory: false,
            sortOder: 'asc'
        }
    },
    props: ['managerID'],
    created() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            this.editCategoryModalOpen = false;
            this.newCategoryModalOpen = false;
            this.categories = [];
            this.assignedCategories = [];
            let self = this;     
            fetch('/api/v1/incident_category/bybuildingmanager?id_building_manager=' + this.managerID + '&include_non_active=false')
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.data;
                    self.categories = uniqBy(self.categories, 'id_incident_category');
                    if (self.searchTerm) {
                        self.search();
                    }
                });
            });
        },
        sortTable(sorter) {
            this.categorySortType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.categories = sortBy(this.categories, sorter);
            if (this.sortOrder === 'desc') {
                this.categories.reverse();
            }
        },
        search($event) {
            this.searchedCategories = [];
            if (this.searchTerm.length > 2) {
                this.searchActive = true;
                this.searchCategories();
            }
            if (this.searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.searchCategories();
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchCategories();
            }
        },
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.searchedCategories = [];
        },
        searchCategories() {
            for (var i = 0; i < this.categories.length; i++) {
                let category = this.categories[i];
                if (category.dutch_name.toLowerCase().includes(this.searchTerm.toLowerCase())) {
                    this.searchedCategories.push(category);
                }
            }
        },
        editCategory(category) {
            if (category.ukrainian_name === null) {
                category.ukrainian_name = '';
            }
            if (category.russian_name === null) {
                category.russian_name = '';
            }
            if (category.polish_name === null) {
                category.polish_name = '';
            }
            if (category.italian_name === null) {
                category.italian_name = '';
            }
            if (category.german_name === null) {
                category.german_name = '';
            }
            if (category.french_name === null) {
                category.french_name = '';
            }
            if (category.contact_phone === null) {
                category.contact_phone = '';
            }
            if (category.contact_url === null) {
                category.contact_url = '';
            }
            this.selectedCategory = category;
            this.editCategoryModalOpen = true;        
        }
    }
}
</script>
