<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.employees.create_employee}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="checkEmail()">
                    <div class="form-field" :class="{focus:fields.actual_name.length}">
                        <label>{{profile.employees.name}} *</label>
                        <input type="text" ref="name" class="form-control" data-testid="employeeInputName" v-model="fields.actual_name" required />
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.username.length, valid: validEmail, error: invalidEmail}">
                                <label>{{profile.employees.email}} *</label>
                                <input type="text" class="form-control" data-testid="employeeInputEmail" v-model="fields.username" @input="validateEmail()" required />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.mobile.length, valid: validPhone, error: invalidPhone}">
                                <label>{{profile.employees.phone}}</label>
                                <input type="text" class="form-control" data-testid="employeeInputPhone" v-model="fields.mobile" @input="validatePhone()" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="emailexists">
                        <div class="col">
                            <div class="notification notification--error">
                                <p><i class="fas fa-exclamation-circle"></i> {{profile.employee_exists}}</p>
                            </div>
                        </div>
                    </div>
                    <button class="button button--icon button--right" v-if="emailexists" v-on:click="enableEmployee()"><i class="fas fa-user"></i>{{profile.enable_employee}}</button>
                    <button type="submit" class="button button--icon" data-testid="employeeSubmitButton" v-else><i class="fas fa-user"></i>{{profile.employees.create_employee}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            emailexists: false,
            fields: {
                actual_name: '',
                username: '',
                mobile: ''
            },
            validPhone: false,
            invalidPhone: false,
            validEmail: false,
            invalidEmail: false
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        checkEmail() {
            let self = this;
            fetch('/api/v1/emailexists?email=' + this.fields.username)
            .then(response => { response.text().then(
                function(data) {
                    if (data === 'false') {
                        self.emailexists = false;
                        self.createNewEmployee();
                    }
                    else {
                        self.emailexists = true;
                    }
                });
            });
        },
        createNewEmployee() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][actual_name]', this.fields.actual_name);
            data.append('data[0][username]', this.fields.username);
            data.append('data[0][mobile]', this.fields.mobile);
            if (!this.fields.mobile.length) {
                this.validPhone = true;
            }
            if (this.validPhone) {
                fetch('/api/v1/repaircompanyemployee', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.closeModal();
                });
            }
        },
        validatePhone() {
            let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            if (pattern.test(this.fields.mobile)) {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            else {
                this.invalidPhone = true;
                this.validPhone = false;
            }
            if (!this.fields.mobile.length) {
                this.validPhone = false;
                this.invalidPhone = false;
            }
        },
        validateEmail() {
            this.emailexists = false;
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.fields.username)) {
                this.validEmail = true;
                this.invalidEmail = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        enableEmployee() {
            let self = this;
            fetch('/api/v1/buildingmanageremployee')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let disabledUsers = [];
                        if (item.username.includes('DISABLED')) {
                            let name = item.username.split(/[, ]+/).pop();
                            item.username = name;
                        }
                        if (item.username === self.fields.username) {
                            const data = new URLSearchParams();
                            data.append('id_shiro_user', item.id_shiro_user);
                            fetch('/api/v1/buildingmanageremployee/reenable', {
                                method: 'post',
                                body: data
                            }).then((resp) => {return resp.json();
                            }).then(function(data) {
                                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                                self.closeModal();
                            });
                        }
                    }
                });
            });
        }
    }
}
</script>
