<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-history"></i>{{profile.mandates.mandates_log}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" data-testid="commissionMandateHistoryClose" @click="closeModal()"><i class="fas fa-times"></i></span>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" @click="sortTable('created')">{{profile.dashboard.building_manager.message_table.date}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('amount')">{{profile.accountPage.account.amount}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('in_btw')">Incl. BTW<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('id_shiro_user')">{{profile.employees.name}}<i class="fas fa-sort"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in incidentMandateLog" :key="item.id_incident_mandate">
                            <td data-testid="commissionMandateHistoryDate">{{item.date}}</td>
                            <td data-testid="commissionMandateHistoryAmount">€ {{item.amount}}</td>
                            <td><i class="fas fa-check" data-testid="commissionMandateHistoryBtw" v-if="item.in_btw"></i></td>
                            <td data-testid="commissionMandateHistoryName">{{item.actual_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    name: 'mandateHistoryModal',
    data() {
        return {
            profile: {},
            sortOrder: 'asc'
        }
    },
    props: ['incidentMandateLog'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
            document.querySelector('.main-content').style.display = 'block';
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.incidentMandates = sortBy(this.incidentMandates, sorter);
            if (this.sortOrder === 'desc') {
                this.incidentMandates.reverse();
            }
        }
    }
}
</script>
