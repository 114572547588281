<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="types-values">
            <h2>{{profile.assets.assign_categories}}</h2>
            <div class="row">
                <div class="col-sm-6">
                    <div class="dropdown-section" style="margin-bottom: 20px;">
                        <span class="dropdown" :class="{active: assetTypeToAssignDropdownToggled}" v-on:click="assetTypeToAssignDropdownToggled = !assetTypeToAssignDropdownToggled">
                            <span v-if="!selectedAssetTypeToAssignId" class="placeholder">{{profile.assets.select_objecttype}}</span>
                            <span data-testid="typeValueSelect" v-if="selectedAssetTypeToAssignId" class="selected">{{selectedAssetTypeToAssignName}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div data-testid="typeValueList" class="dropdown-list" v-if="assetTypeToAssignDropdownToggled" :class="{search: assetTypes.length > 9}">
                            <ul>
                                <li v-for="assetTypeToAssign in assetTypes" v-on:click="selectAssetTypeToAssign(assetTypeToAssign)" :key="assetTypeToAssign.id" v-show="!tempAssetTypesToAssign.length">{{assetTypeToAssign.display_name}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div style="position: relative;" v-if="selectedAssetType.id_asset_type">
                <p style="margin-bottom: 15px;">{{profile.assets.move_categories}}</p>
                <div class="row">
                    <div class="col-sm-6" style="border-right: 1px solid rgb(204, 204, 204);">
                        <h2>{{profile.assets.available_categories}}</h2>
                        <section class="search search-inpage">
                            <form v-on:submit.prevent="searchUnassigned()"> 
                                <input type="text" ref="search" v-bind:placeholder="profile.incident_detail.search_category" @input="e => unassignedSearchTerm = e.target.value" :value="unassignedSearchTerm" @keydown="searchUnassigned($event)"  />
                                <span class="close-suggestions" @click="clearUnassignedSearch()" v-if="searchUnassignedActive"><i class="fas fa-times"></i></span>
                            </form>
                        </section>
                        <draggable class="list-group" :list="unassignedCategories" :group="{name: 'categories', pull: true, put: false}" @start="drag=true" @end="drag=false" :force-fallback="true">
                            <div v-for="category in unassignedCategories" :key="category.id_incident_category" v-show="selectedAssetTypeToAssignId" class="asset-block" style="width: 100%; cursor: move;">
                                <h4 style="margin-top: 0; line-height: 29px;">{{category.dutch_name}}</h4>
                            </div>
                        </draggable>
                    </div>
                    <div class="col-sm-6">
                        <h2>{{profile.assets.assigned_categories}}</h2>
                        <section class="search search-inpage">
                            <form v-on:submit.prevent="searchAssigned()"> 
                                <input type="text" ref="search" v-bind:placeholder="profile.incident_detail.search_category" @input="e => assignedSearchTerm = e.target.value" :value="assignedSearchTerm" @keydown="searchAssigned($event)"  />
                                <span class="close-suggestions" @click="clearAssignedSearch()" v-if="searchAssignedActive"><i class="fas fa-times"></i></span>
                            </form>
                        </section>
                        <draggable class="list-group" :list="assignedCategories" :group="{name: 'categories', pull: false, put: true}" :force-fallback="true" @change="save">
                            <div class="asset-block fullwidth no-hover" v-for="(category, index) in assignedCategories" :key="index">
                                <h4 style="margin-top: 0; line-height: 29px;">{{category.dutch_name}}</h4>
                                <span class="remove" @click="deselectCategory(category)"><i class="far fa-trash-alt"></i></span>      
                            </div>
                        </draggable>
                    </div>
                </div>        
            </div>
            <div class="bottom-bar" v-if="selectedCategories.length">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h3 v-tooltip.left="selectedCategoriesString" style="float: left; font-size: 18px;">{{ selectedCategories.length }} {{profile.assets.selected_categories}}</h3>
                            <div class="button button--cta button--right" @click="connectCategories()">{{profile.assets.assign_categories}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import draggable from 'vuedraggable'
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
export default {
    components: {
        draggable
    },
    mixins: [setLocale],
    props: ['admin', 'assetTypes'],
    data() {
        return {
            profile: {},
            loading: false,
            assetTypeToAssignDropdownToggled: false,
            selectedAssetTypeToAssignName: '',
            selectedAssetTypeToAssignId: 0,
            assetTypeEdit: false,
            assetTypesToAssign: [],
            tempAssetTypesToAssign: [],
            selectedAssetType: {},
            assignedCategories: [],
            unassignedCategories: [],
            selectedCategories: [],
            selectedCategoriesString: '',
            categories: [],
            searchUnassignedActive: false,
            unassignedSearchTerm: '',
            searchAssignedActive: false,
            assignedSearchTerm: '',
        }
    },
    computed: mapState(['bmId', 'resetSets']),
    watch: {
        resetSets() { 
            if (this.resetSets) {
                this.getCategories();
                this.selectedAssetTypeToAssignName = ''; 
                this.selectedAssetTypeToAssignId = 0; 
                this.selectedAssetType = [];
                this.assignedCategories = [];
                this.unassignedCategories = [];
                this.$store.dispatch('updateResetSets', false);
            }      
        }
    },
    created() {
        this.getCategories();
    },
    methods: {
        searchUnassigned($event) {
            let searchTerm = this.unassignedSearchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchUnassignedActive = true;
                this.unassignedCategories = this.unassignedCategories.filter(category => category.dutch_name.toLowerCase().includes(searchTerm));
            }
            if (searchTerm.length < 2 && this.searchUnassignedActive)  {
                this.searchUnassignedActive = false;
                this.getCategories();
            }
            if ($event) {
                if($event.keyCode === 27) {
                    this.searchUnassignedActive = false;
                    this.unassignedSearchTerm = '';
                    this.getCategories();
                }
            }   
        },
        clearUnassignedSearch() {
            this.assets = [];
            this.searchUnassignedActive = false;
            this.unassignedSearchTerm = '';
            this.getCategories();
        },
        searchAssigned($event) {
            let searchTerm = this.assignedSearchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchAssignedActive = true;
                this.assignedCategories = this.assignedCategories.filter(category => category.dutch_name.toLowerCase().includes(searchTerm));
            }
            if (searchTerm.length < 2 && this.searchAssignedActive)  {
                this.searchAssignedActive = false;
                this.getAssignedCategories();
            }
            if ($event) {
                if($event.keyCode === 27) {
                    this.searchAssignedActive = false;
                    this.assignedSearchTerm = '';
                    this.getAssignedCategories();
                }
            }   
        },
        clearAssignedSearch() {
            this.assets = [];
            this.searchAssignedActive = false;
            this.assignedSearchTerm = '';
            this.getAssignedCategories();
        },
        selectAssetTypeToAssign(assetTypeToAssign) {
            this.assetTypeToAssignDropdownToggled = false;
            this.selectedAssetTypeToAssignName = assetTypeToAssign.display_name;
            this.selectedAssetTypeToAssignId = assetTypeToAssign.id_asset_type;
            this.tempAssetTypesToAssign = [];
            this.selectedAssetType = assetTypeToAssign;
            this.getAssignedCategories();
        },
        getCategories() {
            this.categories = [];
            this.assignedCategories = [];
            this.unassignedCategories = [];
            let self = this;
            fetch('/api/v1/incident_category/bybuildingmanager?id_building_manager=' + this.bmId)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let category =  data.data[i];
                        category.selected = false;
                    }                    
                    self.categories = data.data;
                    if (self.selectedAssetTypeToAssignId) {
                        self.getAssignedCategories();
                    }
                });
            });
        },
        getAssignedCategories() {
            let self = this;
            fetch('/api/v1/asset_type_incident_category/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.assignedCategories = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let category = data.data[i];
                        if (category.id_asset_type === self.selectedAssetTypeToAssignId) {
                            self.assignedCategories.push(category);
                        }
                    }  
                    self.assignedCategories = uniqBy(self.assignedCategories, 'dutch_name');
                    self.assignedCategories = sortBy(self.assignedCategories, 'dutch_name');
                    self.unassignedCategories = self.categories.filter(item1 => !self.assignedCategories.some(item2 => item2.id_incident_category === item1.id_incident_category));
                    self.unassignedCategories = uniqBy(self.unassignedCategories, 'dutch_name');
                    self.unassignedCategories = sortBy(self.unassignedCategories, 'dutch_name');
                });
            });
        },
        deselectCategory(category) {
            const data = new URLSearchParams();
            let paramAssetTypeCategory = 'data[' + category.id_asset_type_incident_category + '][id_asset_type_incident_category]';
            let paramAssetTypeId = 'data[' + category.id_asset_type_incident_category + '][id_asset_type]';
            let paramCategoryId = 'data[' + category.id_asset_type_incident_category + '][id_incident_category]';
            data.append('action', 'remove');
            data.append(paramAssetTypeCategory, category.id_asset_type_incident_category);
            data.append(paramAssetTypeId, category.id_asset_type);
            data.append(paramCategoryId, category.id_incident_category);
            let self = this;
            fetch('/api/v1/asset_type_incident_category', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                for (var i = self.assignedCategories.length - 1; i >= 0; i--) {
                    let assignedCategory = self.assignedCategories[i];
                    if (category.id_incident_category === assignedCategory.id_incident_category) {
                        self.assignedCategories.splice(i, 1);
                    }
                }
                self.assignedCategories = sortBy(self.assignedCategories, 'dutch_name');
                self.unassignedCategories = sortBy(self.unassignedCategories, 'dutch_name');
            });
        },
        connectCategory(category) { 
            const data = new URLSearchParams();
            let paramCategoryId = 'data[0][id_incident_category]';
            let paramAssetTypeId = 'data[0][id_asset_type]';
            data.append('action', 'create');
            data.append(paramCategoryId, category.id_incident_category);
            data.append(paramAssetTypeId, this.selectedAssetTypeToAssignId);
            let self = this;
            fetch('/api/v1/asset_type_incident_category', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.assignedCategories = sortBy(self.assignedCategories, 'dutch_name');
                self.unassignedCategories = sortBy(self.unassignedCategories, 'dutch_name');
            });  
        },
        save: function(evt) {
            if (evt.added) {
                this.connectCategory(evt.added.element);
            }
            if (evt.removed) {
                this.deselectCategory(evt.removed.element);
            }
            if (evt.moved) {
                this.assignedCategories = sortBy(this.assignedCategories, 'dutch_name');
            }
        }
    }
}
</script>
