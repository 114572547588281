<template>
    <div>
        <div class="main-content" v-if="profile.incidents.new_incident && (twinq && admin) || !twinq">
            <div class="container">
                <section class="block block--dashboard-actions">
                    <div class="profile-card" style="width: 100%;">
                        <div class="profile-pic-wrapper" style="float:left;">
                            <img :src="profilePic" class="profile-pic" v-if="profilePic" />
                            <div class="profile-pic placeholder" v-else>
                                <i class="far fa-image"></i>
                            </div>                           
                        </div> 
                        <div class="info" style="float:left; width: 71%;">
                            <h2 style="float: none;" data-testid="managerNameText">{{ bmCompanyName }}</h2>
                            <h3 style="display: block; margin-top: 5px;">{{ userName }}</h3>
                        </div>                 
                    </div>
                </section>
                <div class="row d-block d-md-none">
                    <div class="col" style="position: relative;">
                        <router-link tag="span" class="button button--icon new-incident" to="/buildingmanager/new-incident"><i class="fas fa-plus"></i>{{profile.incidents.new_incident.title}}</router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="searchterms" v-if="searchTerms.length">
                            <h4>{{profile.dashboard.building_manager.past_searches}}</h4>
                            <div v-for="(item, index) in searchTerms" :key="index" class="term-wrapper">
                                <span class="searchterm" @click="searchSuggest(item)">{{item}}</span>
                                <i class="fa fa-times" @click="removeTerm(item)"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-9">
                        <section class="search dashboard" :class="{active: searchSuggestions.length}" style="margin-bottom: 20px;">
                            <input type="text" data-testid="searchIncident" v-bind:placeholder="profile.dashboard.building_manager.search" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="searchSuggest($event)" style="margin-bottom: 0;" />
                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                            <ul class="search-suggestions" v-if="searchSuggestions.length" style="margin-top: -3px;">
                                <h4 class="result-counter" v-if="totalSuggestions === 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="totalSuggestions > 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                <li v-for="(suggestion, index) in searchSuggestions" :key="index" @click="toIncident(suggestion)" v-tooltip.center="suggestion.incident_description">
                                    <div class="indicators">
                                        <span class="number" data-testid="searchIncidentNumber">{{suggestion.id_incident}}</span>
                                        <span class="auto-forward" v-if="suggestion.id_ori_incident">auto <i class="fas fa-forward"></i></span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_PERSON' || suggestion.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                        <span class="status" data-testid="searchIncidentStatus" v-if="!profile['statusses'][suggestion.status].buildingmanager">
                                            {{profile['statusses'][suggestion.status]}}
                                            <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                        </span>
                                        <span class="status" data-testid="searchIncidentStatus" v-if="profile['statusses'][suggestion.status].buildingmanager">
                                            {{profile['statusses'][suggestion.status].buildingmanager}}
                                            <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                        </span>
                                        <span class="date">{{profile.incidents.incidenttable.since}}{{suggestion.date}} {{suggestion.time}}</span>
                                    </div>
                                    <span class="category" v-if="locale === 'nl'">{{suggestion.category_dutch_name}}</span>
                                    <span class="category" v-else>{{suggestion.category_english_name}}</span>
                                    <span class="date" style="clear: both; display: block; margin-left: 0;">{{profile.incidents.incidenttable.datetime}}: {{suggestion.createdDate}}</span>
                                    <p data-testid="searchPlaceText">{{suggestion.place_name}}, {{suggestion.address}} {{suggestion.house_number}}</p>
                                    <span class="asset">{{suggestion.asset_name}}</span>
                                    <span class="repaircompany" v-if="suggestion.repair_company_name" style="display: block; font-weight: 500; clear:both; margin-top: 3px;"><i class="fas fa-tools"></i> {{suggestion.repair_company_name}}</span>
                                    <span class="parked" v-if="suggestion.suspended"><i class="fas fa-parking"></i> {{profile.incident_detail.parked}}</span>
                                </li>
                            </ul>
                        </section>
                    </div>
                    <div class="col-md-4 col-lg-3 d-none d-md-block">
                        <router-link tag="span" class="button button--icon new-incident" to="/buildingmanager/new-incident"><i class="fas fa-plus"></i>{{profile.incidents.new_incident.title}}</router-link>
                    </div>
                </div>
                
                    <!-- <div class="col md-6 col-lg-12">
                        <div class="tabs">
                            <div class="tab" :class="{active: searchType === 'regular'}" @click="searchType = 'regular'">
                                <h3>Zoek op melding</h3>
                            </div>
                            <div class="tab" :class="{active: searchType === 'other'}"  @click="searchType = 'other'">
                                <h3>Zoek op melder</h3>
                            </div>
                        </div>
                        <div class="tab-view" :class="{active: searchType === 'regular'}" style="margin-bottom: 20px; overflow: visible;">
                            <section class="search dashboard" :class="{active: searchSuggestions.length}">
                                <input type="text" data-testid="searchIncident" v-bind:placeholder="profile.dashboard.building_manager.search" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="searchSuggest($event)" style="margin-bottom: 0;" />
                                <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                                <ul class="search-suggestions" v-if="searchSuggestions.length" style="margin-top: -3px;">
                                    <h4 class="result-counter" v-if="totalSuggestions === 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                    <h4 class="result-counter" v-if="totalSuggestions > 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                    <li v-for="(suggestion, index) in searchSuggestions" :key="index" @click="toIncident(suggestion)" v-tooltip.center="suggestion.incident_description">
                                        <div class="indicators">
                                            <span class="number" data-testid="searchIncidentNumber">{{suggestion.id_incident}}</span>
                                            <span class="auto-forward" v-if="suggestion.id_ori_incident">auto <i class="fas fa-forward"></i></span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_PERSON' || suggestion.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                            <span class="status" data-testid="searchIncidentStatus" v-if="!profile['statusses'][suggestion.status].buildingmanager">
                                                {{profile['statusses'][suggestion.status]}}
                                                <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                            </span>
                                            <span class="status" data-testid="searchIncidentStatus" v-if="profile['statusses'][suggestion.status].buildingmanager">
                                                {{profile['statusses'][suggestion.status].buildingmanager}}
                                                <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                            </span>
                                            <span class="date">{{profile.incidents.incidenttable.since}}{{suggestion.date}} {{suggestion.time}}</span>
                                        </div>
                                        <span class="category" v-if="locale === 'nl'">{{suggestion.category_dutch_name}}</span>
                                        <span class="category" v-else>{{suggestion.category_english_name}}</span>
                                        <span class="date" style="clear: both; display: block; margin-left: 0;">{{profile.incidents.incidenttable.datetime}}: {{suggestion.createdDate}}</span>
                                        <p data-testid="searchPlaceText">{{suggestion.place_name}}, {{suggestion.address}} {{suggestion.house_number}}</p>
                                        <span class="asset">{{suggestion.asset_name}}</span>
                                        <span class="repaircompany" v-if="suggestion.repair_company_name" style="display: block; font-weight: 500; clear:both; margin-top: 3px;"><i class="fas fa-tools"></i> {{suggestion.repair_company_name}}</span>
                                        <span class="parked" v-if="suggestion.suspended"><i class="fas fa-parking"></i> {{profile.incident_detail.parked}}</span>
                                    </li>
                                </ul>
                            </section>
                        </div> 
                        <div class="tab-view" :class="{active: searchType === 'other'}" style="margin-bottom: 20px;  overflow: visible;">
                            <section class="search dashboard" :class="{active: searchSuggestions.length}">
                                <input type="text" data-testid="searchIncident" v-bind:placeholder="'Zoeken een melding op gegevens van de melder'" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="searchSuggest($event)" style="margin-bottom: 0;" />
                                <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                                <ul class="search-suggestions" v-if="searchSuggestions.length" style="margin-top: -3px;">
                                    <h4 class="result-counter" v-if="totalSuggestions === 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                    <h4 class="result-counter" v-if="totalSuggestions > 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                    <li v-for="(suggestion, index) in searchSuggestions" :key="index" @click="toIncident(suggestion)" v-tooltip.center="suggestion.incident_description">
                                        <div class="indicators">
                                            <span class="number" data-testid="searchIncidentNumber">{{suggestion.id_incident}}</span>
                                            <span class="auto-forward" v-if="suggestion.id_ori_incident">auto <i class="fas fa-forward"></i></span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_PERSON' || suggestion.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                            <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                            <span class="status" data-testid="searchIncidentStatus" v-if="!profile['statusses'][suggestion.status].buildingmanager">
                                                {{profile['statusses'][suggestion.status]}}
                                                <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                            </span>
                                            <span class="status" data-testid="searchIncidentStatus" v-if="profile['statusses'][suggestion.status].buildingmanager">
                                                {{profile['statusses'][suggestion.status].buildingmanager}}
                                                <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                            </span>
                                            <span class="date">{{profile.incidents.incidenttable.since}}{{suggestion.date}} {{suggestion.time}}</span>
                                        </div>
                                        <span class="category" v-if="locale === 'nl'">{{suggestion.category_dutch_name}}</span>
                                        <span class="category" v-else>{{suggestion.category_english_name}}</span>
                                        <span class="date" style="clear: both; display: block; margin-left: 0;">{{profile.incidents.incidenttable.datetime}}: {{suggestion.createdDate}}</span>
                                        <p data-testid="searchPlaceText">{{suggestion.place_name}}, {{suggestion.address}} {{suggestion.house_number}}</p>
                                        <span class="asset">{{suggestion.asset_name}}</span>
                                        <span class="repaircompany" v-if="suggestion.repair_company_name" style="display: block; font-weight: 500; clear:both; margin-top: 3px;"><i class="fas fa-tools"></i> {{suggestion.repair_company_name}}</span>
                                        <span class="parked" v-if="suggestion.suspended"><i class="fas fa-parking"></i> {{profile.incident_detail.parked}}</span>
                                    </li>
                                </ul>
                            </section>
                        </div>     
                    </div> -->
                <div class="row visible-xs">
                    <div class="col">
                        <div class="switch-wrapper dashboard">
                            <p>{{profile.dashboard.building_manager.required_actions}}</p>
                            <label class="switch">
                                <input type="checkbox" v-model="showType">
                                <span class="slider round" data-testid="filterActionSwitch"></span>
                            </label>
                            <p>{{profile.dashboard.building_manager.current_incidents}}</p>
                        </div>
                    </div>
                </div>

                <!-- Filters -->
                <h3 class="filter-expand d-md-none d-lg-none" @click="filterToggle()" :class="{active: showFilters}">Filters <i class="fas fa-chevron-down" v-if="!filtersActive" style="font-size: 16px;"></i></h3>
                <div class="dashboard-filters d-sm-block d-md-block d-lg-block" v-show="showFilters">
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
                        <div class="col" v-if="assignedToList.length">
                            <div class="dropdown-section">
                                <span v-if="settings.filters.assignedToPerson" class="remove-selection" style="top: 18px; cursor: pointer; z-index: 2;" @click="removeAssignedToName()"><i class="fas fa-times"></i></span>
                                <span class="dropdown" data-testid="filterManagerDropdown" :class="{active: assignedToDropdownToggled}" @click="assignedToDropdownToggled = !assignedToDropdownToggled">
                                    <span v-if="!settings.filters.assignedToPerson" class="placeholder">{{profile.incidents.incidenttable.assigned}}</span>
                                    <span v-if="settings.filters.assignedToPerson" class="selected">{{settings.filters.assignedToPerson.assigned_to_name}}</span>
                                    <span v-if="settings.filters.assignedToPerson" class="seperator">|</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="assignedToDropdownToggled" :class="{search: assignedToList.length > 9}">
                                    <input type="text" placeholder="Zoek..." @input="e => assignedToSearchTerm = e.target.value" :value="assignedToSearchTerm" @keyup="searchAssignedToList()" v-if="assignedToList.length > 9" style="margin: 10px 0;" />
                                    <ul>
                                        <li data-testid="filterManagerResult" v-for="(person, index) in assignedToList" v-on:click="selectAssignedTo(person)" :key="index" v-show="!tempAssignedToList.length && !noAssignedToResults">{{person.actual_name}}</li>
                                        <li data-testid="filterManagerResult" v-for="person in tempAssignedToList" v-on:click="selectAssignedTo(person)" :key="person.assigned_to_id" v-show="tempAssignedToList.length">{{person.actual_name}}</li>
                                        <li v-if="noAssignedToResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col" v-if="teamsList.length">
                            <div class="dropdown-section">
                                <span v-if="settings.filters.assignedToTeam" class="remove-selection" style="top: 18px; cursor: pointer; z-index: 2;" @click="removeAssignedToTeam()"><i class="fas fa-times"></i></span>
                                <span class="dropdown" data-testid="filterTeamDropdown" :class="{active: assignedToTeamDropdownToggled}" @click="assignedToTeamDropdownToggled = !assignedToTeamDropdownToggled">
                                    <span v-if="!settings.filters.assignedToTeam" class="placeholder">Team</span>
                                    <span v-if="settings.filters.assignedToTeam" class="selected">{{settings.filters.assignedToTeam.team_name}}</span>
                                    <span v-if="settings.filters.assignedToTeam" class="seperator">|</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="assignedToTeamDropdownToggled">
                                    <ul>
                                        <li data-testid="filterTeamResult" v-for="team in teamsList" v-on:click="selectTeam(team)" :key="team.id_team">{{team.name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col" v-if="repaircompanyList.length">
                            <div class="dropdown-section">
                                <span v-if="settings.filters.repaircompany" style="top: 18px; cursor: pointer; z-index: 2;" class="remove-selection" @click="removeRepaircompany()"><i class="fas fa-times"></i></span>
                                <span class="dropdown" data-testid="filterSupplierDropdown" :class="{active: repaircompanyDropdownToggled}" @click="repaircompanyDropdownToggled = !repaircompanyDropdownToggled">
                                    <span v-if="!settings.filters.repaircompany" class="placeholder">{{profile.incidents.incidenttable.repair_company}}</span>
                                    <span v-if="settings.filters.repaircompany" class="selected">{{settings.filters.repaircompany.repair_company_name}}</span>
                                    <span v-if="settings.filters.repaircompany" class="seperator">|</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="repaircompanyDropdownToggled" :class="{search: repaircompanyList.length > 9}">
                                    <input type="text" placeholder="Zoek..." @input="e => repaircompanySearchTerm = e.target.value" :value="repaircompanySearchTerm" @keyup="searchRepaircompanyList()" v-if="repaircompanyList.length > 9" style="margin: 10px 0;" />
                                    <ul>
                                        <li data-testid="filterSupplierResult" v-for="(repaircompany, index) in repaircompanyList" v-on:click="selectRepaircompany(repaircompany)" :key="index" v-show="!tempRepaircompanyList.length && !noRepaircompanyResults">{{repaircompany.name}}</li>
                                        <li data-testid="filterSupplierResult" v-for="repaircompany in tempRepaircompanyList" v-on:click="selectRepaircompany(repaircompany)" :key="repaircompany.id_repair_company" v-show="tempRepaircompanyList.length">{{repaircompany.name}}</li>
                                        <li v-if="noRepaircompanyResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col" v-if="agreementLabelList.length">
                            <div class="dropdown-section agreementflow">
                                <span v-if="settings.filters.agreementflow" style="top: 18px; cursor: pointer; z-index: 2;" class="remove-selection" @click="removeAgreementLabel()"><i class="fas fa-times"></i></span>
                                <span class="dropdown" data-testid="filterAgreementDropdown" :class="{active: agreementLabelDropdownToggled}" @click="agreementLabelDropdownToggled = !agreementLabelDropdownToggled">
                                    <span v-if="!settings.filters.agreementflow" class="placeholder">{{profile.owners.agreementflow.agreement_flow}}</span>
                                    <span v-if="settings.filters.agreementflow === 'NO_CHOICE'" style="width: 100%;" class="selected">{{profile.owners.agreementflow.no_choice_status}}</span>
                                    <span v-if="settings.filters.agreementflow === 'WAITING_ON_SERVICE_DESK'" style="width: 100%;" class="selected">{{profile.owners.agreementflow.waiting_status}}</span>
                                    <span v-if="settings.filters.agreementflow === 'AGREED'" style="width: 100%;" class="selected">{{profile.owners.agreementflow.agreed_status}}</span>
                                    <span v-if="settings.filters.agreementflow" class="seperator">|</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="agreementLabelDropdownToggled">
                                    <ul>
                                        <li v-for="(agreementlabel, index) in agreementLabelList" v-on:click="selectAgreementLabel(agreementlabel)" :key="index" v-show="agreementlabel === 'NO_CHOICE' || agreementlabel === 'WAITING_ON_SERVICE_DESK' || agreementlabel === 'AGREED'" :class="{active: agreementlabel === 'NO_CHOICE' || agreementlabel === 'WAITING_ON_SERVICE_DESK' || agreementlabel === 'AGREED'}">
                                            <span v-if="agreementlabel === 'NO_CHOICE'" style="width: 100%;">{{profile.owners.agreementflow.no_choice_status}}</span>
                                            <span v-if="agreementlabel === 'WAITING_ON_SERVICE_DESK'" style="width: 100%;">{{profile.owners.agreementflow.waiting_status}}</span>
                                            <span v-if="agreementlabel === 'AGREED'" style="width: 100%;">{{profile.owners.agreementflow.agreed_status}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="filter-checkboxes">
                                <div class="filter-checkbox-block">
                                    <div class="checkbox-indicator" @click="selectFilter('urgent')">
                                        <i class="far fa-check-square" v-if="settings.checkboxes.urgent"></i>
                                        <i class="far fa-square" data-testid="filterUrgentCheckbox" v-else></i>
                                    </div>
                                    <label @click="selectFilter('urgent')">Urgent</label>
                                </div>
                                <div class="filter-checkbox-block">
                                    <div class="checkbox-indicator" @click="selectFilter('unread')">
                                        <i class="far fa-check-square" v-if="settings.checkboxes.unread"></i>
                                        <i class="far fa-square" data-testid="filterUnreadCheckbox" v-else></i>
                                    </div>
                                    <label @click="selectFilter('unread')">{{profile.dashboard.building_manager.messages}}</label>
                                </div>
                                <div class="filter-checkbox-block">
                                    <div class="checkbox-indicator" @click="selectFilter('followup')">
                                        <i class="far fa-check-square" v-if="settings.checkboxes.followup"></i>
                                        <i class="far fa-square" data-testid="filterFollowUpCheckbox" v-else></i>
                                    </div>
                                    <label @click="selectFilter('followup')">{{profile.incident_detail.followup_label}}</label>
                                </div>
                                <div class="text-link" data-testid="agreementflowList" @click="agreementFlowItemsModalOpen = true" style="float: right; font-weight: 500; margin-top: 0; font-size: 14px;" v-if="agreementFlowItems.length"><i class="fas fa-user-check" style="margin-right: 3px;"></i>{{profile.owners.agreementflow.agreement_flow}} ({{agreementFlowItems.length}})</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Action Required Items -->
                <div class="items-wrap">
                    <div class="row" :class="{hiddenxs: showType}">
                        <div class="col">
                            <div class="action-head">
                                <h3 class="action-indicator" data-testid="totalActionResult">{{profile.dashboard.building_manager.required_actions}} ({{actionRequiredCount}})</h3>
                                <div class="view-switch" v-if="actionRequiredItems.length">
                                    <span class="table-switch" @click="switchView('actionrequired', 'table')" :class="{active: actionRequiredView === 'table'}" v-tooltip.right="profile.dashboard.table_view"><i class="fas fa-list-ul"></i></span>
                                    <span class="grid-switch" @click="switchView('actionrequired', 'grid')" :class="{active: actionRequiredView === 'grid'}"  v-tooltip.right="profile.dashboard.list_view"><i class="fas fa-th"></i></span>
                                </div>
                                <div class="pagination" v-if="actionRequiredCount > 8" :class="{nomargin: actionRequiredCount < 8}">
                                    <span class="prev" v-if="actionRequiredCurrentPage !== 1" @click="prevPage('actionrequired')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="actionRequiredCurrentPage !== 1" @click="firstPage('actionrequired')">1</span>
                                    <span class="page-indicator">{{actionRequiredCurrentPage}} / {{actionRequiredTotalPages}}</span>
                                    <span class="page page--last" v-if="actionRequiredTotalPages > 2" @click="lastPage('actionrequired')">{{actionRequiredTotalPages}}</span>
                                    <span class="next" v-if="actionRequiredCurrentPage !== actionRequiredTotalPages" @click="nextPage('actionrequired')"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="actionRequiredView === 'grid'" :class="{hiddenxs: showType}" v-if="actionRequiredItems.length">
                        <div class="col">
                            <span class="sort">{{profile.dashboard.building_manager.sort}}</span>
                            <div class="filters">
                                <span class="filter hidden-xs" data-testid="filterActionId" @click="sortTable('actionrequired', 'id_incident')" :class="{active: actionRequiredSortType === 'id_incident', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterActionAddress" @click="sortTable('actionrequired', 'address')" :class="{active: actionRequiredSortType === 'address', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.address}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterActionAsset" @click="sortTable('actionrequired', 'asset_name')" :class="{active: actionRequiredSortType === 'asset_name', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.asset}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" data-testid="filterActionState" @click="sortTable('actionrequired', 'status')" :class="{active: actionRequiredSortType === 'status', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterActionAssigned" @click="sortTable('actionrequired', 'assigned_to_name')" :class="{active: actionRequiredSortType === 'assigned_to_name', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.assigned}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" data-testid="filterActionSince" @click="sortTable('actionrequired', 'last_status_time')" :class="{active: actionRequiredSortType === 'last_status_time', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_in_status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterActionChat" @click="sortTable('actionrequired', 'last_chat')" :class="{active: actionRequiredSortType === 'last_chat', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_last_chat}} <i class="fa fa-chevron-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-view" v-show="actionRequiredView === 'grid'" :class="{hiddenxs: showType}" v-if="actionRequiredItems.length">
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in actionRequiredItems" :key="index">
                                <div class="incident-wrap" :class="{watched: item.watchers}">
                                    <div class="block block--action" :class="{hasChats: item.chat_count}" @click="toIncident(item)" v-tooltip.right="item.description">
                                        <div class="block-head">
                                            <span class="action-status" v-if="!profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status]}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                            </span>
                                            <span class="action-status" v-if="profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status].buildingmanager}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                                <span style="font-weight: 500;" v-if="item.status === 'returned_to_buildingmanager' && item.mandate_warning === 'no_warning'"> ({{profile.invoicing.refused}})</span>
                                            </span>
                                            <span class="action-status watch" v-show="item.watchers" v-tooltip.right="profile.dashboard.watched_by + ' ' + item.watchers"><i class="fas fa-eye"></i> {{profile.dashboard.watched}}</span>
                                            <span class="number" data-testid="incidentActionNumber">
                                                <i class="fas fa-share" style="font-size: 14px;" v-if="item.passedIncident" v-tooltip.right="profile.incident_detail.passed_tooltip"></i>
                                                <i class="fas fa-paper-plane" style="font-size: 14px;" v-if="item.passedToIncident" v-tooltip.right="profile.incident_detail.passed_to_tooltip_to + ' ' + item.building_manager"></i>
                                                {{ item.id_incident}}
                                            </span>
                                        </div>
                                        <div class="block-content">
                                            <span class="post-it urgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fas fa-exclamation-circle"></i></span>
                                            <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                            <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                            <span class="post-it admin-done" v-if="item.admin_done" v-tooltip.right="profile.incident_detail.adminn_ready"><i class="fas fa-check"></i></span>
                                            <span class="post-it wants-copy" v-if="item.wants_copy" v-tooltip.right="profile.incident_detail.followup_label"><i class="fas fa-calendar-plus"></i></span>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                    <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                                </div>
                                            </div>
                                            <div class="labels">
                                                <span v-if="item.team_name" class="current-user"><i class="fas fa-users"></i> {{item.team_name}}</span>
                                                <span v-if="item.assigned_to_name" class="current-user" v-tooltip.right="profile.incident_detail.incident_who + ' ' + item.assigned_to_name"><i class="fas fa-user"></i> {{item.assigned_to_name}}</span>
                                            </div>
                                            <h4>{{item.address}}</h4>
                                            <h5>{{item.asset_name}}</h5>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                            <h6 v-if="item.repair_company_name"><i class="fas fa-tools"></i> {{item.repair_company_name}}</h6>
                                            <span class="chat-indicator"><i class="fas fa-comment"></i> {{item.lastChatString}}</span>
                                            <span class="time-indicator" :class="item.overtimeStatus">{{item.overtime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-view" v-show="actionRequiredView === 'table'" v-if="actionRequiredItems.length">
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('actionrequired', 'id_incident')" :class="{active: actionRequiredSortType === 'id_incident'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'address')" :class="{active: actionRequiredSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'asset_name')" :class="{active: actionRequiredSortType === 'asset_name'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'status')" :class="{active: actionRequiredSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'assigned_to_name')" :class="{active: actionRequiredSortType === 'assigned_to_name'}">{{profile.incidents.incidenttable.assigned}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'last_status_time')" :class="{active: actionRequiredSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'last_chat')" :class="{active: actionRequiredSortType === 'last_chat'}">{{profile.incidents.incidenttable.time_last_chat}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'repair_company_name')" :class="{active: actionRequiredSortType === 'repair_company_name'}">{{profile.incidents.incidenttable.repair_company}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'dutch_category_name')" :class="{active: currentIncidentSortType === 'dutch_category_name'}" v-if="locale === 'nl'">{{profile.incident_detail.category}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'english_category_name')" :class="{active: currentIncidentSortType === 'english_category_name'}" v-else>{{profile.incident_detail.category}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in actionRequiredItems" :key="index" @click="toIncident(item)" :class="{watched: item.watchers}">
                                            <td style="position: relative;">
                                                <span class="id_incident">{{item.id_incident}}</span>
                                                <div>
                                                    <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                                    <span class="post-it urgent" v-if="item.urgency_level" style="background: orange;" v-tooltip.right="'Urgent'"><i class="fas fa-exclamation-circle"></i></span>
                                                    <span class="post-it invoice" v-if="item.invoice_count" style="background: #0867b3;" v-tooltip.right="profile.invoicing.invoice_added"><i class="fas fa-file-alt"></i></span>
                                                    <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                                    <span class="post-it admin-done" v-if="item.admin_done" v-tooltip.right="profile.incident_detail.adminn_ready"><i class="fas fa-check"></i></span>
                                                </div>
                                                <span class="additional" v-if="item.passedIncident || item.passedToIncident || item.autodispatch || item.id_ori_incident"><i class="fas fa-info-circle"></i></span>
                                                <div class="additional-wrap">
                                                    <span class="additional-item" v-if="item.passedIncident"><i class="fas fa-share"></i> {{profile.dashboard.forwarded}}</span>
                                                    <span class="additional-item" v-if="item.passedToIncident"> <i class="fas fa-paper-plane"></i> {{profile.dashboard.put_through}} {{item.building_manager}}</span>
                                                    <span class="additional-item" v-if="item.autodispatch"><i class="fas fa-forward"></i> {{profile.dashboard.auto_forward}}</span>
                                                    <span class="additional-item" v-if="item.id_ori_incident"><i class="fas fa-copy" style="margin-right: 5px;"></i>{{profile.dashboard.copy_of}} {{item.id_ori_incident}}</span>
                                                </div>
                                                <div class="labels">
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                                </div>
                                            </td>
                                            <td v-tooltip.right="item.description">{{item.address}}</td>
                                            <td v-tooltip.right="item.description">{{item.asset_name}}</td>
                                            <td class="status" v-if="!profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status]}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                            </td>
                                            <td class="status" v-if="profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status].buildingmanager}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span style="font-size: 10px;" v-if="item.status === 'returned_to_buildingmanager' && item.mandate_warning === 'no_warning'"> ({{profile.invoicing.refused}})</span>
                                            </td>
                                            <td class="status watch" v-show="item.watchers" v-tooltip.right="profile.dashboard.watched_by + ' ' + item.watchers"><i class="fas fa-eye"></i> {{profile.dashboard.watched}}</td>
                                            <td v-tooltip.right="item.description">{{item.assigned_to_name}}<span v-if="item.team_name">,</span> {{item.team_name}}</td>
                                            <td v-tooltip.right="item.description" :class="item.overtimeStatus">{{item.overtime}}</td>
                                            <td v-tooltip.right="item.description">{{item.lastChatString}}</td>
                                            <td v-tooltip.right="item.description">{{item.repair_company_name}}</td>
                                            <td v-tooltip.right="item.dutch_category_name" v-if="locale === 'nl'">{{item.dutch_category_name}}</td>
                                            <td v-tooltip.right="item.english_category_name" v-else>{{item.english_category_name}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Current Incident Items -->
                <div class="items-wrap">
                    <div class="row" :class="{hiddenxs: !showType}">
                        <div class="col">
                            <div class="action-head">
                                <h3 class="action-indicator" data-testid="totalRunningResult">{{profile.dashboard.building_manager.current_incidents}} ({{currentIncidentsCount}})</h3>
                                <div class="view-switch" v-if="currentIncidentItems.length">
                                    <span class="table-switch" @click="switchView('currentincidents', 'table')" :class="{active: currentIncidentsView === 'table'}" v-tooltip.right="profile.dashboard.table_view"><i class="fas fa-list-ul"></i></span>
                                    <span class="grid-switch"  @click="switchView('currentincidents', 'grid')" :class="{active: currentIncidentsView === 'grid'}" v-tooltip.right="profile.dashboard.list_view"><i class="fas fa-th"></i></span>
                                </div>
                                <div class="pagination" v-if="currentIncidentsCount > 8" :class="{'no-margin': currentIncidentsCount < 8}">
                                    <span class="prev" v-if="currentIncidentCurrentPage !== 1" @click="prevPage('currentincident')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="currentIncidentCurrentPage !== 1" @click="firstPage('currentincident')">1</span>
                                    <span class="page-indicator">{{currentIncidentCurrentPage}} / {{currentIncidentTotalPages}}</span>
                                    <span class="page page--last" v-if="currentIncidentTotalPages > 2" @click="lastPage('currentincident')">{{currentIncidentTotalPages}}</span>
                                    <span class="next" @click="nextPage('currentincident')" v-if="currentIncidentCurrentPage !== currentIncidentTotalPages"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="currentIncidentsView === 'grid'" :class="{hiddenxs: !showType}" v-if="currentIncidentItems.length">
                        <div class="col">
                            <span class="sort">{{profile.dashboard.building_manager.sort}}</span>
                            <div class="filters">
                                <span class="filter hidden-xs" data-testid="filterCurrentId" @click="sortTable('currentincident', 'id_incident')" :class="{active: currentIncidentSortType === 'id_incident', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.dashboard.building_manager.message_table.incident_ID}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterCurrentAddress" @click="sortTable('currentincident', 'address')" :class="{active: currentIncidentSortType === 'address', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.address}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterCurrentAsset" @click="sortTable('currentincident', 'asset_name')" :class="{active: currentIncidentSortType === 'asset_name', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.asset}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" data-testid="filterCurrentState" @click="sortTable('currentincident', 'status')" :class="{active: currentIncidentSortType === 'status', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterCurrentAssigned" @click="sortTable('currentincident', 'assigned_to_name')" :class="{active: currentIncidentSortType === 'assigned_to_name', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.assigned}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" data-testid="filterCurrentSince" @click="sortTable('currentincident', 'last_status_time')" :class="{active: currentIncidentSortType === 'last_status_time', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_in_status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterCurrentChat" @click="sortTable('currentincident', 'last_chat')" :class="{active: currentIncidentSortType === 'last_chat', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_last_chat}} <i class="fa fa-chevron-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-view" v-show="currentIncidentsView === 'grid'" :class="{hiddenxs: !showType}" v-if="currentIncidentItems.length">
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in currentIncidentItems" :key="index" v-tooltip.right="item.description" @click="toIncident(item)">
                                <div class="incident-wrap" :class="{watched: item.watchers}">
                                    <div class="block block--action">
                                        <div class="block-head">
                                            <span class="action-status" v-if="!profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status]}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                            </span>
                                            <span class="action-status" v-if="profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status].buildingmanager}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                            </span>
                                            <span class="action-status watch" v-show="item.watchers" v-tooltip.right="profile.dashboard.watched_by + ' ' + item.watchers"><i class="fas fa-eye"></i> {{profile.dashboard.watched}}</span>
                                            <span class="number" data-testid="incidentCurrentNumber">
                                                <i class="fas fa-share" style="font-size: 14px;" v-if="item.passedIncident" v-tooltip.right="profile.incident_detail.passed_tooltip"></i>
                                                <i class="fas fa-paper-plane" style="font-size: 14px;" v-if="item.passedToIncident" v-tooltip.right="profile.incident_detail.passed_to_tooltip_to + ' ' + item.building_manager"></i>
                                                {{ item.id_incident}}
                                            </span>
                                        </div>
                                        <div class="block-content">
                                            <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                            <span class="post-it urgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fas fa-exclamation-circle"></i></span>
                                            <span class="post-it invoice" v-if="item.invoice_count" style="background: #0867b3;" v-tooltip.right="profile.invoicing.invoice_added"><i class="fas fa-file-alt"></i></span>
                                            <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                            <span class="post-it picked-up" v-if="item.rep_service_by_id_shiro_user" v-tooltip.right="profile.accountPage.account.picked_up_repaircompany"><i class="fas fa-user-circle"></i></span>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                    <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                                </div>
                                            </div>
                                            <div class="labels">
                                                <span v-if="item.team_name" class="current-user"><i class="fas fa-users"></i> {{item.team_name}}</span>
                                                <span v-if="item.assigned_to_name" class="current-user" v-tooltip.right="profile.incident_detail.incident_who + ' ' + item.assigned_to_name"><i class="fas fa-user"></i> {{item.assigned_to_name}}</span>
                                            </div>
                                            <h4>{{item.address}}</h4>
                                            <h5>{{item.asset_name}}</h5>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                            <h6 v-if="item.repair_company"><i class="fas fa-tools"></i> {{item.repair_company}} <span v-if="item.mechanic">| {{item.mechanic}}</span></h6>
                                            <h6 v-if="item.id_building_manager !== item.original_id_building_manager && !item.repair_company"><i class="fas fa-tools"></i> {{item.building_manager}}</h6>
                                            <span class="chat-indicator"><i class="fas fa-comment"></i> {{item.lastChatString}}</span>
                                            <span class="time-indicator" :class="item.overtimeStatus">{{item.overtime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-view" v-show="currentIncidentsView === 'table'" v-if="currentIncidentItems.length">
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('currentincident', 'id_incident')" :class="{active: currentIncidentSortType === 'id_incident'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'address')" :class="{active: currentIncidentSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'asset_name')" :class="{active: currentIncidentSortType === 'asset_name'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'status')" :class="{active: currentIncidentSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'assigned_to_name')" :class="{active: currentIncidentSortType === 'assigned_to_name'}">{{profile.incidents.incidenttable.assigned}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'repair_company')" :class="{active: currentIncidentSortType === 'repair_company'}">{{profile.incidents.incidenttable.repair_company}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'mechanic')" :class="{active: currentIncidentSortType === 'mechanic'}">{{profile.incidents.incidenttable.mechanic}} <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'last_status_time')" :class="{active: currentIncidentSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'last_chat')" :class="{active: currentIncidentSortType === 'last_chat'}">{{profile.incidents.incidenttable.time_last_chat}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in currentIncidentItems" :key="index" @click="toIncident(item)" :class="{watched: item.watchers}">
                                            <td style="position: relative;">
                                                <span class="id_incident">{{item.id_incident}}</span>
                                                <div>
                                                    <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                                    <span class="post-it urgent" v-if="item.urgency_level" style="background: orange;" v-tooltip.right="'Urgent'"><i class="fas fa-exclamation-circle"></i></span>
                                                    <span class="post-it invoice" v-if="item.invoice_count" style="background: #0867b3;" v-tooltip.right="profile.invoicing.invoice_added"><i class="fas fa-file-alt"></i></span>
                                                    <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                                    <span class="post-it picked-up" v-if="item.rep_service_by_id_shiro_user" v-tooltip.right="profile.accountPage.account.picked_up_repaircompany"><i class="fas fa-user-circle"></i></span>
                                                </div>
                                                <span class="additional" v-if="item.passedIncident || item.passedToIncident || item.autodispatch || item.id_ori_incident"><i class="fas fa-info-circle"></i></span>
                                                <div class="additional-wrap">
                                                    <span class="additional-item" v-if="item.passedIncident"><i class="fas fa-share"></i> {{profile.dashboard.forwarded}}</span>
                                                    <span class="additional-item" v-if="item.passedToIncident"> <i class="fas fa-paper-plane"></i> {{profile.dashboard.put_through}} {{item.building_manager}}</span>
                                                    <span class="additional-item" v-if="item.autodispatch"><i class="fas fa-forward"></i> {{profile.dashboard.auto_forward}}</span>
                                                    <span class="additional-item" v-if="item.id_ori_incident"><i class="fas fa-copy" style="margin-right: 5px;"></i> {{profile.dashboard.copy_of}} {{item.id_ori_incident}}</span>
                                                </div>
                                                <div class="labels">
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                                </div>
                                            </td>
                                            <td v-tooltip.right="item.description">{{item.address}}</td>
                                            <td v-tooltip.right="item.description">{{item.asset_name}}</td>
                                            <td class="status" v-if="!profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status]}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td class="status" v-if="profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status].buildingmanager}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td class="status watch" v-show="item.watchers" v-tooltip.right="profile.dashboard.watched_by + ' ' + item.watchers"><i class="fas fa-eye"></i> {{profile.dashboard.watched}}</td>
                                            <td v-tooltip.right="item.description">{{item.assigned_to_name}}<span v-if="item.team_name">,</span> {{item.team_name}}</td>
                                            <td v-tooltip.right="item.description">{{item.repair_company}}</td>
                                            <td v-tooltip.right="item.description">{{item.mechanic}}</td>
                                            <td :class="item.overtimeStatus" v-tooltip.right="item.description">{{item.overtime}}</td>
                                            <td v-tooltip.right="item.description">{{item.lastChatString}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Suspended incidents -->
                <div class="items-wrap">
                    <div class="row hidden-xs" v-if="suspendedItems.length">
                        <div class="col">
                            <div class="action-head">
                                <h3 class="action-indicator" data-testid="suspendedIncidentTab" @click="showSuspended = !showSuspended" style="cursor: pointer;">{{profile.incident_detail.parked_incidents}} ({{suspendedCount}})</h3>
                                <i class="fas fa-chevron-down toggleclosed" @click="showSuspended = !showSuspended" :class="{active: showSuspended}"></i>
                                <div class="view-switch" v-if="showSuspended">
                                    <span class="table-switch" @click="switchView('suspended', 'table')" :class="{active: suspendedView === 'table'}" v-tooltip.right="profile.dashboard.table_view"><i class="fas fa-list-ul"></i></span>
                                    <span class="grid-switch" @click="switchView('suspended', 'grid')" :class="{active: suspendedView === 'grid'}"  v-tooltip.right="profile.dashboard.list_view"><i class="fas fa-th"></i></span>
                                </div>
                                <div class="pagination" v-if="suspendedCount > 8 && showSuspended" :class="{'no-margin': suspendedCount < 8}">
                                    <span class="prev" v-if="suspendedCurrentPage !== 1" @click="prevPage('suspended')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="suspendedCurrentPage !== 1" @click="firstPage('suspended')">1</span>
                                    <span class="page-indicator">{{suspendedCurrentPage}} / {{suspendedTotalPages}}</span>
                                    <span class="page page--last" v-if="suspendedTotalPages > 2" @click="lastPage('suspended')">{{suspendedTotalPages}}</span>
                                    <span class="next" v-if="suspendedCurrentPage !== suspendedTotalPages" @click="nextPage('suspended')"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="suspendedView === 'grid'" :class="{hiddenxs: !showType}" v-if="suspendedItems.length && showSuspended">
                        <div class="col">
                            <span class="sort">{{profile.dashboard.building_manager.sort}}</span>
                            <div class="filters">
                                <span class="filter hidden-xs" @click="sortTable('suspended', 'id_incident')" :class="{active: suspendedSortType === 'id_incident', asc: suspendedSortOrder === 'asc', desc: suspendedSortOrder === 'desc'}">{{profile.dashboard.building_manager.message_table.incident_ID}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('suspended', 'address')" :class="{active: suspendedSortType === 'address', asc: suspendedSortOrder === 'asc', desc: suspendedSortOrder === 'desc'}">{{profile.incidents.incidenttable.address}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('suspended', 'asset_name')" :class="{active: suspendedSortType === 'asset_name', asc: suspendedSortOrder === 'asc', desc: suspendedSortOrder === 'desc'}">{{profile.incidents.incidenttable.asset}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" @click="sortTable('suspended', 'status')" :class="{active: suspendedSortType === 'status', asc: suspendedSortOrder === 'asc', desc: suspendedSortOrder === 'desc'}">{{profile.incidents.incidenttable.status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('suspended', 'assigned_to_name')" :class="{active: suspendedSortType === 'assigned_to_name', asc: suspendedSortOrder === 'asc', desc: suspendedSortOrder === 'desc'}">{{profile.incidents.incidenttable.assigned}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" @click="sortTable('suspended', 'last_status_time')" :class="{active: suspendedSortType === 'last_status_time', asc: suspendedSortOrder === 'asc', desc: suspendedSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_in_status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('suspended', 'last_chat')" :class="{active: suspendedSortType === 'last_chat', asc: suspendedSortOrder === 'asc', desc: suspendedSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_last_chat}} <i class="fa fa-chevron-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-view" v-show="suspendedView === 'grid'" :class="{hiddenxs: !showType}" v-if="suspendedItems.length && showSuspended">
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in suspendedItems" :key="index" v-tooltip.right="item.description" @click="toIncident(item)">
                                <div class="incident-wrap">
                                    <div class="block block--action">
                                        <div class="block-head">
                                            <span class="action-status" v-if="!profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status]}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                            </span>
                                            <span class="action-status" v-if="profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status].buildingmanager}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                            </span>
                                            <span class="number" data-testid="incidentCurrentNumber">
                                                <i class="fas fa-share" style="font-size: 14px;" v-if="item.passedIncident" v-tooltip.right="profile.incident_detail.passed_tooltip"></i>
                                                <i class="fas fa-paper-plane" style="font-size: 14px;" v-if="item.passedToIncident" v-tooltip.right="profile.incident_detail.passed_to_tooltip_to + ' ' + item.building_manager"></i>
                                                {{ item.id_incident}}
                                            </span>
                                        </div>
                                        <div class="block-content">
                                            <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                            <span class="post-it urgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fas fa-exclamation-circle"></i></span>
                                            <span class="post-it invoice" v-if="item.invoice_count" style="background: #0867b3;" v-tooltip.right="profile.invoicing.invoice_added"><i class="fas fa-file-alt"></i></span>
                                            <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                            <span class="post-it picked-up" v-if="item.rep_service_by_id_shiro_user" v-tooltip.right="profile.accountPage.account.picked_up_repaircompany"><i class="fas fa-user-circle"></i></span>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                    <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                                </div>
                                            </div>
                                            <div class="labels">
                                                <span v-if="item.team_name" class="current-user"><i class="fas fa-users"></i> {{item.team_name}}</span>
                                                <span v-if="item.assigned_to_name" class="current-user" v-tooltip.right="profile.incident_detail.incident_who + ' ' + item.assigned_to_name"><i class="fas fa-user"></i> {{item.assigned_to_name}}</span>
                                            </div>
                                            <h4>{{item.address}}</h4>
                                            <h5>{{item.asset_name}}</h5>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                            <h6 v-if="item.repair_company"><i class="fas fa-tools"></i> {{item.repair_company}} <span v-if="item.mechanic">| {{item.mechanic}}</span></h6>
                                            <h6 v-if="item.id_building_manager !== item.original_id_building_manager && !item.repair_company"><i class="fas fa-tools"></i> {{item.building_manager}}</h6>
                                            <span class="chat-indicator"><i class="fas fa-comment"></i> {{item.lastChatString}}</span>
                                            <span class="time-indicator" :class="item.overtimeStatus">{{item.overtime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-view" v-show="suspendedView === 'table'" v-if="suspendedItems.length && showSuspended">
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('suspended', 'id_incident')" :class="{active: suspendedSortType === 'id_incident'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'address')" :class="{active: suspendedSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'asset_name')" :class="{active: suspendedSortType === 'asset_name'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'status')" :class="{active: suspendedSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'assigned_to_name')" :class="{active: suspendedSortType === 'assigned_to_name'}">{{profile.incidents.incidenttable.assigned}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'repair_company')" :class="{active: suspendedSortType === 'repair_company'}">{{profile.incidents.incidenttable.repair_company}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'mechanic')" :class="{active: suspendedSortType === 'mechanic'}">{{profile.incidents.incidenttable.mechanic}} <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'last_status_time')" :class="{active: suspendedSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('suspended', 'last_chat')" :class="{active: suspendedSortType === 'last_chat'}">{{profile.incidents.incidenttable.time_last_chat}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in suspendedItems" :key="index" @click="toIncident(item)">
                                            <td style="position: relative;">
                                                <span class="id_incident">{{item.id_incident}}</span>
                                                <div>
                                                    <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                                    <span class="post-it urgent" v-if="item.urgency_level" style="background: orange;" v-tooltip.right="'Urgent'"><i class="fas fa-exclamation-circle"></i></span>
                                                    <span class="post-it invoice" v-if="item.invoice_count" style="background: #0867b3;" v-tooltip.right="profile.invoicing.invoice_added"><i class="fas fa-file-alt"></i></span>
                                                    <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                                    <span class="post-it picked-up" v-if="item.rep_service_by_id_shiro_user" v-tooltip.right="profile.accountPage.account.picked_up_repaircompany"><i class="fas fa-user-circle"></i></span>
                                                </div>
                                                <span class="additional" v-if="item.passedIncident || item.passedToIncident || item.autodispatch || item.id_ori_incident"><i class="fas fa-info-circle"></i></span>
                                                <div class="additional-wrap">
                                                    <span class="additional-item" v-if="item.passedIncident"><i class="fas fa-share"></i> {{profile.dashboard.forwarded}}</span>
                                                    <span class="additional-item" v-if="item.passedToIncident"> <i class="fas fa-paper-plane"></i> {{profile.dashboard.put_through}} {{item.building_manager}}</span>
                                                    <span class="additional-item" v-if="item.autodispatch"><i class="fas fa-forward"></i> {{profile.dashboard.auto_forward}}</span>
                                                    <span class="additional-item" v-if="item.id_ori_incident"><i class="fas fa-copy" style="margin-right: 5px;"></i> {{profile.dashboard.copy_of}} {{item.id_ori_incident}}</span>
                                                </div>
                                                <div class="labels">
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                                </div>
                                            </td>
                                            <td v-tooltip.right="item.description">{{item.address}}</td>
                                            <td v-tooltip.right="item.description">{{item.asset_name}}</td>
                                            <td class="status" v-if="!profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status]}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td class="status" v-if="profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status].buildingmanager}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td v-tooltip.right="item.description">{{item.assigned_to_name}}<span v-if="item.team_name">,</span> {{item.team_name}}</td>
                                            <td v-tooltip.right="item.description">{{item.repair_company}}</td>
                                            <td v-tooltip.right="item.description">{{item.mechanic}}</td>
                                            <td :class="item.overtimeStatus" v-tooltip.right="item.description">{{item.overtime}}</td>
                                            <td v-tooltip.right="item.description">{{item.lastChatString}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Closed incidents -->
                <div class="row hidden-xs" v-if="closedIncidentItems.length">
                    <div class="col">
                        <div class="action-head">
                            <h3 class="action-indicator" data-testid="totalClosedResult" @click="toggleClosed()" style="cursor: pointer;">{{profile.dashboard.building_manager.closed_incidents}} ({{closedIncidentsCount}})</h3>
                            <i class="fas fa-chevron-down toggleclosed" @click="toggleClosed()" :class="{active: showClosed}"></i>
                            <div class="pagination" style="margin-top: 5px;" v-if="closedIncidentTotalPages >= 1 && showClosed">
                                <span class="prev" v-if="closedIncidentCurrentPage !== 1" @click="prevPage('closedincident')"><i class="fas fa-chevron-left"></i></span>
                                <span class="next" @click="nextPage('closedincident')" v-if="closedIncidentCurrentPage !== closedIncidentTotalPages"><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-view hidden-xs" v-if="showClosed && closedIncidentItems.length" style="margin-top: 20px;">
                    <div class="row">
                        <div class="col">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" @click="sortTable('closedincident', 'id_incident')" :class="{active: closedIncidentSortType === 'id_incident'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'address')" :class="{active: closedIncidentSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'asset_name')" :class="{active: closedIncidentSortType === 'asset'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'status')" :class="{active: closedIncidentSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" style="cursor: auto;">{{profile.incidents.incidenttable.repair_company}}</th>
                                        <th scope="col" style="cursor: auto;">{{profile.incidents.incidenttable.datetime}}</th>
                                        <th scope="col" @click="sortTable('closedincident', 'last_status_time')" :class="{active: closedIncidentSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in closedIncidentItems" :key="index" @click="toIncident(item)" v-tooltip.right="item.description">
                                        <td>{{item.id_incident}}
                                            <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                            <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                        </td>
                                        <td>{{item.address}}</td>
                                        <td>{{item.asset_name}}</td>
                                        <td v-if="!profile['statusses'][item.status].buildingmanager">
                                            {{profile['statusses'][item.status]}}
                                            <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                        </td>
                                        <td v-if="profile['statusses'][item.status].buildingmanager">
                                            {{profile['statusses'][item.status].buildingmanager}}
                                            <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                        </td>
                                        <td>{{item.repair_company}}</td>
                                        <td>{{item.date}} {{item.time}}</td>
                                        <td :class="item.overtimeStatus">{{item.since}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-content" v-if="twinq && !admin">
            <div class="container">
                <span class="button" @click="newIncidentModalOpen = true">{{profile.dashboard.building_manager.create_incident}}</span>
                <span class="button" @click="excelModalOpen = true" style="margin-left: 10px;"><i class="fas fa-file-excel" style="margin-right: 5px;"></i>{{profile.dashboard.building_manager.request_stats}}</span>
            </div>
        </div>
        <NewIncidentModal v-if="newIncidentModalOpen && bmId" @close="newIncidentModalOpen = false" @getIncidents="getIncidents()" :twinq="twinq" :admin="admin" :bm="bmId" :vve="vve" />
        <AgreementFlowItemsModal v-if="agreementFlowItemsModalOpen" :agreementFlowItems="agreementFlowItems" @close="agreementFlowItemsModalOpen = false" />
        <ExcelExportModal v-if="excelModalOpen" @close="excelModalOpen = false" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import { EventBus } from '@/js/event-bus.js';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import debounce from 'lodash/debounce';
import NewIncidentModal from "@/components/modals/NewIncidentModal.vue";
import AgreementFlowItemsModal from "@/components/modals/AgreementFlowItemsModal.vue";
import ExcelExportModal from "@/components/modals/ExcelExportModal.vue";

export default {
    mixins: [setLocale],
    components: {
        NewIncidentModal,
        AgreementFlowItemsModal,
        ExcelExportModal
    },
    data() {
        return {
            profile: {},
            users: [],
            associatedUsers: [],
            userName: '',
            bmCompanyName: '',
            searchType: 'regular',
            actionRequiredView: 'grid',
            currentIncidentsView: 'grid',
            suspendedView: 'grid',
            actionRequiredItems: [],
            actionRequiredSortType: '',
            actionRequiredCurrentPage: 1,
            actionRequiredTotalPages: 1,
            currentIncidentItems: [],
            currentIncidentSortType: '',
            currentIncidentCurrentPage: 1,
            suspendedItems: [],
            suspendedCurrentPage: 1,
            suspendedTotalPages: 1,
            suspendedCount: 0,
            showSuspended: false,
            suspendedSortOrder: 'asc',
            suspendedSortType: 'id_incident',
            actionRequiredCount: 0,
            currentIncidentsCount: 0,
            closedIncidentsCount: 0,
            closedIncidentItems: [],
            closedIncidentSortType: 'last_status_time',
            closedIncidentCurrentPage: 1,
            currentIncidentTotalPages: 1,
            suggestOpen: true,
            searchTerm: '',
            totalSuggestions: 0,
            searchSuggestions: [],
            searchActive: false,
            newIncidentModalOpen: false,
            actionRequiredSortOrder: '',
            currentIncidentsSortOrder: '',
            closedIncidentsSortOrder: 'desc',
            profilePic: '',
            settings: {},
            searchTerms: [],
            showType: false,
            showClosed: false,
            assignedToDropdownToggled: false,
            assignedToSearchTerm: '',
            tempAssignedToList: [],
            noAssignedToResults: false,
            assignedToList: [],
            assignedToTeamDropdownToggled: false,
            teamsList: [],
            actionRequiredFiltered: [],
            currentIncidentFiltered: [],
            filtersActive: false,
            repaircompanyDropdownToggled: false,
            repaircompanySearchTerm: '',
            tempRepaircompanyList: [],
            noRepaircompanyResults: false,
            repaircompanyList: [],
            showFilters: false,
            showUrgentItems: false,
            showUnreadChatsItems: false,
            showFollowupItems: false,
            agreementFlowItems: [],
            agreementFlowItemsModalOpen: false,
            agreementLabelList: [],
            agreementLabelDropdownToggled: false,
            itemsInterval: null,
            assetTypeCount: 0,
            excelModalOpen: false,
            industry: ''
        }
    },
    computed: mapState(['incident', 'admin', 'vve', 'twinq', 'bmId']),
    watch: {
        incident() {
            setTimeout(function () {
                for (var i = 0; i < this.actionRequiredItems.length; i++) {
                    let item = this.actionRequiredItems[i];
                    if (item.id_incident.toString() === this.incident.id_incident) {
                        if (item.watchers && !this.incident.from) {
                            item.watchers = item.watchers + ', ' + this.incident.user;
                        }
                        if (!item.watchers && !this.incident.from) {
                            item.watchers = this.incident.user;
                        }
                        if (item.watchers === this.incident.user && this.incident.from) {
                            item.watchers = '';
                        }
                        if (item.watchers !== this.incident.user && this.incident.from) {
                            let allWatchers = item.watchers.split(', ');
                            for (var j = 0; j < allWatchers.length; j++) {
                                let watcher = allWatchers[j];
                                if (watcher === this.incident.user) {
                                    allWatchers.splice(j, 1);
                                }
                            }
                            item.watchers = allWatchers.join();
                        }
                    }
                }
                for (var i = 0; i < this.currentIncidentItems.length; i++) {
                    let item = this.currentIncidentItems[i];
                    if (item.id_incident.toString() === this.incident.id_incident) {
                        if (item.watchers && !this.incident.from) {
                            item.watchers = item.watchers + ', ' + this.incident.user;
                        }
                        if (!item.watchers && !this.incident.from) {
                            item.watchers = this.incident.user;
                        }
                        if (item.watchers === this.incident.user && this.incident.from) {
                            item.watchers = '';
                        }
                        if (item.watchers !== this.incident.user && this.incident.from) {
                            let allWatchers = item.watchers.split(', ');
                            for (var j = 0; j < allWatchers.length; j++) {
                                let watcher = allWatchers[j];
                                if (watcher === this.incident.user) {
                                    allWatchers.splice(i, 1);
                                }
                            }
                            item.watchers = allWatchers.join();
                        }
                    }
                }
            }.bind(this), 500);
        }
    },
    created() {
        this.applySettings();
        document.querySelector('body').classList.remove('modal-open');
        document.querySelector('body').classList.remove('media-open');
        document.title = 'S1MONE | Dashboard';
        let self = this;
        fetch('/api/v1/buildingmanagerstats')
        .then(response => { response.json().then(
            function(data) {
                self.profilePic = data.building_manager.profile_image_loc;
                self.bmCompanyName = data.building_manager.name;
                self.getCurrentUser();
                self.getUsers();
                self.getAssociatedUsers();
            });
        });
        EventBus.$on('langChange', busObj => {
            for (var i = 0; i < this.actionRequiredItems.length; i++) {
                let item = this.actionRequiredItems[i];
                if (busObj.locale === 'nl') {
                    item.date = moment(item.created).locale(busObj.locale).format('D MMMM YYYY');
                }
                if (busObj.locale === 'en') {
                    item.date = moment(item.created).locale(busObj.locale).format('MMMM D YYYY');
                }
            }
            for (var j = 0; j < this.currentIncidentItems.length; j++) {
                let item = this.currentIncidentItems[j];
                if (busObj.locale === 'nl') {
                    item.date = moment(item.created).locale(busObj.locale).format('D MMMM YYYY');
                }
                if (busObj.locale === 'en') {
                    item.date = moment(item.created).locale(busObj.locale).format('MMMM D YYYY');
                }
            }
        });
        this.itemsInterval = setInterval(function () {
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getAgreementFlowItems();
            this.getCurrentIncidentItems();
            this.getSuspendedItems();
            this.getClosedIncidentItems();
        }.bind(this), 300000);
    },
    beforeDestroy() {
        clearInterval(this.itemsInterval);
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        applySettings() {
            // Apply LocalStorage settings to vue instance
            if (localStorage.getItem('S1-settings') !== null) {
                let settings = localStorage.getItem('S1-settings');
                settings = JSON.parse(settings);
                this.settings = settings;
                this.actionRequiredView = this.settings.actionRequiredView;
                this.currentIncidentsView = this.settings.currentIncidentsView;
                if (this.settings.actionRequiredSortOrder) {
                    this.actionRequiredSortOrder = this.settings.actionRequiredSortOrder;
                }
                else {
                    this.actionRequiredSortOrder ='asc';
                    this.settings.actionRequiredSortOrder ='asc';
                }
                if (this.settings.currentIncidentsSortOrder) {
                    this.currentIncidentsSortOrder = this.settings.currentIncidentsSortOrder;
                }
                else {
                    this.currentIncidentsSortOrder = 'asc';
                    this.settings.currentIncidentsSortOrder ='asc';
                }
                this.actionRequiredSortType = this.settings.actionRequiredSort;
                this.currentIncidentSortType = this.settings.currentIncidentsSort;
                this.actionRequiredCurrentPage = this.settings.actionRequiredCurrentPage;
                this.currentIncidentCurrentPage = this.settings.currentIncidentCurrentPage;
                this.showClosed = this.settings.showClosed;
                if (!this.settings.filters) {
                    this.settings.filters = {};
                }
                if (!this.settings.checkboxes) {
                    this.settings.checkboxes = {};
                }
            }
            else {
                this.settings.actionRequiredView = 'grid';
                this.settings.currentIncidentsView = 'grid';
                this.settings.actionRequiredSort = 'id_incident';
                this.settings.currentIncidentsSort = 'id_incident';
                this.settings.actionRequiredSortOrder = 'asc';
                this.settings.currentIncidentsSortOrder = 'asc';
                this.settings.currentIncidentCurrentPage = 1;
                this.settings.actionRequiredCurrentPage = 1;
                this.actionRequiredSortType = this.settings.actionRequiredSort;
                this.currentIncidentSortType = this.settings.currentIncidentsSort;
                this.actionRequiredCurrentPage = this.settings.actionRequiredCurrentPage;
                this.currentIncidentCurrentPage = this.settings.currentIncidentCurrentPage;
                this.settings.filters = {};
                this.settings.checkboxes = {};
                this.showClosed = true;
                localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            }
        },
        getIncidents() {
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getAgreementFlowItems();
            this.getCurrentIncidentItems();
            this.getSuspendedItems();
            this.getClosedIncidentItems();
            this.newIncidentModalOpen = false;
        },
        getActionRequiredItems() {
            let orderParam = '?sort_dir=' + this.settings.actionRequiredSortOrder;
            let sortParam = '';
            if (this.settings.actionRequiredSort) {
                sortParam = '&sort_col=' + this.settings.actionRequiredSort;
            }
            let filterParam = '';
            if (this.settings.filters.assignedToPerson) {
                filterParam = '&assigned_to_id_shiro_user=' + this.settings.filters.assignedToPerson.assigned_to_id;
            }
            if (this.settings.filters.assignedToTeam) {
                filterParam = '&id_team=' + this.settings.filters.assignedToTeam.id_team;
            }
            if (this.settings.filters.repaircompany) {
                filterParam = '&id_repair_company=' + this.settings.filters.repaircompany.id_repair_company;
            }
            if (this.settings.filters.agreementflow) {
                filterParam = '&agreement_flow=' + this.settings.filters.agreementflow;
            }
            let checkboxParam = '';
            if (this.settings.checkboxes.urgent) {
                checkboxParam = '&urgency_level=true'
            }
            if (this.settings.checkboxes.unread) {
                checkboxParam = '&chat_count=true'
            }
            if (this.settings.checkboxes.followup) {
                checkboxParam = '&wants_copy=true'
            }
            let offSetParam = this.settings.actionRequiredCurrentPage - 1;
            offSetParam = offSetParam * 8;
            let self = this;
            fetch('/api/v1/incidentcombined/actionrequiredbuildingmanager' + orderParam + sortParam + filterParam + checkboxParam + '&limit=8&offset=' + offSetParam)
            .then(response => { response.json().then(
                function(data) {
                    self.actionRequiredItems = [];
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.created).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.created).locale('nl').format('LT');
                        item.type = 'actionrequired';
                        if (item.original_id_building_manager !== self.bmId && item.original_id_building_manager !== null) {
                            item.passedIncident = true;
                        }
                        if (self.bmId !== item.id_building_manager) {
                            item.passedToIncident = true;
                        }
                        let created = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                        if (item.last_status_time) {
                            created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        }
                        if ((item.original_id_building_manager && item.id_building_manager !== item.original_id_building_manager) && item.status === 'pending_buildingmanager' && !item.assigned_to_id) {
                            item.status = 'new_';
                        }
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.overtime = differenceString;
                        item.asset_name = item.asset_name.charAt(0).toUpperCase() + item.asset_name.slice(1);
                        let chat_time = moment(item.last_chat).format('DD/MM/YYYY HH:mm:ss');
                        let chatDifference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(chat_time,"DD/MM/YYYY HH:mm:ss"));
                        let lastChatObj = self.convertToDays(chatDifference);
                        item.lastChatString = lastChatObj.days + 'd ' + lastChatObj.hours + 'u ' + lastChatObj.minutes + 'm';
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                    }
                    self.actionRequiredCount = data.recordsTotal;
                    self.actionRequiredItems = items;
                    let totalPagesRaw = (self.actionRequiredCount + 1) / 8;
                    self.actionRequiredTotalPages = Math.ceil(totalPagesRaw);
                    if (self.actionRequiredCurrentPage > self.actionRequiredTotalPages) {
                        self.actionRequiredCurrentPage = self.actionRequiredTotalPages;
                        self.settings.actionRequiredCurrentPage = self.actionRequiredCurrentPage;
                    }
                    if (self.actionRequiredCount < 9 && self.settings.actionRequiredCurrentPage > 1) {
                        self.actionRequiredCurrentPage = 1;
                        self.actionRequiredTotalPages = 1;
                        self.settings.actionRequiredCurrentPage = 1;
                        self.getActionRequiredItems();
                    }
                });
            });
        },
        getAgreementFlowItems() {
            let self = this;
            fetch('/api/v1/incidentcombined/inagreementflowbuildingmanager?sort_dir=asc&limit=100')
            .then(response => { response.json().then(
                function(data) {
                    self.agreementFlowItems = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.createdDate = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                        if (item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED') {
                            self.agreementFlowItems.push(item);
                        }
                    }
                });
            });
        },
        getCurrentIncidentItems(type) {
            if (this.settings.assignedToTeamFilter && this.settings.assignedToTeamFilter.id_team) {
                type = 'team';
            }
            let orderParam = '?sort_dir=' + this.settings.currentIncidentsSortOrder;
            let sortParam = '';
            if (this.settings.currentIncidentsSort) {
                sortParam = '&sort_col=' + this.settings.currentIncidentsSort;
            }
            let filterParam = '';
            if (this.settings.filters.assignedToPerson) {
                filterParam = '&assigned_to_id_shiro_user=' + this.settings.filters.assignedToPerson.assigned_to_id;
            }
            if (this.settings.filters.assignedToTeam) {
                filterParam = '&id_team=' + this.settings.filters.assignedToTeam.id_team;
            }
            if (this.settings.filters.repaircompany) {
                filterParam = '&id_repair_company=' + this.settings.filters.repaircompany.id_repair_company;
            }
            if (this.settings.filters.agreementflow) {
                filterParam = '&agreement_flow=' + this.settings.filters.agreementflow;
            }
            let checkboxParam = '';
            if (this.settings.checkboxes.urgent) {
                checkboxParam = '&urgency_level=true'
            }
            if (this.settings.checkboxes.unread) {
                checkboxParam = '&chat_count=true'
            }
            if (this.settings.checkboxes.followup) {
                checkboxParam = '&wants_copy=true'
            }

            let offSetParam = this.settings.currentIncidentCurrentPage - 1;
            offSetParam = offSetParam * 8;
            let self = this;
            fetch('/api/v1/incidentcombined/currentincidentsbuildingmanager' + orderParam + sortParam + filterParam + checkboxParam + '&limit=8&offset=' + offSetParam)
            .then(response => { response.json().then(
                function(data) {
                    let items = [];
                    self.currentIncidentItems = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.created).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.created).locale('nl').format('LT');
                        item.type = 'currentincidents';
                        if (item.original_id_building_manager !== self.bmId && item.original_id_building_manager !== null) {
                            item.passedIncident = true;
                        }
                        if (self.bmId !== item.id_building_manager) {
                            item.passedToIncident = true;
                        }
                        let created = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                        if (item.last_status_time) {
                            created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        }
                        if (item.id_building_manager !== item.original_id_building_manager && item.status === 'pending_buildingmanager') {
                            item.status = 'pending_repaircompany';
                        }
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.overtime = differenceString;
                        let chat_time = moment(item.last_chat).format('DD/MM/YYYY HH:mm:ss');
                        let chatDifference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(chat_time,"DD/MM/YYYY HH:mm:ss"));
                        let lastChatObj = self.convertToDays(chatDifference);
                        item.lastChatString = lastChatObj.days + 'd ' + lastChatObj.hours + 'u ' + lastChatObj.minutes + 'm';
                        item.asset_name = item.asset_name.charAt(0).toUpperCase() + item.asset_name.slice(1);
                        item.appointment = moment(item.start_time).format('DD/MM/YYYY HH:mm');
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                    }
                    self.currentIncidentsCount = data.recordsTotal;
                    self.currentIncidentItems = items;
                    let totalPagesRaw = (self.currentIncidentsCount + 1) / 8;
                    self.currentIncidentTotalPages = Math.ceil(totalPagesRaw);
                    if (self.currentIncidentCurrentPage > self.currentIncidentTotalPages) {
                        self.currentIncidentCurrentPage = self.currentIncidentTotalPages;
                        self.settings.currentIncidentCurrentPage = self.currentIncidentCurrentPage;
                    }
                    if (self.currentIncidentsCount < 9 && self.settings.currentIncidentCurrentPage > 1) {
                        self.currentIncidentCurrentPage = 1;
                        self.currentIncidentTotalPages = 1;
                        self.settings.currentIncidentCurrentPage = 1;
                        self.getCurrentIncidentItems();
                    }
                });
            });
        },
        getSuspendedItems(type) {
            let orderParam = '';
            let sortParam = '';
            if (this.suspendedSortOrder) {
                orderParam = '?sort_dir=' + this.suspendedSortOrder;
            }
            else {
                orderParam = '?sort_dir=desc';
            }
            if (this.suspendedSortType) {
                sortParam = '&sort_col=' + this.suspendedSortType;
            }
            let offSetParam = this.suspendedCurrentPage - 1;
            offSetParam = offSetParam * 8;
            let self = this;
            fetch('/api/v1/incidentcombined/suspendedbuildingmanager' + orderParam + sortParam + '&limit=8&offset=' + offSetParam)
            .then(response => { response.json().then(
                function(data) {
                    self.suspendedItems = [];
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.created).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.created).locale('nl').format('LT');
                        item.type = 'suspended';
                        if (item.original_id_building_manager !== self.bmId && item.original_id_building_manager !== null) {
                            item.passedIncident = true;
                        }
                        if (self.bmId !== item.id_building_manager) {
                            item.passedToIncident = true;
                        }
                        let created = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                        if (item.last_status_time) {
                            created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        }
                        if (item.id_building_manager !== item.original_id_building_manager && item.status === 'pending_buildingmanager' && !item.rep_service_by_id_shiro_user) {
                            item.status = 'new_';
                        }
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.overtime = differenceString;
                        item.asset_name = item.asset_name.charAt(0).toUpperCase() + item.asset_name.slice(1);
                        let chat_time = moment(item.last_chat).format('DD/MM/YYYY HH:mm:ss');
                        let chatDifference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(chat_time,"DD/MM/YYYY HH:mm:ss"));
                        let lastChatObj = self.convertToDays(chatDifference);
                        item.lastChatString = lastChatObj.days + 'd ' + lastChatObj.hours + 'u ' + lastChatObj.minutes + 'm';
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                    }
                    self.suspendedCount = data.recordsTotal;
                    self.suspendedItems = items;
                    let totalPagesRaw = (self.suspendedCount + 1) / 8;
                    self.suspendedTotalPages = Math.ceil(totalPagesRaw);
                    if (self.suspendedCurrentPage > self.suspendedTotalPages) {
                        self.suspendedCurrentPage = self.suspendedTotalPages;
                    }
                });
            });
        },
        getClosedIncidentItems() {
            let orderParam = '&sort_dir=' + this.closedIncidentsSortOrder;
            let self = this;
            let from;
            if (this.closedIncidentCurrentPage === 1) {
                from = 0;
            }
            else {
                from = (this.closedIncidentCurrentPage - 1) * 25;
            }
            let fromParam = '&offset=' + from;
            let sortParam = '';
            if (this.closedIncidentSortType) {
                sortParam = '&sort_col=' + this.closedIncidentSortType;
            }
            fetch('/api/v1/incidentcombined/closedincidentsbuildingmanager?limit=25'+ orderParam + fromParam  + sortParam)
            .then(response => { response.json().then(
                function(data) {
                    self.closedIncidentItems = [];
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.created).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.created).locale('nl').format('LT');
                        item.type = 'currentincidents';
                        let created = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                        if (item.last_status_time) {
                            created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        }
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.since = differenceString;
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                    }
                    self.closedIncidentItems = items;
                    self.closedIncidentsCount = data.recordsTotal;
                    self.closedIncidentTotalPages = data.recordsTotal / 25;
                    self.closedIncidentTotalPages = Math.ceil(self.closedIncidentTotalPages);
                });
            });
        },
        getCurrentUser() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
            .then(response => { response.json().then(
                function(data) {
                    self.userName = data.name;
                    if (self.settings.userName !== data.username) {
                        self.searchTerms = [];
                        self.settings.userName = data.username;
                        self.settings.actionRequiredView = 'grid';
                        self.settings.currentIncidentsView = 'grid';
                        self.settings.actionRequiredSort = 'last_status_time';
                        self.settings.currentIncidentsSort = 'last_status_time';
                        self.settings.actionRequiredSortOrder = 'desc';
                        self.settings.currentIncidentsSortOrder = 'desc';
                        self.settings.currentIncidentCurrentPage = 1;
                        self.settings.actionRequiredCurrentPage = 1;
                    }
                    else {
                        if (localStorage.getItem('S1-searchterms')) {
                            let searchTerms = localStorage.getItem('S1-searchterms');
                            searchTerms = JSON.parse(searchTerms);
                            self.searchTerms = searchTerms;
                            for (var i = 0; i < self.searchTerms.length; i++) {
                                let searchTerm = self.searchTerms[i];
                                if (searchTerm === "") {
                                    self.searchTerms.splice(i, 1);
                                }
                            }
                        }
                    }
                    localStorage.setItem('S1-settings', JSON.stringify(self.settings));
                    localStorage.setItem('S1-searchterms', JSON.stringify(self.searchTerms));
                    self.getAvailableFilters();
                    self.getActionRequiredItems();
                    self.getAgreementFlowItems();
                    self.getCurrentIncidentItems();
                    self.getSuspendedItems();
                    self.getClosedIncidentItems();
                });
            });
        },
        getAvailableFilters() {
            let self = this;
            fetch('/api/v1/incidentcombined/availablefilters')
	        .then(response => { response.json().then(
	            function(data) {
                    self.teamsList = data.available_teams;
                    self.assignedToList = data.available_users;
                    self.repaircompanyList = data.available_repair_companies;
                    self.agreementLabelList = data.agreement_flow_statusses;
                    self.repaircompanyList = sortBy(self.repaircompanyList, 'name');
                    for (var i = 0; i < self.agreementLabelList.length; i++) {
                        let item = self.agreementLabelList[i];
                        if (item !== 'NO_CHOICE' && item !== 'WAITING_ON_SERVICE_DESK' && item !== 'AGREED') {
                            self.agreementLabelList.splice(i, 1);
                        }
                    }
				});
	        });
        },
        getUsers() {
            let self = this;
            fetch('/api/v1/incidentcombined/usersforthiscompany')
	        .then(response => { response.json().then(
	            function(data) {
					self.users = data;
				});
	        });
        },
        getAssociatedUsers() {
            let self = this;
            fetch('/api/v1/incidentcombined/usersforassociatedcompanies')
	        .then(response => { response.json().then(
	            function(data) {
					self.associatedUsers = data;
				});
	        });
        },
        searchSuggest: debounce(function (e) {
            this.searchSuggestions = [];
            if (typeof e === 'string') {
                this.searchTerm = e;
            }
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                let self = this;
                fetch('/api/v1/incidentcombined/search?search_term=' + searchTerm)
    	        .then(response => { response.json().then(
    	            function(data) {
                        self.totalSuggestions = data.data.length;
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (self.locale === 'nl') {
                                item.date = moment(item.last_status_time).locale('nl').format('D MMMM YYYY');
                            }
                            if (self.locale === 'en') {
                                item.date = moment(item.last_status_time).locale('en').format('MMMM D YYYY');
                            }
                            item.createdDate = moment(item.created).locale('nl').format('D MMMM YYYY, HH:mm');
                            item.time = moment(item.last_status_time).locale('nl').format('LT');
                            self.searchSuggestions.push(item);
                        }
    				});
    	        });
            }
            else {
                this.searchActive = false;
                this.searchSuggestions = [];
                this.totalSuggestions = 0;
            }
            if (e !== undefined) {
                if(e.keyCode === 27) {
                    this.searchActive = false;
                    this.searchTerm = '';
                    this.searchSuggestions = [];
                    this.totalSuggestions = 0;
                }
            }
        }, 500),
        removeTerm(item) {
            this.searchTerm = '';
            let index = this.searchTerms.indexOf(item);
            this.searchTerms.splice(index, 1);
            localStorage.setItem('S1-searchterms', JSON.stringify(this.searchTerms));
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
        },
        switchView(type, view) {
            if (type === 'actionrequired') {
                if (view === 'grid') {
                    this.actionRequiredView = 'grid';
                    this.settings.actionRequiredView = 'grid';
                }
                if (view === 'table') {
                    this.actionRequiredView = 'table';
                    this.settings.actionRequiredView = 'table';
                }
            }
            if (type === 'currentincidents') {
                if (view === 'grid') {
                    this.currentIncidentsView = 'grid';
                    this.settings.currentIncidentsView = 'grid';

                }
                if (view === 'table') {
                    this.currentIncidentsView = 'table';
                    this.settings.currentIncidentsView = 'table';
                }
            }
            if (type === 'suspended') {
                if (view === 'grid') {
                    this.suspendedView = 'grid';
                }
                if (view === 'table') {
                    this.suspendedView = 'table';
                }
            }
            this.suspendedCurrentPage = 1;
        },
        sortTable(type, sorter) {
            if (type === 'actionrequired') {
                if (this.settings.actionRequiredSort === sorter) {
                    this.actionRequiredSortOrder = this.actionRequiredSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.actionRequiredSortOrder = 'desc';
                }
                this.settings.actionRequiredSortOrder = this.actionRequiredSortOrder;
                this.settings.actionRequiredSort = sorter;
                this.actionRequiredSortType = sorter;
                this.getAvailableFilters();
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                if (this.settings.currentIncidentsSort === sorter) {
                    this.currentIncidentsSortOrder = this.currentIncidentsSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.currentIncidentsSortOrder = 'desc';
                }
                this.settings.currentIncidentsSortOrder = this.currentIncidentsSortOrder;
                this.settings.currentIncidentsSort = sorter;
                this.currentIncidentSortType = sorter;
                this.getAvailableFilters();
                this.getCurrentIncidentItems();
            }
            if (type === 'suspended') {
                if (this.suspendedSortType === sorter) {
                    this.suspendedSortOrder = this.suspendedSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.suspendedSortOrder = 'asc';
                }
                this.suspendedSortType = sorter;
                this.getAvailableFilters();
                this.getSuspendedItems();
            }
            if (type === 'closedincident') {
                if (this.closedIncidentSortType === sorter) {
                    this.closedIncidentsSortOrder = this.closedIncidentsSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.closedIncidentsSortOrder = 'asc';
                }
                this.closedIncidentSortType = sorter;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        nextPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage++;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getAvailableFilters();
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage++;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getAvailableFilters();
                this.getCurrentIncidentItems();
            }
            if (type === 'suspended') {
                this.suspendedCurrentPage++;
                this.getAvailableFilters();
                this.getSuspendedItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage++;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        prevPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage--;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getAvailableFilters();
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage--;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getAvailableFilters();
                this.getCurrentIncidentItems();
            }
            if (type === 'suspended') {
                this.suspendedCurrentPage--;
                this.getAvailableFilters();
                this.getSuspendedItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage--;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        firstPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage = 1;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getAvailableFilters();
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage = 1;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getAvailableFilters();
                this.getCurrentIncidentItems();
            }
            if (type === 'suspended') {
                this.suspendedCurrentPage = 1;
                this.getAvailableFilters();
                this.getSuspendedItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage = 1;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        lastPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage = this.actionRequiredTotalPages;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getAvailableFilters();
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage = this.currentIncidentTotalPages;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getAvailableFilters();
                this.getCurrentIncidentItems();
            }
            if (type === 'suspended') {
                this.suspendedCurrentPage = this.suspendedTotalPages;
                this.getAvailableFilters();
                this.getSuspendedItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        toIncident(incident) {
            if (this.searchTerms.length === 5) {
                this.searchTerms.splice(0, 1);
            }
            let searchTerm = this.searchTerm.toLowerCase();
            if (!this.searchTerms.includes(searchTerm)) {
                this.searchTerms.push(searchTerm);
                localStorage.setItem('S1-searchterms', JSON.stringify(this.searchTerms));
            }
            if (incident.status === 'closed' || incident.status === 'cancelled') {
                this.settings.showClosed = true;
            }
            else {
                this.settings.showClosed = false;
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.$router.push('/buildingmanager/incident-detail?id=' + incident.id_incident);
        },
        convertToDays(milliSeconds){
            let days = Math.floor(milliSeconds/(86400 * 1000));
            milliSeconds -= days*(86400*1000);
            let hours = Math.floor(milliSeconds/(60 * 60 * 1000 ));
            milliSeconds -= hours * (60 * 60 * 1000);
            let minutes = Math.floor(milliSeconds/(60 * 1000));
            return {
                days, hours, minutes
            }
        },
        filterToggle() {
            if (!this.filtersActive) {
                this.showFilters = !this.showFilters
            }
        },
        selectTeam(team) {
            this.filtersActive = true;
            this.assignedToTeamDropdownToggled = false;
            this.showUrgentItems = false;
            this.showUnreadChatsItems = false
            this.showFollowupItems = false;
            this.settings.filters = {};
            let teamObject = {
                'id_team': team.id_team,
                'team_name': team.name
            }
            this.settings.filters.assignedToTeam = teamObject;
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getSuspendedItems();
            this.getCurrentIncidentItems();
        },
        removeAssignedToTeam() {
            this.filtersActive = false;
            this.assignedToTeamDropdownToggled = false;
            this.settings.filters = {};
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getSuspendedItems();
            this.getCurrentIncidentItems();
        },
        searchAssignedToList() {
            this.tempAssignedToList = [];
            if (this.assignedToSearchTerm.length > 1) {
                let searchTerm = this.assignedToSearchTerm.toLowerCase();
                for (var i = 0; i < this.assignedToList.length; i++) {
                    let person = this.assignedToList[i];
                    console.log(person.actual_name);
                    let personName = person.actual_name.toLowerCase();
                    if (personName.includes(searchTerm)) {
                        this.tempAssignedToList.push(person);
                    }
                    if (!this.tempAssignedToList.length) {
                        this.noAssignedToResults = true;
                    }
                    else {
                        this.noAssignedToResults = false;
                    }
                }
            }
            else {
                this.noAssignedToResults = false;
            }
        },
        selectAssignedTo(person) {
            this.filtersActive = true;
            this.assignedToDropdownToggled = false;
            this.showUrgentItems = false;
            this.showUnreadChatsItems = false
            this.showFollowupItems = false;
            this.settings.filters = {};
            let personObject = {
                'assigned_to_id': person.id_shiro_user,
                'assigned_to_name': person.actual_name
            }
            this.settings.filters.assignedToPerson = personObject;
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getSuspendedItems();
            this.getCurrentIncidentItems();
        },
        removeAssignedToName() {
            this.filtersActive = false;
            this.assignedToDropdownToggled = false;
            this.settings.filters = {};
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getSuspendedItems();
            this.getCurrentIncidentItems();
        },
        searchRepaircompanyList() {
            this.tempRepaircompanyList = [];
            if (this.repaircompanySearchTerm.length > 1) {
                let searchTerm = this.repaircompanySearchTerm.toLowerCase();
                for (var i = 0; i < this.repaircompanyList.length; i++) {
                    let repaircompany = this.repaircompanyList[i];
                    let repaircompanyName = repaircompany.name.toLowerCase();
                    if (repaircompanyName.includes(searchTerm)) {
                        this.tempRepaircompanyList.push(repaircompany);
                    }
                    if (!this.tempRepaircompanyList.length) {
                        this.noRepaircompanyResults = true;
                    }
                    else {
                        this.noRepaircompanyResults = false;
                    }
                }
            }
            else {
                this.noRepaircompanyResults = false;
            }
        },
        selectRepaircompany(repaircompany) {
            this.filtersActive = true;
            this.repaircompanyDropdownToggled = false;
            this.showUrgentItems = false;
            this.showUnreadChatsItems = false
            this.showFollowupItems = false;
            this.settings.filters = {};
            let repaircompanyObject = {
                'id_repair_company': repaircompany.id_repair_company,
                'repair_company_name': repaircompany.name
            }
            this.settings.filters.repaircompany = repaircompanyObject;
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getSuspendedItems();
            this.getCurrentIncidentItems();
        },
        removeRepaircompany() {
            this.filtersActive = false;
            this.repaircompanyDropdownToggled = false;
            this.settings.filters = {};
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getActionRequiredItems();
            this.getCurrentIncidentItems();
        },
        selectAgreementLabel(agreement) {
            this.filtersActive = true;
            this.showUrgentItems = false;
            this.showUnreadChatsItems = false
            this.showFollowupItems = false;
            this.agreementLabelDropdownToggled = false;
            this.settings.filters = {};
            this.settings.filters.agreementflow = agreement;
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getActionRequiredItems();
            this.getSuspendedItems();
            this.getCurrentIncidentItems();
        },
        removeAgreementLabel() {
            this.filtersActive = false;
            this.agreementLabelDropdownToggled = false;
            this.settings.filters = {};
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getActionRequiredItems();
            this.getSuspendedItems();
            this.getCurrentIncidentItems();
        },
        selectFilter(type) {
            this.filtersActive = true;
            this.settings.actionRequiredCurrentPage = 1;
            this.settings.currentIncidentCurrentPage = 1;
            if (type === 'urgent') {
                this.settings.checkboxes.unread = false;
                this.settings.checkboxes.followup = false;
                this.settings.checkboxes.urgent = !this.settings.checkboxes.urgent;
            }
            if (type === 'unread') {
                this.settings.checkboxes.urgent = false;
                this.settings.checkboxes.followup = false;
                this.settings.checkboxes.unread = !this.settings.checkboxes.unread;
            }
            if (type === 'followup') {
                this.settings.checkboxes.urgent = false;
                this.settings.checkboxes.unread = false;
                this.settings.checkboxes.followup = !this.settings.checkboxes.followup;
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getCurrentIncidentItems();
        },
        toggleClosed() {
            this.showClosed = !this.showClosed;
            this.settings.showClosed = this.showClosed;
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        }
    }
}
</script>
