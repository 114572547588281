<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa fa-user-alt-slash"></i> Beheerder inactiveren </h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p> Weet u zeker dat u deze beheerder wilt inactiveren?</p>
                <button class="button button--icon button--right" v-on:click="removePartner()"><i class="fa fa-user-alt-slash"></i>Beheerder inactiveren</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
export default {
    mixins: [setLocale],
    name: 'removePartnerModal',
    props: ['managerID'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        removePartner() {
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append('data[' + this.managerID + '][id_building_manager]', this.managerID);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                let successText = self.profile.dashboard.building_manager.message_table.place + ' <b>' + self.locationName + '</b> ' + self.profile.toast.deleted_successfully;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
                self.$router.push('/simone/buildingmanagers');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
