<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fab fa-whatsapp"></i>{{profile.accountPage.account.new_standard_message}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createMessage()">
                    <div class="form-field" :class="{focus:fields.title.length}">
                        <label>{{profile.accountPage.account.wa_msg_title}} *</label>
                        <input type="text" data-testid="messageTitleInput" ref="title" class="form-control" v-model="fields.title" required />
                    </div>
                    <div class="form-field" :class="{focus:fields.title.length}">
                        <label>{{profile.accountPage.account.message}} *</label>
                        <textarea class="form-control" data-testid="messageTextInput" v-model="fields.message" required></textarea>
                    </div>
                    <button type="submit" data-testid="messageSubmitButton" class="button button--icon"><i class="fab fa-whatsapp"></i>{{profile.accountPage.account.creat_message}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            fields: {
                title: '',
                message: '',
            }
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.title.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        createMessage() {
            let self = this;
            const params = new URLSearchParams();
            params.append('title', this.fields.title);
            params.append('message', this.fields.message);
            fetch('/api/v1/predefinedwamessage/create', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('created');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>
