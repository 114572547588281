<template>
    <div class="modal-wrapper readonly-override" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-link"></i>{{profile.incident_detail.incident_copy_button}} ({{incidentInfo.id_incident}})</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createConnectedIncident()">
                    <div class="form-field" :class="{focus:fields.descr.length}">
                        <label>{{profile.incident_detail.incident_copy_description}}</label>
                        <textarea class="form-control" data-testid="inputIncidentDescription" v-model="fields.descr"></textarea>
                    </div>
                    <select class="form-select readonly-override" aria-label="Selecteer een categorie" data-testid="dropdownCategory" v-model="fields.category" required>
                        <option value="">{{profile.incident_detail.select_category}} *</option>
                        <option v-for="(category, index) in fields.categories" :value="category.id_incident_category" :key="index">{{category.dutch_name}}</option>
                    </select>
                    <span class="notification notification--error" v-if="error" style="margin-bottom: 10px;">{{profile.incidents.new_incident.channels_error}}</span>
                    <button type="submit" class="button button--icon" data-testid="submitIncident"><i class="fas fa-link"></i>{{profile.dashboard.building_manager.create_incident}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'connectedIncidentModal',
    data() {
        return {
            profile: {},
            fields: {
                descr: '',
                categories: [],
                category: ''
            },
            error: false
        }
    },
    props: ['incidentInfo', 'categories'],
    created() {
        this.fields.descr = this.incidentInfo.incident_description;
        this.fields.categories = this.categories;
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        createConnectedIncident() {
            let self = this;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('description', this.fields.descr);
            data.append('id_incident_category', this.fields.category);
            if (this.incidentInfo.wants_copy) {
                data.append('original_wants_copy', true);
            }
            fetch('/api/v1/incident/copyincident', {
                method: 'post',
                body: data
            }).then((resp) => {
                if (!resp.ok) {
                    self.error = true;
                }
                return resp.json();
            }).then(function(data) {
                if (!self.error) {
                    self.closeModal();
                    self.$router.push('/buildingmanager/incident-detail?id=' + data.id_incident);
                }
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
