<template>
    <div>
        <div class="types-values">
            <div class="row">
                <div class="col">
                    <h1>{{profile.assets.types_definition}}</h1>
                    <div class="edit-button icon" @click="newAssetTypeModalOpen = true" v-if="twinq || (!twinq && admin)"><i class="fas fa-plus"></i></div>
                </div>
            </div>
            <div class="types-values">
                <h3>{{profile.assets.select_objecttype}}</h3>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="dropdown-section" style="margin-bottom: 20px;" v-click-outside="closeAssetTypeDropdown">
                            <span class="dropdown" :class="{active: assetTypeDropdownToggled}" v-on:click="assetTypeDropdownToggled = !assetTypeDropdownToggled">
                                <span v-if="!selectedAssetType.id_asset_type" class="placeholder">{{profile.assets.select_objecttype}}</span>
                                <span v-if="selectedAssetType.id_asset_type" class="selected">{{selectedAssetTypeName}}</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list" v-if="assetTypeDropdownToggled" :class="{search: assetTypes.length > 9}">
                                <ul>
                                    <li v-for="assetType in assetTypes" v-on:click="selectAssetType(assetType)" :key="assetType.id">{{assetType.display_name}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div style="position: relative;">
                            <span class="edit-button icon" @click="cancelEdit()" v-if="assetTypeEdit"  style="right: 0;"><i class="fas fa-times" style="font-size: 22px; margin-top: 12px;"></i></span>
                            <span class="edit-button icon" @click="checkForRemoval()" v-if="selectedAssetType.id_asset_type && !assetTypeEdit && (twinq || (!twinq && admin))" style="right: 0; margin-right: 35px;"><i class="fas fa-trash-alt" style="font-size: 18px;"></i></span>
                            <span class="edit-button icon" @click="assetTypeEdit = true" v-if="selectedAssetType.id_asset_type && !assetTypeEdit && (twinq || (!twinq && admin))" style="right: 0;"><i class="fas fa-pen" style="font-size: 18px;"></i></span>
                        </div>
                    </div>
                </div>
                <div class="notification notification--error" style="margin-bottom: 15px; width: 100%;" v-if="removeError">
                    <p style="margin-bottom: 0;">{{profile.assets.remove_error}}</p>
                </div>
                <div v-if="selectedAssetType.id_asset_type">
                    <div v-if="!assetTypeEdit" style="position: relative;">
                        <span style="margin-bottom: 20px; display: block;" v-if="selectedAssetType.extra_description_a"><b>{{profile.assets.fields}}</b> <i class="fas fa-info-circle" v-tooltip.right="profile.assets.define_tooltip" style="cursor: pointer;"></i></span>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_a">
                                <label>{{selectedAssetType.extra_description_a}}</label>
                                <p>{{selectedAssetType.extra_description_a_default}}</p>
                            </div>
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_b">
                                <label>{{selectedAssetType.extra_description_b}}</label>
                                <p>{{selectedAssetType.extra_description_b_default}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_c">
                                <label>{{selectedAssetType.extra_description_c}}</label>
                                <p>{{selectedAssetType.extra_description_c_default}}</p>
                            </div>
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_d">
                                <label>{{selectedAssetType.extra_description_d}}</label>
                                <p>{{selectedAssetType.extra_description_d_default}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_e">
                                <label>{{selectedAssetType.extra_description_e}}</label>
                                <p>{{selectedAssetType.extra_description_e_default}}</p>
                            </div>
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_f">
                                <label>{{selectedAssetType.extra_description_f}}</label>
                                <p>{{selectedAssetType.extra_description_f_default}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_g">
                                <label>{{selectedAssetType.extra_description_g}}</label>
                                <p>{{selectedAssetType.extra_description_g_default}}</p>
                            </div>
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_h">
                                <label>{{selectedAssetType.extra_description_h}}</label>
                                <p>{{selectedAssetType.extra_description_h_default}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_i">
                                <label>{{selectedAssetType.extra_description_i}}</label>
                                <p>{{selectedAssetType.extra_description_i_default}}</p>
                            </div>
                            <div class="col-sm-6" v-if="selectedAssetType.extra_description_j">
                                <label>{{selectedAssetType.extra_description_j}}</label>
                                <p>{{selectedAssetType.extra_description_j_default}}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <form>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.display_name.length, error: nameError}">
                                        <label style="font-weight: 500;">{{profile.dashboard.building_manager.message_table.name}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.display_name" style="margin-bottom: 10px;" ref="name" @input="nameCheck()" />
                                    </div>
                                </div>
                            </div>
                            <span style="margin-bottom: 20px; display: block;"><b>{{profile.assets.fields}}</b> <i class="fas fa-info-circle" v-tooltip.right="profile.assets.define_tooltip" style="cursor: pointer;"></i></span>
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_a}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 1 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_a" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_a_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 1 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_a_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>    
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_b}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 2 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_b" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_b_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 2 standaardwaarde</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_b_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>         
                            </div>
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_c}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 3 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_c" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_c_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 3 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_c_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_d}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 4 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_d" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_d_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 4 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_d_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div>            
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_e}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 5 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_e" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_e_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 5 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_e_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_f}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 6 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_f" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_f_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 6 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_f_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div>              
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_g}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 7 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_g" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_g_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 7 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_g_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_h}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 8 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_h" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_h_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 8 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_h_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div> 
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_i}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 9 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_i" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_i_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 9 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_i_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_j}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 10 {{profile.accountPage.account.wa_msg_title}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_j" style="margin-bottom: 10px;" />
                                    </div>
                                    <div class="form-field" :class="{focus:selectedAssetType.extra_description_j_default}">
                                        <label style="font-weight: 500;">{{profile.assets.field}} 10 {{profile.assets.default_value}}</label>
                                        <input type="text" class="form-control" v-model="selectedAssetType.extra_description_j_default" style="margin-bottom: 10px;" />
                                    </div>
                                </div>
                            </div> 
                            <span class="button button--icon" @click="saveValues()" v-if="assetTypeEdit" style="float: right;margin-top: 10px;"><i class="fas fa-save"></i>Opslaan</span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <NewAssetTypeModal v-if="newAssetTypeModalOpen" @close="newAssetTypeModalOpen = false" @success="getAssetTypes()" />
        <RemoveAssetTypeModal v-if="removeAssetTypeModalOpen" @close="removeAssetTypeModalOpen = false" @success="getAssetTypes()" :selectedAssetType="selectedAssetType" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import NewAssetTypeModal from "@/components/modals/NewAssetTypeModal.vue";
import RemoveAssetTypeModal from "@/components/modals/RemoveAssetTypeModal.vue";

export default {
    mixins: [setLocale],
    components: {
        NewAssetTypeModal,
        RemoveAssetTypeModal
    },
    props: ['admin', 'assetTypes', 'twinq'],
    data() {
        return {
            profile: {},
            assetTypeDropdownToggled: false,
            assetTypeEdit: false,
            selectedAssetType: {},
            newAssetTypeModalOpen: false,
            removeAssetTypeModalOpen: false,
            selectedAssetTypeName: '',
            nameError: false,
            removeError: false
        }
    },
    computed: mapState(['bmId']),
    methods: {
        getAssetTypes() {
            this.newAssetTypeModalOpen = false;
            this.removeAssetTypeModalOpen = false;
            this.selectedAssetType = {};
            this.$emit('getAssetTypes');
        },
        selectAssetType(assetType) {
            console.log(assetType);
            this.assetTypeDropdownToggled = false;
            this.selectedAssetType = assetType;
            this.selectedAssetTypeName = assetType.display_name;
        },
        cancelEdit() {
            this.assetTypeEdit = false;
            this.selectedAssetType.display_name = this.selectedAssetTypeName;
            this.selectedAssetType = {};
        },
        saveValues() {
            if (this.selectedAssetType.display_name.length) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                let idBm = 'data[' + this.selectedAssetType.id_asset_type + '][id_building_manager]';
                let assetTypeNameParam = 'data[' + this.selectedAssetType.id_asset_type + '][display_name]';
                let assetTypeFieldAParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_a]';
                let assetTypeFieldBParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_b]';
                let assetTypeFieldCParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_c]';
                let assetTypeFieldDParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_d]';
                let assetTypeFieldEParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_e]';
                let assetTypeFieldFParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_f]';
                let assetTypeFieldGParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_g]';
                let assetTypeFieldHParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_h]';
                let assetTypeFieldIParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_i]';
                let assetTypeFieldJParam = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_j]';
                let defaultA = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_a_default]';
                let defaultB = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_b_default]';
                let defaultC = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_c_default]';
                let defaultD = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_d_default]';
                let defaultE = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_e_default]';
                let defaultF = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_f_default]';
                let defaultG = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_g_default]';
                let defaultH = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_h_default]';
                let defaultI = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_i_default]';
                let defaultJ = 'data[' + this.selectedAssetType.id_asset_type + '][extra_description_j_default]';
                data.append(idBm, this.bmId);
                data.append(assetTypeNameParam, this.selectedAssetType.display_name);
                if (this.selectedAssetType.extra_description_a) {
                    data.append(assetTypeFieldAParam, this.selectedAssetType.extra_description_a);
                }
                if (this.selectedAssetType.extra_description_b) {
                    data.append(assetTypeFieldBParam, this.selectedAssetType.extra_description_b);
                }
                if (this.selectedAssetType.extra_description_c) {
                    data.append(assetTypeFieldCParam, this.selectedAssetType.extra_description_c);
                }
                if (this.selectedAssetType.extra_description_d) {
                    data.append(assetTypeFieldDParam, this.selectedAssetType.extra_description_d);
                }
                if (this.selectedAssetType.extra_description_e) {
                    data.append(assetTypeFieldEParam, this.selectedAssetType.extra_description_e);
                }
                if (this.selectedAssetType.extra_description_f) {
                    data.append(assetTypeFieldFParam, this.selectedAssetType.extra_description_f);
                }
                if (this.selectedAssetType.extra_description_g) {
                    data.append(assetTypeFieldGParam, this.selectedAssetType.extra_description_g);
                }
                if (this.selectedAssetType.extra_description_h) {
                    data.append(assetTypeFieldHParam, this.selectedAssetType.extra_description_h);
                }
                if (this.selectedAssetType.extra_description_i) {
                    data.append(assetTypeFieldIParam, this.selectedAssetType.extra_description_i);
                }
                if (this.selectedAssetType.extra_description_j) {
                    data.append(assetTypeFieldJParam, this.selectedAssetType.extra_description_j);
                }
                if (this.selectedAssetType.extra_description_a_default) {
                    data.append(defaultA, this.selectedAssetType.extra_description_a_default);
                }
                if (this.selectedAssetType.extra_description_b_default) {
                    data.append(defaultB, this.selectedAssetType.extra_description_b_default);
                }
                if (this.selectedAssetType.extra_description_c_default) {
                    data.append(defaultC, this.selectedAssetType.extra_description_c_default);
                }
                if (this.selectedAssetType.extra_description_d_default) {
                    data.append(defaultD, this.selectedAssetType.extra_description_d_default);
                }
                if (this.selectedAssetType.extra_description_e_default) {
                    data.append(defaultE, this.selectedAssetType.extra_description_e_default);
                }
                if (this.selectedAssetType.extra_description_f_default) {
                    data.append(defaultF, this.selectedAssetType.extra_description_f_default);
                }
                if (this.selectedAssetType.extra_description_g_default) {
                    data.append(defaultG, this.selectedAssetType.extra_description_g_default);
                }
                if (this.selectedAssetType.extra_description_h_default) {
                    data.append(defaultH, this.selectedAssetType.extra_description_h_default);
                }
                if (this.selectedAssetType.extra_description_i_default) {
                    data.append(defaultI, this.selectedAssetType.extra_description_i_default);
                }
                if (this.selectedAssetType.extra_description_j_default) {
                    data.append(defaultJ, this.selectedAssetType.extra_description_j_default);
                }
                let self = this;
                fetch('/api/v1/asset_type', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.selectedAssetTypeName = self.selectedAssetType.display_name;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.assetTypeEdit = false;
                });
            }
            else {
                this.nameError = true;
            }
        },
        nameCheck() {
            if (this.selectedAssetType.display_name.length) {
                this.nameError = false;
            }
        },
        checkForRemoval() {
            let self = this;
            fetch('/api/v1/asset/filtered?limit=25&offset=0&id_asset_type=' + this.selectedAssetType.id_asset_type)
            .then(response => { response.json().then(
                function(data) {
                    if (data.recordsTotal) {
                        self.removeError = true;
                    }
                    else {
                        self.removeAssetTypeModalOpen = true;
                    }
                });
            });
        },
        closeAssetTypeDropdown() {
            this.assetTypeDropdownToggled = false;
        }
    }
}
</script>
