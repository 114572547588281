<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-photo-video"></i> {{profile.incident_detail.add_imagery}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.accountPage.account.upload_media}}<br /></p>
                <form v-on:submit.prevent="uploadMedia()" v-if="!loading">
                    <div class="form-field-upload">
                        <input type="file" data-testid='inputMediaFile' @change="fileChange" multiple required />
                    </div>
                    <span class="notification notification--error" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_error}}</span>
                    <button type="submit" data-testid='submitMediaFile' class="button button--icon"><i class="fas fa-photo-video"></i>{{profile.incident_detail.add_imagery}}</button>
                </form>
                <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';

export default {
    mixins: [setLocale],
    name: 'importLocationModal',
    data() {
        return {
            fields: {
                file: null,
            },
            uploadError: false,
            loading: false,
            videoPresent: false,
            numberOfFiles: 0
        }
    },
    props: ['incidentId'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        uploadMedia() {
            this.loading = true;
            this.uploadError = false;
            this.numberOfFiles = this.fields.file.length;
            let self = this;
            for (var i = 0; i < this.fields.file.length; i++) {
                let file = this.fields.file[i];
                this.numberOfFiles = i;
                if (file.type === 'application/pdf') {
                    const data = new FormData();
                    let filename = file.name.split('.pdf')[0];
                    data.append('file', file);
                    data.append('filename', filename);
                    data.append('id_incident', this.incidentId);
                    fetch('/api/v1/fileupload/uploadincidentpdf', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (!data.url) {
                            self.uploadError = true;
                        }
                        self.numberOfFiles--
                        if (!self.videoPresent && self.numberOfFiles === -1) {
                            self.loading = false;
                            self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                            self.closeModal();
                            self.$emit('success');
                        }
                    });
                }
                if (file.type === 'video/mp4' || file.type === 'video/3gp' || file.type === 'video/ts' || file.type === 'video/mkv' || file.type === 'video/mov' || file.type === 'video/quicktime' || file.type === 'video/avi') {
                    this.videoPresent = true;
                    const data = new FormData();
                    data.append('file', file);
                    data.append('filename', file.name);
                    data.append('id_incident', this.incidentId);
                    fetch('/api/v1/fileupload/uploadincidentvideo', {
                        method: 'post',
                        body: data
                    }).then((resp) => {
                        if (!resp.ok) {
                            self.uploadError = true;
                        }
                        return resp.json();
                    }).then(function(data) {
                        self.loading = false;
                        if (!self.uploadError) {
                            self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                            self.closeModal();
                            self.$emit('success');
                        }
                    });
                }
                if (file.type === 'image/bmp' ||
                    file.type === 'image/eps' ||
                    file.type === 'image/gif' ||
                    file.type === 'image/ico' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/pcx' ||
                    file.type === 'image/png' ||
                    file.type === 'image/raf' ||
                    file.type === 'image/tiff' ||
                    file.type === 'image/webp') {
                    const data = new FormData();
                    data.append('file', file);
                    data.append('id_incident', this.incidentId);
                    fetch('/api/v1/fileupload/uploadincidentimage', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (!data.url) {
                            self.uploadError = true;
                        }
                        self.numberOfFiles--;
                        if (!self.videoPresent && self.numberOfFiles === -1) {
                            self.loading = false;
                            self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                            self.closeModal();
                            self.$emit('success');
                        }
                    });
                }
            }
            self.$store.dispatch('updateFileAdded', true);
        }
    }
}
</script>
