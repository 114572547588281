<template>
    <div>
        <div class="main-content" >
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--actions" style="border-radius: 20px;">
                    <Scanner @scanned="getAsset" :key="scannerKey" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import Scanner from '@/components/mechanic/Scanner.vue';
export default {
    mixins: [setLocale],
    components: {
        Scanner
    },
    data() {
        return {
            profile: {},
            qrCode: 0,
            scannerKey: 0,
        }
    },
    created() {
        document.title = 'S1MONE | Scan S1-Code';
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAsset(object) {
            this.qrCode = object.qr_code;
            this.$router.push('/monteur/opnames?qrcode=' + this.qrCode);
        }
    }
}
</script>
