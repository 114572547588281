<template>
    <div class="block block--owner_detail" style="margin-bottom: 0;">
        <div class="incident-head">
            <h1 style="max-width: 100%;">{{profile.owners.contacts.contacts}}</h1>
            <span class="edit-button icon" data-testid="contactAddButton" @click="newContactModalOpen = true" style="right: 0; top: 7px;" v-if="!disableEdit"><i class="fas fa-plus"></i></span>
        </div>
        <div class="table-wrap" style="margin-top: 20px;" v-if="contacts.length">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" @click="sortTable('name')" :class="{active: sortType === 'name'}">{{profile.accountPage.account.contactname}}<i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('mobile')" :class="{active: sortType === 'mobile'}">{{profile.accountPage.account.contactmobile}}<i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('email')" :class="{active: sortType === 'email'}">{{profile.incidents.new_incident.emailaddress}}<i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('id_shiro_user')" :class="{active: sortType === 'id_shiro_user'}">Account<i class="fas fa-sort"></i></th>
                        <th scope="col" @click="sortTable('role_description')" :class="{active: sortType === 'role_description'}">{{profile.text_languages.text_languagetable.description}}<i class="fas fa-sort"></i></th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(contact, index) in contacts" :key="index" @click="selectContact(contact)">
                        <td data-testid="contactNameText">{{contact.name}}</td>
                        <td data-testid="contactPhoneText">{{contact.mobile}}</td>
                        <td data-testid="contactEmailText">{{contact.email}}</td>
                        <td><span v-if="contact.id_shiro_user">Account</span></td>
                        <td data-testid="contactRoleText"><span v-if="contact.role_description !== 'undefined'">{{contact.role_description}}</span></td>
                    </tr> 
                </tbody>
            </table>
        </div>
        <p v-else style="font-size: 16px; margin: 20px 0 0 0;">{{ profile.owners.contacts.no_contacts }}</p>
        <NewContactModal v-if="newContactModalOpen" @close="newContactModalOpen = false" :contacts="contacts" :vve="vve" :ownerId="ownerId" @success="getContacts()" />
        <EditContactModal v-if="editContactModalOpen" @close="getContacts()" :contacts="contacts" :ownerId="ownerId" :selectedContact="selectedContact" @success="getContacts()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
import NewContactModal from "@/components/modals/NewContactModal.vue";
import EditContactModal from "@/components/modals/EditContactModal.vue";
export default {
    mixins: [setLocale],
    components: {
        NewContactModal,
        EditContactModal
    },
    data: function () {
        return {
            contacts: [],
            newContactModalOpen: false,
            editContactModalOpen: false,
            selectedContact: {},
            sortType: '',
            sortOrder: 'asc'
        }
    },
    props: ['ownerId', 'disableEdit', 'vve'],
    mounted() {
        this.$parent.$on('getContacts', this.getContacts);
    },
    beforeDestroy() {
        this.$parent.$off('getContacts', this.getContacts);
    },
    created() {
        this.getContacts();
    },
    methods: {
        getContacts(tab) { 
            if (tab === 'contacts' || !tab) {
                this.newContactModalOpen = false;
                this.editContactModalOpen = false;
                this.contacts = [];
                let self = this;
                fetch('/api/v1/contactperson/byidowner?id_owner=' + this.ownerId)
                .then(response => { response.json().then(
                    function(data) {
                        let contactsWithAccount = [];
                        let contactsWithOutAccount = [];
                        for (var i = 0; i < data.length; i++) {
                            let contact = data[i];
                            if (contact.id_shiro_user) {
                                contactsWithAccount.push(contact); 
                            }
                            else {
                                contactsWithOutAccount.push(contact); 
                            }
                        }
                        let idsToRemove = contactsWithAccount.map(obj => obj.email);
                        
                        for (let i = contactsWithOutAccount.length - 1; i >= 0; i--) {
                            if (idsToRemove.indexOf(contactsWithOutAccount[i].email) !== -1) {
                                contactsWithOutAccount.splice(i, 1);
                            }
                        }
                        self.contacts = contactsWithAccount.concat(contactsWithOutAccount);
                        self.contacts = sortBy(self.contacts, 'name');
                    });
                });
            }        
        },
        selectContact(contact) {
            if (!this.disableEdit) {
                this.selectedContact = contact;
                this.editContactModalOpen = true;
            }   
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.sortType = sorter;
            this.contacts = sortBy(this.contacts, sorter);
            if (this.sortOrder === 'desc') {
                this.contacts.reverse();
            }
        }
    }
}
</script>
