<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3> Importeer locaties vanuit puntkomma-separated bestand </h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p> Zorg ervoor dat je CSV-bestand het volgende formaat heeft (alle velden zijn vereist): </p>
                <p> Straat;Huisnummer;Postcode;Plaats;Land;Naam;Verdieping;Asset/ruimte </p> 
                <p> Let op: je dient per eigenaar een CSV-bestand te uploaden. </p>
                <br>
                <!-- TODO: Werkt nog niet -->
                <a download="import-places-example.csv"> Of download hier een voorbeeld bestand </a>
                <!-- href="/s1mone/import-places-example.csv" -->
                <br>
                <div class="row">
                    <div class="col-sm-3">
                        <label for="file" class="control-label" style="right:12px; position: absolute;margin-top: 3px">CSV File</label>
                    </div>
                    <div class="col-sm-8">
                        <input type="file" id="fileInput"/>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <label class="control-label" style="right:10px; position: absolute; margin-top: 27px">Eigenaar</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="dropdown-section" style="margin-top: 15px">
                            <select class="form-select" v-model="ownerID">
                                <option value="" disabled selected>Kies een eigenaar</option>
                                <option v-for="(manager, index) in owners" :value="manager.id_owner" :key="index">{{manager.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <label class="control-label" style="right:10px; position: absolute; margin-top: 15px">Objecttype</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="dropdown-section" style="margin-top: 5px">
                            <select class="form-select" v-model="assetType">
                                <option value="" disabled selected>Kies een objecttype</option>
                                <option v-for="(type, index) in assetTypes" :value="type.id_asset_type" :key="index">{{type.display_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <label class="control-label" style="right:10px; position: absolute; margin-top: 15px">Koppel direct aan QR code?</label>
                    </div>
                    <div class="col-sm-9">
                        <select class="form-select" v-model="linkQr">
                            <option value="false">Niet Koppelen</option>
                            <option value="true">Koppel</option>
                        </select>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <button class="button button--icon button--right" style="margin-top:20px" v-on:click="uploadLocation()"><i class="fa fa-upload"></i>Locatie importeren</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
export default {
    mixins: [setLocale],
    name: 'uploadLocationModal',
    props: ['managerID'],
    data() {
        return {
            profile: {},
            owners: [],
            assetTypes: [],
            ownerID: '',
            assetType: '',
            file: '',
            linkQr: false
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        self.getOwners();
        self.getAssetTypes();
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        getOwners(){
            let self = this;
            fetch('/api/v1/owner/byidbuildingmanager?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.owners = data.data;
                    self.owners = sortBy(self.owners, 'name');
                });
            });
        },
        getAssetTypes(){
            let self = this;
            fetch('/api/v1/asset_type/bybuildingmanager?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypes = sortBy(self.assetTypes, 'display_name');
                });
            });
        },
        uploadLocation(){
            this.file = document.getElementById('fileInput').files[0];
            let self = this;
            let data = new FormData();
            data.append('file', this.file);
            data.append('id_owner', this.ownerID);
            data.append('id_building_manager', this.managerID);
            data.append('link_qr', this.linkQr);
            data.append('id_asset_type', this.assetType);
            fetch('/api/v1/placeupload/upload_for_buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}


</script>
