<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="main-content" v-if="profile.dashboard">
            <span id="top_anchor" style="position: absolute; top: 0; left: 0;"></span>
            <div class="container">
                <div class="items-wrap">
                    <div class="row">
                        <div class="col">
                            <h3 style="margin: 10px 0; float: left;">{{profile.actionable.assignments}}</h3>
                            <router-link tag="a" v-if="recordings || recordingsHistory" to="/monteur/opnames" class="text-link" style="float: right; margin-top: 8px; right: 20px; top: 0; font-size: 16px; font-weight: 500; color: #0867b3;">{{profile.actionable.recordings}} ({{recordings}})</router-link>
                        </div>
                    </div>                   
                    <div class="div" v-if="totalAssignments">
                        <div class="row">
                            <div class="col">
                                <div class="dashboard-filters">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="dropdown-section" style="z-index: 6;">
                                                <span v-if="selectedAddress" class="remove-selection" data-testid="filterAddressSelect" style="top: 0; right: 45px; cursor: pointer; z-index: 2; width: 32px; text-align: center; padding-top: 16px;" @click="removeAddress()"><i class="fas fa-times" style="font-size: 22px;"></i></span>
                                                <span class="dropdown" data-testid="filterAddressSelect" :class="{active: addressDropdownToggled}" @click="addressDropdownToggled = !addressDropdownToggled">
                                                    <span v-if="!selectedAddress" class="placeholder">{{profile.incidents.incidenttable.address}}</span>
                                                    <span v-if="selectedAddress" class="selected">{{selectedAddress}}</span>
                                                    <span v-if="selectedAddress" class="seperator">|</span>
                                                    <i class="fas fa-chevron-down chevron"></i>
                                                </span>
                                                <div class="dropdown-list" v-if="addressDropdownToggled">
                                                    <ul>
                                                        <li data-testid="filterAddressResult" v-for="(address, index) in addressList" v-on:click="selectAddress(address)" :key="index">{{address.address}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="dropdown-section">
                                                <span v-if="selectedDate" class="remove-selection" data-testid="filterDateSelect" style="top: 0; right: 45px; cursor: pointer; z-index: 2; width: 32px; text-align: center; padding-top: 16px;" @click="removeDate()"><i class="fas fa-times" style="font-size: 22px;"></i></span>
                                                <span class="dropdown" data-testid="filterDateSelect" :class="{active: dateDropdownToggled}" @click="dateDropdownToggled = !dateDropdownToggled">
                                                    <span v-if="!selectedDate" class="placeholder">{{profile.dashboard.building_manager.message_table.date}}</span>
                                                    <span v-if="selectedDate" class="selected">{{selectedDate}}</span>
                                                    <span v-if="selectedDate" class="seperator">|</span>
                                                    <i class="fas fa-chevron-down chevron"></i>
                                                </span>
                                                <div class="dropdown-list" v-if="dateDropdownToggled">
                                                    <ul>
                                                        <li data-testid="filterDateResult" v-for="(date, index) in dateList" v-on:click="selectDate(date)" :key="index">{{date.date}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button-switcher">
                                    <span v-if="current.length" :class="{active: active === 'current'}" @click="activeType('current')" data-testid="filterCurrentButton">{{profile.dashboard.repair_company.current}} <i>({{current.length}})</i></span>
                                    <span v-if="planned.length" :class="{active: active === 'planned'}" @click="activeType('planned')" data-testid="filterPlannedButton">{{profile.incident_detail.appointments}} <i>({{planned.length}})</i></span>
                                    <span v-if="toPlan.length" :class="{active: active === 'toPlan'}" @click="activeType('toPlan')" data-testid="filterPlanButton">{{profile.incident_detail.to_plan}} <i>({{toPlan.length}})</i></span>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="active === 'current'">
                            <div class="col-sm-6 col-lg-4" v-for="(item, index) in current" :key="index">
                                <span class="bm-label" data-testid="incidentManagerText">{{item.building_manager_name}}</span>
                                <span class="id" data-testid="incidentIdText">{{item.id_incident}}</span>
                                <div class="appointment-block" @click="toIncident(item.id_incident)">
                                    <span class="chatcount" data-testid="incidentChatText" v-if="item.chat_count">{{item.chat_count}}</span>
                                    <span class="time" data-testid="incidentTimeText"><i class="fas fa-calendar-day"></i> {{item.dateTime}}</span>
                                    <span class="status-bar" data-testid="incidentStatusText" :class="item.status">
                                        <span data-testid="incidentMechanicStatusText">{{profile['mechanic_statusses'][item.status]}}</span>
                                    </span>
                                    <p data-testid="incidentDescriptionText">{{item.description}}</p>
                                    <div class="row">
                                        <div class="col">
                                            <i class="fas fa-map-marker-alt"></i>
                                            <span class="address" data-testid="incidentAddressText">
                                                {{item.address_1}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}<br />
                                                {{item.address_2}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <i class="fas fa-cube"></i>
                                            <span class="object" data-testid="incidentAssetText">{{item.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="active === 'planned'">
                            <div class="col-sm-6 col-lg-4" v-for="(item, index) in planned" :key="index">
                                <span class="bm-label" data-testid="incidentManagerText">{{item.building_manager_name}}</span>
                                <span class="id" data-testid="incidentIdText">{{item.id_incident}}</span>
                                <div class="appointment-block" @click="toIncident(item.id_incident)">
                                    <span class="chatcount" data-testid="incidentChatText" v-if="item.chat_count">{{item.chat_count}}</span>
                                    <span class="time" data-testid="incidentTimeText"><i class="fas fa-calendar-day"></i> {{item.dateTime}}</span>
                                    <span class="status-bar" data-testid="incidentStatusText" :class="item.status">
                                        <span data-testid="incidentMechanicStatusText">{{profile['mechanic_statusses'][item.status]}}</span>
                                    </span>
                                    <p data-testid="incidentDescriptionText">{{item.description}}</p>
                                    <div class="row">
                                        <div class="col">
                                            <i class="fas fa-map-marker-alt" data-testid="incidentAddressText"></i>
                                            <span class="address">
                                                {{item.address_1}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}<br />
                                                {{item.address_2}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <i class="fas fa-cube"></i>
                                            <span class="object" data-testid="incidentAssetText">{{item.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="active === 'toPlan'">
                            <div class="col-sm-6 col-lg-4" v-for="(item, index) in toPlan" :key="index">
                                <span class="bm-label" data-testid="incidentManagerText">{{item.building_manager_name}}</span>
                                <span class="id" data-testid="incidentIdText">{{item.id_incident}}</span>
                                <div class="appointment-block" @click="toIncident(item.id_incident)">
                                    <span class="chatcount" data-testid="incidentChatText" v-if="item.chat_count">{{item.chat_count}}</span>
                                    <span class="time" data-testid="incidentTimeText" :class="{latest: item.calendar_choice === 'choose_latest_possible'}">
                                        <i class="fas fa-calendar-day"></i>
                                        <span data-testid="incidentLatestTimeText" v-if="item.calendar_choice === 'choose_latest_possible'"> {{profile.incident_detail.latest}}</span>
                                        {{item.dateTime}}
                                    </span>
                                    <span class="status-bar" data-testid="incidentStatusText" :class="item.status">
                                        <span data-testid="incidentMechanicStatusText">{{profile['mechanic_statusses'][item.status]}}</span>
                                    </span>
                                    <p data-testid="incidentDescriptionText">{{item.description}}</p>
                                    <div class="row">
                                        <div class="col">
                                            <i class="fas fa-map-marker-alt"></i>
                                            <span class="address" data-testid="incidentAddressText">
                                                {{item.address_1}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}<br />
                                                {{item.address_2}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <i class="fas fa-cube"></i>
                                            <span class="object" data-testid="incidentAssetText">{{item.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h3 @click="showClosed = !showClosed" style="cursor: pointer; margin-bottom: 15px;">{{profile.assets.history}} <i data-testid="filterHistoryCount" style="font-weight: 500;">({{history.length}})</i> <i data-testid="showHistoryIncidents" class="fas fa-chevron-down" :class="{active: showClosed}" style="margin-right: 15px; float: right;"></i></h3>
                        <div class="dashboard-filters" v-if="showClosed">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="dropdown-section">
                                        <span v-if="closedSelectedAddress" class="remove-selection" style="top: 0; right: 45px; cursor: pointer; z-index: 2; width: 32px; text-align: center; padding-top: 16px;" @click="removeClosedAddress()"><i class="fas fa-times" style="font-size: 22px;"></i></span>
                                        <span class="dropdown" data-testid="filterHistoryAddressSelect" :class="{active: closedAddressDropdownToggled}" @click="closedAddressDropdownToggled = !closedAddressDropdownToggled">
                                            <span v-if="!closedSelectedAddress" class="placeholder">{{profile.incidents.incidenttable.address}}</span>
                                            <span v-if="closedSelectedAddress" class="selected">{{closedSelectedAddress}}</span>
                                            <span v-if="closedSelectedAddress" class="seperator">|</span>
                                            <i class="fas fa-chevron-down chevron"></i>
                                        </span>
                                        <div class="dropdown-list" v-if="closedAddressDropdownToggled">
                                            <ul>
                                                <li data-testid="filterHistoryAddressResult" v-for="(address, index) in closedAddressList" v-on:click="selectClosedAddress(address)" :key="index">{{address.address}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="dropdown-section">
                                        <span v-if="closedSelectedDate" class="remove-selection" style="top: 0; right: 45px; cursor: pointer; z-index: 2; width: 32px; text-align: center; padding-top: 16px;" @click="removeClosedDate()"><i class="fas fa-times" style="font-size: 22px;"></i></span>
                                        <span class="dropdown" data-testid="filterHistoryDateSelect" :class="{active: closedDateDropdownToggled}" @click="closedDateDropdownToggled = !closedDateDropdownToggled">
                                            <span v-if="!closedSelectedDate" class="placeholder">{{profile.dashboard.building_manager.message_table.date}}</span>
                                            <span v-if="closedSelectedDate" class="selected">{{closedSelectedDate}}</span>
                                            <span v-if="closedSelectedDate" class="seperator">|</span>
                                            <i class="fas fa-chevron-down chevron"></i>
                                        </span>
                                        <div class="dropdown-list" v-if="closedDateDropdownToggled">
                                            <ul>
                                                <li data-testid="filterHistoryDateResult" v-for="(date, index) in closedDateList" v-on:click="selectClosedDate(date)" :key="index">{{date.date}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="showClosed">
                            <div class="col-sm-6 col-lg-4" v-for="(item, index) in history" :key="index">
                                <span class="bm-label" data-testid="incidentHistoryManagerText">{{item.building_manager_name}}</span>
                                <span class="id" data-testid="incidentHistoryIdText">{{item.id_incident}}</span>
                                <div class="appointment-block" @click="toIncident(item.id_incident)">
                                    <span class="chatcount" data-testid="incidentHistoryChatText" v-if="item.chat_count">{{item.chat_count}}</span>
                                    <span class="time" data-testid="incidentHistoryTimeText"><i class="fas fa-calendar-day"></i> {{item.dateTime}}</span>
                                    <span class="status-bar" data-testid="incidentHistoryStatusText">
                                        <span data-testid="incidentHistoryMechanicStatusText">{{profile['mechanic_statusses'][item.status]}}</span>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <p data-testid="incidentHistoryDescriptionText">{{item.description}}</p>
                                            <span class="address" data-testid="incidentHistoryAssetText"><i class="fas fa-map-marker-alt"></i> {{item.name}}</span>
                                            <span class="address" data-testid="incidentHistoryAddressText">{{item.address_1}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}</span>
                                            <span class="address" data-testid="incidentHistoryAddressText">{{item.address_2}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <p v-else>{{ profile.actionable.no_assignments }}</p>      
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { EventBus } from '@/js/event-bus.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            loading: false,
            toPlan: [],
            planned: [],
            current: [],
            history: [],
            active: 'toPlan',
            addressDropdownToggled: false,
            addressList: [],
            selectedAddress: '',
            dateDropdownToggled: false,
            dateList: [],
            selectedDate: '',
            closedAddressDropdownToggled: false,
            closedAddressList: [],
            closedSelectedAddress: '',
            closedDateDropdownToggled: false,
            closedDateList: [],
            closedSelectedDate: '',
            showClosed: false,
            recordings: 0,
            recordingsHistory: false,
            totalAssignments: 0
        }
    },
    created() {
        document.title = 'S1MONE | Dashboard';
        let self = this;
        fetch('/api/v1/plannedactionlist/formechanic')
        .then(response => { response.json().then(
            function(data) {
                if (data.data.length) {
                   self.recordings = data.data.length;
                }
            });
        });
        fetch('/api/v1/plannedactionlist/formechanic?show_completed=true')
        .then(response => { response.json().then(
            function(data) {
                if (data.data.length) {
                   self.recordingsHistory = true;
                }
            });
        });
        if (localStorage.getItem('S1-mechanic-tab')) {
            this.active = localStorage.getItem('S1-mechanic-tab');
        }
        else {
            this.active = 'planned';
        }
        if (localStorage.getItem('S1-mechanic-address')) {
            this.selectedAddress = localStorage.getItem('S1-mechanic-address');
        }
        if (localStorage.getItem('S1-mechanic-date')) {
            this.selectedDate = localStorage.getItem('S1-mechanic-date');
        }
        EventBus.$on('eventPlanned', obj => {
            this.getToPlan();
        });
        this.getToPlan();
        setInterval(function () {
            this.getToPlan();
        }.bind(this), 300000);
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getToPlan() {
            let self = this;
            fetch('/api/v1/appointment/toplan')
            .then(response => { response.json().then(
                function(data) {
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        if (item.calendar_choice === 'choose_latest_possible') {
                            item.dateTime = moment(item.start_time).locale('nl').format('D MMMM YYYY');
                        }
                        else {
                            item.dateTime = moment(item.start_time).locale('nl').format('D MMMM YYYY, HH:mm');
                        }
                        item.date = moment(item.start_time).locale('nl').format('D MMMM YYYY');
                        if (item.floor_name === 'VVEVEST') {
                            if (self.locale === 'nl') {
                                item.floor_name = 'niet van toepassing'
                            }
                            if (self.locale === 'en') {
                                item.floor_name = 'not applicable'
                            }
                        }
                        items.push(item);
                    }
                    self.toPlan = items;
                    self.addressList = uniqBy(items, 'address');
                    self.dateList = uniqBy(items, 'date');
                    EventBus.$emit('gotEvents', items);
                    self.getPlanned();
                    self.getHistory();
                });
            });
        },
        getPlanned() {
            let self = this;
            fetch('/api/v1/appointment/planned')
            .then(response => { response.json().then(
                function(data) {
                    let items = [];
                    self.current = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.chat_count = item.unread_reporter_chat_count + item.unread_service_desk_chat_count;
                        item.dateTime = moment(item.start_time).locale('nl').format('D MMMM YYYY, HH:mm');
                        item.date = moment(item.start_time).locale('nl').format('D MMMM YYYY');
                        if (item.floor_name === 'VVEVEST') {
                            if (self.locale === 'nl') {
                                item.floor_name = 'niet van toepassing'
                            }
                            if (self.locale === 'en') {
                                item.floor_name = 'not applicable'
                            }
                        }
                        if (item.status !== 'on_location_mechanic' && item.status !== 'paused_mechanic') {
                            items.push(item);
                        }
                        else {
                            self.current.push(item);
                        }
                    }
                    self.planned = items;
                    let addressPlanned = uniqBy(self.planned, 'address');
                    self.addressList = self.addressList.concat(addressPlanned);
                    let addressCurrent = uniqBy(self.current, 'address');
                    self.addressList = self.addressList.concat(addressCurrent);
                    let datePlanned = uniqBy(self.planned, 'date');
                    self.dateList = self.dateList.concat(datePlanned);
                    let dateCurrent = uniqBy(self.current, 'date');
                    self.dateList = self.dateList.concat(dateCurrent);
                    self.addressList = uniqBy(self.addressList, 'address');
                    self.dateList = uniqBy(self.dateList, 'start_time');
                    self.addressList = sortBy(self.addressList, 'address');
                    self.dateList = sortBy(self.dateList, 'start_time');
                    if (self.current.length) {
                        self.active = 'current';
                    }
                    if (self.planned.length) {
                        self.active = 'planned';
                    }
                    if (self.toPlan.length) {
                        self.active = 'toPlan';
                    }
                    setTimeout(function () {
                        if (self.selectedAddress) {
                            self.filterAddress();
                        }
                        if (self.selectedDate) {
                            self.filterDate();
                        }
                    }, 100);
                    self.totalAssignments = self.current.length + self.planned.length + self.toPlan.length;
                    self.$store.dispatch('updateTotalAssignments', self.totalAssignments);
                });
            });
        },
        getHistory() {
            let self = this;
            fetch('/api/v1/appointment/history?since_days=365')
            .then(response => { response.json().then(
                function(data) {
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.dateTime = moment(item.start_time).locale('nl').format('D MMMM YYYY, HH:mm');
                        item.date = moment(item.start_time).locale('nl').format('D MMMM YYYY');
                        if (item.floor_name === 'VVEVEST') {
                            if (self.locale === 'nl') {
                                item.floor_name = 'niet van toepassing'
                            }
                            if (self.locale === 'en') {
                                item.floor_name = 'not applicable'
                            }
                        }
                        items.push(item);
                    }
                    self.history = items;
                    self.history = sortBy(self.history, 'id_incident');
                    self.history.reverse();
                    self.closedAddressList = uniqBy(self.history, 'address');
                    self.closedDateList = uniqBy(self.history, 'date');
                    self.closedAddressList = sortBy(self.closedAddressList, 'address');
                    self.closedDateList = sortBy(self.closedDateList, 'start_time');

                    if (self.closedSelectedAddress) {
                        self.filterClosedAddress();
                    }
                    if (self.closedSelectedDate) {
                        self.filterClosedDate();
                    }
                });
            });
        },
        activeType(type) {
            this.active = type;
            localStorage.setItem('S1-mechanic-tab', type);
        },
        selectAddress(address) {
            this.selectedAddress = address.address;
            this.addressDropdownToggled = false;
            this.selectedDate = '';
            this.getToPlan();
            this.getPlanned();
            localStorage.setItem('S1-mechanic-address', this.selectedAddress);
        },
        filterAddress() {
            let toPlanItems = [];
            for (var i = 0; i < this.toPlan.length; i++) {
                let item = this.toPlan[i];
                if (item.address === this.selectedAddress) {
                    toPlanItems.push(item);
                }
            }
            this.toPlan = toPlanItems;
            let currentItems = [];
            for (var i = 0; i < this.current.length; i++) {
                let item = this.current[i];
                if (item.address === this.selectedAddress) {
                    currentItems.push(item);
                }
            }
            this.current = currentItems;

            let plannedItems = [];
            for (var i = 0; i < this.planned.length; i++) {
                let item = this.planned[i];
                if (item.address === this.selectedAddress) {
                    plannedItems.push(item);
                }
            }
            this.planned = plannedItems;

            // Set active tab
            if (this.current.length) {
                this.active = 'current';
            }
            if (this.planned.length && !this.current.length) {
                this.active = 'planned';
            }
            if (this.toPlan.length && !this.current.length && !this.planned.length) {
                this.active = 'toPlan';
            }
            console.log(this.toPlan);
        },
        removeAddress() {
            this.addressDropdownToggled = false;
            this.dateDropdownToggled = false;
            this.selectedAddress = '';
            this.getToPlan();
            this.getPlanned();
            localStorage.removeItem('S1-mechanic-address');
        },
        selectDate(date) {
            this.dateDropdownToggled = false;
            this.addressDropdownToggled = false;
            this.selectedAddress = '';
            this.selectedDate = date.date;
            this.getToPlan();
            this.getPlanned();
            localStorage.setItem('S1-mechanic-date', this.selectedDate);
        },
        filterDate() {
            let items = [];
            for (var i = 0; i < this.toPlan.length; i++) {
                let item = this.toPlan[i];
                if (item.date === this.selectedDate) {
                    items.push(item);
                }
            }
            this.toPlan = items;

            let currentItems = [];
            for (var i = 0; i < this.current.length; i++) {
                let item = this.current[i];
                if (item.date === this.selectedDate) {
                    currentItems.push(item);
                }
            }
            this.current = currentItems;

            let plannedItems = [];
            for (var i = 0; i < this.planned.length; i++) {
                let item = this.planned[i];
                if (item.date === this.selectedDate) {
                    plannedItems.push(item);
                }
            }
            this.planned = plannedItems;

            // Set active tab
            if (this.current.length) {
                this.active = 'current';
            }
            if (this.planned.length && !this.current.length) {
                this.active = 'planned';
            }
            if (this.toPlan.length && !this.current.length && !this.planned.length) {
                this.active = 'toPlan';
            }
        },
        removeDate() {
            this.addressDropdownToggled = false;
            this.dateDropdownToggled = false;
            this.selectedDate = '';
            this.getToPlan();
            this.getPlanned();
            localStorage.removeItem('S1-mechanic-date');
        },
        selectClosedAddress(address) {
            this.closedSelectedAddress = address.address;
            this.closedAddressDropdownToggled = false;
            this.closedSelectedDate = '';
            this.getHistory();
        },
        filterClosedAddress() {
            let items = [];
            for (var i = 0; i < this.history.length; i++) {
                let item = this.history[i];
                if (item.address === this.closedSelectedAddress) {
                    items.push(item);
                }
            }
            this.history = items;
        },
        removeClosedAddress() {
            this.closedAddressDropdownToggled = false;
            this.closedDateDropdownToggled = false;
            this.closedSelectedAddress = '';
            this.getHistory();
        },
        selectClosedDate(date) {
            this.closedDateDropdownToggled = false;
            this.closedAddressDropdownToggled = false;
            this.closedSelectedAddress = '';
            this.closedSelectedDate = date.date;
            this.getHistory();
        },
        filterClosedDate() {
            let items = [];
            for (var i = 0; i < this.history.length; i++) {
                let item = this.history[i];
                if (item.date === this.closedSelectedDate) {
                    items.push(item);
                }
            }
            this.history = items;
        },
        removeClosedDate() {
            this.closedAddressDropdownToggled = false;
            this.closedDateDropdownToggled = false;
            this.closedSelectedDate = '';
            this.getHistory();
        },
        toIncident(id) {
            document.getElementById('top_anchor').scrollIntoView();
            this.$router.push('/monteur/incident-detail?id=' + id);
        }
    }
}
</script>
