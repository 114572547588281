<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-toolbox"></i>{{"Beheerder toevoegen"}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createPartner()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.name.length}">
                                <label>{{"Naam Beheerder"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.name" required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.nameContact.length}">
                                <label>{{"Naam Contactpersoon"}} *</label>
                                <input type="text" class="form-control" v-model="fields.nameContact" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.email.length, valid: validEmail, error: invalidEmail}">
                                <label>{{"E-mailadres"}} *</label>
                                <input type="text" class="form-control" v-model="fields.email" @input="validateEmail()" required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.kvk.length}">
                                <label>{{"KvK-nummer"}} *</label>
                                <!-- TODO: kvk nummer check (niet handig om al te implementeren) -->
                                <input type="text" class="form-control" v-model="fields.kvk" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.zipcode.length, error: !validZipCode}">
                                <label>{{"Postcode"}} *</label>
                                <input type="text" class="form-control" v-model="fields.zipcode" required @input="zipCodeSearch()">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.house_number.length}">
                                <label>{{"Huisnummer"}} *</label>
                                <input type="text" class="form-control" v-model="fields.house_number" required @input="zipCodeSearch()">
                            </div>
                        </div>
                    </div>
                    <div class="notification notification--error" style="margin-bottom: 17px;" v-if="addressError"><i class="fas fa-exclamation-triangle"></i> {{"Vul een geldige postcode en huisnummer combinatie in."}}</div>
                    <div class="row">
                        <div class="col-md-6" v-if="fields.address">
                            <div class="form-field" :class="{focus:fields.address.length}">
                                <label>{{"Straatnaam"}}</label>
                                <input type="text" class="form-control" v-model="fields.address" :disabled="true">
                            </div>
                        </div>
                        <div class="col-md-6" v-if="fields.city">
                            <div class="form-field" :class="{focus:fields.city.length}">
                                <label>{{"Plaats"}}</label>
                                <input type="text" class="form-control" v-model="fields.city" :disabled="true">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <vue-tel-input v-model="fields.mobile" class="form-control" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validMobile, error: invalidMobile}" @input="validateMobile"></vue-tel-input>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.phone.length, valid: validPhone, error: invalidPhone}">
                                <label>{{"Telefoonnummer"}}</label>
                                <input type="text" class="form-control" v-model="fields.phone" @input="validatePhone()">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.bankAccountNumber.length}">
                                <label>{{"Bank rekeningnummer"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.bankAccountNumber">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.bankAccountName.length}">
                                <label>{{"Bank accountnaam"}}</label>
                                <input type="text" class="form-control" v-model="fields.bankAccountName">
                            </div>
                        </div>
                    </div>
                    <div class="switch-wrapper" style="padding-bottom: 15px;">
                        <label class="switch" style="float: left;">
                            <input type="checkbox" v-model="notSameEmail">
                            <span class="slider round"></span>
                        </label>
                        <span style="font-weight: 500;">{{"Gegeven e-mail niet hetzelfde als factuur e-mail?"}}</span>
                    </div>
                    <div class="row" v-if="notSameEmail">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.billingEmail.length, valid: validBillingEmail, error: invalidBillingEmail}">
                                <label>{{"Factuur e-mailadres"}} </label>
                                <input type="text" class="form-control" v-model="fields.billingEmail" @input="validateBillingEmail()">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.description.length}">
                                <textarea class="form-control" v-model="fields.description"></textarea>
                                <label>{{"Extra informatie"}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="switch-wrapper">
                        <label class="switch" style="float: left;">
                            <input type="checkbox" v-model="fields.demoAccount">
                            <span class="slider round"></span>
                        </label>
                        <span style="font-weight: 500;">{{"Is dit een demo account?"}}</span>
                    </div>
                    <button type="submit" class="button button--icon"><i class="fas fa-toolbox"></i><span style="font-weight: 500;">{{"Beheerder toevoegen"}}</span></button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import uniqBy from 'lodash/uniqBy';
import { VueTelInput } from 'vue-tel-input';

export default {
    mixins: [setLocale],
    name: 'newPartnerModal',
    components: {
        VueTelInput
    },
    data() {
        return {
            fields: {
                name: '',
                nameContact: '',
                email: '',
                kvk: '',
                zipcode: '',
                house_number: '',
                address: '',
                city: '',
                phone: '',
                mobile: '',
                bankAccountNumber: '',
                bankAccountName: '',
                billingEmail: '',
                description: '',
                demoAccount: false
            },
            notSameEmail: false,
            validEmail: false,
            invalidEmail: false,
            validZipCode: true,
            existingPlaces: [],
            addressError: false,
            validPhone: false,
            invalidPhone: false,
            validMobile: false,
            invalidMobile: false,
            validBillingEmail: false,
            invalidBillingEmail: false,
            formattedMobile: '',
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder:'',
                }
            }
        }
    },
    created() {
        this.bindProps.inputOptions.placeholder = "Mobiel telefoonnummer";
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        createPartner(){
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][name]', this.fields.name);
            data.append('data[0][nameContact]', this.fields.nameContact);
            data.append('data[0][email]', this.fields.email);
            data.append('data[0][kvk]', this.fields.kvk);
            data.append('data[0][zipcode]', this.fields.zipcode);
            data.append('data[0][house_number]', this.fields.house_number);
            data.append('data[0][address]', this.fields.address);
            data.append('data[0][city]', this.fields.city);
            data.append('data[0][phone]', this.fields.phone);
            data.append('data[0][mobile]', this.formattedMobile);
            data.append('data[0][bankAccountNumber]', this.fields.bankAccountNumber);
            data.append('data[0][bankAccountName]', this.fields.bankAccountName);
            data.append('data[0][billingEmail]', this.fields.billingEmail);
            data.append('data[0][description]', this.fields.description);
            data.append('data[0][demoAccount]', this.fields.demoAccount);
            let self = this;
            if (!this.fields.phone) {
                this.validPhone = true;
            }
            if (!this.fields.mobile) {
                this.validMobile = true;
            }
            if (!this.fields.mobile) {
                this.validBillingEmail = true;
            }
            if (this.validEmail && this.validPhone && this.validBillingEmail && this.validMobile && this.validZipCode) {
                fetch('/api/v1/buildingmanager', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success("Uw gegevens zijn bijgewerkt", "Wijzigingen opgeslagen");
                    self.closeModal();
                    //self.$router.push('/buildingmanager/owner-detail?id=' + data.data[0].id_owner);
                });
            }
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.fields.email)) {
                this.validEmail = true;
                this.invalidEmail = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        validateBillingEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (this.fields.email !== this.fields.billingEmail && pattern.test(this.fields.billingEmail)) {
                this.validBillingEmail = true;
                this.invalidBillingEmail = false;
            }
            else {
                this.invalidBillingEmail = true;
                this.validBillingEmail = false;
            }
            
        },
        zipCodeSearch() {
            let self = this;
            let zipcodeRegex = new RegExp('^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$');
            this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
            if (this.validZipCode && this.fields.house_number) {
                setTimeout(function () {
                    fetch('/api/v1/zipcode/getaddress?zipcode=' + this.fields.zipcode + '&housenumber=' + this.fields.house_number)
                    .then(response => { response.json().then(
                        function(data) {
                            if (data.message) {
                                self.addressError = true;
                            }
                            else {
                                self.existingPlaces = [];
                                self.addressError = false;
                                self.fields.address = data.street;
                                self.fields.city = data.city;
                                //let address = self.fields.address.toLowerCase();
                                //self.getPlaces(address);
                            }
                        });
                    });
                }.bind(this), 500);
            }
        },
        getPlaces(address){
            let searchParam = '&search_name=' + address;
            let self = this;
            fetch('/api/v1/place/withownerinfo?limit=100' + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    self.places = data.data;
                    let strippedZip = self.fields.zipcode.replace(/\s/g, '');
                    strippedZip = strippedZip.toUpperCase();
                    for (var i = 0; i < self.places.length; i++) {
                        let place = self.places[i];
                        let strippedPlaceZip = place.zipcode.replace(/\s/g, '');
                        strippedPlaceZip = strippedPlaceZip.toUpperCase();
                        let placeAddress = place.address.toLowerCase().replace(/\s/g, '');
                        if (strippedZip === strippedPlaceZip && placeAddress === address && self.fields.house_number === place.house_number) {
                            self.existingPlaces.push(place);
                            self.existingPlaces = uniqBy(self.existingPlaces, 'id_place');
                        }
                    }
                });
            });
        },
        validatePhone() {
            let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            if (pattern.test(this.fields.phone)) {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            else {
                this.invalidPhone = true;
                this.validPhone = false;
            }
        },
        validateMobile: function (number, object) {
            if (object.formatted) {
                if (object.valid) {
                    this.validMobile = true;
                    this.invalidMobile = false;
                    // Moet hier wel object voor staan? number van functie is zo niet gebruikt
                    this.formattedMobile = number;
                }
                else {
                    this.validMobile = false;
                    this.invalidMobile = true;
                }
            }
            else {
                this.validMobile = false;
                this.invalidMobile = false;
            }
        }
                
            
    }
}
</script>
