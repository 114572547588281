<template>
  <header class="mech" v-if="profile.nav">
      <v-idle
        @idle="onidle"
        :loop="true"
        :duration="2000"
        style="display: none;" />
    <div class="container">
        <router-link tag="a" to="/monteur" class="logo"><img src="@/assets/img/logo.png" /></router-link>
        <section class="utils d-none d-md-block d-lg-block active">
            <span class="util util--toplan" data-testid="tabMehcanicToPlan" v-if="toPlan.length" style="padding: 18px 15px 14px 15px" @click="toggleToPlan = !toggleToPlan">
                <span class="counter">{{toPlan.length}}</span>
                <i class="fas fa-calendar-day" style="font-size: 20px; margin-top: 2px;"></i>
            </span>
            <ul class="to-plan-list" v-if="toggleToPlan">
                <li v-for="(item, index) in toPlan" :key="index" @click="toItem(item)">
                    <span class="incident_id" data-testid="planIncidentNumber">{{item.id_incident}}</span>
                    <span class="time" data-testid="planIncidentTime" :class="{latest: item.calendar_choice === 'choose_latest_possible'}">
                        <i class="fas fa-calendar-day"></i>
                        <span v-if="item.calendar_choice === 'choose_latest_possible'"> {{profile.incident_detail.latest}}</span>
                        {{item.dateTime}}
                    </span>
                    <p>{{item.description}}</p>
                    <div class="row">
                        <div class="col">
                            <span class="address" data-testid="planIncidentAddress">
                                {{item.address_1}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}
                                {{item.address_2}}
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
            <router-link tag="span" to="/monteur/connect" class="util util--qr"><p>Scan</p> <i class="fas fa-qrcode"></i></router-link>
            <span class="util util--language" data-testid="tabLanguage" v-if="langSwitch"><img v-if="locale === 'nl'" src="@/assets/icons/dutch.svg" /><img v-if="locale === 'en'" src="@/assets/icons/english.svg" />
                <ul class="dropdown-nav">
                    <span class="util util--language" data-testid="mechanicTabDutch" v-if="locale === 'en'" @click="setLang('nl')"><img src="@/assets/icons/dutch.svg" /></span>
                    <span class="util util--language" data-testid="mechanicTabEnglish" v-if="locale === 'nl'" @click="setLang('en')"><img src="@/assets/icons/english.svg" /></span>
                </ul>
            </span>
            <span class="util util--user"><i class="fas fa-user"></i>
                <ul class="dropdown-nav" data-testid="tabProfile">
                    <router-link tag="li" data-testid="tabAccount" to="/monteur/account">{{profile.nav.account.account}}</router-link>
                    <router-link tag="li" to="/monteur/news">{{profile.nav.news}}</router-link>
                    <li ><a data-testid="tabLogout" href="/signin.html">{{profile.nav.account.logout}}</a></li>
                </ul>
            </span>
        </section>
        <NewsModal v-if="news && (news.for_role === 'ALL' || news.for_role === 'MECHANIC')" @close="newsRead()" :news="news" />
    </div>
  </header>
</template>

<script>
import { EventBus } from '@/js/event-bus.js';
import NewsModal from "@/components/mechanic/modals/NewsModal.vue";

export default {
    components: {
        NewsModal
    },
    data: function () {
        return {
            toggleNotifications: false,
            profile: {},
            locale: 'nl',
            notifications: [],
            langSwitch: true,
            toPlan: [],
            toggleToPlan: false,
            news: null,
            specialistInterval: null
        }
    },
    created() {
        this.profile = require('../../assets/locales/' + this.locale + '/housing.json');
        this.getSpecialist();
        EventBus.$on('gotEvents', toPlan => {
            this.toPlan = toPlan;
        });
        this.specialistInterval = setInterval(function () {
            this.getSpecialist();
        }.bind(this), 1800000);
    },
    methods: {
        onidle() {
            window.location = '/signin.html?timeout=true';
       },
        setLang(lang) {
            this.locale = lang;
            let busObj = {
                locale: lang,
                industry: 'housing'
            }
            localStorage.setItem('S1-locale', lang);
            EventBus.$emit('langChange', busObj);
            this.profile = require('../../assets/locales/' + this.locale + '/housing.json');
        },
        toItem(item) {
            this.$router.push('/monteur/incident-detail?id=' + item.id_incident);
            this.toggleToPlan = false;
        },
        getSpecialist() {
            let self = this;
            fetch('/api/v1/formechanic/info').then((response) => {
                if (response.ok) {
                    return response.json();
                }
                window.location = '/signin.html';
            })
            .then((data) => {
                self.news = data.new_news[0];
            })
            .catch((error) => {
                window.location = '/signin.html';
            });
        },
        newsRead() {
            this.news = false;
            fetch('/api/v1/newsmessage/hasread', {
                method: 'post'
            }).then((resp) => {return resp.json();
            }).then(function(data) {});
        }
    }
}
</script>
