<template>
    <div class="modal-wrapper" v-if="profile.owners">
        <div class="modal">
            <div class="modal-header">
                <h3 v-if="vve"><i class="fas fa-trash-alt"></i>{{profile.owners.ownertable.deletevve}}</h3>
                <h3 v-else><i class="fas fa-trash-alt"></i>{{profile.owners.ownertable.deleteowner}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p v-if="vve" v-html="profile.owner.areyousure_vve"></p>
                <p v-else v-html="profile.owner.areyousure"></p>
                <br />
                <label class="radio-container">
                    <input type="radio" name="keep" value="true" id="keep" v-model="keep" @change="ownerSelectError = false">
                    <label for="keep">{{profile.owners.ownertable.keep_objects}}</label>
                </label>
                <label class="radio-container">
                    <input type="radio" name="keep" value="false" id="no_keep" v-model="keep">
                    <label for="no_keep" v-if="vve">{{profile.owners.ownertable.remove_objects_vve}}</label>
                    <label for="no_keep" v-else>{{profile.owners.ownertable.remove_objects}}</label>
                </label>
                <p v-if="keep === 'false'" style="margin-bottom: 10px; font-weight: 500;">{{profile.owners.ownertable.choose_owner}}</p>
                <div class="dropdown-section" v-if="keep === 'false'">
                    <span class="dropdown" v-on:click="ownersDropdownToggled = !ownersDropdownToggled" :class="{active: ownersDropdownToggled}">
                        <span v-if="!selectedOwner.id_owner">{{profile.places.placetable.owner}} *</span>
                        <span v-if="selectedOwner.id_owner" class="selected">{{selectedOwner.name}}</span>
                        <i class="fas fa-chevron-down chevron"></i>
                    </span>
                    <div class="dropdown-list" v-if="ownersDropdownToggled" :class="{search: owners.length > 9}">
                        <input type="text" @input="e => ownerSearchTerm = e.target.value" :value="ownerSearchTerm" placeholder="Zoek een eigenaar..." @keyup="searchOwners()" v-if="owners.length > 9"  />
                        <ul>
                            <li v-for="owner in owners" v-on:click="selectOwner(owner)" :key="owner.id_owner" v-show="!tempOwners.length && !ownersNoResults">{{owner.name}}</li>
                            <li v-for="(owner, index) in tempOwners" v-on:click="selectOwner(owner)" :key="index" v-show="tempOwners.length">{{owner.name}}</li>
                            <li v-if="ownersNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                        </ul>
                    </div>
                </div>
                <div class="notification notification--error" style="margin: 20px 0;" v-if="ownerSelectError">
                        <p><b>{{profile.owners.ownertable.choose_owner}}</b></p>
                    </div>
                <button class="button button--icon button--right" v-on:click="removeOwner()"><i class="fas fa-trash-alt"></i><span v-if="vve" style="font-weight: 500;">{{profile.owners.ownertable.deletevve}}</span><span style="font-weight: 500;" v-else>{{profile.owners.ownertable.deleteowner}}</span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    name: 'removeOwnerModal',
    props: ['ownerId'],
    data() {
        return {
            profile: {},
            keep: 'true',
            owners: [],
            ownersDropdownToggled: false,
            ownerSearchTerm: '',
            ownersNoResults: false,
            tempOwners: [],
            selectedOwner: {},
            ownerSelectError: false
        }
    },
    computed: mapState(['vve']),
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getOwners();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        removeOwner() {
            if (this.keep === 'false' && !this.selectedOwner.id_owner) {
                this.ownerSelectError = true;
            }
            if (!this.ownerSelectError) {
                const data = new URLSearchParams();
                data.append('id_owner', this.ownerId);
                let self = this;
                fetch('/api/v1/owner/deactivatewithouttree', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {         
                    if (self.keep === 'false') {
                        self.transferAssets();
                    }
                    else {
                        self.removeAssets();
                    }
                });
            }   
        },
        transferAssets() {
            const data = new URLSearchParams();
            data.append('current_id_owner', this.ownerId);
            data.append('new_id_owner', this.selectedOwner.id_owner);
            let self = this;
            fetch('/api/v1/owner/updateplaceswithnewowner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.closeModal();
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$router.push('/buildingmanager/owners');
            });
        },
        removeAssets() {
            const data = new URLSearchParams();
            data.append('id_owner', this.ownerId);
            let self = this;
            fetch('/api/v1/owner/deletewithtree', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {           
                self.closeModal();
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$router.push('/buildingmanager/owners');
            });
        },
        getOwners() {
            let self = this;
            fetch('/api/v1/owner')
            .then(response => { response.json().then(
                function(data) {
                    self.owners = data.data;
                    self.owners = sortBy(self.owners, 'name');
                });
            });
        },
        searchOwners() {
            this.tempOwners = [];
            if (this.ownerSearchTerm.length > 1) {
                let searchTerm = this.ownerSearchTerm.toLowerCase();
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    let ownerName = owner.name.toLowerCase();
                    if (ownerName.includes(searchTerm)) {
                        this.tempOwners.push(owner);
                    }
                    if (!this.tempOwners.length) {
                        this.ownersNoResults = true;
                    }
                    else {
                        this.ownersNoResults = false;
                    }
                }
            }
            else {
                this.ownersNoResults = false;
            }
        },
        selectOwner(owner) {
            this.ownerSelectError = false;
            this.selectedOwner = owner;
            this.ownersDropdownToggled = false;
        }
    }
}
</script>
