<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fab fa-whatsapp" style="margin-top: 0;"></i>{{profile.accountPage.account.add_default_message}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div style="overflow: auto; max-height: 500px; padding-bottom: 1px;">
                    <p>{{profile.accountPage.account.add_default_message_tooltip}}.</p>
                    <input type="text" data-testid="defaultSearchInput" v-model="searchTerm" class="form-control" :placeholder="profile.accountPage.account.search_wa_message" style="margin: 10px 0 15px 0;" @input="search()"  />
                    <div v-if="searchActive">
                        <span v-if="!defaultMessagesSearch.length"><b>{{ profile.dashboard.building_manager.no_resultss }}.</b></span>
                        <div v-for="message in defaultMessagesSearch" :key="message.id_predefined_wa_message" class="mandate select" @click="selectMessage(message)">
                            <div>
                                <h6 data-testid="defaultTitleText" style="margin-bottom: 10px;">{{message.title}}</h6>
                                <p data-testid="DefaultMessageText">{{ message.message }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-for="message in defaultMessages" :key="message.id_predefined_wa_message" class="mandate select" @click="selectMessage(message)">
                            <div>
                                <h6 style="margin-bottom: 10px;">{{message.title}}</h6>
                                <p>{{ message.message }}</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            defaultMessages: [],
            defaultMessagesSearch: [],
            searchTerm: '',
            searchActive: false
        }
    },
    created() {
        this.getWaMessages();
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getWaMessages() {
            this.defaultMessages = [];
            this.newMessageModalOpen = false;
            let self = this;
            fetch('/api/v1/predefinedwamessage/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.defaultMessages = data;
                });
            });
        },
        selectMessage(message) {
            this.$emit('messageSelected', message);
        },
        search() {
            let searchTerm = this.searchTerm.toLowerCase();
            this.defaultMessagesSearch = [];
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.defaultMessages.length; i++) {
                    let message = this.defaultMessages[i];
                    if (message.title.toLowerCase().includes(searchTerm) || message.message.toLowerCase().includes(searchTerm)) {
                        this.defaultMessagesSearch.push(message);
                    }
                }
            }
            else {
                this.searchActive = false;
                this.defaultMessagesSearch = [];
            }
            console.log(this.searchActive);
        }
    }
}
</script>
