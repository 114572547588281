import { EventBus } from '@/js/event-bus.js';

export const setLocale = {
    data() {
        return {
            profile: {},
            locale: '',
            industry: ''
        }
    },
    created() {
        this.industry = localStorage.getItem('S1-industry');
        if (!this.industry) {
            let self = this;
            fetch('/api/v1/repaircompanystats')
            .then(response => { response.json().then(
                function(data) {
                    self.industry = data.repair_company.industry_constant.toLowerCase();
                    self.industry = self.industry.replace(/\s/g, '');
                    if (self.industry === 'vvemanagement') {
                        self.industry = 'housing';
                    }
                    if (self.locale) {
                        self.profile = require('../assets/locales/' + self.locale + '/' + self.industry + '.json');
                    }
                    else {
                        self.profile = require('../assets/locales/nl/' + self.industry + '.json');
                    }
                });
            });
        }
        this.locale = localStorage.getItem('S1-locale');

        if (!this.locale) {
            this.locale = 'nl';
        }
        if (window.location.href.includes('login=true')) {
            if (window.location.href.includes('locale')) {
                if (this.getUrlParameter('locale') === 'en') {
                    this.locale = 'en';
                    localStorage.setItem('S1-locale', 'en');
                }
            }
        }
        if (window.location.href.includes('login=true')) {
            if (!window.location.href.includes('locale')) {
                this.locale = 'nl';
                localStorage.setItem('S1-locale', 'nl');
            }
        }
        if (this.industry && this.locale) {
            this.industry = this.industry.toLowerCase();
            this.industry = this.industry.replace(/\s/g, '');
            if (this.industry === 'vvemanagement') {
                this.industry = 'housing';
            }
            this.profile = require('../assets/locales/' + this.locale + '/' + this.industry + '.json');
        }

        EventBus.$on('langChange', busObj => {
            this.locale = busObj.locale;
            this.profile = {};
            if (busObj.industry === 'vvemanagement') {
                busObj.industry = 'housing';
            }
            this.profile = require('../assets/locales/' + busObj.locale + '/' + busObj.industry + '.json');
        });
    },
    methods: {
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        }
    }
}
