<template>
    <div class="modal-wrapper" v-if="profile.repair_companies">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-file-excel"></i>{{profile.repair_companies.repaircompanytable.importrepaircompanies}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <h4>{{profile.repair_companies.repaircompanytable.upload}}</h4>
                <p class="notification notification--error" v-if="feedbackMsg" v-html="feedbackMsg"></p>
                <br v-if="feedbackMsg" />
                <p>{{profile.places.uploadinstruction_not_req}} {{profile.repair_companies.repaircompanytable.uploadexample}}
                {{profile.repair_companies.repaircompanytable.uploadextra}}<br />
                <a href="../import-repaircompanies-example.csv" download>{{profile.places.csvexample}}</a>
                </p>
                <form v-on:submit.prevent="importRepairCompanies()">
                    <div class="form-field-upload">
                        <label>{{profile.places.csvfile}}</label>
                        <input type="file" @change="fileChange" />
                    </div>
                    <button type="submit" class="button button--icon"><i class="fas fa-file-excel"></i>{{profile.repair_companies.repaircompanytable.importrepaircompanies}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    name: 'ImportRepairCompaniesModal',
    data() {
        return {
            owners: [],
            fields: {
                file: null,
            },
            success: false,
            feedbackMsg: '',
            error: true
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        fetch('/api/v1/owner/bybuildingmanager')
        .then(response => { response.json().then(
            function(data) {
                self.owners = data.data;
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        importRepairCompanies() {
            let self = this;
            const data = new FormData();
            data.append('file', this.fields.file[0]);
            fetch('/api/v1/repaircompanyupload/upload', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                let colErrorsText = data.colErrors.replaceAll(';', ',');
                var colErrors = data.colErrors && data.colErrors !== "" ? self.profile.places.upload_result_colErrors + colErrorsText + "<br/>" : "";
                var kvkExists    = data.kvkAlreadyExists && data.kvkAlreadyExists !== "" ? self.profile.places.upload_result_kvkAlreadyExists + data.kvkAlreadyExists + "<br/>" : "";
                var emailExists    = data.emailAlreadyExists && data.emailAlreadyExists !== "" ? self.profile.places.upload_result_emailAlreadyExists + data.emailAlreadyExists + "<br/>" : "";
                var kvkTooLong =  data.kvkNumberTooLong && data.kvkNumberTooLong !== "" ? self.profile.places.upload_result_kvkTooLong + data.kvkNumberTooLong + "<br/>" : "";
                var errors    = data.errors && data.errors !== "" ? self.profile.places.upload_result_errors + data.errors : "";
                self.feedbackMsg =  colErrors + kvkExists + kvkTooLong + emailExists + errors;
                if (data.successes && data.successes !== "") {
                    self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                    self.closeModal();
                }
            });
        }
    }
}
</script>
