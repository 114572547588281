<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-link"></i>{{profile.incident_detail.copies_popup}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{{profile.incidents.incidenttable.datetime}}</th>
                            <th scope="col">{{profile.incidents.incidenttable.description}}</th>
                            <th scope="col">{{profile.incidents.incidenttable.status}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="incidents.copiedFrom && incidents.copiedFrom.length" @click="toIncident(incidents.copiedFrom[0].id_ori_incident)">
                            <td>{{incidents.copiedFrom[0].created}}</td>
                            <td>{{incidents.copiedFrom[0].id_ori_incident}} - {{incidents.copiedFrom[0].description}}</td>
                            <td v-if="!profile['statusses'][incidents.copiedFrom[0].status].buildingmanager">{{profile['statusses'][incidents.copiedFrom[0].status]}}</td>
                            <td v-if="profile['statusses'][incidents.copiedFrom[0].status].buildingmanager">{{profile['statusses'][incidents.copiedFrom[0].status].buildingmanager}}</td>
                        </tr>
                        <tr @click="toIncident(incidents.incident.id_incident)" v-if="incidents.incident">
                            <td>{{incidents.incident.created}}</td>
                            <td class="indented"><i class="fas fa-level-up-alt" style="transform: rotate(90deg); margin-right: 5px;"></i> {{incidents.incident.id_incident}} - {{incidents.incident.description}}</td>
                            <td v-if="!profile['statusses'][incidents.incident.status].buildingmanager">{{profile['statusses'][incidents.incident.status]}}</td>
                            <td v-if="profile['statusses'][incidents.incident.status].buildingmanager">{{profile['statusses'][incidents.incident.status].buildingmanager}}</td>
                        </tr>
                        <tr v-for="(item, index) in incidents.copiedTo" :key="index" @click="toIncident(item.id_copy_incident)">
                            <td>{{item.created}}</td>
                            <td class="double-indented"><i class="fas fa-level-up-alt" style="transform: rotate(90deg); margin-right: 5px;"></i> {{item.id_copy_incident}} - {{item.description}}</td>
                            <td v-if="!profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status]}}</td>
                            <td v-if="profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status].buildingmanager}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
export default {
    mixins: [setLocale],
    name: 'connectedIncidentModal',
    data() {
        return {
            profile: {},
            incidents: []
        }
    },
    props: ['incidentInfo'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        fetch('/api/v1/incidentcopy/byincident?id_incident=' + this.incidentInfo.id_incident)
        .then(response => { response.json().then(
            function(data) {
                console.log(data);
                self.incidents = data;
                self.incidents.incident.created = new Date(self.incidents.incident.created);
                self.incidents.incident.created = moment(self.incidents.incident.created).format('DD/MM/YYYY H:MM');
                if (self.incidents.copiedFrom.length) {
                    self.incidents.copiedFrom[0].created = new Date(self.incidents.copiedFrom[0].created);
                    self.incidents.copiedFrom[0].created = moment(self.incidents.copiedFrom[0].created).format('DD/MM/YYYY H:MM');
                }
                for (var i = 0; i < self.incidents.copiedTo.length; i++) {
                    let item = self.incidents.copiedTo[i];
                    item.created = new Date(item.created);
                    item.created = moment(item.created).format('DD/MM/YYYY H:MM');
                }
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        toIncident(id) {
            this.$router.replace({ path: 'incident-detail', query: { id: id} })
        }
    }
}
</script>
