<template>
    <div class="main-content" v-if="profile.owners">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="block" v-for="item in news" :key="item.id_news_message" :class="{warning: item.type === 'WARNING'}">
                        <span class="date" data-testid="newsDateText" style="background: #6a6a6f; color: white; font-weight: 500; padding: 5px 8px; border-radius: 3px; float: left; margin-bottom: 10px;">{{ item.date }}</span>
                        <h4 data-testid="newsTitleText" style="clear: both; padding-top: 10px; display: block;">{{ item.title }}</h4>
                        <div v-html="item.body" class="news" data-testid="newsDescriptionText"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            news: [] 
        }
    },
    created() {
        this.getNews();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getNews() {
            let self = this;
            fetch('/api/v1/newsmessage/all')
            .then(response => { response.json().then(
                function(data) {
                   for (var i = 0; i < data.length; i++) {
                        let newsItem = data[i];
                        newsItem.date = moment(newsItem.created).locale('nl').format('D MMMM YYYY');
                        if (newsItem.for_role === 'BUILDINGMANAGER' || newsItem.for_role === 'ALL') {
                            self.news.push(newsItem);
                        }
                    }
                    self.news = sortBy(self.news, 'created', 'asc');
                    self.news.reverse();
                });
            });
        }
    }
}
</script>
