<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-photo-video"></i> {{profile.invoicing.add_invoice}} ({{ incidentId }})</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" data-testid="commissionInvoiceCancel" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p style="margin-bottom: 20px;">{{profile.invoicing.upload_invoice_expla}}</p>
                <div class="row" v-if="incidentInfo.invoice_value_supplier || incidentInfo.invoice_number_supplier">
                    <div class="col-sm-6" v-if="incidentInfo.invoice_value_supplier">
                        <label>{{profile.invoicing.invoice_amount}}</label>
                        <p style="font-size: 16px; margin-bottom: 10px;">{{incidentInfo.invoice_value_supplier}}</p>
                    </div>
                    <div class="col-sm-6" v-if="incidentInfo.invoice_number_supplier && incidentInfo.invoice_number_supplier !== '0'">
                        <label>{{profile.invoicing.invoice_number}}</label>
                        <p style="font-size: 16px; margin-bottom: 10px;">{{incidentInfo.invoice_number_supplier}}</p>
                    </div>
                </div>
                <form v-on:submit.prevent="uploadMedia()" v-if="!loading">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-field focus">
                                <label>{{profile.accountPage.account.amount}} *</label>
                                <input type="text" class="form-control" data-testid="commissionInvoiceAmount" v-model="fields.amount" @keyup="validateAmount()" required>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field focus">
                                <label>{{profile.invoicing.invoice_number}} *</label>
                                <input type="text" class="form-control" data-testid="commissionInvoiceNumber" v-model="fields.number" required>
                            </div>
                        </div>
                    </div>
                    <div class="form-field-upload">
                        <input type="file" data-testid="commissionInvoiceUpload" @change="fileChange" required />
                    </div>
                    <span class="notification notification--error" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_error}}</span>
                    <button type="submit" class="button button--icon" data-testid="commissionInvoiceSubmit"><i class="fas fa-file-pdf"></i>{{profile.invoicing.add_invoice}} ({{ incidentId }})</button>
                </form>
                <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';

export default {
    mixins: [setLocale],
    name: 'UploadQuotationModal',
    data() {
        return {
            fields: {
                file: null,
                amount: null,
                number: ''
            },
            uploadError: false,
            loading: false,
            invoices: []
        }
    },
    props: ['incidentId', 'incidentInfo'],
    created() {
        if (this.incidentInfo.invoice_value_supplier) {
            this.fields.amount = this.incidentInfo.invoice_value_supplier;
        }
        if (this.incidentInfo.invoice_value_supplier) {
            this.fields.number = this.incidentInfo.invoice_number_supplier;
        }
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        uploadMedia() {
            this.uploadError = false;
            if (this.fields.file) {
                for (var i = 0; i < this.fields.file.length; i++) {
                    let file = this.fields.file[i];
                    if (file.type === 'application/pdf') {
                        this.loading = true;
                        const data = new FormData();
                        let filename = 'factuur-' + this.incidentId + '.pdf';
                        data.append('file', file);
                        data.append('filename', filename);
                        data.append('id_incident', this.incidentId);
                        data.append('filetype', 'invoice_pdf_supplier');
                        let self = this;
                        fetch('/api/v1/fileupload/uploadincidentpdf', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            if (!data.url) {
                                self.uploadError = true;
                            }
                            else {
                                self.saveInvoiceAmount(data.id_file_upload);
                            }
                        });
                    }
                    else {
                        this.uploadError = true;
                    }
                }
            }
        },
        validateAmount() {
            this.fields.amount = this.fields.amount.replace(/[A-Za-z!@#$%^&*()]/g, '');
        },
        saveInvoiceAmount(fileId) {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            if (this.fields.amount) {
                this.fields.amount = this.fields.amount.replaceAll(',', '.');
            }
            data.append('invoice_value_supplier', this.fields.amount);
            data.append('invoice_number_supplier', this.fields.number);
            data.append('id_file_upload', fileId);
            let self = this;
            fetch('/api/v1/order/addsupplierinvoice', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.getInvoices();
            });
        },
        getInvoices() {
            let self = this;
            fetch('/api/v1/order/getsupplierinvoices?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    if (data) {
                        let invoices = [];
                        for (var i = 0; i < data.length; i++) {
                            let invoice = data[i];
                            if (self.locale === 'nl') {
                                invoice.date = moment(invoice.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                                invoice.description = 'Factuur-' + invoice.invoice_number;
                            }
                            else {
                                invoice.date = moment(invoice.created).format('MMM D YYYY HH:mm:ss');
                                invoice.description = 'Invoice-' + invoice.invoice_number;
                            }
                            invoices.push(invoice);
                        }
                        // For now we only support one invoice, we need to update this later
                        self.invoices = invoices;
                        self.loading = false;
                        self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                        self.$store.dispatch('updateInvoices', self.invoices);
                        self.closeModal();
                        self.$emit('success');
                    }                 
                });
            });
        }
    }
}
</script>
