<template>
    <div>
        <div>
            <div class="row">
                <div class="col-sm-9">
                    <section class="search search-inpage" :class="{active: searchActive}">
                        <form>
                            <input type="text" ref="search" v-bind:placeholder="profile.actionable.search_street" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="search($event)"  />
                            <span class="close-suggestions" @click="clearSearch()" v-if="searchActive"><i class="fas fa-times"></i></span>
                        </form>
                    </section>
                </div>
            </div>
            <div class="row" v-if="searchActive">
                <div class="col">
                    <h4 class="result-counter" v-if="!recurringJobs.length">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                    <h4 class="result-counter" v-if="recurringJobs.length >= 1">{{recurringJobs.length }} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="switch-wrapper" style="margin-bottom: 20px;">
                        <label class="switch" style="float: left;" v-tooltip.right="profile.actionable.automatic_incident_create_expla">
                            <input type="checkbox" v-model="automated" @change="saveAutomaticCreate()">
                            <span class="slider round"></span>
                        </label>
                        <span  v-tooltip.right="profile.actionable.automatic_incident_create_expla" style="display: block;">{{profile.actionable.automatic_incident_create}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <table class="table table-striped" v-if="recurringJobs.length">
                        <thead>
                            <tr>
                                <th scope="col" colspan="2" @click="sortTable('asset_name')" :class="{active: listsSortType === 'name'}">{{profile.incidents.new_incident.id_asset}} <i class="fas fa-sort"></i></th>
                                <th scope="col" colspan="2" @click="sortTable('description')" :class="{active: listsSortType === 'description'}">{{profile.actionable.action}} <i class="fas fa-sort"></i></th>
                                <th scope="col" colspan="2" @click="sortTable('next_action_date')" :class="{active: listsSortType === 'next_action_date'}">{{profile.actionable.first_action_date}} <i class="fas fa-sort"></i></th>
                                <th scope="col" colspan="2" @click="sortTable('next_action_date')" :class="{active: listsSortType === 'next_action_date'}">{{profile.actionable.days_till_action}} <i class="fas fa-sort"></i></th>
                                <th scope="col" colspan="3"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="job in recurringJobs" :key="job.id_recurring_action">
                                <td colspan="2"><b>{{job.asset_name}}</b><br />{{job.address}} {{job.house_number}}, {{profile.incident_detail.floor_name}} {{job.floor_name}} <br />{{job.place_name}}</td>
                                <td colspan="2" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{job.description}}</td>
                                <td colspan="2">{{ job.next_action_date_full }}</td>  
                                <td colspan="2">
                                    <span v-if="job.overtime" style="color: #bd0b0b;">{{ job.day_diff }}</span>
                                    <span v-else style="color: #00C900;">{{ job.day_diff }}</span>
                                </td>  
                                <td colspan="3" style="position: relative;">
                                    <div class="edit-button icon" style="margin: 10px 0 0 0;" @click="removeQueueAction(job)"><i class="far fa-trash-alt"></i></div>
                                    <div class="edit-button icon d-sm-block d-md-none" style="margin: 10px 40px 0 0;" @click="createIncident(job)"><i class="fas fa-plus"></i></div>
                                    <div class="button button--small button--outline icon d-none d-md-block" style="margin: 0 0 0 0;" @click="createIncident(job)"><i class="fas fa-plus"></i> {{profile.actionable.create_incident_queue}}</div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <RemoveFromQueueModal v-if="removeFromQueueModalOpen" :job="selectedJob" @close="removeFromQueueModalOpen = false" @success="getRecurringJobs()" />
        <CreateFromQueueModal v-if="createFromQueueModalOpen" :job="selectedJob" @close="createFromQueueModalOpen = false" @success="getRecurringJobs()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import moment from 'moment';
import RemoveFromQueueModal from "@/components/modals/recurring/RemoveFromQueueModal.vue";
import CreateFromQueueModal from "@/components/modals/recurring/CreateFromQueueModal.vue";

export default {
    mixins: [setLocale],
    components: {
        RemoveFromQueueModal,
        CreateFromQueueModal
    },
    data() {
        return {
            profile: {},
            recurringJobs: [],
            listsSortType: 'next_action_date',
            selectedJob: {},
            searchActive: false,
            searchTerm: '',
            removeFromQueueModalOpen: false,
            createFromQueueModalOpen: false,
            sortOrder: 'asc',
            today: null,
            automated: null
        }
    },
    computed: mapState(['recurringJobsGet']),
    watch: {
        recurringJobsGet() {     
            this.getRecurringJobs();
        } 
    },
    created() {
       this.getSetting();
       this.getRecurringJobs();
       let today = new Date();
       this.today = moment(today).format('YYYY-MM-DD');
    },
    methods: {
        getSetting() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.automated = data.recurring_job_automated;
                });
            });
        },
        search($event) {
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                this.getRecurringJobs();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.getRecurringJobs();
            }
            if($event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.getRecurringJobs();
            }
        },
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.getRecurringJobs();
        },
        getRecurringJobs: debounce(function (e) {
            this.removeFromQueueModalOpen = false;
            this.createFromQueueModalOpen = false;
            this.recurringJobs = [];
            let orderParam = '?sort_dir=' + this.sortOrder;
            let sortParam = '';
            if (this.listsSortType) {
                sortParam = '&sort_col=' + this.listsSortType;
            }
            let searchParam = '';
            if (this.searchTerm) {
                searchParam = '&search_term=' + this.searchTerm;
            }
            let self = this;
            fetch('/api/v1/recurringaction/searchnice' + orderParam + sortParam + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    self.recurringJobs = data.data;
                    for (var i = 0; i < self.recurringJobs.length; i++) {
                        let job = self.recurringJobs[i];
                        let date = moment(job.next_action_date).format('YYYY-MM-DD');
                        date = moment(date);
                        job.day_diff = date.diff(self.today, 'days');
                        if (job.day_diff.toString().includes('-')) {
                            job.overtime = true;
                        }
                        else {
                            job.overtime = false;
                        }
                        if (job.next_action_date) {
                            if (self.locale === 'nl') {
                                job.next_action_date_full = moment(job.next_action_date).locale('nl').format('D MMMM YYYY');
                            }
                            if (self.locale === 'en') {
                                job.next_action_date_full = moment(job.next_action_date).locale('en').format('MMMM D YYYY');
                            }
                        } 
                    }
                    if (self.recurringJobs.filter(e => e.day_diff.toString().includes('-')).length > 0) {
                        self.$store.dispatch('updateOvertime', self.recurringJobs.filter(e => e.day_diff.toString().includes('-')).length);
                    }
                    else {
                        self.$store.dispatch('updateOvertime', 0);
                    }
                });
            });
        }, 500),
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.listsSortType = sorter;
            this.getRecurringJobs();
        },
        removeQueueAction(job) {
            this.selectedJob = job;
            this.removeFromQueueModalOpen = true;
        },
        createIncident(job) {
            this.selectedJob = job;
            this.createFromQueueModalOpen = true;
        },
        saveAutomaticCreate() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][recurring_job_automated]', this.automated);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
