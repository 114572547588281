<template>
    <div>
        <div class="main-content" v-if="profile.accountPage">
            <div class="container">
                <div class="tabs">
                    <div class="tab" data-testid="accountHeaderGeneral" @click="currentTab = 'general'" :class="{active: currentTab === 'general'}">
                        <h3>{{profile.accountPage.account.general}}</h3>
                    </div>
                    <div class="tab" data-testid="accountHeaderEmployees" @click="currentTab = 'employees_teams'" :class="{active: currentTab === 'employees_teams'}">
                        <h3>{{profile.accountPage.account.employees_teams}}</h3>
                    </div>
                    <div class="tab" data-testid="accountHeaderInvoice" @click="currentTab = 'invoicing'" :class="{active: currentTab === 'invoicing'}">
                        <h3>{{profile.accountPage.account.invoicing}}</h3>
                    </div>
                    <div class="tab" data-testid="accountHeaderAuto" @click="currentTab = 'autodispatch'" :class="{active: currentTab === 'autodispatch'}">
                        <h3>Autodispatch</h3>
                    </div>
                    <div class="tab" data-testid="accountHeaderHours" @click="currentTab = 'office_hours'" :class="{active: currentTab === 'office_hours'}">
                        <h3>{{profile.accountPage.account.office_hours}}</h3>
                    </div>
                    <div class="tab" data-testid="accountHeaderOther" @click="currentTab = 'other'" :class="{active: currentTab === 'other'}">
                        <h3>{{profile.accountPage.account.other}}</h3>
                    </div>
                    <div class="tab" data-testid="accountHeaderPremium" @click="currentTab = 'premium'" :class="{active: currentTab === 'premium'}" v-if="!accountInfo.can_owner_chat || !accountInfo.updates_via_wa_active || !accountInfo.has_actionable_work || !accountInfo.recurring_job_module || !accountInfo.wants_autodispatch || !accountInfo.has_mandates || !accountInfo.has_agreement_flow || !accountInfo.outside_office_hours_extra">
                        <h3>Premium</h3>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'general'}">
                    <div class="block block--account" style="margin-bottom: 0;">
                        <div class="row" v-if="!loading">
                            <div class="col">
                                <div class="profile-card" style="border-right: 0; margin-bottom: 20px;">
                                    <div class="row">
                                        <div class="col-3">
                                            <div class="profile-pic-wrapper">
                                                <span class="edit" @click="editLogo()" data-testid="editLogoButton" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="fas fa-pen"></i></span>
                                                <span class="seperator" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'">|</span>
                                                <span class="remove" @click="removeLogo()" data-testid="removeLogoButton" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="far fa-trash-alt"></i></span>
                                                <img :src="accountInfo.profile_image_loc" class="profile-pic" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'" />
                                                <span class="add" @click="editLogo()" v-if="!accountInfo.profile_image_loc || accountInfo.profile_image_loc === '/images/no-profile-img.png'"><i class="fas fa-plus"></i></span>
                                                <div class="profile-pic placeholder" v-if="!accountInfo.profile_image_loc || accountInfo.profile_image_loc === '/images/no-profile-img.png'">
                                                    <i class="far fa-image"></i>
                                                </div>
                                                <input type="file" id="profile-image-upload" style="display: none;" @change="uploadLogo($event)"/>
                                            </div>
                                        </div>
                                        <div class="col-9">
                                            <h2 style="float: none;">{{accountInfo.building_manager_name}}</h2>
                                            <h3 style="color: #9d9797; margin-top: 5px;">{{userName}}</h3>
                                        </div>
                                    </div>
                                    <span class="notification notification--error" v-if="uploadError" style="margin-top: 20px;">{{profile.accountPage.account.upload_error}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="block block--account block--border">
                                    <div class="account-info">
                                        <h4>{{profile.accountPage.subscription.my_subscription}}</h4>
                                        <div class="account-contents" v-if="paid">
                                            <div class="sub-row">
                                                <b>{{profile.s1mone.stickertable.total}}</b>
                                                <span>{{subscription.total}}</span>
                                            </div>
                                            <div class="sub-row">
                                                <b>{{profile.s1mone.stickertable.inactive_stickers}}</b>
                                                <span>{{subscription.inactiveCoupledTotal}}</span>
                                            </div>
                                            <div class="sub-row" v-if="subscription.total">
                                                <b>{{profile.s1mone.stickertable.coupled_stickers}}</b>
                                                <span>{{subscription.coupledTotal}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="block block--incident_detail location block--border" style="min-height: 0; overflow: hidden;">
                                    <section class="incident-head">
                                        <h1 style="max-width: 100%;">{{profile.accountPage.account.need_service}}</h1>
                                    </section>
                                    <img class="qr-code" :src="'/open-api/v1/buildingmanager/qrcodeasimage?qr_code=' + serviceQr" v-tooltip.right="profile.accountPage.account.service_qr" />
                                    <a :href="'/open/onboarding/?qrcode=' + serviceQr" target="blank" class="button button-qr-code" style="text-decoration: none;"><i class="fas fa-concierge-bell" style="margin-right: 10px;"></i>{{profile.accountPage.account.ask_service}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <MainBlock :accountInfo="accountInfo" @getAccountInfo="getAccountInfo" />
                            </div>
                            <div class="col-sm-6">
                                <div class="block block--account block--border">
                                    <div class="account-info">
                                        <h4>Downloads</h4>
                                        <span class="button button--small button--icon" @click="excelModalOpen = true" data-testid="reportOpenButton" style="margin-bottom: 10px;"><i class="fas fa-file-excel"></i>{{profile.dashboard.building_manager.request_stats}}</span>
                                        <span class="button button--small button--icon" @click="invoiceExcelModalOpen = true" style="margin-bottom: 10px;"><i class="fas fa-file-excel"></i>{{profile.dashboard.building_manager.request_invoices}}</span>
                                        <a href="/api/v1/ownerincidentcategoryrepaircompany/asexcel" class="button button--small button--icon" target="_blank"><i class="fas fa-file-excel"></i>{{profile.dashboard.building_manager.preferred_supplier_dl}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                        <CompanyBlock :accountInfo="accountInfo" :vve="vve" />
                        <MyInfoBlock :accountInfo="accountInfo" :userName="userName" />
                    </div>
                </div>   
                <div class="tab-view" :class="{active: currentTab === 'employees_teams'}">      
                    <EmployeesBlock :accountInfo="accountInfo" />
                    <ServiceTeamBlock :accountInfo="accountInfo" :mechanics="mechanics" :specialisms="specialisms" :admin="accountInfo.building_manager_admin" @getAccountInfo="getAccountInfo" @getMechanics="getMechanics" />
                </div>   
                <div class="tab-view" :class="{active: currentTab === 'invoicing'}">  
                    <InvoicingBlock :accountInfo="accountInfo" v-if="paid" />    
                    <MandatesBlock :accountInfo="accountInfo" v-if="accountInfo.has_mandates" />
                    <DefaultInvoicePartyBlock :accountInfo="accountInfo" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'autodispatch'}">  
                    <div class="block block--account block--border" v-if="accountInfo.can_autodispatch" id="autodispatchBlock" style="margin-bottom: 0;">
                        <div class="account-info">
                            <h4>{{profile.accountPage.account.autodispatch}} <div class="premium-label" v-tooltip.right="profile.accountPage.account.payed_functionality"><i class="fas fa-euro-sign"></i> Premium</div></h4>
                            <div class="switch-wrapper top-right" v-if="accountInfo.building_manager_admin">
                                <label class="switch">
                                    <input type="checkbox" v-model="accountInfo.wants_autodispatch" @change="toggleAutoDispatch()">
                                    <span class="slider round" data-testid="autoSwitchOnOff"></span>
                                </label>
                            </div>
                            <AutoDispatch :accountInfo="accountInfo" @save="getAccountInfo()" :admin="accountInfo.building_manager_admin" v-if="accountInfo.wants_autodispatch && paid" />
                        </div>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'office_hours'}">  
                    <OfficeHoursBlock :accountInfo="accountInfo" :officeHoursOn="officeHoursOn" :officeHours="officeHours" :oooPhone="accountInfo.ooophone" :admin="accountInfo.building_manager_admin" v-if="paid" />
                    <!-- Outside office hours feature -->
                    <div class="block block--account block--border" v-if="accountInfo.outside_office_hours_extra && accountInfo.building_manager_admin && paid" style="margin-bottom: 0;">
                        <div class="account-info">
                            <h4>{{profile.accountPage.account.notify_costs_feature}}<div class="premium-label" v-tooltip.right="profile.accountPage.account.payed_functionality"><i class="fas fa-euro-sign"></i> Premium</div>
                                <span class="edit-button edit-button--small" data-testid="extraEditButton" @click="officeHoursCostEdit = true" v-if="!officeHoursCostEdit && this.accountInfo.building_manager_admin"><i class="fas fa-pen"></i></span>
                                <span class="edit-button edit-button--small" data-testid="extraSaveButton" @click="saveOfficeHoursCost()" v-if="officeHoursCostEdit"><i class="fas fa-save"></i></span>
                            </h4>
                            <div v-if="officeHoursCostEdit">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div style="position: relative;">
                                            <label>{{profile.accountPage.account.amount}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.amount_tooltip" style="cursor: pointer;"></i></label>
                                            <div class="currency">EUR</div>
                                            <input type="text"  class="form-control" data-testid="extraAmountInput" v-model="officeHours.outside_office_hours_extra_cost" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{profile.accountPage.account.pricing_page}}</label>
                                        <input type="text" class="form-control" data-testid="extraLinkInput" placeholder="https://www.uwpagina.nl" v-model="officeHours.outside_office_hours_extra_link" />
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div style="position: relative;">
                                            <label>{{profile.accountPage.account.amount}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.amount_tooltip" style="cursor: pointer;"></i></label>
                                            <p data-testid="extraAmountText">{{officeHours.outside_office_hours_extra_cost}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{profile.accountPage.account.pricing_page}}</label>
                                        <a :href="officeHours.outside_office_hours_extra_link" data-testid="extraLinkText" target="blank" style="font-size: 14px;">{{officeHours.outside_office_hours_extra_link}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'other'}">  
                    <WithHoldTenants :accountInfo="accountInfo" :admin="accountInfo.building_manager_admin" @getAccountInfo="getAccountInfo" v-if="vve" />
                    <AgreementFlowBlock :accountInfo="accountInfo" :admin="accountInfo.building_manager_admin" @getAccountInfo="getAccountInfo" v-if="accountInfo.has_agreement_flow" />
                    <UnfinishedIncidents :accountInfo="accountInfo" :admin="accountInfo.building_manager_admin" />
                    <div class="block block--account block--border">
                        <div class="account-info">
                            <h4>Master S1-code</h4>
                        </div>
                        <img id="qr-code" style="width: 220px; border-radius: 5px; border: 1px solid #ececec;" :src="'/api/v1/buildingmanager/qrcodeasimage?id_building_manager=' + bmId">
                    </div>
                    <WaMessages :accountInfo="accountInfo" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'premium'}">  
                    <!-- Owner chat (inactive) -->
                    <div class="block block--account block--border inactive" v-if="!accountInfo.can_owner_chat" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive">
                        <div class="account-info">
                            <h4>{{profile.accountPage.account.owner_chat}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                    <!-- Notifications through WhatsApp (inactive) -->
                    <div class="block block--account block--border inactive" v-if="!accountInfo.updates_via_wa_active" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive">
                        <div class="account-info">
                            <h4>{{profile.accountPage.account.notifications_via_wa}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>    
                    <!-- Recordings module (inactive) -->
                    <div class="block block--account block--border inactive" v-if="!accountInfo.has_actionable_work" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive">
                        <div class="account-info">
                            <h4>{{profile.actionable.recordings}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                    <!-- Repeating actions (inactive) -->
                    <div class="block block--account block--border inactive" v-if="!accountInfo.recurring_job_module" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive">
                        <div class="account-info">
                            <h4>{{profile.actionable.repeating_job}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                    <!-- Autodispatch feature -->
                    <div class="block block--account block--border inactive" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive" v-if="!accountInfo.can_autodispatch">
                        <div class="account-info">
                            <h4>{{profile.accountPage.account.autodispatch}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                    <!-- Mandates feature -->
                    <div class="block block--account block--border inactive" v-tooltip.right="profile.accountPage.account.payed_functionality" v-if="!accountInfo.has_mandates">
                        <div class="account-info">
                            <h4>{{profile.mandates.mandates}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                    <!-- Agreementflow feature -->
                    <div class="block block--account block--border inactive" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive" v-if="!accountInfo.has_agreement_flow">
                        <div class="account-info">
                            <h4>{{profile.owners.agreementflow.agreement_flow}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                     <!-- Office hours costs -->
                     <div class="block block--account block--border inactive" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive" v-if="!accountInfo.has_agreement_flow">
                        <div class="account-info">
                            <h4>{{profile.owners.agreementflow.agreement_flow}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                    <!-- Office hours costs -->
                    <div class="block block--account block--border inactive" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive" v-if="!accountInfo.outside_office_hours_extra">
                        <div class="account-info">
                            <h4>{{profile.accountPage.account.notify_costs_feature}}</h4><div class="premium-label"><i class="fas fa-euro-sign"></i> Premium</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ExcelExportModal v-if="excelModalOpen" @close="excelModalOpen = false" />
        <InvoiceExcelExportModal v-if="invoiceExcelModalOpen" @close="invoiceExcelModalOpen = false" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import MainBlock from "@/components/account/MainBlock.vue";
import CompanyBlock from "@/components/account/CompanyBlock.vue";
import InvoicingBlock from "@/components/account/InvoicingBlock.vue";
import MandatesBlock from "@/components/account/MandatesBlock.vue";
import DefaultInvoicePartyBlock from "@/components/account/DefaultInvoicePartyBlock.vue";
import MyInfoBlock from "@/components/account/MyInfoBlock.vue";
import EmployeesBlock from "@/components/account/EmployeesBlock.vue";
import OfficeHoursBlock from "@/components/account/OfficeHoursBlock.vue";
import ServiceTeamBlock from "@/components/account/ServiceTeamBlock.vue";
import AgreementFlowBlock from "@/components/account/AgreementFlowBlock.vue";
import AutoDispatch from "@/components/account/AutoDispatch.vue";
import ExcelExportModal from "@/components/modals/ExcelExportModal.vue";
import InvoiceExcelExportModal from "@/components/modals/InvoiceExcelExportModal.vue";
import WithHoldTenants from "@/components/account/WithHoldTenants.vue";
import UnfinishedIncidents from "@/components/account/UnfinishedIncidents.vue";
import WaMessages from "@/components/account/WaMessages.vue";

export default {
    mixins: [setLocale],
    components: {
        MainBlock,
        CompanyBlock,
        InvoicingBlock,
        MandatesBlock,
        DefaultInvoicePartyBlock,
        MyInfoBlock,
        EmployeesBlock,
        OfficeHoursBlock,
        ServiceTeamBlock,
        AgreementFlowBlock,
        AutoDispatch,
        ExcelExportModal,
        InvoiceExcelExportModal,
        WithHoldTenants,
        UnfinishedIncidents,
        WaMessages
    },
    data() {
        return {
            profile: {},
            loading: false,
            userName: '',
            accountInfo: {},
            subscription: {},
            officeHours: {},
            officeHoursOn: true,
            excelModalOpen: false,
            invoiceExcelModalOpen: false,
            sortOrder: 'asc',
            mechanics: [],
            specialisms: [],
            selectedUser: {},
            uploadError: false,
            updatesEdit: false,
            officeHoursCostEdit: false,
            paid: false,
            currentTab: 'general'
        }
    },
    computed: mapState(['vve', 'bmId', 'serviceQr']),
    created() {
        document.title = 'S1MONE | Account';
        this.getCurrentUser();
        this.getAccountInfo();
        this.getSubscription();
        this.getOpeningHours(); 
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getCurrentUser() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
	        .then(response => { response.json().then(
	            function(data) {
					self.userName = data.username;
				});
	        });
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.paid = data.is_paid_account;
                    self.accountInfo = [];
                    self.accountInfo = data;
                    if (self.accountInfo.building_manager_email_addresses === 'null') {
                        self.accountInfo.building_manager_email_addresses = '';
                    }
                    if (self.accountInfo.building_manager_contact_name === 'null') {
                        self.accountInfo.building_manager_contact_name = '';
                    }
                    if (self.accountInfo.building_manager_correspondence_email === 'null') {
                        self.accountInfo.building_manager_correspondence_email = '';
                    }
                    if (self.accountInfo.building_manager_email === 'null') {
                        self.accountInfo.building_manager_email = '';
                    }
                    if (self.accountInfo.building_manager_mobile === 'null') {
                        self.accountInfo.building_manager_mobile = '';
                    }
                    if (self.accountInfo.building_manager_name === 'null') {
                        self.accountInfo.building_manager_name = '';
                    }
                    if (self.accountInfo.agreement_flow_reminder_minutes) {
                        self.accountInfo.agreement_flow_reminder_hours = self.accountInfo.agreement_flow_reminder_minutes / 60;
                    }
                    if (self.accountInfo.agreement_flow_forced_minutes) {
                        self.accountInfo.agreement_flow_forced_hours = self.accountInfo.agreement_flow_forced_minutes / 60;
                    }
                    for (var i = 0; i < self.accountInfo.repaircompanies.length; i++) {
                        let repaircompany = self.accountInfo.repaircompanies[i];
                        repaircompany.lowerName = repaircompany.name.toLowerCase();
                        self.accountInfo.repaircompanies = sortBy(self.accountInfo.repaircompanies, 'lowerName');
                        if (repaircompany.id_repair_company === self.accountInfo.canbe_id_repair_company) {
                            self.accountInfo.ownRepairCompany = repaircompany;
                            self.accountInfo.canbe_id_repair_company = repaircompany.id_repair_company;
                            self.getMechanics();
                            self.getSpecialisms();
                        }
                    }
                    for (var j = 0; j < self.accountInfo.incidentcategories.length; j++) {
                        let category = self.accountInfo.incidentcategories[j];
                        for (var k = 0; k < self.accountInfo.buildingmanagerincidentcategoryrepaircompanies.length; k++) {
                            let autoCategory = self.accountInfo.buildingmanagerincidentcategoryrepaircompanies[k];
                            if (category.id_incident_category === autoCategory.id_incident_category) {
                                self.accountInfo.incidentcategories.splice(j, 1);
                            }
                        }
                    }
                    if (self.locale === 'nl') {
                        self.accountInfo.incidentcategories = sortBy(self.accountInfo.incidentcategories, 'dutch_name');
                    }
                    if (self.locale === 'en') {
                        self.accountInfo.incidentcategories = sortBy(self.accountInfo.incidentcategories, 'english_name');
                    }
                    console.log(self.accountInfo);
                });
            });
        },
        getSubscription() {
            let self = this;
            fetch('/api/v1/stickerhistory/totalbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.subscription = data;
                });
            });
        },
        getOpeningHours() {
            let self = this;
            fetch('/api/v1/opening_hours')
            .then(response => { response.json().then(
                function(data) {
                    self.officeHours = data;
                    if (self.officeHours.active) {
                        self.officeHoursOn = true;
                    }
                });
            });
        },
        getMechanics() {
            this.mechanics = [];
            let self = this;
            fetch('/api/v1/mechanic')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let mechanic = data.data[i];
                        mechanic.specialismsList = [];
                        mechanic.edit = false;
                        for (const [key, value] of Object.entries(mechanic.specialisms)) {
        					let specialism = {
                                id_specialism: key,
                                name_specialism: value
                            }
                            mechanic.specialismsList.push(specialism);
        				}
                        self.mechanics.push(mechanic);
                    }
                });
            });
        },
        getSpecialisms() {
            let self = this;
            fetch('/api/v1/specialism')
            .then(response => { response.json().then(
                function(data) {
                    self.specialisms = data.data;
                });
            });
        },
        editLogo() {
            if (this.accountInfo.building_manager_admin) {
                document.getElementById('profile-image-upload').click();
            }
        },
        uploadLogo($event) {
            if ($event.target.files.length == 1) {
                this.loading = true;
                const data = new FormData();
                data.append('file', $event.target.files[0]);
                let self = this;
                fetch('/api/v1/fileupload/uploadlogo', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    if (!data.includes('Error')) {
                        self.accountInfo.profile_image_loc = data;
                        self.uploadError = false;
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    }
                    else {
                        self.uploadError = true;
                    }
                    self.loading = false;
                });
            }
        },
        removeLogo() {
            const data = new URLSearchParams();
            let self = this;
            fetch('/api/v1/fileupload/deletelogo', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.accountInfo.profile_image_loc = '';
            });
        },
        toggleAutoDispatch() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][wants_autodispatch]', this.accountInfo.wants_autodispatch);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        canEditWACheck(item) {
            if (!item.mobile) {
                item.notAllowedWA = true;
            }
            else {
                item.notAllowedWA = false;
            }
        },
        saveOfficeHoursCost() {
            this.officeHoursCostEdit = false;
            const data = new URLSearchParams();
            let paramCost = 'data[' + this.officeHours.id_opening_hours + '][outside_office_hours_extra_cost]';
            let paramLink = 'data[' + this.officeHours.id_opening_hours + '][outside_office_hours_extra_link]';
            data.append('action', 'edit');
            data.append(paramCost, this.officeHours.outside_office_hours_extra_cost);
            data.append(paramLink, this.officeHours.outside_office_hours_extra_link);
            let self = this;
            fetch('/api/v1/opening_hours', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
