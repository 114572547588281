<template>
    <div class="modal-wrapper" v-if="profile.incidents">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-plus"></i>{{profile.incidents.new_incident.title}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner" id="inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createNewIncident()" style="overflow: hidden;" v-if="!showAgreementMessage">
                    <h5>{{profile.dashboard.reporter_info}}</h5>
                    <div class="form-field" :class="{focus:fields.name.length, error: nameError, valid: fields.name.length}">
                        <label>{{profile.incidents.new_incident.reporter_name}} *</label>
                        <input type="text" class="form-control" v-model="fields.name" data-testid="inputName" @input="checkName()" ref="name">
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.email.length, valid: validEmail, error: invalidEmail}">
                                <label>{{profile.incidents.new_incident.emailaddress}}</label>
                                <input type="text" class="form-control" data-testid="inputEmail" v-model="fields.email" @input="validateEmail()">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <vue-tel-input v-model="fields.phone" class="form-control" data-testid="inputPhone" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone"></vue-tel-input>
                        </div>
                    </div>
                    <div style="margin-bottom: 20px;" v-if="industry === 'housing' && vve">
                        <label>{{profile.incident_detail.renterOrOwner}}</label>
                        <div class="button-group">
                            <span class="switch-button" id="owner-button" :class="{active: !vveOwner}" @click="vveOwner = false">{{profile.incident_detail.renter}}</span>
                            <span class="switch-button" id="resident-button" :class="{active: vveOwner}" @click="vveOwner = true">{{profile.incident_detail.owner}}</span>
                        </div>
                    </div>
                    <div class="switch-wrapper" :class="{disabled: invalidPhone || !fields.phone.length}">
                        <label class="switch" v-tooltip.right="profile.incidents.new_incident.add_phone" v-if="invalidPhone || !fields.phone.length">
                            <input type="checkbox" v-model="fields.whatsapp" disabled>
                            <span class="slider round" data-testid="switchPhone"></span>
                        </label>
                        <label class="switch" v-else>
                            <input type="checkbox" v-model="fields.whatsapp">
                            <span class="slider round" data-testid="switchWhatsapp"></span>
                        </label>
                        <span><b>{{profile.incidents.new_incident.add_to_whatsapp}}</b> <i class="fas fa-info-circle" v-tooltip.right="profile.incidents.new_incident.no_mobile"></i></span>
                    </div>
                    <div class="notification notification--error" style="margin-top: 20px;" v-if="channelsError">
                        <p>{{profile.incidents.new_incident.channels_error}}</p>
                    </div>
                    <br />
                    <h5>{{profile.accountPage.account.general}}</h5>
                    <p style="margin-bottom: 10px;"><b>{{ profile.incidents.new_incident.search_loc }}</b></p> 
                    <div class="form-field clearable" :class="{focus: placeSearchTerm.length, error: placeError}" style="margin-bottom: 10px;">
                        <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}} *</label>
                        <div>
                            <div class="selected-info seperate" v-if="selectedPlace.name" style="border: 2px solid #00C900;">
                                <h4>{{selectedPlace.name}}</h4>
                                <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                                <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                <span class="general-label" v-if="selectedPlace.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                            </div>
                            <input type="text" ref="place" class="form-control" data-testid="inputLocation" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                        </div>
                    </div>
                    <div class="row" v-if="!selectedPlace.name">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.zipcode.length, error: !validZipCode}">
                                <label>{{profile.places.placetable.zipcode}} *</label>
                                <input type="text" class="form-control" v-model="fields.zipcode" @input="zipCodeSearch()">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.house_number.length}">
                                <label>{{profile.places.placetable.house_number}} *</label>
                                <input type="text" class="form-control" v-model="fields.house_number" @input="zipCodeSearch()">
                            </div>
                        </div>
                    </div>
                    <ul v-if="places.length || generalPlaces.length" class="dropdown-list" style="padding: 0; margin-top: 0; border-radius: 10px; max-height: 400px; overflow: auto;">
                        <li v-for="place in generalPlaces" :key="place.id_place" class="dropdown-list-item" data-testid="listLocation" v-on:click="selectPlace(place)">
                            <h4>{{place.name}}</h4>
                            <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                            <span class="general-label" v-if="place.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                        </li>
                        <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" data-testid="listLocation" v-on:click="selectPlace(place)">
                            <h4>{{place.name}}</h4>
                            <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                        </li>
                    </ul>
                    <hr style="margin-top: 0;" />
                    <div v-if="selectedPlace.id_place">
                        <div class="dropdown-section">
                            <span class="dropdown" data-testid="selectObject" v-on:click="assetsDropdownToggled = !assetsDropdownToggled" :class="{active: assetsDropdownToggled, error: assetError, valid: selectedAssetId}">
                                <span v-if="!selectedAssetId">{{profile.incidents.new_incident.choose_asset}} *</span>
                                <span v-if="selectedAssetId" class="selected">{{selectedAssetName}}</span>
                                <i class="fas fa-chevron-down chevron"></i></span>
                            <div class="dropdown-list" data-testid="listObject" v-if="assetsDropdownToggled" :class="{search: assets.length > 9}">
                                <input type="text" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" placeholder="Zoek een locatienaam..." @keyup="searchAssets()" v-if="assets.length > 9"  />
                                <ul>
                                    <li v-for="asset in assets" v-on:click="selectAsset(asset)" :key="asset.id_asset" v-show="!tempAssets.length && !assetsNoResults">
                                        {{asset.name}}
                                        <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                        <span class="inactive-label" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                                    </li>
                                    <li v-for="(asset, index) in tempAssets" v-on:click="selectAsset(asset)" :key="index" v-show="tempAssets.length">{{asset.name}}</li>
                                    <li v-if="assetsNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dropdown-section">
                                <span class="dropdown" data-testid="selectCategory" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled, error: categoryError, valid: selectedCategory.dutch_name}">
                                    <span v-if="!selectedCategory.id_incident_category">{{profile.incident_detail.category}} *</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{selectedCategory.dutch_name}}</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'en'" class="selected">{{selectedCategory.english_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" v-if="categoryDropdownToggled" :class="{search: categories.length > 9}">
                                    <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" :placeholder="profile.incident_detail.search_category" @keyup="searchCategories()" v-if="categories.length > 9"  />
                                    <ul>
                                        <li v-for="category in categories" v-on:click="selectCategory(category)" :key="category.id_category" v-show="!tempCategories.length && !categoriesNoResults">
                                            <span data-testid="selectCategoryResultNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span data-testid="selectCategoryResultEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-for="(category, index) in tempCategories" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">
                                            <span data-testid="selectCategoryResultIndexNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span data-testid="selectCategoryResultIndexEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-field" :class="{focus:fields.descr.length, error: descriptionError, valid: fields.descr.length}">
                        <label>{{profile.incidents.new_incident.description}} *</label>
                        <textarea class="form-control" v-model="fields.descr" data-testid="inputDescription" @input="checkDescr()"></textarea>
                    </div>
                    <button type="submit" class="button button--icon" data-testid="buttonSubmit" id="incidentSubmit" v-if="!incidentCreate"><i class="fas fa-plus"></i>{{profile.dashboard.building_manager.create_incident}}</button>
                </form>
                <div class="notification notification--warning" data-testid="agreementIncidentText" style="margin-bottom: 5px; width: 100%;" v-if="showAgreementMessage">
                    <p>{{profile.incidents.new_incident.agreement_message}}</p>
                </div>
                <span class="button button--icon" v-if="showAgreementMessage" data-testid="agreementIncidentClose" style="margin-top: 15px; float: right;" @click="getIncidents()">{{profile.incident_detail.back_to_dashboard}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { VueTelInput } from 'vue-tel-input';

export default {
    name: 'newIncidentModal',
    mixins: [setLocale],
    props: ['bm', 'admin', 'twinq', 'vve'],
    components: {
        VueTelInput
    },
    data() {
        return {
            profile: {},
            places: [],
            generalPlaces: [],
            assets: [],
            categories: [],
            fields: {
                category: null,
                name: '',
                email: '',
                phone: '',
                descr: '',
                whatsapp: false,
                reporter_locale: 'nl-NL',
                zipcode: '',
                house_number: ''
            },
            vveOwner: false,
            validPhone: false,
            invalidPhone: true,
            formattedPhone: '',
            validEmail: false,
            invalidEmail: false,
            validZipCode: true,
            addressError: false,
            assetsDropdownToggled: false,
            selectedPlace: {},
            selectedAssetId: 0,
            selectedAssetName: '',
            selectedCategory: {},
            categoryDropdownToggled: false,
            categorySearchTerm: '',
            tempCategories: [],
            categoriesNoResults: false,
            assetsNoResults: false,
            placesNoResults: false,
            tempAssets: [],
            placeSearchTerm: '',
            assetSearchTerm: '',
            nameError: false,
            descriptionError: false,
            placeError: false,
            assetError: false,
            categoryError: false,
            channelsError: false,
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder: '',
                }
            },
            intCode: '',
            incidentCreate: false,
            showAgreementMessage: false
        }
    },
    created() {
        this.bindProps.inputOptions.placeholder = this.profile.accountPage.account.contactmobile;
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        fetch('api/v1/incident_category/bybuildingmanager?id_building_manager=' + this.bm)
        .then(response => { response.json().then(
            function(data) {
                self.categories = data.data;
                self.categories = uniqBy(self.categories, 'id_incident_category');
                for (var i = 0; i <self.categories.length; i++) {
                    let category = self.categories[i];
                    category.dutch_name = category.dutch_name.split('*')[0];
                    category.english_name = category.english_name.split('*')[0];
                    if (category.dutch_name.includes('-2') || category.dutch_name.includes('-3')|| category.dutch_name.includes('-4')) {
                        self.categories.splice(i, 1);
                    }
                }
                if (self.locale === 'nl') {
                    self.categories = sortBy(self.categories, 'dutch_name');
                }
                else {
                    self.categories = sortBy(self.categories, 'english_name');
                }
                if ((window.location.href.includes('localhost') || window.location.href.includes('waldorf')) && self.bm === 70) {
                    self.preFillAll();
                }
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        if (this.vve) {
            this.vveOwner = true;
        }
        else {
            this.vveOwner = false;
        }
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        getAssets() {
            let self = this;
            fetch('/api/v1/asset/activebyplacewithqr?id_place=' + this.selectedPlace.id_place)
            .then(response => { response.json().then(
                function(data) {
                    if (data.data.length) {
                        self.assets = [];
                        self.fields.asset = '';
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (item.name) {
                                self.assets.push(item);
                            }
                        }
                    }
                });
            });
        },
        selectPlace(place) {
            this.places = [];
            this.generalPlaces = [];
            this.selectedPlace = place;
            this.placeSearchTerm = '';
            this.fields.zipcode = '';
            this.fields.house_number = '';
            this.getAssets();
            this.selectedAssetId = 0;
            this.assetsDropdownToggled = false;
            this.placeError = false;
        },
        selectAsset(asset) {
            this.assetsDropdownToggled = false;
            this.selectedAssetName = asset.name;
            this.selectedAssetId = asset.id_asset;
            this.tempAssets = [];
            this.assetSearchTerm = '';
            this.assetError = false;
            const innerDiv = document.getElementById("inner");
            innerDiv.scrollTop = innerDiv.scrollHeight;
        },
        searchPlaces() {
            if (this.placeSearchTerm.length > 2) {
                this.fields.zipcode = '';
                this.fields.house_number = '';
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/bybuildingmanageronlywithassets' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        self.generalPlaces = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            if (place.floor_name === 'VVEVEST') {
                                self.generalPlaces.push(place);
                            }
                            else {
                                self.places.push(place);
                            }
                        }
                        if (!self.places && !self.generalPlaces) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
                this.generalPlaces = [];
            }
        },
        removePlaceSelection() {
            this.selectedPlace = {};
            this.generalPlaces = [];
            this.places = [];
        },
        searchAssets() {
            this.tempAssets = [];
            if (this.assetSearchTerm.length > 1) {
                let searchTerm = this.assetSearchTerm.toLowerCase();
                for (var i = 0; i < this.assets.length; i++) {
                    let asset = this.assets[i];
                    let assetName = asset.name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssets.push(asset);
                    }
                    if (!this.tempAssets.length) {
                        this.assetsNoResults = true;
                    }
                    else {
                        this.assetsNoResults = false;
                    }
                }
            }
            else {
                this.assetsNoResults = false;
            }
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
            this.tempCategories = [];
            this.categorySearchTerm = '';
            this.categoryError = false;
        },
        searchCategories(type) {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryName;
                    if (this.locale === 'en') {
                        categoryName = category.english_name.toLowerCase();
                    }
                    else {
                        categoryName = category.dutch_name.toLowerCase();
                    }
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        createNewIncident() {
            const data = new URLSearchParams();
            data.append('id_asset', this.selectedAssetId);
            data.append('description', this.fields.descr);
            if (this.intCode) {
                data.append('int_code', this.intCode);
            }
            if (!this.fields.phone && !this.fields.whatsapp) {
                this.invalidPhone = false;
            }
            if (this.fields.whatsapp && !this.fields.phone) {
                this.validPhone = false;
            }
            data.append('category', this.selectedCategory)
            data.append('reporter_name', this.fields.name);
            data.append('emailaddress', this.fields.email);
            data.append('id_incident_category', this.selectedCategory.id_incident_category);
            data.append('is_whatsapp', this.fields.whatsapp);
            data.append('reporter_locale', this.locale);
            data.append('phone', this.formattedPhone);
            data.append('reporter_is_owner', this.vveOwner);
            let self = this;
            if (!this.selectedPlace.id_place) {
                this.placeError = true;
            }
            if (!this.fields.name) {
                this.nameError = true;
            }
            if (!this.fields.descr) {
                this.descriptionError = true;
            }
            if (!this.selectedCategory.id_incident_category) {
                this.categoryError = true;
            }
            if (!this.selectedAssetId) {
                this.assetError = true;
            }
            if (this.fields.name && this.fields.descr && !this.invalidEmail & !this.invalidPhone && this.selectedPlace.id_place && this.selectedCategory.id_incident_category && this.selectedAssetId) {
                this.incidentCreate = true;
                fetch('/api/v1/incident/createnewfromdashboard', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    if (data.wa_number === 'NO_MORE') {
                        self.channelsError = true;
                        self.incidentCreate = false;
                    }
                    else {
                        if (self.locale === 'nl') {
                            self.locale = 'nl-NL';
                        }
                        if (self.locale === 'en') {
                            self.locale = 'en-EN';
                        }
                        self.fields = [];
                        self.createdId = data.id_incident;
                        self.$vToastify.success(self.profile.toast.changes_saved, self.profile.dashboard.building_manager.message_table.incident_ID + ' ' + self.profile.toast.is_added);
                        if (data.agreement_flow) {
                            self.showAgreementMessage = true;
                        }
                        else {
                            self.$emit('close');
                            if ((self.twinq && self.admin) || !self.twinq) {
                                setTimeout(function () {
                                    self.$router.push('/buildingmanager/incident-detail?id=' + self.createdId);
                                }.bind(this), 1000)
                            }
                        }
                    }
                });
            }
        },
        validateEmail() {
            if (this.fields.email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.fields.email)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        validatePhone: function (number, object) {
            this.intCode = object.countryCallingCode
            if (object.formatted) {
                if (object.valid) {
                    this.fields.whatsapp = true;
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.fields.whatsapp = false;
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.formattedPhone = '';
                this.fields.whatsapp = false;
                this.validPhone = false;
                this.invalidPhone = false;
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getIncidents() {
            this.$emit('getIncidents');
            document.querySelector('body').classList.remove('modal-open');
        },
        checkName() {
            if (this.fields.name) {
                this.nameError = false;
            }
            else {
                this.nameError = true;
            }
        },
        checkDescr() {
            if (this.fields.descr) {
                this.descriptionError = false;
            }
            else {
                this.descriptionError = true;
            }
        },
        preFillAll() {
            this.fields.name = 'Thomas de Vries';
            this.fields.email = 'thomasdevries87@hotmail.com';
            this.selectedPlace.id_place = 3460;
            this.getAssets();
            this.placeSearchTerm = 'Centraal terrein'
            this.selectedCategory = this.categories[1];
            this.fields.descr = 'Bla bla bla bla bla';
            this.fields.phone = '+31627090259';
            this.validPhone = true;
            this.validEmail = true;
            setTimeout(function () {
                this.selectedAssetId = this.assets[0].id_asset;
                this.selectedAssetName = this.assets[0].name
            }.bind(this), 1000);
        },
        zipCodeSearch() {
            this.placeSearchTerm = '';
            let self = this;
            let zipcodeRegex = new RegExp('^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$');
            this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
            if (this.validZipCode && this.fields.house_number) {
                this.fields.zipcode = this.fields.zipcode.replace(' ', '');
                setTimeout(function () {
                    fetch('/api/v1/place/byzipcodehousenumber?zipcode=' + this.fields.zipcode + '&housenumber=' + this.fields.house_number)
                    .then(response => { response.json().then(
                        function(data) {
                            self.places = [];
                            self.generalPlaces = [];
                            for (var i = 0; i < data.length; i++) {
                                let place = data[i];
                                if (place.floor_name === '0') {
                                    if (self.locale === 'nl') {
                                        place.floor_name = 'BG';
                                    }
                                    else {
                                        place.floor_name = 'GF';
                                    }
                                }
                                if (place.floor_name === 'VVEVEST') {
                                    self.generalPlaces.push(place);
                                }
                                else {
                                    self.places.push(place);
                                }
                            }
                            if (!self.places && !self.generalPlaces) {
                                self.placesNoResults = true;
                            }
                        });
                    });
                }.bind(this), 500);
            }     
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
