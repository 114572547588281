<template>
    <div>
        <div class="chat" :class="{active: chatOpen, enlarged: chatEnlarged}" v-if="showChat && dialogs && dialogs.length">
            <span class="chat-head" data-testid="incidentChatHead" @click="openChat()">
                <i class="fas fa-comments"></i> Chat
                <span class="newchats" v-if="newChats"></span>
            </span>
            <span class="enlarge-chat" @click="chatEnlarged = !chatEnlarged">
                <i class="fas fa-expand-alt"></i>
            </span>
            <div class="chat-body">
                <div class="selected-dialog single" v-if="selectedDialog.nameShown">
                    <div class="dialog-head">
                        <span class="profile-name">
                            <span class="chat-type head">{{profile.incident_detail.incident_building_manager}}</span>
                            <span class="name">
                                <span class="initials">{{servicedeskInitials}}</span>
                                <span style="font-weight: 500;">{{incidentInfo.building_manager_name}}</span>
                            </span>
                        </span>
                    </div>
                    <div class="messages" id="messages">
                        <div class="message" v-for="(message, index) in selectedDialog.messages" :key="index" :class="{received: message.received, sent: message.sent, undelivered: message.status === 'whatsapp_undelivered', mediaText: message.mediaText !== '' && message.mediaText !== ' ' && message.mediaText !== 'null '}" v-show="message.body">                 
                            <img :src="message.body" class="image" data-testid="chatVideoMessage" v-if="message.mediaContentType && message.mediaContentType.includes('image/')" />
                            <video data-testid="chatVideoMessage" v-if="message.mediaContentType && message.mediaContentType.includes('video')" muted loop autoplay><source :src="message.body" type="video/mp4"></video>
                            <p data-testid="chatPdfMessage" v-if="message.isMedia && message.body.includes('.pdf')"><a :href="message.body" style="color: white; text-decoration: none;" target="blank"><i class="fas fa-file-pdf" style="font-size: 24px; margin-right: 5px;"></i> {{message.docName}}</a></p>
                            <p data-testid="chatTextMessage" v-if="message.mediaText !== '' && message.mediaText !== ' ' && message.mediaText !== undefined && !message.mediaText.includes('null')" class="media-text">{{message.mediaText}}</p>
                            <p data-testid="chatMediaMessage" v-if="!message.isMedia && !message.sent" v-html="message.body"></p>
                            <p data-testid="chatMediaMessage" v-if="!message.isMedia && message.sent" v-html="message.body"></p>
                            <span data-testid="chatFileButton"><b v-if="message.status === 'whatsapp_undelivered'">{{profile.incident_detail.whatsapp_undelivered}} | </b>{{message.time}}</span>
                        </div>
                    </div>
                    <form v-on:submit.prevent="sendMessage()" class="new-message" v-if="!readOnly">
                        <div style="float: left; width: 100%; position: relative;">
                            <span class="add-attachment" data-testid="chatFileButton" @click="mediaModalOpen = true" v-tooltip.right="profile.accountPage.account.add_files"><i class="fas fa-paperclip"></i></span>
                            <span class="add-defaultmessage" data-testid="chatDefaultButton" @click="defaultMessageModalOpen = true" v-tooltip.right="profile.accountPage.account.add_default_message"><i class="far fa-comment-dots"></i></span>
                            <textarea class="form-control" data-testid="chatTextInput" rows="2" placeholder="Verstuur een bericht..." @input="e => newMessage = e.target.value" :value="newMessage" @click="scrollToBottom()" @keydown="sendMessage($event)" id="textinput" maxlength="1500" ref="messageInput"></textarea>
                            <button type="submit" data-testid="chatSendButton" v-if="((!incidentReadOnly || forwardedReadOnly) || !forwardedReadOnly) && !typing"><i class="fas fa-paper-plane"></i></button>
                            <div class="typing" v-if="typing"><img src="@/assets/icons/loading.gif" /></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <MediaModal v-if="mediaModalOpen" @close="getIncidentInfo()" @success="showWhatsAppChats()" @sendMessage="sendMessage" :incidentId="incidentInfo.id_incident" :files="incidentInfo.pictures" :selectedDialog="selectedDialog" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { websocketOwner} from '@/mixins/websocket-owner.js';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import MediaModal from "@/components/modals/MediaModal.vue";

export default {
    mixins: [setLocale, websocketOwner],
    components: {
        MediaModal
    },
    name: 'Chat',
    props: ['buildingmanager', 'incidentId', 'incidentInfo', 'incidentReadOnly', 'forwardedReadOnly', 'canOwnerChat'],
    data() {
        return {
            profile: {},
            userName: '',
            userNames: [],
            selectedDialog: {},
            showChat: false,
            chatOpen: false,
            newChats: false,
            newMessage: '',
            chatEnlarged: false,
            readOnly: false,
            mediaModalOpen: false,
            servicedeskInitials: '',
            typing: true,
        }
    },
    created() {
        this.getCurrentUser();
        this.addWhatsappToChat();
        this.servicedeskInitials = this.incidentInfo.building_manager_name.split(" ").map((n)=>n[0]).join("");
        this.$root.$on('newchat', () => {
            this.showWhatsAppChats();
        });
    },
    methods: {
        getIncidentInfo() {
            this.$emit('getIncidentInfo');
            this.showWhatsAppChats();
            this.mediaModalOpen = false;
        },
        getCurrentUser() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
	        .then(response => { response.json().then(
	            function(data) {
					self.userName = data.name;
				});
	        });
        },
        backButton() {
            this.selectedDialog = {};
        },
        openChat() {
            this.chatOpen = !this.chatOpen;
            this.selectedDialog = this.dialogs[0];
            this.setwhatsappasread('owneruser');
            setTimeout(function () {
                if (!this.readOnly) {
                    document.getElementById('textinput').focus();
                }
                let element = document.getElementById('messages');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }.bind(this), 200);
        },
        scrollToBottom() {
            let element = document.getElementById('messages');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        },
        addWhatsappToChat() {
            this.showChat = false;
            this.dialogs = [];
            if (this.incidentInfo.ownerUserChats) {
                let self = this;
                // Add servicedesk chat to the list
                fetch('/api/v1/chat/unreadincident?id_incident='+this.incidentInfo.id_incident + '&type=owneruser_servicedesk', {
                }).then((resp) => {
                    return resp.json();
                }).then(function(data) {
                    let servicedeskDialog = {
                        nameShown: self.incidentInfo.building_manager_name,
                        unread: data.count,
                        type: 'serviceDeskChat',
                        messages: []
                    }
                    if (data.count) {
                        self.newChats = true;
                    }
                    self.dialogs.push(servicedeskDialog);
                });
                setTimeout(function () {
                    this.showWhatsAppChats();
                    let queryString = window.location.search;
                    let openchat;
                    if (queryString.includes('openchat')) {
                        openchat = queryString.split('=')[2];
                    }
                    if (openchat) {
                        this.chatOpen = true;
                    }
                    this.selectedDialog = this.dialogs[0];
                    this.singleDialog = true;
                    this.showChat = true;
                    if (this.chatOpen) {
                        if (this.selectedDialog.type === 'serviceDeskChat') {
                            this.setwhatsappasread();
                        }
                        this.newChats = false;
                    }
                }.bind(this), 1000);
            }
        },
        showWhatsAppChats() {
            this.mediaModalOpen = false;
            // Servicedesk chat
            let self = this;
            fetch('/api/v1/chat/byincident?id_incident='+this.incidentInfo.id_incident+'&limit='+100 + '&type=owneruser_servicedesk', {
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.typing = false;
                if (data.messages) {
                    self.processChats(self.selectedDialog, data);
                }
            });
        },
        processChats(dialog, data) {
            dialog.unread = data.unread_count;
            if (dialog.unread) {
                this.newChats = true;
            }
            for (var i = 0; i < data.messages.length; i++) {
                let dateStr = '';
                let received = false;
                let sent = false;
                let mediaText = '';
                if (data.messages[i].dateSentMillis) {
                    let dateSent = new Date(data.messages[i].dateSentMillis);
                    let month = dateSent.getMonth() + 1;
                    month = month.toString();
                    dateStr = dateSent.getDate() + " " + this.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+this.pad(dateSent.getMinutes());
                }
                if (data.messages[i].from.includes('bm-')) {
                    received = true;
                    sent = false;
                }
                if (data.messages[i].from.includes('ou-')) {
                    received = false;
                    sent = true;
                }
                if (data.messages[i].isMedia) {
                    mediaText = data.messages[i].body.split('https')[0];
                    data.messages[i].body = 'https' + data.messages[i].body.split('https')[1];
                }
                let message = {
                    body: data.messages[i].body,
                    bodyOriginal: data.messages[i].bodyOriginal,
                    from: data.messages[i].from,
                    time: dateStr,
                    received: received,
                    sent: sent,
                    isMedia: data.messages[i].isMedia,
                    mediaText: mediaText,
                    docName: '',
                    status: data.messages[i].status,
                    timestamp: data.messages[i].dateSentMillis,
                    isBMNotification: data.messages[i].isBMNotification,
                    mediaContentType: data.messages[i].mediaContentType,
                    idChat: data.messages[i].idChat
                }
                if (data.messages[i].isMedia && !data.messages[i].mediaContentType) {
                    message.mediaContentType = 'image/jpeg';
                }
                else {
                    message.body = message.body.replaceAll(/\r?\n/g, '<br>');
                }
                if (message.body.includes('.pdf')) {
                    let n = message.body.lastIndexOf('/');
                    let fileName = message.body.substring(n + 1);
                    message.docName = fileName;
                }
                if (message.received) {
                    dialog.receivedChats = true;
                }
                if (dialog.nameShown) {
                    if (!message.isBMNotification && message.status !== 'resend') {
                        dialog.messages.push(message);
                    }
                    dialog.messages = uniqBy(dialog.messages, 'timestamp');
                    dialog.messages = sortBy(dialog.messages, 'timestamp');
                    dialog.lastMessage = dialog.messages.slice(-1).pop();
                    if (this.selectedDialog.messages && this.selectedDialog.messages.length) {
                        setTimeout(function () {
                            let element = document.getElementById('messages');
                            if (element) {
                                element.scrollTop = element.scrollHeight;
                            }
                            if (this.userName === this.incidentInfo.serviced_by_bm_user_first_name) {
                                dialog.unread = 0;
                            }
                        }.bind(this), 200);
                    }
                }
            }
        },
        setwhatsappasread() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('type', 'servicedesk');
            let self = this;
            fetch('/api/v1/chat/setwhatsappasread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                setTimeout(function() {
                    if (self.dialogs.length > 1) {
                        const unreadCheck = self.dialogs.every(({ unread }) => !unread);
                        if (unreadCheck) {
                            self.newChats = false;
                        }
                        else {
                            self.newChats = true;
                        }
                    }
                    if (self.dialogs.length < 1 || self.selectedDialog.type) {
                        self.newChats = false;
                    }
                }.bind(self), 500);
            });
        },
        pad(n) {
            return n < 10 ? '0' + n : n
        },
        createNiceDateString(theDate) {
            var result = '';
            let now = new Date();
            let startToday = now.getTime()-(now.getMilliseconds()+now.getSeconds()*1000+now.getMinutes()*60*1000+now.getHours()*60*60*1000);
            let startTheDay = theDate.getTime()-(theDate.getMilliseconds()+theDate.getSeconds()*1000+theDate.getMinutes()*60*1000+theDate.getHours()*60*60*1000);
            let hoursMinutes = this.pad(theDate.getHours())+':'+this.pad(theDate.getMinutes());
            if (startTheDay==startToday) {
                result += hoursMinutes;
            } else if (startTheDay+24*60*60*1000==startToday) {
                let yesterdayStr = this.profile.datetime.yesterday;
                result +=  hoursMinutes+' '+yesterdayStr;
            } else {
                result = this.pad(theDate.getDate())+"/"+this.pad(theDate.getMonth()+1)+"/"+theDate.getFullYear();
            }
            return result;
        },
        sendMessage($event) {
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id');
            let textarea = this.$refs.messageInput;
            if (this.incidentInfo.id_incident.toString() === id) {
                document.querySelector('body').classList.remove('modal-open');
                this.mediaModalOpen = false;
                if ($event) {
                    if (typeof $event === 'string') {
                        this.getIncidentInfo();
                    }
                    if ($event.keyCode === 13 && !this.newMessage.length) {           
                        textarea.blur();
                        setTimeout(function () {
                            textarea.focus();
                        }.bind(this), 200);                
                    }
                    if(($event.keyCode === 13 && this.newMessage.length || typeof $event === 'string') && !$event.shiftKey) {
                        this.handleSend($event);
                    }
                } 
                else {
                    if(this.newMessage.length) {       
                        this.handleSend($event);
                    }
                }  
            }
        },
        handleSend($event) {
            let textarea = this.$refs.messageInput;
            let action = "sendmessage_owneruser_to_servicedesk"   
            let idIncident =  parseInt(this.incidentInfo.id_incident);
            let body = this.userName + ': ' + this.newMessage;
            let to;
            let from = this.incidentInfo.id_owner;
            to  =  this.incidentInfo.id_building_manager;
            if (typeof $event === 'string') {
                body = $event;
            }
            this.sendToServer(action, body, idIncident, to, from);
            let dateSent = new Date();
            let month = dateSent.getMonth() + 1;
            month = month.toString();
            let element = document.getElementById('messages');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
            this.newMessage = '';
            if (textarea) {
                textarea.blur();
                setTimeout(function () {
                    textarea.focus();
                }.bind(this), 200);
            }            
        },
        setAsUnread(dialog) {
            let idChat;
            for (var i = 0; i < dialog.messages.length; i++) {
                let message = dialog.messages[i];
                if (message.received) {
                    idChat = message.idChat;
                }
            }
            const data = new URLSearchParams();
            data.append('id_chat', idChat);
            let self = this;
            fetch('/api/v1/chat/setasunread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.showWhatsAppChats();
            });
        }
    }
}
</script>
