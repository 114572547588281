<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-file-excel"></i>{{profile.places.importplaces}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner fullheight">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <h4>{{profile.places.upload}}</h4>
                <p class="notification notification--error" v-if="feedbackMsg" v-html="feedbackMsg"></p>
                <br v-if="feedbackMsg" />
                <div class="expander-wrap">
                    <h5 class="expander" @click="generalToggled = !generalToggled">
                        {{profile.accountPage.account.general}}
                        <i class="fas fa-chevron-down" v-if="!generalToggled"></i>
                        <i class="fas fa-chevron-up" v-if="generalToggled"></i>
                    </h5>
                    <p class="expandable" v-if="generalToggled">
                        {{profile.places.uploadinstruction}}<br />{{profile.places.uploadexample}}<br />
                        {{profile.places.uploadextra}}<br />
                        <a href="../import-places-example-realestate.csv" v-if="industry === 'housing' && !vve" download>{{profile.places.csvexample}}</a>
                        <a href="../import-places-example-vve.csv" v-if="industry === 'housing' && vve" download>{{profile.places.csvexample}}</a>
                        <a href="../import-places-example-mobility.csv" v-if="industry === 'leasing'" download>{{profile.places.csvexample}}</a>
                        <a href="../import-places-example-facility.csv" v-if="industry !== 'leasing' && industry !== 'housing'" download>{{profile.places.csvexample}}</a>
                    </p>
                </div>
                <div class="expander-wrap">
                    <h5 class="expander" @click="importInstructionsToggled = !importInstructionsToggled">
                        {{profile.places.import_instruction_title}}
                        <i class="fas fa-chevron-down" v-if="!importInstructionsToggled"></i>
                        <i class="fas fa-chevron-up" v-if="importInstructionsToggled"></i>
                    </h5>
                    <p class="expandable" v-if="importInstructionsToggled" v-html="profile.places.import_instruction"></p>
                </div>
                <div class="expander-wrap">
                    <h5 class="expander" @click="columnInstructionsToggled = !columnInstructionsToggled">
                        {{profile.places.column_instruction_title}}
                        <i class="fas fa-chevron-down" v-if="!columnInstructionsToggled"></i>
                        <i class="fas fa-chevron-up" v-if="columnInstructionsToggled"></i>
                    </h5>
                    <p class="expandable" v-if="columnInstructionsToggled">
                        <span v-html="profile.places.column_instruction_1"></span>
                        <span v-html="profile.places.column_instruction_2"></span>
                        <br />
                        <span v-html="profile.places.column_instruction_3"></span>
                    </p>
                </div>
                <form v-on:submit.prevent="importLocations()">
                    <div class="form-field-upload" style="margin-bottom: 0;">
                        <label>{{profile.places.csvfile}}</label>
                        <input type="file" @change="fileChange" />
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dropdown-section">
                                <label>{{profile.assets.select_objecttype}}</label>
                                <span class="dropdown" :class="{active: assetTypeDropdownToggled}" v-on:click="assetTypeDropdownToggled = !assetTypeDropdownToggled">
                                    <span v-if="!selectedAssetTypeId" class="placeholder">{{profile.assets.select_objecttype}}</span>
                                    <span v-if="selectedAssetTypeId" class="selected">{{selectedAssetTypeName}}</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="assetTypeDropdownToggled" :class="{search: assetTypes.length > 9}">
                                    <input type="text" @input="e => assetTypeSearchTerm = e.target.value" :value="assetTypeSearchTerm" :placeholder="profile.assets.select_objecttype" @keyup="searchAssetTypes()" v-if="assetTypes.length > 9"  />
                                    <ul>
                                        <li v-on:click="deselectAssetType()" v-if="!assetTypesNoResults && !tempAssetTypes.length">{{profile.assets.none}}</li>
                                        <li v-for="assetType in assetTypes" v-on:click="selectAssetType(assetType)" :key="assetType.id" v-show="!tempAssetTypes.length && !assetTypesNoResults">{{assetType.display_name}}</li>
                                        <li v-for="(assetType, index) in tempAssetTypes" v-on:click="selectAssetType(assetType)" :key="index" v-show="tempAssetTypes.length">{{assetType.display_name}}</li>
                                        <li v-if="assetTypesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="switch-wrapper">
                                <label class="switch" style="float: left; margin-top: 2px; margin-bottom: 10px;">
                                    <input type="checkbox" v-model="toggleOwnerDropdown">
                                    <span class="slider round"></span>
                                </label>
                                <p style="font-weight: 500; margin-bottom: 0;">{{profile.places.placetable.add_owners}}</p>
                            </div>
                            <div class="dropdown-section" v-if="toggleOwnerDropdown">
                                <label>{{profile.places.placetable.owner}} *</label>
                                <span class="dropdown" :class="{active: ownerDropdownToggled}" v-on:click="ownerDropdownToggled = !ownerDropdownToggled">
                                    <span v-if="!selectedOwnerId" class="placeholder">{{profile.places.placetable.owner}}</span>
                                    <span v-if="selectedOwnerId" class="selected">{{selectedOwnerName}}</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="ownerDropdownToggled" :class="{search: owners.length > 9}">
                                    <input type="text" @input="e => ownerSearchTerm = e.target.value" :value="ownerSearchTerm" :placeholder="profile.assets.select_objecttype" @keyup="searchOwners()" v-if="owners.length > 9"  />
                                    <ul>
                                        <li v-for="owner in owners" v-on:click="selectOwner(owner)" :key="owner.id" v-show="!tempOwners.length && !ownersNoResults">{{owner.name}}</li>
                                        <li v-for="(owner, index) in tempOwners" v-on:click="selectOwner(owner)" :key="index" v-show="tempOwners.length">{{owner.name}}</li>
                                        <li v-if="ownersNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                            <span class="notification notification--error" v-if="ownerError && toggleOwnerDropdown">{{profile.places.no_owner_error}}.</span>
                        </div>
                    </div>
                    <button type="submit" class="button button--icon"><i class="fas fa-file-excel"></i>{{profile.places.importplaces}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    name: 'importLocationModal',
    data() {
        return {
            fields: {
                file: null,
                owner: ''
            },
            success: false,
            feedbackMsg: '',
            ownerError: false,
            generalToggled: false,
            importInstructionsToggled: false,
            columnInstructionsToggled: false,
            assetTypes: [],
            tempAssetTypes: [],
            assetTypesNoResults: false,
            assetTypeDropdownToggled: false,
            assetTypeSearchTerm: '',
            selectedAssetTypeId: 0,
            selectedAssetTypeName: '',
            owners: [],
            tempOwners: [],
            ownersNoResults: false,
            ownerDropdownToggled: false,
            ownerSearchTerm: '',
            selectedOwnerId: 0,
            selectedOwnerName: '',
            toggleOwnerDropdown: false
        }
    },
    computed: mapState(['vve']),
    created() {
        this.getCurrentBm();
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        fetch('/api/v1/owner/bybuildingmanager')
        .then(response => { response.json().then(
            function(data) {
                self.owners = data.data;
                self.owners = sortBy(self.owners, 'name');
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        getCurrentBm() {
            let self = this;
            fetch('/api/v1/buildingmanagerstats')
            .then(response => { response.json().then(
                function(data) {
                    let id_bm = data.building_manager.id_building_manager;
                    self.getAssetTypes(id_bm);
                });
            });
        },
        getAssetTypes(id_bm) {
            let self = this;
            fetch('/api/v1/asset_type/bybuildingmanager?id_building_manager=' + id_bm)
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypes = sortBy(self.assetTypes, 'display_name');
                });
            });
        },
        searchAssetTypes() {
            this.tempAssetTypes = [];
            if (this.assetTypeSearchTerm.length > 1) {
                let searchTerm = this.assetTypeSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetTypes.length; i++) {
                    let assetType = this.assetTypes[i];
                    let assetName = assetType.display_name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssetTypes.push(assetType);
                    }
                    if (!this.tempAssetTypes.length) {
                        this.assetTypesNoResults = true;
                    }
                    else {
                        this.assetTypesNoResults = false;
                    }
                }
            }
            else {
                this.assetTypesNoResults = false;
            }
        },
        selectAssetType(assetType) {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetTypeName = assetType.display_name;
            this.selectedAssetTypeId = assetType.id_asset_type;
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
        },
        deselectAssetType() {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetTypeName = '';
            this.selectedAssetTypeId = 0;
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
        },
        searchOwners() {
            this.tempOwners = [];
            if (this.ownerSearchTerm.length > 1) {
                let searchTerm = this.ownerSearchTerm.toLowerCase();
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    let ownerName = owner.name.toLowerCase();
                    if (ownerName.includes(searchTerm)) {
                        this.tempOwners.push(owner);
                    }
                    if (!this.tempOwners.length) {
                        this.ownersNoResults = true;
                    }
                    else {
                        this.ownersNoResults = false;
                    }
                }
            }
            else {
                this.ownersNoResults = false;
            }
        },
        selectOwner(owner) {
            this.ownerError = false;
            this.ownerDropdownToggled = false;
            this.selectedOwnerName = owner.name;
            this.selectedOwnerId = owner.id_owner;
            this.tempOwners = [];
            this.ownerSearchTerm = '';
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        importLocations() {
            let self = this;
            const data = new FormData();
            if (!this.selectedOwnerId) {
                this.ownerError = true;
            }
            if (this.selectedOwnerId || (!this.selectedOwnerId && !this.ownerDropdownToggled)) {
                data.append('file', this.fields.file[0]);
                data.append('id_owner', this.selectedOwnerId);
                data.append('id_asset_type', this.selectedAssetTypeId);
                fetch('/api/v1/placeupload/upload', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    var colErrors = data.colErrors && data.colErrors !== "" ? self.profile.places.upload_result_colErrors + data.colErrors + "<br/>" : "";
                    var exists    = data.alreadyExists && data.alreadyExists !== "" ? self.profile.places.upload_result_alreadyExists + data.alreadyExists + "<br/>" : "";
                    var zipErrors = data.zipCodeErrors && data.zipCodeErrors !== "" ? self.profile.places.upload_result_zipCodeError + data.zipCodeErrors + "<br/>" : "";
                    var floorErrors = data.floorErrors && data.floorErrors !== "" ? self.profile.places.upload_result_floorAlreadyExists + data.floorErrors + "<br/>" : "";
                    var countryErrors = data.countryErrors && data.countryErrors !== "" ? self.profile.places.upload_result_countryError + data.countryErrors + "<br/>" : "";
                    var ownerError = data.noIdOwnerErrors && data.noIdOwnerErrors !== "" ? self.profile.places.upload_result_ownerError + "<br/>" : "";
                    var errors    = data.errors && data.errors !== "" ? self.profile.places.upload_result_errors + data.errors : "";
                    self.feedbackMsg = colErrors + zipErrors + countryErrors + exists + floorErrors + ownerError + errors;
                    console.log(self.feedbackMsg);
                    if (data.successes && data.successes !== "" || data.asRoom) {
                        self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                        self.closeModal();
                    }
                });
            }
        }
    }
}
</script>
