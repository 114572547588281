export const websocket = {
    data() {
        return {
            errCount: 0,
            logs: [],
            status: 'disconnected',
            socket: null,
            connection: null
        }
    },
    created() {
        if (!window.location.href.includes('8080') && !window.location.href.includes('8081')) {
            this.startWebSocket();
        }
    },
    methods: {
        startWebSocket(message, resend) {
            let wsProtocol =  'localhost'==window.location.hostname ? 'ws://' : 'wss://';
            let host = window.location.host;
            let wsUri = wsProtocol + host + "/ws/v1/chatbase/incident";
            this.connection  = new WebSocket(wsUri);
            let self = this;
            this.connection.onopen = function(event) { self.onOpen(event); }
            this.connection.onmessage = function(event) { self.onMessage(event); }
            this.connection.onerror = function(event) { self.onError(event); }
            this.connection.onclose = function(event) { self.onClose(event); }
            setTimeout(function () {
                if (resend) {
                    this.sendMessageToServer(message, resend);
                }
            }.bind(this), 2000);
        },
        onOpen() {
            let self = this;
            fetch('/api/v1/keyforwebsocket', {
            }).then((resp) => {
                return resp.json();
            }).then(function(json) {
                self.sendToServer("auth", JSON.stringify(json), self.incidentId);
            });
        },
        onMessage(event) {
            if (window.location.href.includes('incident-detail')) {
                let sentMessage = JSON.parse(event.data);
                sentMessage = JSON.parse(event.data);
                let dateSend = new Date(sentMessage.dateSentMillis);
                let month = dateSend.getMonth() + 1;
                let dateStr = dateSend.getDate() + " " + this.profile.months_numbered[month]  + ", " + dateSend.getHours() + ":"+this.pad(dateSend.getMinutes());
                let received = false;
                let sent = false;
                if (sentMessage.from && sentMessage.from.indexOf("+3197")==-1) {
                    received = true;
                }
                else {
                    sent = true;
                }
                let message = {
                    body: sentMessage.body,
                    from: sentMessage.from,
                    action: sentMessage.action,
                    time: dateStr,
                    received: received,
                    sent: sent
                }
                if (sentMessage.action) {
                    this.showWhatsAppChats();
                }
                setTimeout(function () {
                    if (message.action !== 'sendmessage_servicedesk_to_mechanic') {
                        for (var i = 0; i < this.dialogs.length; i++) {
                            let dialog = this.dialogs[i];
                            // If no dialog is selected yet, set the according dialog to unread
                            if (dialog.messages && dialog.messages.length) {
                                if (dialog.type === 'reporterChat' && !message.action !== 'sendmessage_mechanic_to_servicedesk' && (dialog.messages[0].body.includes(this.incidentInfo.mechanic_name) || message.action === 'wamessage_from_reporter')) {
                                    // If the message is sent in the reporter channel
                                    if (message.action !== 'sendmessage_servicedesk_to_mechanic') {
                                        dialog.unread = true;
                                        this.newChats = true;
                                    }
                                }
                                // If the message is sent in the owner channel
                                if (dialog.type === 'ownerChat' && message.action === 'wamessage_from_owner') {
                                    dialog.unread = true;
                                    this.newChats = true;
                                }
                            }
                            // If the message is sent in the seperate owner channel
                            if (dialog.type === 'ownerSeperateChat' && (message.action === 'sendmessage_servicedesk_to_owneruser' || message.action === 'sendmessage_owneruser_to_servicedesk')) {
                                if (this.selectedDialog.type !== 'ownerSeperateChat') {
                                    dialog.unread = true;
                                    this.newChats = true;
                                }
                            }
                            // If the message is sent in the supplier channel
                            if (dialog.type === 'supplierChat' && (message.action === 'sendmessage_supplier_to_servicedesk' || message.action === 'sendmessage_servicedesk_to_supplier')) {
                                if (this.selectedDialog.type !== 'supplierChat') {
                                    dialog.unread = true;
                                    this.newChats = true;
                                }
                            }
                            // If the message is sent in the mechanic channel
                            if (dialog.type === 'mechanicChat' && (message.action === 'sendmessage_mechanic_to_servicedesk' || message.action === 'sendmessage_servicedesk_to_mechanic')) {
                                if (this.selectedDialog.type !== 'mechanicChat') {
                                    dialog.unread = true;
                                    this.newChats = true;
                                }
                            }
                            // If the message is sent in the servicedesk channel
                            if (dialog.type === 'servicedeskChat' && message.action === 'sendmessage_servicedesk_to_servicedesk') {
                                if (this.selectedDialog.type !== 'servicedeskChat') {
                                    dialog.unread = true;
                                    this.newChats = true;
                                }
                            }
                            if (message.type === 'sendmessage_servicedesk_to_owneruser') {
                                if (dialog.type === 'ownerSeperateChat') {
                                    this.selectedDialog = dialog;
                                    dialog.unread = false;
                                    this.newChats = false;
                                }
                            }
                        }
                    }
                    // If a dialog is selected, get the selected dialog included with the new messages
                    if (this.selectedDialog.type && this.chatOpen) {
                        if (this.selectedDialog.type === 'reporterChat') {
                            this.setwhatsappasread('reporter');
                        }
                        if (this.selectedDialog.type === 'ownerChat') {
                            this.setwhatsappasread('owner');
                        }
                        if (this.selectedDialog.type === 'ownerSeperateChat') {
                            this.setwhatsappasread('owneruser');
                        }
                        if (this.selectedDialog.type === 'specialistChat') {
                            this.setwhatsappasread('mechanic');
                        }
                        if (this.selectedDialog.type === 'servicedeskChat') {
                            this.setwhatsappasread('servicedesk');
                        }
                        if (this.selectedDialog.type === 'supplierChat') {
                            this.setwhatsappasread('supplier');
                        }
                        setTimeout(function () {
                            let element = document.getElementById('messages');
                            element.scrollTop = element.scrollHeight;
                        }.bind(this), 500);
                    }
                }.bind(this), 1000);
            }
        },
        onClose() {
            let self = this;
            fetch('/api/v1/isauthenticated/asservicedesk')
            .then(response => { response.text().then(
                function(data) {
                    if (data === 'true' && window.location.href.includes('incident-detail')) {
                        self.startWebSocket();
                    }
                    if (data === 'false') {
                        window.location = '/signin.html';
                    }
                });
            });     
        },
        onError(error) {
            //console.log('[error]', error.message);
        },
        sendToServer(action, body, idIncident, to, from, ownerChatId) {
            var message = new Object();
            message.action     = action;
            message.body       = body;
            message.idIncident = idIncident;
            message.to         = to;
            message.from       =  from;
            if (action === 'sendmessage_servicedesk_to_owneruser') {
                message.idOwnerUser = ownerChatId;
            }
            this.sendMessageToServer(message);
        },
        sendMessageToServer(message, resend) {
            console.log(message);
            if (!resend) {
                message = JSON.stringify(message);
            }
            if (this.connection && this.connection.readyState==1) {
                this.connection.send(message);
                this.errCount=0;
                setTimeout(function () {
                    let element = document.getElementById('messages');
                    if (element) {
                        element.scrollTop = element.scrollHeight;
                    }
                }.bind(this), 500);
            } else if (!this.connection || (this.connection.readyState==3 && this.errCount<5)) {
                this.errCount++;
                this.startWebSocket(message, true);
            } else if (this.errCount<20) {
                this.errCount++;
            } else {
                if (this.errCount%10 == 0 ) console.log("too many errors");
            }
        }
    }
}
