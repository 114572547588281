<template>
    <div id="app">
        <Header v-if="type === 'buildingmanager'" />
        <MechHeader v-if="type === 'mechanic'" />
        <RepHeader v-if="type === 'repaircompany'" />
        <OwnerHeader v-if="type === 'owner'" />
        <S1moneHeader v-if="type === 's1mone'" />
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
    import Header from '@/components/headers/Header.vue'
    import MechHeader from '@/components/headers/MechHeader.vue'
    import RepHeader from '@/components/headers/RepHeader.vue'
    import OwnerHeader from '@/components/headers/OwnerHeader.vue'
    import S1moneHeader from '@/components/headers/S1moneHeader.vue'

    export default {
        components: {
            MechHeader,
            RepHeader,
            OwnerHeader,
            S1moneHeader,
            Header
        },
        data() {
            return {
                loading: false,
                type: ''
            }
        },
        created() {
            document.title = 'S1MONE | Dashboard';
            if (!window.location.href.includes('/buildingmanager')
            && !window.location.href.includes('/twilio')
            && !window.location.href.includes('/supplier')
            && !window.location.href.includes('/repaircompany')
            && !window.location.href.includes('/owner')
            && !window.location.href.includes('/monteur')
            && !window.location.href.includes('/s1mone')
            && !window.location.href.includes('/logout')
            && !window.location.href.includes('/onboarding')
            && !window.location.href.includes('/milestones')
            && !window.location.href.includes('/open-api')
            && !window.location.href.includes('/errorpages')
            && !window.location.href.includes('/websocket')
            && !window.location.href.includes('/s1mone')
            ) {
                let baseUrl = window.location.href;
                let pathName = window.location.pathname;
                baseUrl = baseUrl.replace(pathName, '');
                window.location.href = baseUrl + '/signin.html';
            }
            if (window.location.href.includes('logout')) {
                let logout = window.location.href.replace('logout', 'signin.html');
                window.location.href = logout;
            }
            if (window.location.href.includes('/buildingmanager')) {
                this.type = 'buildingmanager';
            }
            if (window.location.href.includes('/monteur')) {
                this.type = 'mechanic';
            }
            if (window.location.href.includes('/supplier')) {
                this.type = 'repaircompany';
            }
            if (window.location.href.includes('/owner/')) {
                this.type = 'owner';
            }
            if (window.location.href.includes('/s1mone')) {
                this.type = 's1mone';
            }
        }
    }
</script>

<style lang="scss">
    @import '@/scss/base.scss';
</style>
