<template>
    <div>
        <div class="stick-block" id="stickBlock">
            <div class="block block--incident_detail" style="overflow: visible;">
                <section class="incident-head">
                    <div class="row">
                        <div class="col-sm-9 col-md-10">
                            <span data-testid="incidentCreateDate" class="date">{{incidentInfo.day}} {{incidentInfo.date}}, {{incidentInfo.time}}</span>
                            <span class="status-bar" v-if="incidentInfo.incident_status && !profile['statusses'][incidentInfo.incident_status].buildingmanager">
                                <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic'"></div>
                                <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                                <span data-testid="statusBar1" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                                <span data-testid="statusBar2" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                                <span data-testid="statusBar3" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                            </span>
                            <span class="status-bar" v-if="incidentInfo.incident_status && profile['statusses'][incidentInfo.incident_status].buildingmanager">
                                <div v-html="profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].buildingmanager"></div>
                                <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && !profile['status_icons'][incidentInfo.incident_status].buildingmanager"></div>
                                <div v-html="profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-tooltip.right="incidentInfo.pause_reason"></div>
                                <div v-htmlF="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                                <span data-testid="statusBar4" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                                <span data-testid="statusBar5" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                                <span data-testid="statusBar6" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                            </span>
                            <span class="time-indicator" :class="incidentInfo.overtimeStatus"><i class="fas fa-stopwatch"></i>{{profile.incidents.incidenttable.time_in_status}} {{incidentInfo.overtime}}</span>
                        </div>
                    </div>
                    <h1 test-datalabel="incidentNumber" class="incident_nr">{{incidentInfo.id_incident}}</h1>
                    <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.agreement_flow_name === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                    <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.agreement_flow_name === 'WAITING_ON_PERSON' || incidentInfo.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                    <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.agreement_flow_name === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                    <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.agreement_flow_name === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                    <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.agreement_flow_name === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                    <span class="followup" data-testid="incidentFollowup" v-if="incidentInfo.wants_copy"><i class="fas fa-calendar-plus"></i> {{profile.incident_detail.followup_label}}</span>
                    <span class="handmade" v-if="incidentInfo.origin === 'service_desk_manual'"><i class="fas fa-hand-pointer"></i>{{profile.incidents.incidenttable.hand_made}}</span>
                    <!-- <span class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="incidentInfo.copiedFrom.length">{{incidentInfo.copiedFrom[0].id_ori_incident}} <i class="fas fa-copy"></i></span> -->
                    <!-- <span class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="!incidentInfo.copiedFrom.length && incidentInfo.copiedTo.length">{{incidentInfo.copiedTo[0].id_copy_incident}} <i class="fas fa-copy"></i></span> -->
                    <span data-testid="autoDispatchLabelText" class="autodispatch-indicator" v-if="incidentInfo.autodispatch">auto <i class="fas fa-forward"></i></span>
                </section>
                <div class="actions" v-if="incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON' && incidentInfo.incident_agreement_flow !== 'WAITING_ON_PERSON_REMINDED'" style="margin-bottom: -5px;">
                    <div class="main-buttons">
                        <span class="button button--cta button--icon" data-testid="buttonReopenIncident" @click.stop="stickAction('restart')" v-if="readyForRestart" v-tooltip.right="profile.button_tooltips.restart_incident"><i class="fas fa-play"></i>{{profile.incident_detail.reactivate_task}}</span>
                        <span class="button button--cta button--icon" data-testid="buttonStartIncident" @click.stop="stickAction('start')" v-if="newIncident" v-tooltip.right="profile.button_tooltips.start_incident"><i class="fas fa-play"></i>{{profile.incident_detail.activate_task}}</span>
                        <span class="button button--cta button--icon" data-testid="buttonTakeOverIncident" @click.stop="stickAction('takeover')" v-if="readyForTakeover" v-tooltip.right="profile.button_tooltips.takeover_incident"><i class="fas fa-portrait"></i>{{profile.incident_detail.take_over_incident}}</span>
                        <span class="button button--cta button--icon" data-testid="buttonCommissionIncident" @click.stop="stickAction('commission')" v-if="(!incidentInfo.has_order && !readOnly && incidentInfo.serviced_by_bm_user_id === incidentInfo.current_user_id) && !commissionStart" v-tooltip.right="profile.button_tooltips.commission_incident"><i class="fas fa-thumbs-up"></i>{{profile.invoicing.submit}}</span>
                        <span class="button button--cta button--icon" @click.stop="addHandler()" v-if="(incidentInfo.incident_status === 'returned_to_buildingmanager' || incidentInfo.incident_status === 'fixed_agreed_repaircompany' || incidentInfo.incident_status === 'fixed_agreed_auto') && !hideRestart" v-tooltip.right="profile.button_tooltips.commission_incident"><i class="fas fa-thumbs-up"></i>{{profile.invoicing.re_submit}}</span>
                        <div class="extra-buttons" v-if="incidentInfo.wants_copy && !readOnly">
                            <span class="button button--cta button--icon" data-testid="buttonFollowUpIncident" @click.stop="stickAction('followup')" v-tooltip.right="profile.button_tooltips.followup"><i class="fas fa-calendar-plus"></i>{{profile.incident_detail.followup_button}}</span>
                        </div>
                        <span class="button button--cta button--icon" data-testid="buttonFinishIncident" @click.stop="stickAction('finish')" v-if="readyToFinish" v-tooltip.right="profile.button_tooltips.approve_incident"><i class="fas fa-handshake"></i>{{profile.incident_detail.agree_and_close}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="incidentInfo.id_building_manager !== buildingmanager.id_building_manager">
            <div class="col">
                <span class="original_bm" v-tooltip.right="profile.incident_detail.passed_to_tooltip"><i class="fas fa-share"></i> {{incidentInfo.building_manager_name}}</span>
            </div>
        </div>
        <div class="row" v-if="incidentInfo.original_id_building_manager && incidentInfo.original_id_building_manager !== incidentInfo.id_building_manager && incidentInfo.original_id_building_manager !== buildingmanager.id_building_manager">
            <div class="col">
                <span class="original_bm" v-tooltip.right="profile.incident_detail.passed_tooltip"><i class="fas fa-share"></i> {{incidentInfo.original_building_manager_name}}</span>
            </div>
        </div>
        <div class="block block--incident_detail" style="overflow: visible;" :class="{tabbed: (incidentInfo.id_building_manager !== buildingmanager.id_building_manager && !incidentInfo.has_order) || (incidentInfo.original_id_building_manager && incidentInfo.original_id_building_manager !== incidentInfo.id_building_manager && !incidentInfo.has_order)}">
            <section class="incident-head">
                <div class="row">
                    <div class="col-sm-9 col-md-10">
                        <span class="date">{{incidentInfo.day}} {{incidentInfo.date}}, {{incidentInfo.time}}</span>
                        <span class="status-bar" v-if="incidentInfo.incident_status && !profile['statusses'][incidentInfo.incident_status].buildingmanager">
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic'"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                            <span v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                            <span v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                        </span>
                        <span class="status-bar" v-if="incidentInfo.incident_status && profile['statusses'][incidentInfo.incident_status].buildingmanager">
                            <div v-html="profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].buildingmanager"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && !profile['status_icons'][incidentInfo.incident_status].buildingmanager"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                            <span v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                            <span v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                        </span>
                        <span class="time-indicator" :class="incidentInfo.overtimeStatus"><i class="fas fa-stopwatch"></i>{{profile.incidents.incidenttable.time_in_status}} {{incidentInfo.overtime}}</span>
                        <span class="status_reason" v-if="incidentInfo.cancelled_reason"><b>{{profile.incident_detail.status_reason}}:</b> {{incidentInfo.cancelled_reason}}</span>
                    </div>
                    <div class="col-sm-3 col-md-2" v-if="!forwardedReadOnly">
                        <section class="actions" style="margin-top: 0;">
                            <div class="button-wrap">
                                <span class="button button--small button--outline logbook" data-testid="buttonLog" @click="logIncidentModalOpen = true"><i class="fas fa-book"></i>{{profile.incident_detail.log}}</span>
                            </div>
                        </section>
                    </div>
                </div>
                <span class="suspend-indicator" data-testid="incidentParkLabel" v-if="incidentInfo.service_desk_suspended_until_date && incidentInfo.service_desk_suspended_reason" v-tooltip.top="profile.incident_detail.park_reason + ': ' + incidentInfo.service_desk_suspended_reason"><i class="fas fa-parking"></i> {{profile.incident_detail.parked_since}} {{incidentInfo.service_desk_suspended_until_date}} <i class="fas fa-info-circle" style="color: #212529;"></i></span>
                <span class="suspend-indicator" data-testid="incidentParkLabel1" v-if="incidentInfo.service_desk_suspended_until_date && !incidentInfo.service_desk_suspended_reason"><i class="fas fa-parking"></i> {{profile.incident_detail.parked_since}} {{incidentInfo.service_desk_suspended_until_date}}</span>
                <div v-if="incidentInfo.rating" class="rating">
                    <span v-if="incidentInfo.rating === 2">
                        <i class="fas fa-star" data-testid="buttonRatingTwo"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i>
                    </span>
                    <span v-if="incidentInfo.rating === 1">
                        <i class="fas fa-star" data-testid="buttonRatingOne"></i><i class="fas fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i>
                    </span>
                    <span v-if="incidentInfo.rating === 3">
                        <i class="fas fa-star" data-testid="buttonRatingThree"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i>
                    </span>
                    <span v-if="incidentInfo.rating === 4">
                        <i class="fas fa-star" data-testid="buttonRatingFour"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="far fa-star"></i>
                    </span>
                    <span v-if="incidentInfo.rating === 5">
                        <i class="fas fa-star" data-testid="buttonRatingFive"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>
                    </span>
                </div>
                <h1 data-testid="incidentDetailNumber" class="incident_nr">{{incidentInfo.id_incident}}</h1>
                <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.incident_agreement_flow === 'WAITING_ON_PERSON' || incidentInfo.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                <span class="agreement-bar" data-testid="incidentAgreementBar" v-if="incidentInfo.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                <span class="mandate-violation active" data-testid="statusBackLabel" v-if="incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning === 'no_warning'"><i class="fa fa-exclamation"></i> {{profile.invoicing.commission_cancel}}</span>
                <span class="mandate-violation active" data-testid="statusViolationLabel" v-if="incidentInfo.mandate_warning !== 'no_warning'"><i class="fa fa-exclamation"></i> {{profile.mandates.mandate_violation}}</span>
                <span class="followup" data-testid="incidentFollowup" v-if="incidentInfo.wants_copy"><i class="fas fa-calendar-plus"></i> {{profile.incident_detail.followup_label}}</span>
                <span class="handmade" v-if="incidentInfo.origin === 'service_desk_manual'"><i class="fas fa-hand-pointer"></i>{{profile.incidents.incidenttable.hand_made}}</span>
                <span class="handmade" v-if="incidentInfo.origin === 'recurring_action'"><i class="fas fa-redo"></i>{{profile.actionable.repeating_job}}</span>
                <span class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="incidentInfo.copiedFrom.length">{{incidentInfo.copiedFrom[0].id_ori_incident}} <i class="fas fa-copy"></i></span>
                <span class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="!incidentInfo.copiedFrom.length && incidentInfo.copiedTo.length">{{incidentInfo.copiedTo[0].id_copy_incident}} <i class="fas fa-copy"></i></span>
                <span class="autodispatch-indicator" data-testid="autoDispatchLabel" v-if="incidentInfo.autodispatch"><i class="fas fa-forward"></i>auto</span>
                <span class="adminready-indicator" v-if="incidentInfo.admin_done"><i class="fas fa-check"></i>{{ profile.incident_detail.adminn_ready }}</span>

            </section>
            <div class="incident-contents" id="incidentHead">
                <div class="incident-info">
                    <div class="row" style="clear: both;">
                        <div class="col-md-6 col-lg-4">
                            <label>{{profile.incident_detail.category}}</label>
                            <!-- Only category name for readonly incidents -->
                            <p v-if="((forwardedReadOnly || readOnly) && locale === 'nl' && incidentInfo.incidentCategory)">{{incidentInfo.incidentCategory.dutch_name}}</p>
                            <p v-if="((forwardedReadOnly || readOnly) && locale === 'en' && incidentInfo.incidentCategory)">{{incidentInfo.incidentCategory.english_name}}</p>
                            <!-- Category dropdowns -->
                            <div>
                                <div class="dropdown-section" v-click-outside="closeCategoryDropdown" v-if="(!readOnly && !forwardedReadOnly) ||
                                                                    (incidentInfo.serviced_by_bm_user_id && !readOnly && admin && !forwardedReadOnly && !incidentInfo.has_order)">
                                    <span class="dropdown" data-testid="selectCategory" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled}">
                                        <span v-if="incidentInfo.incidentCategory && locale === 'nl'" class="selected">{{incidentInfo.incidentCategory.dutch_name}}</span>
                                        <span v-if="incidentInfo.incidentCategory && locale === 'en'" class="selected">{{incidentInfo.incidentCategory.english_name}}</span>
                                        <i class="fas fa-chevron-down chevron"></i></span>
                                    <div class="dropdown-list" data-testid="listCategory" v-if="categoryDropdownToggled" style="position: absolute; z-index: 2; width: 100%;">
                                        <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" :placeholder="profile.incident_detail.search_category" @keyup="searchCategories()" v-if="categories.length > 9" style="margin: 10px 0;"  />
                                        <ul>
                                            <li v-for="(category, index) in categories" class="with-label" v-on:click="selectCategory(category)" :key="index" v-show="!tempCategories.length && !categoriesNoResults">
                                                <span data-testid="selectCategoryResultNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                <span data-testid="selectCategoryResultEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                            </li>
                                            <li v-for="(category, index) in tempCategories" class="with-label" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">
                                                <span data-testid="selectCategoryResultIndexNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                <span data-testid="selectCategoryResultIndexEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                            </li>
                                            <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <label style="position: relative;">{{profile.incident_detail.description}}
                        <span class="edit-button edit-button--small" data-testid="buttonEditDescription" @click="descriptionEditmode()" v-if="!descriptionEdit && !readOnly"><i class="fas fa-pen"></i></span>
                        <span class="edit-button edit-button--small" data-testid="buttonSaveDescription" @click="saveDescription()" v-if="descriptionEdit"><i class="fas fa-save"></i></span>
                    </label>
                    <div style="overflow: auto; max-height: 250px;">
                        <p data-testid="descriptionText" v-if="!descriptionEdit && (incidentInfo.incidentCategory && incidentInfo.incidentCategory.dutch_name !== 'INLOSSALDO' && incidentInfo.incidentCategory.dutch_name !== 'AMORTISATIESCHEMA' && incidentInfo.incidentCategory.dutch_name !== 'RENTETABEL')" v-html="incidentInfo.incident_description" style="margin-bottom: 0; float: left; max-width: 90%; width: auto;"></p>
                    </div>
                    <span class="lang" v-if="incidentInfo.reporter_locale !== 'nl-NL' && !descriptionEdit && buildingmanager.auto_translate" v-tooltip.right="incidentInfo.incident_description_original" style="float: left; width: 20px; margin: -3px 0 0 5px;">
                        <img src="@/assets/icons/flags/en.svg" v-if="incidentInfo.reporter_locale === 'en-GB'">
                        <img src="@/assets/icons/flags/it.svg" v-if="incidentInfo.reporter_locale === 'it-IT'">
                        <img src="@/assets/icons/flags/pl.svg" v-if="incidentInfo.reporter_locale === 'pl-PL'">
                        <img src="@/assets/icons/flags/ukr.svg" v-if="incidentInfo.reporter_locale === 'uk-UA'">
                        <img src="@/assets/icons/flags/ru.svg" v-if="incidentInfo.reporter_locale === 'ru-RU'">
                        <img src="@/assets/icons/flags/de.svg" v-if="incidentInfo.reporter_locale === 'de-DE'">
                        <img src="@/assets/icons/flags/fr.svg" v-if="incidentInfo.reporter_locale === 'fr-FR'">
                    </span>
                    <span v-if="!descriptionEdit && incidentInfo.incidentCategory">
                        <p v-if="incidentInfo.incidentCategory.dutch_name === 'INLOSSALDO'" style="margin-bottom: 0;">
                                 {{profile.incident_detail.redemption_pre_descr}}
                                 <span v-html="profile.incident_detail.reasons[incidentInfo.incident_description]"></span>
                        </p>
                        <p v-if="incidentInfo.incidentCategory.dutch_name === 'AMORTISATIESCHEMA'" style="margin-bottom: 0;">
                                 {{profile.incident_detail.amortisation_pre_descr}}
                                 <span v-html="profile.incident_detail.reasons[incidentInfo.incident_description]"></span>
                        </p>
                        <p v-if="incidentInfo.incidentCategory.dutch_name === 'RENTETABEL'" style="margin-bottom: 0;">
                                 {{profile.incident_detail.rentetable_pre_descr}}
                                 <span v-html="profile.incident_detail.reasons[incidentInfo.incident_description]"></span>
                        </p>
                    </span>
                    <p v-if="!descriptionEdit && !incidentInfo.incidentCategory" v-html="incidentInfo.incident_description" style="margin-bottom: 0;"></p>
                    <textarea class="form-control" data-testid="inputDescription" v-if="descriptionEdit" v-model="incidentInfo.incident_description" style="resize: vertical;"></textarea>
                    <div v-if="(readOnly && incidentInfo.urgency_level && !incidentInfo.serviced_by_bm_user_id) || (readOnly && incidentInfo.serviced_by_bm_user_id && !admin)">
                        <span class="urgent incident_detail" v-if="incidentInfo.urgency_level"><i class="fas fa-exclamation-circle"></i> URGENT</span>
                    </div>
                    <div v-if="!readOnly || (readOnly && incidentInfo.serviced_by_bm_user_id && admin) && !forwardedReadOnly" style="clear: both;">
                        <div class="row">
                            <div class="col" style="cursor: pointer;">
                                <div class="switch-wrapper" style="margin-top: 10px;">
                                    <label class="switch" style="float: left; margin-top: 2px;">
                                        <input type="checkbox" v-model="incidentInfo.urgency_level" @change="saveUrgency()">
                                        <span class="slider round" data-testid="switchUrgent"></span>
                                    </label>
                                    <span class="urgent active" data-testid="showUrgent" v-if="incidentInfo.urgency_level"><i class="fas fa-exclamation-circle"></i> URGENT</span>
                                    <p v-else style="font-weight: 500; margin-bottom: 0;">Urgent</p>
                                </div>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>
            <IncidentLogModal v-if="logIncidentModalOpen" @close="logIncidentModalOpen = false" :incidentInfo="incidentInfo" />
            <ConnectedIncidentsModal v-if="connectedIncidentsModalOpen" @close="connectedIncidentsModalOpen = false" :incidentInfo="incidentInfo" />
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import IncidentLogModal from "@/components/modals/IncidentLogModal.vue";
import ConnectedIncidentsModal from "@/components/modals/ConnectedIncidentsModal.vue";

export default {
    name: 'MainBlock',
    mixins: [setLocale],
    components: {
        IncidentLogModal,
        ConnectedIncidentsModal
    },
    data: function () {
        return {
            categoryDropdownToggled: false,
            selectedCategory: 0,
            descriptionEdit: false,
            refNumberEdit: false,
            logIncidentModalOpen: false,
            connectedIncidentsModalOpen: false,
            tempCategories: [],
            categorySearchTerm: '',
            started: false,
            categoriesNoResults: false
        }
    },
    props: ['buildingmanager',
            'incidentInfo',
            'readOnly',
            'forwardedReadOnly',
            'admin',
            'categories',
            'readyToFinish',
            'readyForRestart',
            'newIncident',
            'readyForTakeover',
            'commissionStart',
            'hideRestart'
        ],
        beforeDestroy() {
            this.removeHandler();
        },
    created() {
        setTimeout(function () {
            window.addEventListener("scroll", function() {
                let element = document.getElementById("incidentHead");
                let stickElement = document.getElementById("stickBlock");
                if (element) {
                    if(window.scrollY >(element.offsetHeight + element.offsetTop)){
                        this.showStickBlock = true;
                        stickElement.classList.add('active');
                    }
                    else {
                        stickElement.classList.remove('active');
                    }
                }
            });
        }.bind(this), 200);
    },
    methods: {
        descriptionEditmode() {
            this.descriptionEdit = true;
            this.incidentInfo.incident_description = this.incidentInfo.incident_description.replace(/<br\s?\/?>/g,"\n");
        },
        saveDescription() {
            this.descriptionEdit = false;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('description', this.incidentInfo.incident_description);
            this.incidentInfo.incident_description = this.incidentInfo.incident_description.replace(/\r?\n/g, '<br>');
            let self = this;
            fetch('/api/v1/incident/updatedescription', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.tempCategories = [];
            this.categorySearchTerm = '';
            this.selectedCategory = category.id_incident_category;
            this.incidentInfo.incidentCategory.dutch_name = category.dutch_name;
            this.incidentInfo.incidentCategory.english_name = category.english_name;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('id_incident_category', this.selectedCategory);
            let self = this;
            fetch('/api/v1/incident/updatecategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                let successText = self.profile.incident_detail.category +  ' ' + self.profile.toast.edited_successfully;
                self.$vToastify.success(successText, self.profile.toast.category_saved);
            });
        },
        searchCategories(type) {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryName;
                    if (this.locale === 'en') {
                        categoryName = category.english_name.toLowerCase();
                    }
                    else {
                        categoryName = category.dutch_name.toLowerCase();
                    }
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        saveUrgency() {
            const data = new URLSearchParams();
            let urgencyLevel;
            if (this.incidentInfo.urgency_level) {
                urgencyLevel = 5;
            }
            else {
                urgencyLevel = 0;
            }
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('urgency_level', urgencyLevel);
            let self = this;
            fetch('/api/v1/incident/seturgencylevel', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        stickAction(type) {
            if (type === 'restart') {
                this.$store.dispatch('updateStickAction', 'restart');
            }           
            if (type === 'start' && !this.started) {
                this.started = true;
                this.$store.dispatch('updateStickAction', 'start');
            }
            if (type === 'takeover') {
                this.$store.dispatch('updateStickAction', 'takeover');
            }
            if (type === 'commission') {
                this.$store.dispatch('updateStickAction', 'commission');
            }
            if (type === 'recommission') {
                this.$store.dispatch('updateStickAction', 'recommission');
            }
            if (type === 'followup') {
                this.$store.dispatch('updateStickAction', 'followup');
            }
            if (type === 'finishwork') {
                this.$store.dispatch('updateStickAction', 'finishwork');
            }
            if (type === 'finish') {
                this.$store.dispatch('updateStickAction', 'finish');
            }
        },
        addHandler() {
            window.addEventListener('click', this.stickAction('recommission'));
        },
        removeHandler() {
            window.removeEventListener('click', this.stickAction('recommission'));
        },
        closeCategoryDropdown() {
            this.categoryDropdownToggled = false;
        }
    }
}
</script>
