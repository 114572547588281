<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="checkin-buttons" v-if="incidentInfo.calendar_choice === 'choose_latest_possible'" style="float: left; width: 100%; margin-bottom: 10px;">
            <span class="button button--cta button--icon" id="createAppointment" @click="createAppointment()" style="margin-right: 0; float: right;"><i class="fas fa-calendar-day"></i>{{profile.incident_detail.create_appointment}}</span>
        </div>
        <div class="button-header" style="padding: 0; border-radius: 0; float: left; width: 100%;">
            <div class="row">
                <div class="col">
                    <button class="button button--outline today" @click="today()" style="float: left;">Vandaag</button>
                    <div class="view-buttons">
                        <button class="button button--outline switch-button" @click="changeView('week')" style="float: left;">Week</button>
                        <button class="button button--outline switch-button" @click="changeView('day')" style="float: left;">Dag</button>
                    </div>
                    <button class="button button--outline next" @click="next()" style="float: right; margin-left: 5px;"><i class="fas fa-chevron-right"></i></button>
                    <button class="button button--outline prev" @click="prev()" style="float: right;"><i class="fas fa-chevron-left"></i></button>
                    <button class="button button--outline weekends" @click="toggleWeekends()" :class="{active: !calendarOptions.week.workweek}">{{profile.incident_detail.weekend}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h3 style="text-transform: capitalize; padding: 0; background: white; margin: 13px 0 10px 0; float: left;">{{currentMonth}}</h3>
                </div>
            </div>
            <div class="appointment-info">
                <h3 style="margin-bottom: 0; font-size: 12px;">{{profile.incident_detail.latest_appointment}}</h3>
                <p v-if="incidentAppointment.location === 'toPlan'" style="margin-bottom: 0; font-size: 14px;">{{profile.incident_detail.choose_latest_possible}}: {{incidentAppointment.appointmentDate}} |
                    <span class="text-link" @click="createAppointment('new')" style="display: inline; margin: 0; float: none;">{{profile.incident_detail.create_appointment}}</span>
                </p>
                <p style="margin-bottom: 0; font-size: 14px;" v-else>{{incidentAppointment.appointmentDate}}, {{incidentAppointment.appointmentStart}} - {{incidentAppointment.appointmentEnd}} |
                    <span class="text-link" @click="createAppointment('new')" style="display: inline; margin: 0; float: none;">{{profile.incident_detail.edit_appointment_short}}</span>
                </p>
            </div>
        </div>
        <div class="row" @click="changeAppointmentFromInput('start')">
            <div class="col calendar-wrap">
                <Calendar style="height: 800px"
                    ref="calendar"
                    :template="calendarOptions.template"
                    :view="calendarOptions.view"
                    :use-detail-popup="false"
                    :isReadOnly="true"
                    :month="calendarOptions.month"
                    :events="calendarOptions.events"
                    :week="calendarOptions.week"
                    @clickEvent="eventEdit"
                />
                <div class="creation-popup mech" ref="draggableContainer" id="draggable-container" v-if="createNewAppointment">
                    <div class="popup-header" @mousedown="dragMouseDown">
                        <span><i class="fas fa-expand-arrows-alt"></i>{{profile.incident_detail.drag_here}}</span>
                    </div>
                    <h3 v-if="!eventReadOnly && incidentInfo.calendar_choice === 'choose_latest_possible'">{{profile.incident_detail.create_appointment}}</h3>
                    <h3 v-if="!eventReadOnly && incidentInfo.calendar_choice !== 'choose_latest_possible'">{{profile.incident_detail.edit_appointment}}</h3>
                    <span @click="cancelAppointmentEdit()" class="close-popup"><i class="fa fa-times"></i></span>
                    <div v-if="eventReadOnly" class="eventreadonly">
                        <span class="eventDate" v-if="currentEvent.toPlan">{{profile.incident_detail.choose_latest_possible}}: {{currentEvent.appointmentDate}}</span>
                        <span class="eventDate" v-else>{{currentEvent.appointmentDate}}, {{currentEvent.appointmentStart}} - {{currentEvent.appointmentEnd}}</span>
                        <div v-html="currentEvent.title"></div>
                        <router-link tag="a" v-bind:to="'/monteur/incident-detail?id=' + currentEvent.id" style="clear: both;font-weight: 500;">{{currentEvent.id}}</router-link>
                    </div>
                    <div v-else>
                        <form class="new_appointment" v-on:submit.prevent="planAppointment()" style="padding-top: 40px;">
                            <div style="clear: both;">
                                <flat-pickr v-model="newAppointment.date" class="form-control" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" @on-change="changeAppointmentFromInput()" @on-close="changeAppointmentFromInput()"></flat-pickr>
                                <div class="row">
                                    <div class="col-6">
                                        <flat-pickr v-model="newAppointment.start_time" class="form-control" data-testid="selectAppointmentStart"  :config="flatPickrTimeConfig" :placeholder="profile.incident_detail.pick_starttime" @on-change="changeAppointmentFromInput('start')" @on-close="changeAppointmentFromInput('start')" @blur="changeAppointmentFromInput('start')"></flat-pickr>
                                    </div>
                                    <div class="col-6">
                                        <flat-pickr v-model="newAppointment.end_time" class="form-control" data-testid="selectAppointmentEnd" :config="flatPickrTimeConfig" :placeholder="profile.incident_detail.pick_endtime" @on-change="changeAppointmentFromInput()" @on-close="changeAppointmentFromInput()"></flat-pickr>
                                    </div>
                                </div>
                                <span class="notification notification--error" v-if="pastError" style="margin-top: 10px;">{{profile.incident_detail.pastError}}</span>
                                <button type="submit" class="button button--small button--right" data-testid="submitAppointment" v-if="!pastError && !timeError"><i class="far fa-calendar"></i>
                                    <span v-if="!eventReadOnly && incidentInfo.calendar_choice === 'choose_latest_possible'">{{profile.incident_detail.create_appointment}}</span>
                                    <span v-if="!eventReadOnly && incidentInfo.calendar_choice !== 'choose_latest_possible'">{{profile.incident_detail.edit_appointment}}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import { EventBus } from '@/js/event-bus.js';
import Calendar from '@toast-ui/vue-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

export default {
    name: 'Schedule',
    mixins: [setLocale],
    components: {
        flatPickr,
        Calendar
    },
    data: function () {
        return {
            loading: false,
            calendarOptions: {
                view: 'week',
                week: {
                    dayNames: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
                    workweek: true,
                    taskView: false,
                    hourStart: 7,
                    hourEnd: 21,
                    visibleWeeksCount: 3
                },
                month: {
                    dayNames: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
                    visibleWeeksCount: 3,
                },
                template: {
                    timegridDisplayPrimaryTime({ time }) {
                        let date = time.d.d;
                        let times = moment(date).format('HH:mm');
                        return times;
                    },
                    timegridDisplayTime({ time }) {
                        return `sub timezone: ${time}`;
                    },
                    alldayTitle() {
                        return 'Uiterste<br />uitvoer';
                    }
                },
                events: []
            },
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            newAppointment: {
                date: null,
                start_time: null,
                end_time: null
            },
            createNewAppointment: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            },
            eventReadOnly: false,
            appointmentEdit: false,
            currentEvent: {},
            appointmentPassed: false,
            currentMonth: '',
            latestPossible: false,
            timeError: false,
            pastError: false,
            tempAdditionalMechanic: '',
            incidentAppointment: {}
        }
    },
    props: ['incidentId', 'incidentInfo', 'readOnly', 'admin', 'selectedMechanic', 'mechanics', 'mechanicsNoS1mone'],
    computed: {
        calendarInstance() {
            if (this.$refs.calendar) {
                return this.$refs.calendar.getInstance();
            }
            else {
                return ''
            }
        }
    },
    created() {
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        this.getAppointments();
        if (this.incidentInfo.id_appointment) {
            this.toAppointment();
        }
    },
    methods: {
        toggleWeekend() {
            if (this.weekend) {
                this.calendarOptions.week.workweek = false;
            }
            else {
                this.calendarOptions.week.workweek = true;
            }
        },
        getAppointments() {
            this.eventReadOnly = false;
            this.calendarOptions.events = [];
            let self = this;
            fetch('/api/v1/appointment/planned')
            .then(response => { response.json().then(
                function(data) {
                    let appointments = data.data;
                    if (appointments.length) {
                        let tempEvents = [];
                        for (var i = 0; i < appointments.length; i++) {
                            let event = appointments[i];
                            event.startTime = new Date(event.start_time);
                            event.endTime = new Date(event.end_time);
                            let calendarEvent = {};
                            calendarEvent.id = event.id_incident;
                            let date = moment(event.start_time).locale('nl').format('D MMMM');
                            let start = moment(event.start_time).locale('nl').format('h:mm');
                            let end = moment(event.end_time).locale('nl').format('h:mm');
                            calendarEvent.title = '<h3>' + event.asset_name + ' |</h3><span class="description" style="display: block; font-weight: 500;">' + event.description + '|</span><p>' + event.address + '</p>';
                            calendarEvent.start = new Date(event.start_time);
                            calendarEvent.end = new Date(event.end_time);
                            calendarEvent.isReadOnly = true;
                            calendarEvent.color = 'white';
                            calendarEvent.calendarId = '1';
                            calendarEvent.backgroundColor = '#2AB69D';
                            if (event.status === 'on_location_mechanic'
                                || event.status === 'fixed_mechanic'
                                || event.status === 'fixed_agreed_auto'
                                || event.status === 'closed'
                                || event.status === 'cancelled'
                                || event.status === 'fixed_agreed_repaircompany'
                                || event.status === 'maintenance_checkin_mechanic'
                            ) {
                                calendarEvent.backgroundColor = '#ccc';
                            }
                            let startTime = calendarEvent.start.getHours();
                            let endTime = calendarEvent.end.getHours();
                            if (startTime === 1 & endTime === 23) {
                                calendarEvent.isAllday = true;
                            }
                            if (calendarEvent.id === self.incidentInfo.id_incident) {
                                self.incidentAppointment = calendarEvent;
                                self.eventReadOnly = false;
                                calendarEvent.isReadOnly = false;
                                calendarEvent.backgroundColor = 'orange';
                                calendarEvent.appointmentDate = moment(calendarEvent.start).locale('nl').format('D MMMM');
                                calendarEvent.appointmentStart = moment(calendarEvent.start).locale('nl').format('HH:mm');
                                calendarEvent.appointmentEnd = moment(calendarEvent.end).locale('nl').format('HH:mm');
                            }

                            calendarEvent.customStyle = {
                                borderLeft: 'none'
                            }
                            tempEvents.push(calendarEvent);
                            self.calendarOptions.events = tempEvents;
                            self.toAppointment();
                            self.loading = false;
                        }
                        setTimeout(function () {
                            let blocks = document.querySelectorAll('.toastui-calendar-template-time span');
                            blocks.forEach(function(el) {
                                el.innerHTML = el.innerHTML.replaceAll('|', '<br />')
                            });
                            if (localStorage.getItem('S1-CreateAppointment')) {
                                self.createAppointment('new');
                                localStorage.removeItem('S1-CreateAppointment');
                            }
                        }.bind(this), 1000);
                    }
                    self.getAppointmentsToPlan();
                });
            });
        },
        getAppointmentsToPlan() {
            let self = this;
            fetch('/api/v1/appointment/toplan')
            .then(response => { response.json().then(
                function(data) {
                    let appointments = data.data;
                    if (appointments.length) {
                        for (var i = 0; i < appointments.length; i++) {
                            let event = appointments[i];
                            event.startTime = new Date(event.start_time);
                            event.endTime = new Date(event.end_time);
                            let calendarEvent = {};
                            calendarEvent.id = event.id_incident;
                            let date = moment(event.start_time).locale('nl').format('D MMMM');
                            let start = moment(event.start_time).locale('nl').format('h:mm');
                            let end = moment(event.end_time).locale('nl').format('h:mm');
                            calendarEvent.title = '<h3>' + event.asset_name + ' |</h3><span class="description" style="display: block; font-weight: 500;">' + event.description + '|</span><p>' + event.address + '</p>';
                            calendarEvent.start = new Date(event.start_time);
                            calendarEvent.end = new Date(event.end_time);
                            calendarEvent.isReadOnly = true;
                            calendarEvent.color = 'white';
                            calendarEvent.calendarId = '1';
                            calendarEvent.backgroundColor = '#2AB69D';
                            calendarEvent.location = 'toPlan';
                            if (event.status === 'on_location_mechanic'
                                || event.status === 'fixed_mechanic'
                                || event.status === 'fixed_agreed_auto'
                                || event.status === 'closed'
                                || event.status === 'cancelled'
                                || event.status === 'fixed_agreed_repaircompany'
                                || event.status === 'maintenance_checkin_mechanic'
                            ) {
                                calendarEvent.backgroundColor = '#ccc';
                            }
                            let startTime = calendarEvent.start.getHours();
                            let endTime = calendarEvent.end.getHours();
                            if (startTime === 1 & endTime === 23) {
                                calendarEvent.isAllday = true;
                            }
                            if (calendarEvent.id === self.incidentId) {
                                self.incidentAppointment = calendarEvent;
                                self.eventReadOnly = false;
                                calendarEvent.isReadOnly = false;
                                calendarEvent.backgroundColor = 'orange';
                                calendarEvent.appointmentDate = moment(calendarEvent.start).locale('nl').format('D MMMM');
                            }
                            calendarEvent.customStyle = {
                                borderLeft: 'none'
                            }
                            self.calendarOptions.events.push(calendarEvent);
                            self.loading = false;
                        }
                        setTimeout(function () {
                            let blocks = document.querySelectorAll('.toastui-calendar-template-time span');
                            blocks.forEach(function(el) {
                                el.innerHTML = el.innerHTML.replaceAll('|', '<br />')
                            });
                        }.bind(this), 1000);
                    }
                });
            });
        },
        prev() {
            this.calendarInstance.prev();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
        },
        next() {
            this.calendarInstance.next();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
        },
        changeView(type) {
            if (type === 'week') {
                this.calendarInstance.changeView('week');
            }
            else {
                this.calendarInstance.changeView('day');
            }
        },
        today() {
            this.calendarInstance.today();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
        },
        toggleWeekends() {
            if (this.calendarOptions.week.workweek) {
                this.calendarOptions.week.workweek = false;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: false
                    }
                });
            }
            else {
                this.calendarOptions.week.workweek = true;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: true
                    }
                });
            }
        },
        changeAppointmentFromInput(type) {
            if (this.newAppointment.start_time) {
                let date;
                if (this.newAppointment.date.toString().includes('-')) {
                    date = new Date(this.newAppointment.date);
                }
                else {
                    date = this.newAppointment.date;
                }
                // Create start moment for event
                let startHour = this.newAppointment.start_time.split(':')[0];
                let startMinutes = this.newAppointment.start_time.split(':')[1]
                startHour = Number(startHour);
                startMinutes = Number(startMinutes);
                let startTime = date.setHours(startHour, startMinutes);
                let startTimeDate = new Date(startTime);
                if (type === 'start') {
                    let endTime = this.newAppointment.end_time = this.newAppointment.start_time.split(':')[0];
                    endTime = Number(endTime) + 1;
                    endTime = endTime.toString();
                    endTime = endTime + ':' + this.newAppointment.start_time.split(':')[1];
                    this.newAppointment.end_time = endTime;
                }
                // Create end moment for event
                let endHour = this.newAppointment.end_time.split(':')[0];
                let endMinutes = this.newAppointment.end_time.split(':')[1];
                endMinutes = Number(endMinutes);
                let endTime = date.setHours(endHour, endMinutes);
                let endTimeDate = new Date(endTime);
                let id = Number(this.incidentId);
                let now = new Date();
                now = now.getTime();
                let planDate = startTimeDate.getTime();
                if (planDate < now) {
                    this.pastError = true;
                }
                else {
                    this.pastError = false;
                }
                this.calendarInstance.updateEvent(id, '1', {
                    start: startTimeDate,
                    end: endTimeDate
                });
            }
        },
        planAppointment() {
            this.loading = true;
            this.createNewAppointment = false;
            let start_time = new Date(this.newAppointment.date);
            let end_time = new Date(this.newAppointment.date);
            let time_start = this.newAppointment.start_time.split(':');
            let time_end = this.newAppointment.end_time.split(':');
            start_time.setHours(time_start[0], time_start[1]);
            end_time.setHours(time_end[0], time_end[1]);
            start_time = start_time.getTime();
            end_time = end_time.getTime();
            let now = new Date();
            now = now.getTime();
            let planDate = start_time;
            if (planDate < now) {
                this.pastError = true;
            }
            else {
                this.pastError = false;
            }
            if (this.appointmentEdit) {
                const data = new URLSearchParams();
                let paramStart = 'data[' + this.incidentInfo.id_appointment + '][start_time]';
                let paramEnd = 'data[' + this.incidentInfo.id_appointment + '][end_time]';
                let paramId = 'data[' + this.incidentInfo.id_appointment + '][id_incident]';
                let paramMechanic = 'data[' + this.incidentInfo.id_appointment + '][id_mechanic]';
                data.append('action', 'edit');
                data.append(paramStart, start_time);
                data.append(paramEnd, end_time);
                data.append(paramId, this.incidentId);
                data.append(paramMechanic, this.incidentInfo.id_mechanic);
                let self = this;
                fetch('/api/v1/appointment', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.appointmentEdit = false;
                    self.createNewAppointment  = false;
                    self.getIncidentInfo();
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.loading = false;
                    EventBus.$emit('eventPlanned', true);
                });
            }
        },
        startNewAppointment() {
            this.createNewAppointment = true;
            let now = new Date();
            this.newAppointment.date = now;
            this.newAppointment.start_time = moment(now).add(5, 'minutes').format().split('T')[1];
        },
        cancelAppointmentEdit() {
            this.createNewAppointment = false;
            self.appointmentEdit = false;
        },
        eventEdit(event) {
            this.eventReadOnly = event.event.isReadOnly;
            if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'none';
            }
            let strippedTitle = event.event.title.replaceAll('|', '');
            this.createNewAppointment = true;
            this.appointmentEdit = true;
            let appointmentDate = moment(event.event.start.d.d).locale('nl').format('D MMMM');
            let appointmentStart = moment(event.event.start.d.d).locale('nl').format('h:mm');
            let appointmentEnd = moment(event.event.end.d.d).locale('nl').format('h:mm');
            let currentEvent = {
                title: strippedTitle,
                body: event.event.body,
                id: event.event.id,
                appointmentDate: appointmentDate,
                appointmentStart: appointmentStart,
                appointmentEnd: appointmentEnd,
                toPlan: event.event.location
            }
            this.currentEvent = currentEvent;
            if (event.event.isReadOnly) {
                this.eventReadOnly = true;
            }
            else {
                this.eventReadOnly = false;
            }
            let now = new Date();
            now = now.getTime();
            let start_time;
            let end_time;
            if (event.changes && event.changes.start) {
                start_time = event.changes.start.d.d.getTime();
                this.newAppointment.date = event.changes.start.d.d;
                this.newAppointment.start_time = moment(event.changes.start.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    start: event.changes.start.d.d
                });
            }
            else {
                if (event.event.id === this.incidentId) {
                    start_time = event.event.start.d.d.getTime();
                    this.newAppointment.date = event.event.start.d.d;
                    this.newAppointment.start_time = moment(event.event.start.d.d).format().split('T')[1];
                    this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                        start: event.event.start.d.d
                    });
                }
            }
            if (event.changes && event.changes.end) {
                end_time = event.changes.end.d.d.getTime();
                this.newAppointment.end_time = moment(event.changes.end.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    end: event.changes.end.d.d
                });
            }
            else {
                if (event.event.id === this.incidentId) {
                    end_time = event.event.end.d.d.getTime();
                    this.newAppointment.end_time = moment(event.event.end.d.d).format().split('T')[1];
                    this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                        end: event.event.end.d.d
                    });
                }
            }
            if (start_time < now) {
                this.pastError = true;
                // this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                //     isReadOnly: true
                // });
            }
            else {
                this.pastError = false;
            }
            if (event.event.location === 'toPlan') {
                this.newAppointment.start_time = moment(this.newAppointment.date).set('hour', 9).format();
                this.newAppointment.end_time = moment(this.newAppointment.date).set('hour', 10).format();
                this.newAppointment.start_time = this.newAppointment.start_time.split('T')[1];
                this.newAppointment.end_time = this.newAppointment.end_time.split('T')[1];
            }
        },
        createAppointment(type) {
            this.createNewAppointment = true;
            this.appointmentEdit = true;
            for (var i = 0; i < this.calendarOptions.events.length; i++) {
                let appointment = this.calendarOptions.events[i];
                if (appointment.id === this.incidentId) {
                    let strippedTitle = appointment.title.replaceAll('|', '');
                    this.currentEvent = appointment;
                    this.currentEvent.title = strippedTitle;
                }
            }
            let now = new Date();
            now = now.getTime();
            let start_time;
            let end_time;
            start_time = this.currentEvent.start.getTime();
            end_time = this.currentEvent.end.getTime();
            this.newAppointment.date = this.currentEvent.start;
            if (type === 'new') {
                this.newAppointment.start_time = moment(this.currentEvent.start).set('hour', 9).format();
                this.newAppointment.end_time = moment(this.currentEvent.end).set('hour', 10).format();
            }
            else {
                this.newAppointment.start_time = moment(this.currentEvent.start).format();
                this.newAppointment.end_time = moment(this.currentEvent.end).format();
            }
            this.newAppointment.start_time = this.newAppointment.start_time.split('T')[1];
            this.newAppointment.end_time = this.newAppointment.end_time.split('T')[1];
            if (start_time < now) {
                this.pastError = true;
            }
            else {
                this.pastError = false;
            }
        },
        toAppointment() {
            setTimeout(function () {
                let date = new Date(this.incidentInfo.start_time);
                if (this.locale === 'nl') {
                    this.currentMonth = moment(date).locale('nl').format('MMMM');
                }
                else {
                    this.currentMonth = moment(date).format('MMMM');
                }
                let parsedDate = moment(date).format();
                parsedDate = parsedDate.split('T')[0];
                if (this.calendarInstance) {
                    this.calendarInstance.setDate(parsedDate);
                }
            }.bind(this), 500);
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        },
        getIncidentInfo() {
            this.$emit('getIncidentInfo');
            this.getAppointments();
        }
    }
}
</script>
