<template>
    <div class="block block--account block--border" style="margin: 20px 0 0 0;" v-if="accountInfo.autodispatch_invoice_type">
        <div class="account-info">
            <h4>{{profile.invoicing.default_invoice_party}}
            </h4>
            <div class="account-contents">
                <p v-if="!accountInfo.autodispatch_invoice_type || accountInfo.autodispatch_invoice_type === 'NONE'" style="display: block; font-size: 16px; margin: -5px 0 5px 0;">{{ profile.invoicing.n_a_set }}.</p>   
                <div class="dropdown-section" style="margin-bottom: 20px;">
                    <span class="dropdown" v-on:click="invoiceDropdownToggled = !invoiceDropdownToggled" :class="{active: invoiceDropdownToggled}">
                        <span v-if="!accountInfo.autodispatch_invoice_type || accountInfo.autodispatch_invoice_type === 'NONE'">{{profile.invoicing.select_invoice_party}} *</span>
                        <span v-if="accountInfo.autodispatch_invoice_type && accountInfo.autodispatch_invoice_type === 'BUILDINGMANAGER'" class="selected">{{profile.invoicing.manager}}</span>
                        <span v-if="accountInfo.autodispatch_invoice_type && accountInfo.autodispatch_invoice_type === 'OWNER'" class="selected">{{profile.invoicing.vve_owner}}</span>
                        <span v-if="accountInfo.autodispatch_invoice_type && accountInfo.autodispatch_invoice_type === 'OWNER_PA_BUILDINGMANAGER'" class="selected">{{profile.invoicing.owner_pa_manager}}</span>
                        <span v-if="accountInfo.autodispatch_invoice_type && accountInfo.autodispatch_invoice_type === 'OTHER'" class="selected">{{profile.invoicing.invoiceparty_na}}</span>
                        <i class="fas fa-chevron-down chevron" v-if="accountInfo.building_manager_admin"></i></span>
                    <div class="dropdown-list" v-if="invoiceDropdownToggled && accountInfo.building_manager_admin">
                        <ul>
                            <li @click="selectInvoiceParty('OWNER')">{{profile.invoicing.vve_owner}}</li>
                            <li @click="selectInvoiceParty('BUILDINGMANAGER')">{{profile.invoicing.manager}}</li>
                            <li @click="selectInvoiceParty('OWNER_PA_BUILDINGMANAGER')">{{profile.invoicing.owner_pa_manager}}</li>
                            <li @click="selectInvoiceParty('OTHER')">{{profile.invoicing.invoiceparty_na}}</li>
                            <li @click="selectInvoiceParty('NONE')"><span style="color: #9f9f9f;">{{profile.invoicing.n_a}}</span></li>
                        </ul> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    name: 'MandatesBlock',
    mixins: [setLocale],
    data: function () {
        return {
            profile: {},
            invoiceDropdownToggled: false
        }
    },
    props: ['accountInfo'],
    methods: {
        selectInvoiceParty(type) {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][autodispatch_invoice_type]', type);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.invoiceDropdownToggled = false;
                self.accountInfo.autodispatch_invoice_type = type;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
