<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.incident_detail.fixed_mechanic}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="checkOut()">
                    <div class="form-field" :class="{focus:fields.feedback.length}">
                        <label>{{profile.incident_detail.feedback}}</label>
                        <textarea class="form-control" v-model="fields.feedback"></textarea>
                    </div>
                    <div class="switch-wrapper" v-if="incidentInfo.canbe_id_repair_company === incidentInfo.id_repair_company">
                        <label class="switch">
                            <input type="checkbox" v-model="fields.followup">
                            <span class="slider round"></span>
                        </label>
                        <span><b>{{profile.incident_detail.followup_label}}</b></span>
                    </div>
                    <button type="submit" class="button button--icon"><i class="fas fa-pause"></i>Check out</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';

export default {
    mixins: [setLocale],
    name: 'MechanicCheckOutModal',
    data() {
        return {
            profile: {},
            fields: {
                feedback: '',
                followup: false
            }
        }
    },
    props: ['incidentId', 'incidentInfo', 'qrCode'],
    methods: {
        checkOut() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('checkin', false);
            data.append('checkout', true);
            data.append('qr_code', this.qrCode);
            data.append('checkout_message', this.fields.feedback);
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/checkincheckout', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                if (data.error) {
                    self.error = true;
                    self.scannerKey += 1;
                }
                else {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    document.querySelector('body').classList.remove('modal-open');
                }
                if (self.fields.followup) {
                    self.followUp();
                }
                else {
                    self.$emit('checkout');
                }
            });
        },
        followUp() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('wants_copy', true);
            let self = this;
            fetch('/api/v1/incident/setcopywanted', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('checkout');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
