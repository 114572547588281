<template>
    <div class="block block--incident_detail" style="min-height: 0;" :class="{nonotes: !notes.length}">
          <div class="incident-notes" style="position: relative;">
              <div class="incident-head">
                  <h1 style="max-width: 100%; font-size: 18px;">{{profile.incident_detail.notes}} <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.notes_tooltip"></i></h1>
                  <span data-testid="noteAddButton" class="edit-button edit-button--small icon" @click="createNoteModalOpen = true;" style="margin-top: 0;" v-if="!readOnly"><i class="fas fa-plus" style="font-size: 18px;"></i></span>
              </div>
              <div v-for="(note, index) in notes" :key="index">
                  <div class="note" @click="selectNote(note)" v-tooltip.right="note.content">
                      <span data-testid="noteAuthorText" class="written-by"><i class="fas fa-feather"></i> {{note.actual_name}}</span>
                      <span data-testid="noteViolationText" class="mandate-violation" :class="{active: incidentInfo.mandate_warning}" v-if="note.note_type === 'mandate'"><i class="fa fa-exclamation"></i> {{profile.mandates.mandate_violation}}</span>
                      <span data-testid="noteUrlText" style="clear: both; text-decoration: underline; color: #007bff;" v-if="note.content.includes('https://')" @click.stop="goUrl(note.content)">{{note.content}}</span>
                      <span data-testid="noteDescriptionText" style="clear: both;" v-else>{{note.content}}</span>
                      <b data-testid="noteDateText">{{note.date}}</b>
                  </div>
              </div>
              <CreateNoteModal v-if="createNoteModalOpen" @close="createNoteModalOpen = false" :supplier="true" :incidentInfo="incidentInfo" @getnotes="getNotes" />
              <EditNoteModal v-if="editNoteModalOpen" @close="editNoteModalOpen = false" :supplier="true" :incidentInfo="incidentInfo" :note="selectedNote" @getnotes="getNotes" />
          </div>
    </div>
</template>

<script>
import moment from 'moment';
import { setLocale } from '@/mixins/setLocale-supplier.js';
import CreateNoteModal from "@/components/modals/notes/CreateNoteModal.vue";
import EditNoteModal from "@/components/modals/notes/EditNoteModal.vue";
import sortBy from 'lodash/sortBy';
import { EventBus } from '@/js/event-bus.js';

export default {
    name: 'Notes',
    mixins: [setLocale],
    components: {
        CreateNoteModal,
        EditNoteModal
    },
    data: function () {
        return {
            notes: [],
            profile: {},
            createNoteModalOpen: false,
            editNoteModalOpen: false,
            removeNoteModalOpen: false,
            selectedNote: {}
        }
    },
    props: ['incidentInfo', 'readOnly'],
    created() {
        this.getNotes();
    },
    methods: {
        getNotes() {
            this.notes = [];
            let self = this;
            fetch('/api/v1/incident_note/byidincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    let mandateNotes = [];
                    for (var i = 0; i < data.length; i++) {
                        let note = data[i];
                        if (note.id_repair_company && note.note_type !== 'mandate') {
                            self.notes.push(note);
                        }
                        if (note.note_type === 'mandate') {
                            mandateNotes.push(note);
                        }
                        if (self.locale === 'nl') {
                            note.date = moment(note.created).locale('nl').format('D MMM YYYY');
                        }
                        else {
                            note.date = moment(note.created).format('MMM Do YYYY');
                        }
                    }
                    self.notes = sortBy(self.notes, 'created');
                    self.notes.reverse();
                    mandateNotes = sortBy(mandateNotes, 'created');
                    let latestMandateNote = mandateNotes.at(-1);
                    EventBus.$emit('mandateNote', latestMandateNote);
                });
            });
        },
        selectNote(note) {
            this.selectedNote = note;
            this.editNoteModalOpen = true;
        },
        goUrl(url) {
            window.open(url, '_blank');
        }
    }
}
</script>
