<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.enable_employee}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.areyousurethat}} <b>{{selectedUser.username}}</b> {{profile.wanttoenable}}<br /><br /></p>
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col">
                        <div class="notification notification--error" v-if="alreadyExists">
                            <p><i class="fas fa-exclamation-circle"></i> {{profile.enable_employee_exists}}</p>
                        </div>
                    </div>
                </div>
                <button class="button button--icon button--right" data-testid="employeeSubmitActivateButton" v-on:click="enableEmployee()"><i class="fas fa-user"></i>{{profile.enable_employee}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'enableEmployeeModal',
    props: ['selectedUser'],
    data() {
        return {
            profile: {},
            alreadyExists: false
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        enableEmployee() {
            const data = new URLSearchParams();
            data.append('id_shiro_user', this.selectedUser.id_shiro_user);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/reenable', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                if (data.EXISTS === 'TRUE') {
                    self.alreadyExists = true;
                }
                else {
                    self.closeModal();
                }
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
