<template>
    <div>
        <p style="margin-bottom: 0;" v-if="!actionLists.length && !searchActive">{{profile.actionable.no_planned_recordings}}</p>
        <div class="row">
            <div class="col-sm-9">
                <section class="search search-inpage" :class="{active: searchActive}">
                    <form v-on:submit.prevent="search()">
                        <input type="text" ref="search" v-bind:placeholder="profile.actionable.search_street" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="search($event)"  />
                        <span class="close-suggestions" @click="clearSearch()" v-if="searchActive"><i class="fas fa-times"></i></span>
                    </form>
                </section>
            </div>
            <div class="col-sm-3" v-if="houseNumbers.length">
                <div class="dropdown-section less-border" style="margin-top: 10px;">
                    <span class="dropdown" v-on:click="houseNumbersDropdownToggled = !houseNumbersDropdownToggled" :class="{active: houseNumbersDropdownToggled}">
                        <span v-if="!selectedHouseNumber">{{profile.places.placetable.house_number}} *</span>
                        <span v-if="selectedHouseNumber" class="selected">{{selectedHouseNumber}}</span>
                        <i class="fas fa-times times" v-if="selectedHouseNumber" @click.stop="deselectHouseNumber()"></i>
                        <i class="fas fa-chevron-down chevron" v-else></i>
                    </span>
                    <div class="dropdown-list" v-if="houseNumbersDropdownToggled">
                        <ul>
                            <li v-for="(houseNumber, index) in houseNumbers" v-on:click="selectHouseNumber(houseNumber.house_number)" :key="index">
                                <span>{{houseNumber.house_number}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="searchActive">
            <div class="col">
                <h4 class="result-counter" v-if="!actionLists.length" style="margin-bottom: 20px;">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                <h4 class="result-counter" v-if="actionLists.length >= 1" style="margin-bottom: 20px;">{{actionLists.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped" v-if="actionLists.length">
                    <thead>
                        <tr>
                            <th scope="col" @click="sortTable('asset_name')" :class="{active: listsSortType === 'asset_name'}">{{profile.incidents.new_incident.id_asset}} <i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('planned_action_list_name')" :class="{active: listsSortType === 'planned_action_list_name'}">{{profile.actionable.recordingslist}} <i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('latest_possible')" :class="{active: listsSortType === 'latest_possible'}">{{profile.actionable.latest_recording_date}} <i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('mechanic_name')" :class="{active: listsSortType === 'mechanic_name'}">{{profile.incident_detail.mechanic_comment}} <i class="fas fa-sort"></i></th>
                            <th scope="col">{{profile.actionable.number_actions}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="list in actionLists" :key="list.id_planned_action_list" @click="selectActionList(list)">
                            <td><b>{{list.asset_name}}</b><br />{{list.address}} {{list.house_number}}, {{profile.incident_detail.floor_name}} {{list.floor_name}} <br />{{list.place_name}}</td>
                            <td>{{list.planned_action_list_name}}</td>
                            <td>{{list.latestDate}}</td>
                            <td>{{list.mechanic_name}}</td>
                            <td>{{list.planned_actions.length}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ActionListModal :selectedActionList="selectedActionList" :mechanics="mechanics" v-if="actionlistModalOpen" @close="getActionLists()" @success="getActionLists()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import debounce from 'lodash/debounce';
import moment from 'moment';
import ActionListModal from "@/components/modals/recordings/ActionListModal.vue";
import uniqBy from 'lodash/uniqBy';
export default {
    mixins: [setLocale],
    components: {
        ActionListModal
    },
    props: ['mechanics'],
    data() {
        return {
            profile: {},
            searchTerm: '',
            searchActive: false,
            actionLists: [],
            listsSortType: 'name',
            sortOrder: 'asc',
            selectedActionList: {},
            actionlistModalOpen: false,
            houseNumbers: [],
            houseNumbersDropdownToggled: false,
            selectedHouseNumber: null
        }
    },
    created() {
        this.getActionLists();
    },
    methods: {
        search($event) {
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                this.searchActionLists();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.getActionLists();
            }
            if ($event) {
                if($event.keyCode === 27) {
                    this.searchActive = false;
                    this.searchTerm = '';
                    this.getActionLists();
                }
            }     
        },
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.getActionLists();
        },
        getActionLists() {
            this.actionlistModalOpen = false;
            this.actionLists = [];
            let orderParam = '&sort_dir=' + this.sortOrder;
            let sortParam = '';
            if (this.listsSortType) {
                sortParam = '&sort_col=' + this.listsSortType;
            }
            let self = this;
            fetch('/api/v1/plannedactionlist/nicewithactions?limit=100' + orderParam + sortParam)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let list = data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            list.latestDate  = moment(list.latest_possible).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            list.latestDate  = moment(list.latest_possible).locale('en').format('MMMM D YYYY');
                        }
                        list.groups = [];
                        if (list.planned_actions.length && (list.status !== 'completed' && list.status !== 'archived')) {
                            for (var j = 0; j < list.planned_actions.length; j++) { 
                                let action = list.planned_actions[j];
                                action.sure_remove = false;
                            }
                            self.actionLists.push(list);
                        }
                    }
                });
            });
        },
        searchActionLists: debounce(function (e) {
            this.actionLists = [];
            this.houseNumbers = [];
            let searchParam = '';
            if (this.searchTerm) {
                searchParam = '?search_term=' + this.searchTerm;
            }
            let orderParam = '&sort_dir=' + this.sortOrder;
            let sortParam = '';
            if (this.listsSortType) {
                sortParam = '&sort_col=' + this.listsSortType;
            }
            let self = this;
            fetch('/api/v1/plannedactionlist/searchnicewithactions' + searchParam + orderParam + sortParam)
            .then(response => { response.json().then(
                function(data) {
                    self.getHouseNumbers();
                    for (var i = 0; i < data.length; i++) {
                        let list = data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            list.latestDate  = moment(list.latest_possible).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            list.latestDate  = moment(list.latest_possible).locale('en').format('MMMM D YYYY');
                        }
                        list.groups = [];
                        if (list.planned_actions.length && list.status !== 'completed') {
                            for (var j = 0; j < list.planned_actions.length; j++) {
                                let action = list.planned_actions[j];
                                action.sure_remove = false;
                            }
                            self.actionLists.push(list);
                        }
                    }
                });
            });
        }, 500),
        getHouseNumbers() {
            let self = this;
            fetch('/api/v1/place/housenumbersbyaddress?address=' + this.searchTerm)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length > 1) {
                        for (var i = 0; i < self.actionLists.length; i++) {
                           let actionList = self.actionLists[i];
                           for (var j = 0; j < data.length; j++) {
                               let houseNumber = data[j];
                               if (actionList.house_number === houseNumber.house_number) {
                                   self.houseNumbers.push(houseNumber);
                               }
                           }
                           self.houseNumbers = uniqBy(self.houseNumbers, 'house_number');
                       }
                    }
                });
            });
        },
        selectHouseNumber(housenumber) {
            let tempLists = this.actionLists;
            this.houseNumbersDropdownToggled = false;
            this.selectedHouseNumber = housenumber;
            this.actionLists = [];
            for (var i = 0; i < tempLists.length; i++) {
                let actionList = tempLists[i];
                if (actionList.house_number === housenumber) {
                    this.actionLists.push(actionList);
                }
            }
        },
        deselectHouseNumber() {
            this.selectedHouseNumber = null;
            this.houseNumbersDropdownToggled = false;
            this.searchActionLists();
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.listsSortType = sorter;
            if (this.searchActive) {
                this.searchActionLists();
            }
            else {
                this.getActionLists();
            }
        },
        selectActionList(list) {
            this.selectedActionList = list;
            this.actionlistModalOpen = true;
        }
    }
}
</script>
