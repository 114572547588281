<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-photo-video"></i> {{profile.incident_detail.remove_file}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p v-html="profile.incident_detail.remove_file_sure"></p>
                <div class="row">
                    <div class="col">
                        <img :src="file.file_url" v-if="file.content_type === 'image/jpeg' || file.content_type === null && !file.i_frame_code" style="width: 100px; margin-top: 10px;" />
                        <video v-if="file.content_type === 'video/mp4'"><source :src="file.file_url" type="video/mp4"></video>
                        <a v-if="file.pdf && file.description" :href="file.file_url" target="blank" style="font-size: 14px; margin: 10px 0 20px 0;"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>{{file.description}}</a>
                        <a v-if="file.pdf && !file.description" :href="file.file_url" target="blank" style="font-size: 14px; margin: 10px 0 20px 0;"><i class="fas fa-file-pdf" style="margin-right: 5px;"></i>{{file.filename}}</a>
                    </div>
                </div>
                <button class="button button--icon button--right" data-testid='submitMediaRemoval' v-on:click="removeFile()"><i class="fas fa-trash-alt"></i>{{profile.incident_detail.remove_file}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['file', 'incidentId'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        removeFile() {
            if (this.file.length) {
                this.removeInvoices();
            }
            else {
                const data = new URLSearchParams();
                data.append('id_file_upload', this.file.id_file_upload);
                let self = this;
                fetch('/api/v1/fileupload/deletefile', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    if (self.file.type === 'quotation_pdf_supplier') {
                        self.removeQuotationValues();
                    }
                    if (self.file.type === 'invoice_pdf_supplier') {
                        self.removeInvoiceValues();
                    }
                    else {
                        self.$store.dispatch('updateFileRemoved', true);
                        document.querySelector('body').classList.remove('modal-open');
                        self.$emit('success');
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    }
                });
            }
        },
        removeInvoices() {
            let self = this;
            fetch('/api/v1/order/getsupplierinvoices?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let invoice = data[i];
                        self.removeInvoiceValues(invoice.id_supplier_invoice);      
                        const params = new URLSearchParams();
                        params.append('id_incident_invoice', invoice.id_supplier_invoice);
                        fetch('/api/v1/order/removesupplierinvoice', {
                            method: 'post',
                            body: params
                        }).then((resp) => {return resp.text();
                        }).then(function(data) {
                            self.$store.dispatch('updateInvoices', []);
                        });
                    }
                });
            }); 
        },
        removeQuotationValues() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('quotation_value_supplier', '');
            data.append('quotation_number_supplier', '');
            let self = this;
            fetch('/api/v1/order/supplierquotation', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
            });
        },
        removeInvoiceValues(id) {
            const data = new URLSearchParams();
            data.append('id_incident_invoice', id);
            data.append('invoice_value_supplier', '');
            data.append('invoice_number_supplier', '');
            let self = this;
            fetch('/api/v1/order/updatesupplierinvoice', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
            });
        }
    }
}
</script>
