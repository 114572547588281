<template>
    <div>
        <span class="edit-button icon" @click="newRepeatingActionModalOpen = true" style="margin-top: 3px;"><i class="fas fa-plus" style="font-size: 24px;"></i></span>
        <div>
            <div class="row">
                <div class="col-sm-9">
                    <section class="search search-inpage" :class="{active: searchActive}">
                        <form>
                            <input type="text" ref="search" v-bind:placeholder="profile.actionable.search_street" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="search($event)"  />
                            <span class="close-suggestions" @click="clearSearch()" v-if="searchActive"><i class="fas fa-times"></i></span>
                        </form>
                    </section>
                </div>
            </div>
            <div class="row" v-if="searchActive">
                <div class="col">
                    <h4 class="result-counter" v-if="!recurringJobs.length">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                    <h4 class="result-counter" v-if="recurringJobs.length >= 1">{{recurringJobs.length }} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <table class="table table-striped" v-if="recurringJobs.length">
                        <thead>
                            <tr>
                                <th scope="col" @click="sortTable('asset_name')" :class="{active: listsSortType === 'name'}">{{profile.incidents.new_incident.id_asset}} <i class="fas fa-sort"></i></th>
                                <th scope="col" @click="sortTable('description')" :class="{active: listsSortType === 'description'}">{{profile.actionable.action}} <i class="fas fa-sort"></i></th>
                                <th scope="col" @click="sortTable('frequency')" :class="{active: listsSortType === 'frequency'}">{{profile.actionable.frequency}} <i class="fas fa-sort"></i></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="job in recurringJobs" :key="job.id_recurring_action">
                                <td @click="editRepeatingAction(job)"><b>{{job.asset_name}}</b><br />{{job.address}} {{job.house_number}}, {{profile.incident_detail.floor_name}} {{job.floor_name}} <br />{{job.place_name}}</td>
                                <td @click="editRepeatingAction(job)" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{job.description}}</td>
                                <td @click="editRepeatingAction(job)">
                                    <span v-if="locale === 'nl' && job.frequency === 'WEEKLY'">Wekelijks</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'MONTHLY'">Maandelijks</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'QUARTERLY'">Per kwartaal</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'YEARLY'">Jaarlijks</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'TWO_YEARLY'">Elke 2 jaar</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'YEAR_AND_A_HALFLY'">Elke 1.5 jaar</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'THREE_YEARLY'">Elke 3 jaar</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'FOUR_YEARLY'">Elke 4 jaar</span>
                                    <span v-if="locale === 'nl' && job.frequency === 'FIVE_YEARLY'">Elke 5 jaar</span>
                                    <span v-if="locale === 'en' && job.frequency === 'WEEKLY'">Weekly</span>
                                    <span v-if="locale === 'en' && job.frequency === 'MONTHLY'">Monthly</span>
                                    <span v-if="locale === 'en' && job.frequency === 'QUARTERLY'">Quarterly</span>
                                    <span v-if="locale === 'en' && job.frequency === 'YEARLY'">Yearly</span>
                                    <span v-if="locale === 'en' && job.frequency === 'TWO_YEARLY'">Two yearly</span>
                                    <span v-if="locale === 'en' && job.frequency === 'THREE_YEARLY'">Three yearly</span>
                                    <span v-if="locale === 'en' && job.frequency === 'FOUR_YEARLY'">Four yearly</span>
                                    <span v-if="locale === 'en' && job.frequency === 'FIVE_YEARLY'">Five yearly</span>

                                </td>  
                                <td style="position: relative;"> 
                                    <div class="edit-button icon" style="margin: 10px 0 0 0;" @click="removeRepeatingAction(job)"><i class="far fa-trash-alt"></i></div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <RemoveRepeatingActionModal v-if="removeRepeatingActionModalOpen" :job="selectedJob" @close="removeRepeatingActionModalOpen = false" @success="getRecurringJobs()" />
        <EditRepeatingActionModal v-if="editRepeatingActionModalOpen" :selectedAction="selectedJob" @close="editRepeatingActionModalOpen = false" @success="getRecurringJobs()" />
        <NewRepeatingActionModal v-if="newRepeatingActionModalOpen" :selectedAction="selectedJob" @close="newRepeatingActionModalOpen = false" @success="getRecurringJobs()" />
   </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import debounce from 'lodash/debounce';
import RemoveRepeatingActionModal from "@/components/modals/recurring/RemoveRepeatingActionModal.vue";
import EditRepeatingActionModal from "@/components/modals/recurring/EditRepeatingActionModal.vue";
import NewRepeatingActionModal from "@/components/modals/recurring/NewRepeatingActionModal.vue";

export default {
    mixins: [setLocale],
    components: {
        RemoveRepeatingActionModal,
        EditRepeatingActionModal,
        NewRepeatingActionModal
    },
    data() {
        return {
            profile: {},
            recurringJobs: [],
            listsSortType: 'asset_name',
            selectedJob: {},
            searchActive: false,
            searchTerm: '',
            removeRepeatingActionModalOpen: false,
            editRepeatingActionModalOpen: false,
            newRepeatingActionModalOpen: false,
            sortOrder: 'asc'
        }
    },
    created() {
       this.getRecurringJobs();
    },
    methods: {
        search($event) {
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                this.getRecurringJobs();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.getRecurringJobs();
            }
            if($event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.getRecurringJobs();
            }
        },
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.getRecurringJobs();
        },
        getRecurringJobs: debounce(function (e) {
            this.$store.dispatch('updateGetRecurringJobs');
            this.removeRepeatingActionModalOpen = false;
            this.recurringJobs = [];
            let orderParam = '?sort_dir=' + this.sortOrder;
            let sortParam = '';
            if (this.listsSortType) {
                sortParam = '&sort_col=' + this.listsSortType;
            }
            let searchParam = '';
            if (this.searchTerm) {
                searchParam = '&search_term=' + this.searchTerm;
            }
            let self = this;
            fetch('/api/v1/recurringaction/searchnice' + orderParam + sortParam + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    self.recurringJobs = data.data;
                });
            });
        }, 500),
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.listsSortType = sorter;
            this.getRecurringJobs();
        },
        removeRepeatingAction(job) {
            this.selectedJob = job;
            this.removeRepeatingActionModalOpen = true;
        },
        editRepeatingAction(job) {
            this.selectedJob = job;
            this.editRepeatingActionModalOpen = true;
        }
    }
}
</script>
