<template>
    <div class="modal-wrapper" v-if="profile.assets">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-history"></i>{{profile.incident_detail.history_of_asset}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div v-if="!loading">
                    <p v-if="!incidents.length">{{profile.assets.no_history}}</p>
                    <table class="table table-striped" v-if="incidents.length">
                        <thead>
                            <tr>
                                <th scope="col">{{profile.incidents.incidenttable.id_incident}}</th>
                                <th scope="col">{{profile.s1mone.stickertable.created}}</th>
                                <th scope="col">{{profile.incidents.incidenttable.description}}</th>
                                <th scope="col">{{profile.incidents.incidenttable.status}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in incidents" :key="index" @click="toIncident(item.id_incident)">
                                <td>{{item.id_incident}}</td>
                                <td>{{item.date}}</td>
                                <td>{{item.incident_description}}</td>
                                <td v-if="!profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status]}}</td>
                                <td v-if="profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status].buildingmanager}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    name: 'assetHistoryModal',
    data() {
        return {
            profile: {},
            incidents: [],
            loading: false
        }
    },
    props: ['assetId'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        this.loading = true;
        let self = this;
        fetch('/api/v1/incidentcombined/previousincidentsbyasset?id_asset=' + this.assetId)
        .then(response => { response.json().then(
            function(data) {
                for (var i = 0; i < data.length; i++) {
                    let item = data[i];
                    item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                    self.incidents.push(item);
                }
                self.loading = false;
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.logBook = sortBy(this.logBook, sorter);
            if (this.sortOrder === 'desc') {
                this.logBook.reverse();
            }
        },
        toIncident(id) {
            this.$router.push('/buildingmanager/incident-detail?id=' + id);
        }
    }
}
</script>
